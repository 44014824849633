import { useGetClientCurrentLanguage } from "@/pages/clients/DynamicMenu/hooks/useGetClientCurrentLanguage";
import React from "react";
import { Dropdown, MenuProps } from "antd";
import { useLanguageLabels } from "./useLanguageLabels";
import { Flag } from "./components/Flag";
import { LanguageSwitcher, Label, LanguageRow } from "./styles";

interface LanguagesFlagsProps {
  languages?: string[];
  onClick?: (key: string) => void;
}

export const LanguagesFlags = ({ languages, onClick }: LanguagesFlagsProps) => {
  const { currentLanguage } = useGetClientCurrentLanguage();
  const translatedLanguages = useLanguageLabels(languages ?? []);

  const items: MenuProps['items'] = translatedLanguages?.map((language, index) => {
    return {
      key: index,
      label: (
        <LanguageRow onClick={() => onClick?.(language.key)}>
          <span>{language.label}</span>
          <Flag lang={language.key} />
        </LanguageRow>
      ),
    };
  }) || [];

  return (
    <LanguageSwitcher>
      <Dropdown menu={{ items }} placement="bottomRight" arrow trigger={['click']}>
        <Label>{currentLanguage?.toLocaleUpperCase()}</Label>
      </Dropdown>
    </LanguageSwitcher>
  );
};
