import { useState } from 'react';
import { useFetch } from '@/hooks/useFetch';
import { Coupon } from '@/models/Coupon';

const CLIENT_COUPON_CODE = 'clientCouponCode';

export const useCouponCode = (slug: string) => {
  const [couponCode, setCouponCode] = useState<string>();
  const [phone, setPhone] = useState<string>();

  const { data, ...rest } = useFetch<Coupon>({
    enabled: !!couponCode && !!phone,
    queryKey: [CLIENT_COUPON_CODE, couponCode],
    url: `api/coupon/validate/code?couponCode=${couponCode}&slug=${slug}&phone=${encodeURIComponent(
      phone ?? ''
    )}`,
  });

  return { data, setCouponCode, setPhone, ...rest };
};
