import React from "react";
import LazyLoad from "react-lazyload";
import { Company } from "../../../../../../../models/Company";
import { SummaryList } from "../../../../../../../components/SummaryList";

interface LogoLinksProps {
  company: Company | undefined;
}

const NONE = 'N/A';

const getImagePlaceholder = (url: string | undefined) => (
  url ? (
    <LazyLoad height={60} once={true}>
      <img
        className="image-thumbnail"
        src={url}
        alt={url}
      />
    </LazyLoad>
  ) : NONE
);

export const LogoLinks = ({ company }: LogoLinksProps) => (
  <SummaryList title="Logo/Cover">
    <SummaryList.Item>
      <SummaryList.Label>Logo</SummaryList.Label>
      <SummaryList.Value>{getImagePlaceholder(company?.logoUrl)}</SummaryList.Value>
    </SummaryList.Item>

    <SummaryList.Item>
      <SummaryList.Label>Cover</SummaryList.Label>
      <SummaryList.Value>{getImagePlaceholder(company?.coverUrl)}</SummaryList.Value>
    </SummaryList.Item>

    <SummaryList.Item>
      <SummaryList.Label>Logo landing page</SummaryList.Label>
      <SummaryList.Value>{getImagePlaceholder(company?.logoSiteUrl)}</SummaryList.Value>
    </SummaryList.Item>
  </SummaryList>
);
