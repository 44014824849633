import React from "react";
import { Checkbox, Skeleton } from "antd";
import { Language } from "@/models/Languages";
import { Container, Label } from "./styles";
import { useLanguages } from "./useLanguages";

interface LanguageListProps {
  selectedLanguages: Language[];
  onChange: (languages: Language[]) => void;
}

export const LanguageList = ({
  selectedLanguages = [],
  onChange,
}: LanguageListProps) => {
  const { data: languages, isLoading } = useLanguages();

  const onLanguageChange = (value: Language) => {
    const currentIndex = selectedLanguages.findIndex(l => l.key === value.key);
    const pickedLangs = [...selectedLanguages];

    if (currentIndex === -1) {
      pickedLangs.push(value);
    } else {
      pickedLangs.splice(currentIndex, 1);
    }

    onChange(pickedLangs);
  };

  if (isLoading) {
    return <Skeleton />
  }

  return (
    <Container>
      {languages?.map((lang) => (
        <div key={lang.key}>
          <Checkbox
            checked={selectedLanguages.some(selected => selected.key === lang.key)}
            onChange={() => onLanguageChange(lang)}
            value={lang}
          >
            <Label>{lang.label}</Label>
          </Checkbox>
        </div>
      ))}
    </Container>
  );
};
