import { ProductCatalog } from '../models/ProductCatalog';
import { useFetch } from './useFetch';

interface UseClientProductsProps {
  companyId: string | undefined;
  currentLanguage?: string;
}

const CLIENT_PRODUCTS = 'clientProducts';

export const useClientProducts = ({ companyId, currentLanguage }: UseClientProductsProps) => {
  const { data: products, isFetching } = useFetch<ProductCatalog[]>({
    enabled: !!companyId && !!currentLanguage,
    isPublicAPI: true,
    queryKey: [CLIENT_PRODUCTS, currentLanguage],
    url: `api/boot/products/${companyId}?lang=${currentLanguage}`,
  });

  return {
    isFetching,
    products,
  };
};
