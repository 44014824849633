import React from "react";
import { FormInputField } from "../../../../../../../components/FormInputField";
import { FormSubtitle } from "../../../../../../../common-styles";
import UploadForm from "../../../../../../../components/upload/UploadForm";

interface LogoAndCoverProps {
  logoUrl: string;
  coverUrl: string | undefined;
}

export const LogoAndCover = ({ logoUrl, coverUrl }: LogoAndCoverProps) => {
  return (
    <>
      <FormSubtitle>Logo</FormSubtitle>
      <UploadForm
        setUrl={(url: string) => console.log(url)}
        url={logoUrl}
        setUploadInProgress={() => {}}
      />

      {coverUrl && (
        <>
          <FormSubtitle>Imagem de capa</FormSubtitle>
          <UploadForm
            setUrl={(url: string) => console.log(url)}
            url={coverUrl}
            setUploadInProgress={() => {}}
          />
        </>
      )}

      <FormInputField
        help="Esta logo será usada em nosso site. Ao deixar vazio, esta loja não irá aparecer na lista de cliente em nosso site."
        label="Logo para o nosso site"
        name="logoSiteUrl"
        style={{ marginTop: 20 }}
      />
    </>
  );
}