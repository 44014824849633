import React, { ChangeEvent, useEffect, useState } from 'react';
import { Button, Checkbox, Drawer, Form, Input, Space } from 'antd';
import { FormRadioGroup } from '../../../../../../../../../../components/FormRadioGroup';
import { WeekdayList } from '@/components/WeekdaysList';
import { MenuTabOption } from '../../../../../../../../../../models/DataResponse';
import { HourErrorMessage } from '../../../../../../../../../../common-styles';
import { FormLabelField } from '../../../../../../../../../../components/FormLabelField';
import { TimePickers } from '@/components/TimePicker';
import { MIDNIGHT } from '@/utils/Constants';
import { useSaveMenuOptions } from '../../../../../hooks/useSaveMenuOptions';
import { AlertNotification } from '@/components/AlertNotification';
import { NotificationType } from '@/components/AlertNotification/NotificationType';

interface MenuOptionEditorProps {
  closeDrawer(): void;
  isOpen: boolean;
  featureId: string | undefined;
  menuOption?: MenuTabOption;
}

// TODO: Refactor this component to use the Form component from antd formSubmission, extract components to a separate file
export const MenuOptionEditor = ({
  closeDrawer,
  isOpen,
  featureId,
  menuOption,
}: MenuOptionEditorProps) => {
  const [formData, setFormData] = useState<MenuTabOption>(
    {} as MenuTabOption
  );
  const [isEnabled, setEnabled] = useState<boolean>(true);
  const [isEveryday, setIsEveryday] = useState<boolean>(true);
  const [isAllDay, setAllDay] = useState<boolean>(true);
  const [isSellViaWhatsappEnabled, setSellViaWhatsappEnabled] =
    useState<boolean>(true);
  const [startWeekdays, setStartWeekdays] = useState<number[]>([]);
  const [weekdays, setWeekdays] = useState<number[]>([]);
  const [hoursErrorMessage, setHoursErrorMessage] = useState<string>('');

  const { createOrUpdate } = useSaveMenuOptions();

  useEffect(() => {
    if (menuOption) {
      setFormData(menuOption);
      setAllDay(menuOption.allDay);
      setSellViaWhatsappEnabled(
        menuOption.isSellViaWhatsappEnabled
      );
      setIsEveryday(menuOption.weekdays === 'everyday');
      setStartWeekdays(
        menuOption.weekdays
          .split('|')
          .filter((day) => day)
          .map((day) => Number(day))
      );
    }
  }, [menuOption]);

  const handleAddNewMenuOption = () => {
    if (!formData.order) {
      formData.order = 1;
    }

    if (
      formData.endHour &&
      formData.startHour &&
      formData.endHour.localeCompare(formData.startHour) < 0
    ) {
      setHoursErrorMessage(
        'A hora final não pode ser inferior a hora inicial.'
      );
      return;
    }

    const localMenuOption = {
      _id: menuOption?._id ?? undefined,
      name: formData.name,
      icon: formData.icon ? formData.icon : '',
      order: formData.order,
      weekdays: getWeekdays(),
      startHour: formData.startHour ? formData.startHour : '',
      endHour: formData.endHour ? formData.endHour : '',
      allDay: formData.allDay,
      isEnabled,
      isSellViaWhatsappEnabled,
      featureId,
    } as MenuTabOption;

    createOrUpdate(localMenuOption, {
      onSuccess: () => {
        closeDrawer();
      },
      onError: () => {
        AlertNotification({
          message: 'Erro ao tentar salvar a aba',
          type: NotificationType.ERROR,
        });
      },
    });
  };

  const getWeekdays = () => {
    if (isEveryday || weekdays.length === 0) {
      return 'everyday';
    }

    return weekdays.join('|');
  };

  const handleInputChange = (event: ChangeEvent<HTMLInputElement>) => {
    const { id, value } = event.target;

    setFormData({
      ...formData,
      [id]: value,
    });
  };

  return (
    <Drawer
      destroyOnClose
      open={isOpen}
      onClose={closeDrawer}
      placement="right"
      size="default"
      title={menuOption ? 'Editar Aba' : 'Nova Aba'}
      footer={
        <Space direction="horizontal" style={{ width: '100%', justifyContent: 'space-around', alignItems: 'center', alignContent: 'center' }}>
          <Button danger onClick={() => closeDrawer()}>
            Cancelar
          </Button>

          <Button type="primary" onClick={handleAddNewMenuOption}>
            <i
              className="fa fa-check-circle"
              aria-hidden="true"
              style={{ marginRight: '10px' }}
            />
            Salvar
          </Button>
        </Space>
      }
    >
      <Form layout="vertical" initialValues={menuOption}>
        <FormRadioGroup
          label="Disponibilidade"
          propertyId="isEnabled"
          defaultValue={isEnabled}
          handleOnChange={(checked) => setEnabled(checked)}
        />
        <Form.Item
          label={<FormLabelField label="Nome" />}
          name="name"
          rules={[
            {
              required: true,
              message: 'Campo obrigatório',
            },
          ]}
        >
          <Input id="name" type="text" onChange={handleInputChange} style={{ height: '40px' }} />
        </Form.Item>

        <Form.Item
          name="order"
          label={<FormLabelField label="Ordem" />}
        >
          <Input id="order" type="text" onChange={handleInputChange} style={{ height: '40px' }} />
        </Form.Item>

        <Form.Item
          name="icon"
          label={<FormLabelField label="Ícone" />}
        >
          <Input id="icon" type="text" onChange={handleInputChange} style={{ height: '40px' }} />
        </Form.Item>

        <Space direction="vertical">
          <Checkbox
            id="isEveryday"
            checked={isEveryday}
            onChange={(e) => setIsEveryday(e.target.checked)}>
            Exibir todos os dias
          </Checkbox>

          {!isEveryday && (
            <div style={{ marginLeft: '16px' }}>
              <WeekdayList
                selectedWeekdays={startWeekdays}
                handleSelectedWeekdays={(days) => setWeekdays(days)}
              />
            </div>
          )}

          <Checkbox
            id="allDay"
            checked={isAllDay}
            onChange={(e) => {
              const checked = e.target.checked;
              setFormData({
                ...formData,
                allDay: checked,
              });
              setAllDay(checked);
            }}>
            Dia inteiro
          </Checkbox>

          {!isAllDay && (
            <Space direction='vertical'>
              <Space>
                <TimePickers
                  id={'startHourTemp'} // FIXME: remove Temp suffix when we start using the Form correctly, right now, the initialValues is setting the defaultValue as moment and it's raising an error.
                  label="Início"
                  defaultValue={formData.startHour || MIDNIGHT}
                  handleTimeChange={(_, value) => {
                    setFormData({
                      ...formData,
                      startHour: value,
                    });
                  }}
                />
                <TimePickers
                  id={'endHourTemp'}
                  label="Fim"
                  defaultValue={formData.endHour || MIDNIGHT}
                  handleTimeChange={(_, value) => {
                    setFormData({
                      ...formData,
                      endHour: value,
                    });
                  }}
                />
              </Space>

              <HourErrorMessage>{hoursErrorMessage}</HourErrorMessage>
            </Space>
          )}

          <Checkbox
            id="isSellViaWhatsappEnabledForAllTabs"
            checked={isSellViaWhatsappEnabled}
            onChange={(e) => setSellViaWhatsappEnabled(e.target.checked)}>
            Venda por WhatsApp
          </Checkbox>
        </Space>
      </Form>
    </Drawer>
  );
};
