import { ClientOrder } from "@/models/ClientOrder";
import { ClientOrderInfo } from "../models/ClientOrderInfo";
import { Coupon } from "../models/Coupon";
import { MenuConfiguration, ProductWhatsappOrder } from "../models/DataResponse";
import { getBrowserType, getOperatingSystem } from "./Util";

export interface OrderBody {
  additionalInformation: string;
  browser: string;
  client: ClientOrderInfo;
  companyId: string;
  companyName: string;
  coupon?: Coupon;
  currency: string;
  operatingSystem: string;
  products: ProductWhatsappOrder[];
  subtotal: number;
  total: number;
}

interface PrepareOrderToSendArgs {
  menuConfiguration: MenuConfiguration;
  order: ClientOrder;
}

export const prepareOrderToSend = ({ menuConfiguration, order }: PrepareOrderToSendArgs): OrderBody => {
  const { currency } = menuConfiguration.companyFeatures.sellsConfiguration;

  const { products, customerAddressInfo } = order;
  const isReal = currency === 'R$'; // TODO: https://github.com/uiltonjose/qrcodepreferido-app/issues/52

  return {
    additionalInformation: order.additionalInformation,
    browser: getBrowserType(),
    client: {
      name: order.name,
      phone: order.phone,
      address: order.address ? getAddressFormatted(order, isReal) : '',
      latitude: customerAddressInfo?.latitude,
      longitude: customerAddressInfo?.longitude,
      number: order.number || '',
      referencePoint: order.referencePoint || '',
      complement: order.complement || '',
      paymentType: order.paymentType,
      moneyChange: order.moneyChange || '',
      observation: order.observation,
      clientFare: order.clientFare,
      deliveryType: order.deliveryType,
      identification: order.taxData?.identification,
      scheduledDate: order.scheduledDate,
      scheduledTime: order.scheduledTime,
      canUseSameAddress: order.canUseSameAddress || false,
      taxData: order.taxData,
      email: order.email,
      instagram: order.instagram,
    },
    companyId: products[0].company,
    companyName: menuConfiguration.companyName,
    coupon: order.coupon,
    currency,
    products: products.map((item) => {
      return {
        _id: item._id,
        name: item.name,
        price: item.price,
        counter: item.counter,
        extraNote: item.extraNote || '',
        description: item.description || '',
        complements: item.selectedComplements || [],
      };
    }),
    operatingSystem: getOperatingSystem(),
    subtotal: order.subtotal || 0,
    total: order.totalCost || 0,
  };
}

const getAddressFormatted = (order: ClientOrder, isReal: boolean) => {
  let addressStr = order.address;

  if (order.number) {
    addressStr += ` - Nº ${order.number}`;
  }

  if (order.complement && !isReal) {
    addressStr += `, ${order.complement}`;
  }

  if (order.postalCode) {
    addressStr += `, ${order.postalCode}`;
  }

  return addressStr;
}
