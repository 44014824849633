import React from "react";
import { Company } from "../../../../../../../models/Company";
import { SummaryList } from "../../../../../../../components/SummaryList";
import { formatDatetime } from "../../../../../../../utils/dateTime";
import { Link } from "react-router-dom";
import { getClientMenuUrl } from "../../../../../../../utils/Util";

interface GeneralProps {
  company: Company | undefined;
}

export const General = ({ company }: GeneralProps) => (
  <SummaryList title="Geral">
    <SummaryList.Item>
      <SummaryList.Label>ID</SummaryList.Label>
      <SummaryList.Value>{company?._id}</SummaryList.Value>
    </SummaryList.Item>

    <SummaryList.Item>
      <SummaryList.Label>Código de Acesso</SummaryList.Label>
      <SummaryList.Value>{company?.accessCode}</SummaryList.Value>
    </SummaryList.Item>

    <SummaryList.Item>
      <SummaryList.Label>Link/Slug</SummaryList.Label>
      <SummaryList.Value>
        <Link to={getClientMenuUrl(company?.menuUrl ?? '')} target="_blank" style={{textDecoration: 'underline'}}>
          {company?.menuUrl}
        </Link>
      </SummaryList.Value>
    </SummaryList.Item>

    <SummaryList.Item>
      <SummaryList.Label>Data de criação</SummaryList.Label>
      <SummaryList.Value>{formatDatetime(company?.createdAt)}</SummaryList.Value>
    </SummaryList.Item>

    <SummaryList.Item>
      <SummaryList.Label>Última atualização</SummaryList.Label>
      <SummaryList.Value>{formatDatetime(company?.updatedAt)}</SummaryList.Value>
    </SummaryList.Item>


    <SummaryList.Item>
      <SummaryList.Label>Observação</SummaryList.Label>
      <SummaryList.Value>{company?.observation}</SummaryList.Value>
    </SummaryList.Item>
  </SummaryList>
);
