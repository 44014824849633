import React from 'react';
import { useTranslation } from 'react-i18next';
import { FormInputField } from '@/components/FormInputField';
import { AutocompleteField } from './AutocompleteField';

interface AddressInputProps {
  isAutocomplete?: boolean;
  onAddressSelected: (address: string) => void;
}

export const AddressInput = ({ isAutocomplete, onAddressSelected }: AddressInputProps) => {
  const { t } = useTranslation();
  return (
    <>
      {isAutocomplete ? (
        <AutocompleteField onAddressSelected={onAddressSelected} />
      ) : (
        <FormInputField
          label={t('order.address.address_input')}
          name="address"
          placeholder={t('order.address.address_input_hint')}
          useDefaultRules
        />
      )}
    </>
  );
}