import React from "react";
import { OrderTitleSection } from "../OrderTitleSection";
import { ChildrenContainer } from "./styles";

interface OrderSectionProps {
  title: string;
  subtitle?: string;
  children: React.ReactNode;
}

export const OrderSection = ({ title, subtitle, children }: OrderSectionProps) => (
  <>
    <OrderTitleSection title={title} subtitle={subtitle} />
    <ChildrenContainer>
      {children}
    </ChildrenContainer>
  </>
);
