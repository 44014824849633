import React from "react";
import shortid from 'shortid';

import { ProductStore } from "@/hooks/ProductStore/productsStore";
import { ProductMenu } from "@/models/ProductCatalog";
import { ProductRowContainer } from "./styles";
import { ProductOrderItem } from "./components/ProductOrderItem";
import { OrderComplementExhibition } from "@/models/DataResponse";
import { Separator } from "@/common-styles";

interface ProductsChosenProps {
  currency: string;
  orderComplementExhibition: OrderComplementExhibition;
  handleEditAction: (isUpdate: boolean, product: ProductMenu) => void;
}

export const ProductsChosen = ({ currency, orderComplementExhibition, handleEditAction }: ProductsChosenProps) => {
  const products = ProductStore.products;

  return (
    <>
      {products.map((product: ProductMenu) => (
        <ProductRowContainer key={shortid.generate() + product._id}>
          <ProductOrderItem
            key={shortid.generate() + product._id}
            product={product}
            currency={currency}
            orderComplementExhibition={orderComplementExhibition}
            handleDeleteAction={() => ProductStore.removeProduct(product.transientId)}
            handleEditAction={() => handleEditAction(true, product)}
          />

          <Separator />
        </ProductRowContainer>
      ))}
    </>
  );
};
