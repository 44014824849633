import React, { useEffect, useState } from 'react';
import isNil from 'lodash/isNil';
import { useTranslation } from 'react-i18next';
import { showInfoModal } from '@/utils/DisplayInfoModal';
import { DeliveryType } from '@/utils/DeliveryUtil';
import { ClientOrder } from '@/models/ClientOrder';
import { CompanyFeatures } from '../../../../../../../models/CompanyFeatures';
import { DeliveryFee } from '../../../../../../../models/DeliveryFee';
import { ProductMenu } from '@/models/ProductCatalog';
import { Coupon } from '@/models/Coupon';
import { useCustomerAddressInfo } from '../../hooks/useAddressAutocomplete';
import { useFormOrderInstance } from '../../hooks/useFormOrderContext';
import { FormInputField } from '@/components/FormInputField';
import { DeliveryTypeRadioGroup } from './components/DeliveryType';
import { CouponInput } from './components/CouponInput';
import { OrderCost } from '../OrderCost';
import { PaymentMethods } from './components/PaymentMethods';
import { ClientAddress } from './components/ClientAddress';
import { TaxData } from './components/TaxData';
import { DeliveryFeeFields } from './components/DeliveryFee';
import { ScheduleOrder } from './components/ScheduleOrder';
import { CustomInformationWrapper } from './components/CustomInformation';
import { Identification } from './components/Identification';
import { OrderSection } from '../OrderSection';

interface ClientOrderFormProps {
  companyFeatures: CompanyFeatures;
  handleEditSelectedProduct: (isUpdate: boolean, product: ProductMenu) => void;
  previousOrderCustomerData?: ClientOrder;
  slug: string;
}

export const ClientOrderForm = ({ companyFeatures, handleEditSelectedProduct, previousOrderCustomerData, slug }: ClientOrderFormProps) => {
  const form = useFormOrderInstance();
  const { sellsConfiguration } = companyFeatures;
  const [coupon, setCoupon] = useState<Coupon | undefined>();
  const [selectedAddress, setSelectedAddress] = useState<string>('');
  const [clientPhone, setClientPhone] = useState<string>();
  const [deliveryFee, setDeliveryFee] = useState<DeliveryFee>();
  const [deliveryType, setDeliveryType] = useState<string>();
  const [useSameAddress, setUseSameAddress] = useState<boolean>(true);
  const currency = sellsConfiguration.currency || 'BRL';
  const canShowAddressFields = deliveryType === DeliveryType.DELIVERY;
  const { t } = useTranslation();

  const { data: customerAddressInfo } = useCustomerAddressInfo({
    address: selectedAddress,
    isAutocompleteEnabled: !isNil(companyFeatures.location?.radius),
    storeAddress: companyFeatures.location?.address,
    radius: companyFeatures.location?.radius,
    slug,
  });

  useEffect(() => {
    if (previousOrderCustomerData?.deliveryType) {
      setDeliveryType(previousOrderCustomerData.deliveryType);
      return;
    }
    
    const { hasDelivery, hasPickUp } = sellsConfiguration;

    if (hasDelivery) return setDeliveryType(DeliveryType.DELIVERY);
    if (hasPickUp) return setDeliveryType(DeliveryType.TAKE_AWAY);

    setDeliveryType(DeliveryType.EAT_IN_LOCO);
  }, [previousOrderCustomerData?.deliveryType, sellsConfiguration]);

  useEffect(() => {
    if (customerAddressInfo && companyFeatures.location?.radius) {
      form.setFieldsValue({ customerAddressInfo });

      if (!customerAddressInfo.isAddressAvailable) {
        showInfoModal({
          message: t('order.delivery.out_of_radius')
        });
      }
    }

  }, [companyFeatures.location, customerAddressInfo, form, t]);

  return (
    <>
      <DeliveryTypeRadioGroup
        sellsConfiguration={sellsConfiguration}
        onDeliveryChange={(deliveryType) => setDeliveryType(deliveryType)}
      />

      <CustomInformationWrapper customInformation={sellsConfiguration.customInformation} />

      <ScheduleOrder sellsConfiguration={sellsConfiguration} hintMessagePreSchedule={companyFeatures.hintMessageForDateTimePicker} />

      {canShowAddressFields && (
        <>
          <DeliveryFeeFields
            currency={currency}
            sellsConfiguration={sellsConfiguration}
            onDeliveryFeeChange={(fee) => setDeliveryFee(fee)} />

          <ClientAddress
            radius={companyFeatures.location?.radius}
            onAddressSelected={(address) => setSelectedAddress(address)}
          />
        </>
      )}

      <Identification
        sellsConfiguration={sellsConfiguration}
        onPhoneChanged={(phone) => setClientPhone(phone)}
      />

      {sellsConfiguration.isTaxDataVisible && (
        <TaxData
          canShowAddressFields={canShowAddressFields}
          sellsConfiguration={sellsConfiguration}
          useSameAddress={useSameAddress}
          setUseSameAddress={setUseSameAddress}
          phone={clientPhone}
        />
      )}

      {sellsConfiguration.showCouponFeature && (
        <CouponInput
          onCouponResult={(coupon) => setCoupon(coupon)}
          slug={slug}
        />
      )}

      <OrderSection title={t('common.products')}>
        <OrderCost
          coupon={coupon}
          deliveryFee={deliveryFee}
          sellsConfiguration={sellsConfiguration}
          handleEditAction={handleEditSelectedProduct}
        />
      </OrderSection>

      <PaymentMethods
        currency={currency}
        paymentMethods={sellsConfiguration.paymentMethods}
      />

      <FormInputField
        label={t('order.general_observations')}
        name="observation"
        placeholder={t('order.general_observations')}
        maxLength={300}
        isInputArea={true}
        inputTextAreaProps={{ showCount: true, rows: 5 }}
        style={{ margin: '0 16px' }}
      />
    </>
  );
};
