import React, { useState, useEffect, useMemo } from 'react';
import * as Sentry from '@sentry/browser';

import { Button, Form, Select, Tabs } from 'antd';

import { ContentHeader } from '../../../../components/ContentHeader';
import { FormRadioGroup } from '../../../../components/FormRadioGroup';
import UploadForm from '../../../../components/upload/UploadForm';

import api from '../../../../services/api';
import { CompanyActivityLogService } from '../../../../services/CompanyActivityLogService';
import { Features } from '../../../../models/Features';
import { Category } from '@/models/Category';
import { ComplementOfProduct } from '../../../../models/ProductCatalog';
import { Product } from '../../../../models/Product';
import { FormLayout } from '../../../../utils/Styles';

import ComplementList from './Tabs/Complements';
import { ProductComplement } from './Tabs/Complements/ComplementPage';
import { PriceAndPromotion } from './Tabs/PriceAndPromotion';
import { ProductStock } from './Tabs/Stock';

import { PageDetailsButtonsContainer } from '../../../../common-styles';
import { useProduct } from '../../../../hooks';
import { AlertNotification } from '@/components/AlertNotification';
import { NotificationType } from '@/components/AlertNotification/NotificationType';
import { InputTitleAndDescription } from '@/components/InputTitleAndDescription';

interface AddOrUpdateProductProps {
  categories: Category[];
  company: string;
  features?: Features;
  pageProductDismissed(): void;
  preFetchCategoryId: string;
  selectedProduct?: Product;
};

enum Tab {
  DETAILS = 'Detalhes',
  PRICE = 'Preço/Promoção',
  COMPLEMENTS = 'Complementos',
  STOCK = 'Estoque',
}

const promotionOptions = [
  { label: 'Sem promoção', value: false },
  { label: 'Com promoção', value: true },
];

const { TabPane } = Tabs;
const { Option } = Select;

const AddOrUpdateProduct = ({
  categories,
  company,
  features,
  pageProductDismissed,
  preFetchCategoryId,
  selectedProduct,
}: AddOrUpdateProductProps) => {
  const [imageUrl, setUrl] = useState(
    selectedProduct ? selectedProduct.imageUrl : ''
  );
  const [isEnabled, setEnabled] = useState<boolean>(
    selectedProduct ? selectedProduct.isEnabled : true
  );
  const [selectedCategoryId, setSelectedCategory] = useState<string>(
    selectedProduct ? selectedProduct.categoryId : preFetchCategoryId
  );
  const [isUploadInProgress, setUploadInProgress] = useState<boolean>(false);
  const [hasPromotion, setHasPromotion] = useState<boolean>(false);
  const [promotionType, setPromotionType] = useState<boolean>(
    selectedProduct?.promotion?.discountPercent ? false : true
  );
  const [isShowingComplement, setShowingComplement] = useState<boolean>(false);
  const [currentTab, setCurrentTab] = useState(Tab.DETAILS);
  const [complements, setComplements] = useState<ComplementOfProduct[]>([]);
  const [selectedComplement, setSelectedComplement] = useState<
    ComplementOfProduct | any
  >(null);

  const [form] = Form.useForm();
  const { create, isLoadingCreate, isLoadingUpdate, update } = useProduct();
  const isLoading = isLoadingCreate || isLoadingUpdate;

  useEffect(() => {
    if (selectedProduct) {
      if (
        selectedProduct.promotion &&
        selectedProduct.promotion.oldPrice &&
        selectedProduct.promotion.newPrice
      ) {
        setHasPromotion(true);
      }

      if (selectedProduct.complements) {
        setComplements(
          selectedProduct.complements.map((item, index) => {
            return {
              ...item,
              index,
            };
          })
        );
      }
    }
  }, [selectedProduct]);

  const isClone = useMemo(
    () => (selectedProduct && !selectedProduct._id ? true : false),
    [selectedProduct]
  );

  function dropdownListHandler(selectedCategoryId: string) {
    setSelectedCategory(selectedCategoryId);
  }

  function onFinishFailed() {
    setCurrentTab(Tab.DETAILS);
  }

  function onFinish(values: any) {
    addOrUpdateProduct(values);
  }

  const addOrUpdateProduct = async (values: any) => {
    const formData = {
      ...(selectedProduct || {}),
      ...values,
      imageUrl,
      isEnabled,
      categoryId: selectedCategoryId,
      translations: {
        name: {
          ...(selectedProduct?.translations?.name || {}),
          ...(values.translations?.name || {})
        },
        description: {
          ...(selectedProduct?.translations?.description || {}),
          ...(values.translations?.description || {})
        }
      }
    } as Product;

    if (complements && complements.length > 0) {
      complements
        .filter((item) => item.isTransientData)
        .forEach((complement) => delete complement._id);
      formData.complements = complements;
    } else {
      formData.complements = [];
    }

    if (hasPromotion) {
      formData.price = values.promotion
        ? values.promotion.newPrice
        : selectedProduct?.promotion?.newPrice;
      formData.promotion = values.promotion ?? selectedProduct?.promotion;
    } else {
      formData.promotion = {} as any;

      if (isClone && !formData.price) {
        formData.price = selectedProduct?.price ?? '0';
      }
    }

    if (selectedProduct && !isClone) {
      update({
        ...formData,
        _id: selectedProduct._id,
        company: selectedProduct.company,
      },
        {
          onSuccess: () => {
            AlertNotification({
              message: 'Operação realizada com sucesso',
            });
            pageProductDismissed();
          },
          onError: () => {
            AlertNotification({
              message: 'Erro ao tentar atualizar produto',
              type: NotificationType.ERROR,
            });
          }
        },
      );
    } else {
      create(
        { ...formData, company },
        {
          onSuccess: () => {
            AlertNotification({
              message: 'Operação realizada com sucesso',
            });
            pageProductDismissed();
          },
          onError: () => {
            AlertNotification({
              message: 'Erro ao cadastrar Produto',
              type: NotificationType.ERROR,
            });
          }
        },
      );
    }
  }

  const handleComplement = (
    complement: ComplementOfProduct,
    isUpdate: boolean
  ) => {
    if (isUpdate) {
      const index = complements.indexOf(selectedComplement);
      complements[index] = complement;
      setSelectedComplement(null);
      setComplements(complements);

      if (selectedProduct && !isClone) {
        dispatchUpdateForComplements(complements);
      }
    } else {
      setComplements([...complements, complement]);
    }
  }

  async function dispatchUpdateForComplements(
    complements: ComplementOfProduct[]
  ) {
    const formData = { ...form.getFieldsValue() };

    if (complements && complements.length > 0) {
      complements
        .filter((item) => item.isTransientData)
        .forEach((complement) => delete complement._id);
      formData.complements = complements;
    } else {
      formData.complements = [];
    }

    try {
      const response = await api.put(
        `api/catalog/${selectedProduct?._id}`,
        formData,
        {
          headers: {
            company: company,
          },
        }
      );

      if (response) {
        CompanyActivityLogService.register({ action: 'Complementos atualizados', actionArea: 'Produtos', extra: JSON.stringify(complements) });

        AlertNotification({
          message: 'Complementos salvos.',
        });
      }
    } catch (e) {
      AlertNotification({
        message: 'Erro ao tentar atualizar complementos.',
        type: NotificationType.ERROR,
      });
      Sentry.captureException(e);
    }
  }

  const onTabChanged = (key: string) => setCurrentTab(key as Tab);

  const getPageTitle = () => {
    return isClone
      ? `Copia de ${selectedProduct?.name}`
      : selectedProduct
        ? selectedProduct.name
        : 'Novo Produto';
  }

  return (
    <>
      <ContentHeader title={getPageTitle()} />

      <Form
        {...FormLayout}
        form={form}
        layout="vertical"
        initialValues={selectedProduct}
        onFinish={onFinish}
        onFinishFailed={onFinishFailed}
        style={{ paddingBottom: '80px' }}
      >
        <Tabs size="large" activeKey={currentTab} onChange={onTabChanged}>
          <TabPane tab={Tab.DETAILS} key={Tab.DETAILS}>
            <FormRadioGroup
              label="Disponibilidade"
              propertyId="isEnabled"
              defaultValue={isEnabled}
              handleOnChange={(checked) => setEnabled(checked)}
            />

            <Form.Item
              label={<label className="label-input-field">Categoria</label>}
            >
              <Select
                defaultValue={selectedCategoryId}
                value={selectedCategoryId}
                onChange={dropdownListHandler}
              >
                <Option value="" disabled>
                  Selecione uma categoria
                </Option>

                {categories.map((category) => (
                  <Option key={category._id} value={category._id}>
                    {category.category}
                  </Option>
                ))}
              </Select>
            </Form.Item>

            <InputTitleAndDescription
              features={features}
              defaultTitleId="name"
              useRichText
            />

            <Form.Item
              label={
                <label className="label-input-field">Imagem do produto</label>
              }
              name="imageUrl"
            >
              <UploadForm
                setUrl={(url: string) => setUrl(url)}
                url={imageUrl}
                setUploadInProgress={setUploadInProgress}
              />
            </Form.Item>
          </TabPane>

          <TabPane tab={Tab.PRICE} key={Tab.PRICE}>
            <FormRadioGroup
              defaultValue={hasPromotion}
              handleOnChange={(checked) => setHasPromotion(checked)}
              label="Tipo de preço"
              options={promotionOptions}
              propertyId="hasPromotion"
            />

            <PriceAndPromotion
              hasPromotion={hasPromotion}
              promotionType={promotionType}
              setPromotionType={setPromotionType}
              formRef={form}
            />
          </TabPane>

          <TabPane tab={Tab.COMPLEMENTS} key={Tab.COMPLEMENTS}>
            <ComplementList
              complements={complements}
              handleShowComplementPage={() => setShowingComplement(true)}
              onComplementsListChanged={(complements) =>
                setComplements(complements)
              }
              handleEditComplement={(complement) => {
                setSelectedComplement(complement);
                setShowingComplement(true);
              }}
              handleCloneComplement={(complement) => {
                const clonedObject = { ...complement };
                delete clonedObject._id;

                clonedObject.complementItemOptions.forEach(
                  (option: any) => delete option._id
                );
                clonedObject.index = complements.length - 1;

                setComplements([...complements, clonedObject]);

                AlertNotification({
                  message: 'Complementos duplicado.',
                });
              }}
              handleDeleteComplement={(complement) => {
                const index = complements.indexOf(complement);

                const list = [...complements];
                list.splice(index, 1);
                setComplements(list);

                AlertNotification({
                  message: 'Complementos removido.',
                });

                if (selectedProduct && !isClone) {
                  dispatchUpdateForComplements(list);
                }
              }}
            />
          </TabPane>

          {features?.sellViaWhatsapp && (
            <TabPane tab={Tab.STOCK} key={Tab.STOCK}>
              <ProductStock stock={selectedProduct?.stock} />
            </TabPane>
          )}
        </Tabs>

        <PageDetailsButtonsContainer
          style={{ display: 'flex', float: 'right' }}
        >
          <Button
            danger
            style={{ marginRight: '10px' }}
            onClick={pageProductDismissed}
          >
            <i
              className="fa fa-times"
              aria-hidden="true"
              style={{ marginRight: '10px' }}
            />
            Cancelar
          </Button>

          <Button
            disabled={isUploadInProgress}
            type="primary"
            htmlType="submit"
            loading={isLoading}
          >
            <i
              className="fa fa-check-circle"
              aria-hidden="true"
              style={{ marginRight: '10px' }}
            />
            Salvar
          </Button>
        </PageDetailsButtonsContainer>

      </Form>

      {/* TODO: This piece of code should be moved along to the ComplementList file */}
      <ProductComplement
        companyId={company}
        isComplementPageVisible={isShowingComplement}
        onClose={() => {
          setSelectedComplement(null);
          setShowingComplement(false);
        }}
        handleComplementSubmission={handleComplement}
        selectedComplement={selectedComplement}
        hasSellViaWhatsappEnabled={features?.sellViaWhatsapp ?? false}
      />
    </>
  );
};

export default AddOrUpdateProduct;
