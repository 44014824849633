import React from "react";
import { Button } from "antd";
import { Container } from "./styles";
import { useTranslation } from "react-i18next";

interface FeedbackFooterProps {
  onClose: () => void;
  handleSubmit: () => void;
}

export const FeedbackFooter = ({ handleSubmit, onClose }: FeedbackFooterProps) => {
  const { t } = useTranslation();

  return (
    <Container>
      <Button type='primary' danger onClick={onClose}>
        <i
          className="fa fa-close"
          aria-hidden="true"
          style={{ marginRight: '10px' }}
        />{' '}
        {t('common.cancel')}
      </Button>

      <Button
        type="primary"
        onClick={() => handleSubmit()}
      >
        <i
          className="fa fa-paper-plane"
          aria-hidden="true"
          style={{ marginRight: '10px' }}
        />{' '}
        {t('common.send')}
      </Button>
    </Container>
  );
};
