import React from "react";
import { Button, Divider, Form, Select } from "antd";
import { CaretDownOutlined } from '@ant-design/icons';

import { SelectInputContainer } from "./styles";
import { SellsConfiguration } from "../../../../../../models/SellsConfiguration";
import { FormSwitchItem } from "../../../../../../components/FormSwitchItem";
import { FormHelper } from "../../../../../../components/FormHelper";
import { NamePath } from "@/components/FormInputField";

interface NotificationTabProps {
  handleCheckboxChange(checkedValue: boolean, switchId: string | NamePath): void;
  onSettingsChanged(sellsConfiguration: SellsConfiguration): void;
  sellsConfiguration: SellsConfiguration;
}

const audios = () => [
  {
    value: 'service_bell.mp3',
    label: 'Campainha',
  },
  {
    value: 'phone_ringing.mp3',
    label: 'Telefone',
  },
];

export const NotificationTab = ({ handleCheckboxChange, onSettingsChanged, sellsConfiguration }: NotificationTabProps) => {
  return (
    <>
      <Form.Item
        label={<label className="label-input-field">Som do pedido</label>}
        name="ringing"
        colon={true}
        help={<FormHelper label="Ao chegar um novo pedido, é imitido um som, para notificar o recebimento." />}
      >
        <SelectInputContainer>
          <Select
            className='select'
            defaultValue={sellsConfiguration?.ringing?.song}
            onChange={(song) => {
              sellsConfiguration.ringing = {
                song
              }
              onSettingsChanged(sellsConfiguration);
            }}
            suffixIcon={<CaretDownOutlined />}
          >
            {audios().map((audio) => (
              <Select.Option
                key={audio.value}
                value={audio.value}
              >
                {audio.label}
              </Select.Option>
            ))}
          </Select>

          <Button
            type="dashed"
            className='play-button'
            onClick={() => {
              const media = require(`../../../../../../assets/sounds/${sellsConfiguration.ringing.song}`);
              const audio = new Audio(media);
              audio.play();
            }}>
            <i
              className="fa fa-play"
              aria-hidden="true"
              style={{ marginRight: '10px' }}
            />
            Tocar
          </Button>
        </SelectInputContainer>
      </Form.Item>

      <Divider />
      <FormSwitchItem
        label="Tocar até aceitar pedido?"
        propertyId="playUntilAccept"
        helper="Ao ativar essa opção, ao receber um novo pedido, o toque continuará até o pedido ser aceite."
        defaultChecked={sellsConfiguration.ringing.playUntilAccept ?? false}
        handleCheckboxChange={handleCheckboxChange}
      />
    </>
  );
}