import { useQueryClient } from 'react-query';
import { useEffect } from 'react';
import { useFetch } from '@/hooks/useFetch';
import { BootClientSetup, MenuConfiguration } from '@/models/DataResponse';
import { CLIENT_CONFIGURATION } from './useMenuConfiguration';
import i18n from '@/i18n';

interface UseBootClientSetupProps {
  slug: string | undefined;
  currentLanguage?: string;
}

const BOOT_CLIENT_SETUP = 'bootClientSetup';

export const useBootClientSetup = ({ currentLanguage, slug }: UseBootClientSetupProps) => {
  const queryClient = useQueryClient();

  const { data, ...rest } = useFetch<BootClientSetup>({
    enabled: !!slug && !!currentLanguage,
    isPublicAPI: true,
    queryKey: [BOOT_CLIENT_SETUP, currentLanguage],
    url: `api/boot/setup/${slug}?lang=${currentLanguage}`,
    refetchOnWindowFocus: true,
    staleTime: 1000 * 60 * 30, // 30 minutes
  });

  useEffect(() => {
    if (!data) return;

    queryClient.setQueryData<MenuConfiguration>(CLIENT_CONFIGURATION, data.configuration);

    if (!data.configuration.modules.isMultilanguageEnabled) {
      i18n.changeLanguage('pt');
    }
  }, [data, queryClient]);

  return {
    data,
    isCountByProduct: data?.configuration?.companyFeatures?.sellsConfiguration?.orderCountType === 'BY_PRODUCT',
    ...rest
  };
};
