import { ProductStore } from "@/hooks/ProductStore/productsStore";
import { useCallback, useState } from "react";
import { ClientOrder } from "@/models/ClientOrder";
import { DeliveryType } from "@/utils/DeliveryUtil";
import { Address, TaxData } from "@/models/DataResponse";
import { CARD_PAYMENT_TYPE, CASH_MONEY, MBWAY_PAYMENT_TYPE } from "@/utils/Constants";
import { CompanyFeatures } from "@/models/CompanyFeatures";
import { getOperatingSystem } from "@/utils/Util";
import { prepareOrderToSend } from "@/utils/PrepareOrderToSendUtil";
import { EasyPayPaymentType, useEasypayPayment } from "../../../hooks/useEasypayPayment";
import { EasypayPaymentResponse } from "../models/EasypayPayment";
import { useOrderRequest } from "../../../hooks/useOrderRequest";
import { useOrderSendMessage } from "../../../hooks/useOrderSendMessage";
import { usePaymentProcess } from "@/hooks/usePaymentProcess";
import { LocalStorageService, PAYMENT_EASYPAY } from "@/services/LocalStorageService";
import { showInfoModal } from "@/utils/DisplayInfoModal";
import { useMenuConfiguration } from "../../../hooks/useMenuConfiguration";

interface UseFormSubmissionProps {
  slug: string;
  companyFeatures: CompanyFeatures;
}

export const useFormSubmission = ({ slug, companyFeatures }: UseFormSubmissionProps) => {
  const [paymentCardUrl, setPaymentCardUrl] = useState<string>();
  const [paymentMethod, setPaymentMethod] = useState<string>();
  const { menuConfiguration } = useMenuConfiguration();
  const { setOrder: setOrderPayment, isLoading: isLoadingOrderSendMessage } = useOrderSendMessage(slug);
  const { submitPayment: submitPaymentEasypay } = useEasypayPayment();
  const { isLoading: isLoadingOrderRequest, isRequestCompleted, executeOrderRequest, setIsRequestCompleted } = useOrderRequest(slug);
  const { sellsConfiguration, isEasypayEnabled } = companyFeatures;

  const { isWaitingPayment, setIsWaitingPayment } = usePaymentProcess((isSuccess: boolean, paymentPayload: EasypayPaymentResponse | null) => {
    if (isSuccess) {
      setOrderPayment(paymentPayload?.order);
      ProductStore.clearProducts();
    } else {
      const isMbway = paymentMethod === MBWAY_PAYMENT_TYPE;
      const message = isMbway ? 'verifique se o seu número está correto, ou se o pagamento foi validado na app do MBway.' :
        'verifique se o seu cartão está correto, ou se o pagamento foi rejeitado na app do seu banco.';

      showInfoModal({ title: 'Falha no pagamento', message: `Ocorreu um erro ao processar o pagamento, ${message}` });
      LocalStorageService.clear(PAYMENT_EASYPAY);
    }

    setIsRequestCompleted(true);
  });

  const processEasypayPayment = useCallback((order: ClientOrder, isMbway: boolean) => {
    if (isEasypayEnabled && menuConfiguration && (isMbway || CARD_PAYMENT_TYPE.includes(order.paymentType.toUpperCase()))) {

      const isDesktop = getOperatingSystem() === 'Desktop';

      setIsWaitingPayment(isDesktop || isMbway);

      const orderBody = prepareOrderToSend({ menuConfiguration, order });

      submitPaymentEasypay({
        amount: order.totalCost,
        customer: {
          name: order.name,
          email: order.email,
          phone: order.phone.substring(4),
          phone_indicative: order.phone.substring(0, 4),
          fiscal_number: order.taxData?.identification && `PT${order.taxData?.identification}`,
          key: 'final-customer',
          language: 'PT',
        },
        deliveryType: order.deliveryType,
        method: isMbway ? EasyPayPaymentType.MBWAY : EasyPayPaymentType.CREDIT_CARD,
        slug,
        order: orderBody,
      }, {
        onSuccess: (data: EasypayPaymentResponse) => {
          const paymentUrl = data.method.url;
          if (paymentUrl) {
            if (isDesktop) {
              window.open(paymentUrl, '_blank');
            } else {
              setPaymentCardUrl(paymentUrl);
            }
          }
        },
        onError: () => {
          setIsWaitingPayment(false);
          showInfoModal({ title: 'Falha no pagamento', message: 'Ocorreu um erro ao processar o pagamento, por favor tente novamente.' });
        },
      });
    } else {
      executeOrderRequest(order);
    }
  }, [executeOrderRequest, isEasypayEnabled, menuConfiguration, setIsWaitingPayment, slug, submitPaymentEasypay]);

  const handleClientOrderSubmit = useCallback((order: ClientOrder, scheduledDate?: string, scheduledTime?: string) => {
    order.products = ProductStore.products;
    order.scheduledDate = scheduledDate || '';
    order.scheduledTime = scheduledTime || '';

    if (!CASH_MONEY.includes(order.paymentType)) order.moneyChange = '';

    if (order.deliveryType === DeliveryType.TAKE_AWAY || order.deliveryType === DeliveryType.EAT_IN_LOCO) {
      order.clientFare = null;
      order.address = '';
    } else {
      if (order.canUseSameAddress) {
        if (!order.taxData || order.taxData === undefined) {
          order.taxData = {} as TaxData;
        }
        order.taxData.fiscalAddress = {
          address: order.address,
          number: order.number,
          postalCode: order.postalCode,
        } as Address;
      }

      if (order.deliveryType === undefined) {
        order.deliveryType = sellsConfiguration.hasDelivery ? DeliveryType.DELIVERY : DeliveryType.TAKE_AWAY;
      }
    }

    setPaymentMethod(order.paymentType);

    const isMbway = order.paymentType.toUpperCase() === MBWAY_PAYMENT_TYPE;
    if (isEasypayEnabled && menuConfiguration && (isMbway || CARD_PAYMENT_TYPE.includes(order.paymentType.toUpperCase()))) {
      processEasypayPayment(order, isMbway);
    } else {
      executeOrderRequest(order);
    }
  }, [executeOrderRequest, isEasypayEnabled, menuConfiguration, processEasypayPayment, sellsConfiguration.hasDelivery]);

  return {
    handleClientOrderSubmit,
    isLoadingOrderRequest,
    isLoadingOrderSendMessage,
    isRequestCompleted,
    isWaitingPayment,
    paymentCardUrl,
    paymentMethod,
    setPaymentCardUrl,
    setIsWaitingPayment,
  };
};
