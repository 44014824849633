import React from "react";
import { useTranslation } from "react-i18next";
import { FeedbackRateButton } from "@/components/FeedbackRateButton";
import { Company } from "@/models/Company";
import { MenuConfiguration } from "@/models/DataResponse";
import { CoverContent, LogoContent, ScheduleAndFeedbackContainer, SeeMoreButton } from "../../styles";
import { LanguagesFlags } from "../EstablishmentHeaderV2/components/LanguagesFlags";

interface EstablishmentHeaderV1Props {
  company: Company;
  configuration: MenuConfiguration;
  changeLanguage: (lang: string) => void;
  toggleAboutUsDrawer: () => void;
  titleColor: string;
  toggleFeedbackDrawer: () => void;
}

export const EstablishmentHeaderV1 = ({
  company,
  configuration,
  changeLanguage,
  toggleAboutUsDrawer,
  titleColor,
  toggleFeedbackDrawer,
}: EstablishmentHeaderV1Props) => {
  const { t } = useTranslation();

  return (
    <>
      {company?.coverUrl && (
        <div className="header">
          <CoverContent
            src={company.coverUrl}
            alt="Cover"
            dimens={company.styles?.coverDimens}
          />

          {configuration.modules.isMultilanguageEnabled && (
            <LanguagesFlags
              languages={configuration.languages.map((lang) => lang.key)}
              onClick={(lang) => changeLanguage(lang)} />
          )}
        </div>
      )}

      <div className="menuonline-header-info">
        {company?.logoUrl && (
          <LogoContent
            src={company.logoUrl}
            alt="menuonline"
            dimens={company.styles?.logoDimens}
          />
        )}
      </div>

      <ScheduleAndFeedbackContainer>
        <FeedbackRateButton
          customColor={titleColor}
          feedbackConfig={configuration.companyFeatures.feedback}
          onClick={toggleFeedbackDrawer} />

        {configuration.companyFeatures?.openingHours !== undefined &&
          configuration.companyFeatures?.openingHours?.length > 0 && (
            <>
              <SeeMoreButton
                color={titleColor}
                onClick={toggleAboutUsDrawer}
              >
                <i
                  className="fa fa-info-circle"
                  aria-hidden="true"
                  style={{ marginRight: '2px' }}
                />{' '}
                {t('schedules')}
              </SeeMoreButton>
            </>
          )}
      </ScheduleAndFeedbackContainer>
    </>
  );
};
