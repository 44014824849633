import React, { useState } from 'react';
import { MenuTabOption } from '@/models/DataResponse';
import { MenuOptionsTable } from '../MenuOptionsTable';
import { MenuOptionEditor } from '../MenuOptionEditor';

interface MenuTabOptionsProps {
  onClose: () => void;
  onOpen: () => void;
  isOpen: boolean;
  featureId: string | undefined;
  previousMenuOptions: MenuTabOption[];
}

export const MenuTabOptions = ({ onClose, onOpen, isOpen, featureId, previousMenuOptions }: MenuTabOptionsProps) => {
  const [selectedMenuOption, setSelectedMenuOption] = useState<MenuTabOption>();

  return (
    <>
      <MenuOptionEditor
        featureId={featureId}
        menuOption={selectedMenuOption}
        closeDrawer={() => {
          setSelectedMenuOption(undefined);
          onClose();
        }}
        isOpen={isOpen}
      />

      <MenuOptionsTable
        menuOptions={previousMenuOptions}
        handleEditMenuOption={(option) => {
          setSelectedMenuOption(option);
          onOpen();
        }}
      />
    </>
  );
};
