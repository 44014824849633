import React from "react";
import { Dropdown, MenuProps } from "antd";

interface TableContextMenuPros {
  menuProps: MenuProps;
  isButtonType?: boolean;
  primaryOption?: React.ReactNode;
  handlePrimaryClick?: () => void;
}

export const TableContextMenu = ({ menuProps, isButtonType, primaryOption, handlePrimaryClick }: TableContextMenuPros) => {
  return (
    isButtonType ?
      <Dropdown.Button menu={menuProps} onClick={handlePrimaryClick}>
        {primaryOption}
      </Dropdown.Button> :
      <Dropdown menu={menuProps}>
        <div style={{ cursor: 'pointer', width: 100 }}>
          <i
            className="fa fa-ellipsis-v"
            aria-hidden="true"
            style={{ fontSize: '18px' }}
          />
        </div>
      </Dropdown>
  );
}
