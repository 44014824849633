import React, { useState } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import { Button, Form, Tabs, TabsProps } from 'antd';

import { useCompanyFeatures, useFeatures, useGetCompany, useUpdateCompanyFeatures } from '@/hooks';
import { useUpdateFeatures } from '@/hooks/useUpdateFeatures';
import { Features } from '@/models/Features';

import { AlertNotification } from '../../../../../components/AlertNotification';
import { ContentHeader } from '../../../../../components/ContentHeader';
import { LoadingPage } from '../../../../../components/LoadingPage';
import { General } from './components/Tabs/General';
import { Seals } from './components/Tabs/Seals';
import { MultiTabs } from './components/Tabs/MultiTabs';
import { Payments } from './components/Tabs/Payments';
import { MultiLanguages } from './components/Tabs/MultiLanguages';
import { FooterActionButtonsContainer } from './styles';
import { NotificationType } from '@/components/AlertNotification/NotificationType';

enum Tab {
  GENERAL = 'Geral',
  SEALS = 'Módulo vendas',
  TABS = 'Módulo abas',
  LANGUAGES = 'Módulo línguas',
  PAYMENTS = 'Módulo pagamentos',
}

export const FeaturesPage = () => {
  const navigate = useNavigate();
  const [currentTab, setCurrentTab] = useState(Tab.GENERAL);
  const { id: companyId } = useParams();
  const { data: company, isLoading: isLoadingGetCompany } = useGetCompany(companyId);

  const { companyFeatures, isFetching: isFetchingCompanyFeatures } = useCompanyFeatures(companyId);
  const { update: updateCompanyFeatures, isLoading: isLoadingUpdateCompanyFeatures } = useUpdateCompanyFeatures();
  const { update: updateFeatures, isLoading: isLoadingUpdateFeatures } = useUpdateFeatures();
  const { data: features, isLoading: isLoadingFeatures } = useFeatures(companyId);
  const isLoading = isLoadingGetCompany || isFetchingCompanyFeatures || isLoadingFeatures || isLoadingUpdateCompanyFeatures || isLoadingUpdateFeatures;

  const items: TabsProps['items'] = [
    {
      key: Tab.GENERAL,
      label: Tab.GENERAL,
      children: <General />,
    },
    {
      key: Tab.SEALS,
      label: Tab.SEALS,
      children: <Seals />,
    },
    {
      key: Tab.TABS,
      label: Tab.TABS,
      children: <MultiTabs />,
    },
    {
      key: Tab.LANGUAGES,
      label: Tab.LANGUAGES,
      children: <MultiLanguages />,
    },
    {
      key: Tab.PAYMENTS,
      label: Tab.PAYMENTS,
      children: <Payments />,
    },
  ];

  const handleFormSubmit = (values: any) => {
    const toSave = {
      ...features,
      ...values,  
      _id: features?._id
    } as Features;

    updateFeatures(toSave, {
      onSuccess: () => AlertNotification({
        message: 'Operação realizada com sucesso',
      }),
      onError: () => AlertNotification({
        message: 'Erro ao tentar realizar operação',
        type: NotificationType.ERROR,
      }),
    });

    const { paymentMethods } = values;

    if (companyFeatures && paymentMethods) {
      updateCompanyFeatures({
        ...companyFeatures,
        sellsConfiguration: {
          ...companyFeatures.sellsConfiguration,
          paymentMethods,
        },
      }, {});
    }
  };

  return (
    <>
      <ContentHeader title={`Funcionalidades - ${company?.fantasyName}`} />

      <Form
        key={features?._id}
        initialValues={features}
        layout="vertical"
        onFinish={handleFormSubmit}
      >
        <Tabs
          activeKey={currentTab.toString()}
          items={items}
          onChange={(key: string) => setCurrentTab(key as Tab)}
          style={{ marginTop: '16px' }}
          size='large'
        />

        <FooterActionButtonsContainer>
          <Button
            danger
            onClick={() => navigate(-1)}
          >
            Fechar
          </Button>

          {currentTab !== Tab.GENERAL && (
            <Button type='primary' htmlType="submit">
              <i
                className="fa fa-check-circle"
                aria-hidden="true"
                style={{ marginRight: '10px' }}
              />
              Salvar
            </Button>
          )}
        </FooterActionButtonsContainer>
      </Form>

      {isLoading && <LoadingPage />}
    </>
  );
};
