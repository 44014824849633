import { useFetch } from '@/hooks/useFetch';
import { Category } from '@/models/Category';

const CATEGORY = 'category';

export const useCategory = (id: string | undefined) => {
  const { data, ...rest } = useFetch<Category>({
    enabled: !!id,
    queryKey: [CATEGORY, id],
    url: `api/category/${id}`,
  });

  return { data, ...rest };
};
