import React, { useState } from 'react';
import { Button, Form, Space, Switch } from 'antd';

import {
  OpeningHours,
} from '../../../../../../models/DataResponse';
import {
  CompanyFeatures,
} from '../../../../../../models/CompanyFeatures';
import { MIDNIGHT } from '../../../../../../utils/Constants';

import { WorkSchedulePageDetailDrawer } from './components/WorkSchedulePageDetailDrawer';
import { WorkScheduleList } from './components/WorkScheduleList';

import { UpdateCompanyFeaturesService } from '../../../../../../services/UpdateCompanyFeaturesService';
import { AlertNotification } from '@/components/AlertNotification';
import { NotificationType } from '@/components/AlertNotification/NotificationType';
import { FormLabelField } from '@/components/FormLabelField';

export interface WorkHours {
  startHour: string;
  endHour: string;
}

interface WorkScheduleProps {
  companyFeatures: CompanyFeatures;
  isSellsEnabled: boolean;
};

export const WorkSchedule = ({ isSellsEnabled, companyFeatures }: WorkScheduleProps) => {
  const [isShowingPage, setShowingPage] = useState(false);
  const [isEstablishmentOpen, setEstablishmentOpen] = useState(companyFeatures.isEstablishmentOpen);
  const [selectedOpenHours, setSelectedOpenHours] = useState<OpeningHours | null>(null);
  const [selectedOpeningHourList, setSelectedOpeningHourList] = useState<OpeningHours[]>(companyFeatures.openingHours);
  const [startWeekdays, setStartWeekdays] = useState<number[]>([]);

  const [workHour, setWorkHour] = useState<WorkHours>({
    startHour: MIDNIGHT,
    endHour: MIDNIGHT,
  } as WorkHours);

  function handleAddOrEditAction(open: OpeningHours | null) {
    if (open) {
      setSelectedOpenHours(open);
      setStartWeekdays(open.weekdays);
      setWorkHour({
        startHour: open.startHour,
        endHour: open.endHour,
      });
    } else {
      setSelectedOpenHours(null);
      setStartWeekdays([]);
      setWorkHour({ startHour: MIDNIGHT, endHour: MIDNIGHT } as WorkHours);
    }
    setShowingPage(true);
  }

  async function handleDeleteAction(id?: string) {
    if (!id) return;

    companyFeatures.openingHours = selectedOpeningHourList.filter(
      (item) => item._id !== id
    );

    const response = await UpdateCompanyFeaturesService.update(companyFeatures);
    if (response) {
      setSelectedOpeningHourList(response.openingHours);
      AlertNotification({
        message: 'Horário removido com sucesso.',
      });
    } else {
      AlertNotification({
        message: 'Não foi possível efetuar a operação.',
        type: NotificationType.ERROR,
      });
    }
  }

  async function handleEstablishmentClosing(checkedValue: boolean) {
    companyFeatures.isEstablishmentOpen = checkedValue;

    const response = await UpdateCompanyFeaturesService.update(companyFeatures);
    if (response) {
      setEstablishmentOpen(checkedValue);
      const message = checkedValue
        ? 'Estabelecimento aberto para vendas'
        : 'Estabelecimento encerrado para vendas';

      AlertNotification({
        message,
      });
    } else {
      AlertNotification({
        message: 'Não foi possível efetuar a operação.',
        type: NotificationType.ERROR,
      });
    }
  }

  async function handlePageResult(features: CompanyFeatures | null) {
    if (features === null) {
      setShowingPage(false);
      return;
    }

    const response = await UpdateCompanyFeaturesService.update(features);
    if (response) {
      setSelectedOpeningHourList(response.openingHours);
      setShowingPage(false);
      AlertNotification({
        message: 'Operação realizada com sucesso.',
      });
    } else {
      AlertNotification({
        message: 'Não foi possível efetuar a operação.',
        type: NotificationType.ERROR,
      });
    }
  }

  return (
    <>
      <WorkSchedulePageDetailDrawer
        isOpen={isShowingPage}
        selectedOpenHours={selectedOpenHours}
        startWorkHour={workHour}
        startWeekdays={startWeekdays}
        companyFeatures={companyFeatures}
        pageCallbackResult={handlePageResult}
      />

      {isSellsEnabled && (
        <Form layout="vertical">
          <Form.Item
            help="Esta opção permite encerrar o estabelecimento, independentemente do horário previamente configurado. Assim, o cliente poderá ver os produtos, mas não conseguirá efetuar compras."
            label={<FormLabelField label="Fechar fora do horário programado" />}
            name="isEstablishmentOpen"
          >
            <Switch
              id="isEstablishmentOpen"
              key="isEstablishmentOpen"
              defaultChecked={isEstablishmentOpen}
              checkedChildren="Aberto"
              unCheckedChildren="Fechado"
              onChange={handleEstablishmentClosing}
            />
          </Form.Item>
        </Form>
      )}

      <Space direction='vertical' style={{ marginTop: '40px' }}>
        <Button type="primary" onClick={() => handleAddOrEditAction(null)}>
          <i
            className="fa fa-plus-circle"
            aria-hidden="true"
            style={{ marginRight: '10px' }}
          />
          Adicionar os horários
        </Button>

        <WorkScheduleList
          isSellsEnabled={isSellsEnabled}
          items={selectedOpeningHourList}
          deleteAction={handleDeleteAction}
          editAction={handleAddOrEditAction}
        />
      </Space>
    </>
  );
};
