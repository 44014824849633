import React, { useCallback, useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { Button, Input, Tabs } from 'antd';
import type { TabsProps } from 'antd';

import { ContentHeader } from '../../../../../components/ContentHeader';
import { CompanyTab } from '../../components/CompanyTab';
import { PendingTab } from '../../components/PendingTab';

import { MenuOption } from '../../../../../models/MenuOption';
import { useInitCompaniesCounter } from '../../hooks/useInitCompaniesCounter';

enum Tab {
  ACTIVE = 'Ativo',
  INACTIVE = 'Desativado',
  PENDING = 'Pendente',
}

export const ListCompanyPage = () => {
  const { inactiveCounter, pendingCounter } = useInitCompaniesCounter();

  const [searchInput, setSearchInput] = useState<string>('');
  const [activeCount, setActiveCount] = useState<number>(0);
  const [currentTab, setCurrentTab] = useState(Tab.ACTIVE);
  const [inactiveCount, setInactiveCount] = useState<number>(0);
  const [pendingCount, setPendingCount] = useState<number>(0);

  const navigate = useNavigate();

  useEffect(() => {
    if (inactiveCounter) {
      setInactiveCount(inactiveCounter);
    }

    if (pendingCounter) {
      setPendingCount(pendingCounter);
    }
  }, [inactiveCounter, pendingCounter]);

  const onCompanyActiveUpdate = useCallback((count: number) => setActiveCount(count), []);

  const onCompanyInactiveUpdate = useCallback((count: number) => setInactiveCount(count), []);

  const onCompanyPendingUpdate = useCallback((count: number) => setPendingCount(count), []);

  const onTabChanged = (key: string) => setCurrentTab(key as Tab);

  const onSearch = (value: string) => setSearchInput(value);

  const items: TabsProps['items'] = [
    {
      key: Tab.ACTIVE,
      label: `${Tab.ACTIVE} (${activeCount})`,
      children: (
        <CompanyTab
          isActive
          searchInput={searchInput}
          onCompaniesCounterLoaded={onCompanyActiveUpdate}
        />
      ),
    },
    {
      key: Tab.INACTIVE,
      label: `${Tab.INACTIVE} (${inactiveCount})`,
      children: (
        <CompanyTab
          isActive={false}
          searchInput={searchInput}
          onCompaniesCounterLoaded={onCompanyInactiveUpdate}
        />
      ),
    },
    {
      key: Tab.PENDING,
      label: `${Tab.PENDING} (${pendingCount})`,
      children: (
        <PendingTab
          onCompaniesCounterLoaded={onCompanyPendingUpdate}
        />
      ),
    },
  ];

  return (
    <>
      <ContentHeader title={MenuOption.COMPANY}>
        {/*TODO:  Use debounce to improve this */}
        <Input.Search
          allowClear
          defaultValue={searchInput}
          enterButton="Pesquisar"
          onSearch={onSearch}
          placeholder="Pesquisar..."
          style={{ height: '40px' }}
        />
      </ContentHeader>

      <Button type="primary" onClick={() => navigate('/sysadmin/companies/create')} style={{ marginBottom: '10px' }}>
        <i
          className="fa fa-plus-circle"
          aria-hidden="true"
          style={{ marginRight: '10px' }}
        />
        Adicionar
      </Button>

      <Tabs
        activeKey={currentTab.toString()}
        items={items}
        onChange={onTabChanged}
        style={{ marginTop: '16px' }}
        size='large'
      />
    </>
  );
};
