import { MenuTabOption } from "@/models/DataResponse";
import api from "@/services/api";

export class TabMenuOptionService {
  public static async create(option: MenuTabOption): Promise<MenuTabOption> {
    const response = await api.post('api/features/menuoption/', option);

    return response.data.data;
  }

  public static async update(option: MenuTabOption): Promise<MenuTabOption> {
    const response = await api.put(`api/features/menuoption/${option._id}`, option);

    return response.data.data;
  }

  public static async delete(id: string): Promise<void> {
    return await api.delete(`/api/features/menuoption/${id}`);
  }
}