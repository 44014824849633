import { useFetch } from "../../../../hooks/useFetch";
import { EasypayPaymentStatus } from "../components/ResumeOrderDrawer/models/EasypayPayment";

const PAYMENT_STATUS = 'paymentStatus';

interface UseEasypayPaymentStatusProps {
  paymentId: string | undefined;
  slug: string | undefined;
}

export const useEasypayPaymentStatus = ({ paymentId, slug }: UseEasypayPaymentStatusProps) => {
  const { data, ...rest } = useFetch<EasypayPaymentStatus>({
    enabled: paymentId !== undefined && slug !== undefined,
    isPublicAPI: true,
    queryKey: [PAYMENT_STATUS, paymentId],
    url: `api/payment-easypay/${paymentId}?slug=${slug}`,
  });

  return { data, ...rest };
}