import React, { useEffect, useState } from 'react';
import { Radio } from 'antd';

import { ComplementItemOption } from '../../../../../../models/ProductCatalog';
import { ComplementAction } from '../../../../../../models/Enum';
import formatCurrency from '../../../../../../utils/formatCurrency';

import {
  ColorItem,
  ComplementContentRow,
  ItemContainer,
  Separator,
  TitleHolder,
} from './style';

interface ComplementItemOptionsSingleChoiceProps {
  options: ComplementItemOption[];
  currency: string;
  hasSellViaWhatsappEnabled: boolean;
  onSelectedOptions(action: ComplementAction): void;
};

export const ComplementItemOptionsSingleChoice = ({ options, currency, hasSellViaWhatsappEnabled, onSelectedOptions }: ComplementItemOptionsSingleChoiceProps) => {
  const [selectedItem, setSelectedItem] = useState<string>('');
  const [selectedOptions, setSelectedOptions] = useState<ComplementItemOption[]>([]);

  useEffect(() => {
    const foundOpt = options.find((option) => option.counter === 1);
    if (foundOpt) {
      setSelectedItem(foundOpt._id ?? '');
      setSelectedOptions([foundOpt]);
    }
  }, [options]);

  function onValueChanged(item: ComplementItemOption, toRemove: boolean) {
    let action = ComplementAction.DELETE;
    let currentSelectedOptions = selectedOptions;
    options.forEach((option) => (option.counter = 0));

    if (toRemove) {
      // Remove from the list
      currentSelectedOptions = currentSelectedOptions.filter(
        (option) => option._id !== item._id
      );
    } else {
      item.counter = 1;

      if (currentSelectedOptions.length === 0) {
        action = ComplementAction.ADD;
        currentSelectedOptions.push(item);
      } else {
        action = ComplementAction.UPDATE;
        currentSelectedOptions[0] = item;
      }
    }

    setSelectedOptions(currentSelectedOptions);
    onSelectedOptions(action);
  }

  return (
    <ul>
      <Radio.Group
        aria-label="selectedOption"
        name="selectedOption"
        value={selectedItem}
        style={{ width: '100%' }}
        onChange={(e) => {
          const itemChecked = e.target.value;
          setSelectedItem(itemChecked);
          onValueChanged(
            options.find((opt) => opt._id === itemChecked)!!,
            false
          );
        }}
      >
        {options.map((item) => {
          return (
            <li key={item._id} style={{ listStyle: 'none' }}>
              <ComplementContentRow
                hasExtraProps={
                  (item.description !== undefined &&
                    item.description !== '') ||
                  item.price > 0
                }
              >
                <ItemContainer>
                  <TitleHolder>
                    {item.color !== undefined && item.color !== '' && (
                      <ColorItem color={item.color} />
                    )}
                    <p className="item-title-container">{item.title}</p>
                  </TitleHolder>
                  {item.description !== undefined &&
                    item.description !== '' && (
                      <p className="item-description-container">
                        {item.description}
                      </p>
                    )}
                  {item.price > 0 && (
                    <span>{formatCurrency(item.price, currency)}</span>
                  )}
                </ItemContainer>

                {hasSellViaWhatsappEnabled && (
                  <Radio
                    value={item._id}
                    onClick={() => {
                      if (selectedItem === item._id) {
                        setSelectedItem('');
                        onValueChanged(
                          options.find(
                            (opt) => opt._id === selectedItem
                          )!!,
                          true
                        );
                      }
                    }}
                  />
                )}
              </ComplementContentRow>

              <Separator />
            </li>
          );
        })}
      </Radio.Group>
    </ul>
  );
};
