import React, { useEffect, useState } from 'react';
import { Checkbox } from 'antd';
import { WeekdaysList } from '../../utils/Constants';
import { CheckboxContainer } from './styles';
import { FormLabelField } from '../FormLabelField';

interface WeekdayListProps {
  selectedWeekdays: number[];
  handleSelectedWeekdays(days: number[]): void;
}

const ENTRY_LIST = [0, 1, 2, 3, 4, 5, 6];

export const WeekdayList = ({ selectedWeekdays, handleSelectedWeekdays }: WeekdayListProps) => {
  const [checkedList, setCheckedList] = useState<number[]>([]);

  useEffect(() => {
    if (selectedWeekdays.length === 0) {
      setCheckedList(ENTRY_LIST);
    } else {
      setCheckedList(selectedWeekdays);
    }
  }, [selectedWeekdays, setCheckedList]);

  useEffect(() => {
    handleSelectedWeekdays(checkedList.sort((a, b) => a - b));
  }, [checkedList, handleSelectedWeekdays]);

  const handleToggle = (value: number) => () => {
    const currentIndex = checkedList.indexOf(value);
    const newChecked = [...checkedList];

    if (currentIndex === -1) {
      newChecked.push(value);
    } else {
      newChecked.splice(currentIndex, 1);
    }

    setCheckedList(newChecked);
  };

  return (
    <>
      <FormLabelField label="Dias da semana" />
      <CheckboxContainer style={{ marginTop: '8px' }}>
        {ENTRY_LIST.map((value: number) => (
          <Checkbox
            key={value}
            onChange={handleToggle(value)}
            checked={checkedList.includes(value)}
            style={{ margin: 'unset' }}
          >
            {WeekdaysList[value]}
          </Checkbox>
        ))}
      </CheckboxContainer>
    </>
  );
};
