import React, { useState } from "react";
import { useParams } from "react-router-dom";
import { Divider, Form } from "antd";

import { Language } from "@/models/Languages";
import { useFeatures } from "@/hooks";
import { FormSwitchItem } from "@/components/FormSwitchItem";
import { LanguageList } from "./components/LanguageList";
import { ChooseDefaultLanguage } from "./components/ChooseDefaultLanguage";

export const MultiLanguages = () => {
  const { id: companyId } = useParams();
  const { data: features } = useFeatures(companyId);
  const [isEnabled, setIsEnabled] = useState<boolean>(features?.multiLanguage.isEnabled ?? false);
  const [selectedLanguages, setSelectedLanguages] = useState<Language[]>(features?.multiLanguage.languages ?? []);

  return (
    <>
      <FormSwitchItem
        label="Módulo línguas"
        propertyId={['multiLanguage', 'isEnabled']}
        defaultChecked={isEnabled}
        checkedValueText={'Ativo'}
        uncheckedValueText={'Pausado'}
        handleCheckboxChange={setIsEnabled}
      />

      {isEnabled && (
        <>
          <Form.Item name={['multiLanguage', 'languages']}>
            <LanguageList
              selectedLanguages={selectedLanguages}
              onChange={(languages) => setSelectedLanguages(languages)}
            />
          </Form.Item>

          <Divider orientation="left"><strong>Selecione a língua padrão</strong></Divider>

          <Form.Item name={['multiLanguage', 'languages']}>
            <ChooseDefaultLanguage
              languages={selectedLanguages}
              onChange={(languages) => setSelectedLanguages(languages)}
            />
          </Form.Item>
        </>
      )}
    </>
  );
};
