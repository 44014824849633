import React, { useEffect, useMemo, useState } from 'react';
import { Form, Input, Select } from 'antd';
import { CaretDownOutlined } from '@ant-design/icons';
import { isEmpty } from 'lodash';

import { ContainerFilters, ContentFilters } from '../../../../../common-styles';
import { Category } from '@/models/Category';
import { Features } from '../../../../../models/Features';
import { useListCompaniesForCustomers, useLoggedUser } from '../../../../../hooks';
import { Role } from '../../../../../models/Enum';
import { ALL_TABS, DEFAULT_SELECT_COMPANY } from '../../../../../utils/Constants';
import { FormLabelField } from '../../../../../components/FormLabelField';
import { useDebounceValue } from '@/hooks/useDebounceValue';

interface CategoryFiltersProps {
  categories?: Category[];
  companyId: string;
  features?: Features;
  onCompanySelect: (companyId: string) => void;
  setFilteredCategories: (categories: Category[] | null) => void;
  setSearchInputFilter: (searchInput: string) => void;
}

const DEFAULT_TAB = 'Todos';

export const CategoryFilters = ({
  categories,
  companyId,
  features,
  onCompanySelect,
  setFilteredCategories,
  setSearchInputFilter,
}: CategoryFiltersProps) => {
  const [searchInput, setSearchInput] = useState<string>('');
  const debouncedSearch = useDebounceValue(searchInput);
  const [selectedTabName, setSelectedTabName] = useState(ALL_TABS);

  const { data: companies } = useListCompaniesForCustomers();
  const { userLogged: { user: { role, companyAssociation } } } = useLoggedUser();

  useEffect(() => {
      setSearchInputFilter(debouncedSearch);
    }, [debouncedSearch, setSearchInputFilter]);

  const companiesName = useMemo(() => {
    let names;

    if (role === Role.KITCHEN && !isEmpty(companyAssociation)) {
      names = companyAssociation.map((company) => {
        return {
          value: company.companyId,
          label: company.companyName,
        };
      });
    } else {
      names = companies?.map((company) => {
        return {
          value: company._id,
          label: company.fantasyName,
        };
      }) ?? [];
    }

    names.sort((a, b) => a.label.localeCompare(b.label)).unshift({
      value: '',
      label: DEFAULT_SELECT_COMPANY,
    });
    return names;
  }, [companies, companyAssociation, role]);

  const hasTabsAvailable = useMemo(() => {
    return features?.menuOptions && features?.menuOptions.length > 0;
  }, [features]);

  const tabsName = useMemo(() => {
    if (features?.menuOptions) {
      const names =
        features?.menuOptions.map((menuOption) => {
          return {
            value: menuOption._id,
            label: menuOption.name,
          };
        }) || [];
      names.unshift({
        value: DEFAULT_TAB,
        label: DEFAULT_TAB,
      });
      names.unshift({
        value: ALL_TABS,
        label: 'Sem filtro',
      });
      return names;
    }
    return [];
  }, [features]);

  const handleSelectedCategoryName = (tab: string) => {
    setSelectedTabName(tab);

    if (tab === ALL_TABS) {
      setFilteredCategories(null);
      return;
    }

    if (tab === DEFAULT_TAB) {
      tab = 'all';
    }

    const filtered = categories?.filter((category) => {
      return category.tabsAssociated.includes(tab);
    });

    setFilteredCategories(filtered || null);
  };

  return (
    <ContainerFilters>
      <Form
        layout="vertical"
        style={{ marginTop: '16px' }}
      >
        <ContentFilters>
          {role !== Role.USER && (
            <Form.Item
              label={<FormLabelField label="Empresas" />}
            >
              <Select
                defaultValue={companyId}
                onChange={onCompanySelect}
                suffixIcon={<CaretDownOutlined />}
              >
                {companiesName?.map((company) => (
                  <Select.Option
                    key={company.value}
                    value={company.value}
                  >
                    {company.label}
                  </Select.Option>
                ))}
              </Select>
            </Form.Item>
          )}

          <Form.Item
            label={<FormLabelField label="Pesquisar" />}
          >
            <Input.Search
              allowClear
              defaultValue={searchInput}
              onChange={(event) => setSearchInput(event.target.value)}
              onSearch={setSearchInput}
              placeholder="Pesquise por qualquer entrada"
              style={{ height: '40px' }}
            />
          </Form.Item>

          {hasTabsAvailable && (
            <Form.Item
              label={<FormLabelField label="Filtrar" />}
            >
              <Select
                defaultValue={selectedTabName}
                onChange={handleSelectedCategoryName}
                suffixIcon={<CaretDownOutlined />}
              >
                {tabsName.map((tab) => (
                  <Select.Option key={tab.value} value={tab.value}>
                    {tab.label}
                  </Select.Option>
                ))}
              </Select>
            </Form.Item>
          )}
        </ContentFilters>
      </Form>
    </ContainerFilters>
  );
}