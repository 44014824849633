import React, { useCallback, useEffect, useMemo, useState } from 'react';
import * as Sentry from '@sentry/browser';

import { MenuHeaderTitle } from '../MenuHeaderTitle';
import { CatalogInfo } from '../CatalogInfo';
import { CardInfo } from '../CardInfo';

import { LayoutType } from '../../../../../models/Enum';
import { MenuTabOption, MenuConfiguration } from '../../../../../models/DataResponse';
import {
  ProductCatalog,
  ProductMenu,
} from '../../../../../models/ProductCatalog';

import { CatalogContainer, MenuContainer } from '../../styles';
import { CategoryOptions } from '../CategoryOptions';
import { removeAccents } from '../../../../../utils/Util';

interface MainContentProps {
  configuration: MenuConfiguration;
  data: ProductCatalog[];
  handleProductSelection: (product: ProductMenu) => void;
  layoutType?: string;
  selectedMenuOption: MenuTabOption | null;
  shouldShowTitle: boolean;
  subTitleColor: string;
  tabsAvailable: boolean;
  titleColor: string;
};

let manualCategorySelection = false;
let isScrolling: NodeJS.Timeout;
const THRESHOLD_SCROLL = 60;

export const MainContent = ({
  configuration,
  data,
  handleProductSelection,
  layoutType = LayoutType.MENU,
  selectedMenuOption,
  shouldShowTitle,
  subTitleColor,
  tabsAvailable,
  titleColor,
}: MainContentProps) => {
  const [selectedCategory, setSelectedCategory] = useState('');

  const categoriesFiltered = useMemo(() => {
    if (!data) return [];

    setSelectedCategory('');

    return data.filter((item) => item.isSectionType)
      .map((item, index) => {
        return {
          category: item.category.name,
          isSelected: index === 0,
          key: item.category.name + item.products.length
        }
      });
  }, [data]);

  const categories = useMemo(() => {
    if (!categoriesFiltered) return [];

    return categoriesFiltered.map((item, index) => {
      return {
        ...item,
        isSelected: (index === 0 && selectedCategory === '') || removeAccents(item.category) === selectedCategory,
      }
    });
  }, [categoriesFiltered, selectedCategory]);

  const onCategorySelected = useCallback((category: string, autoScroll: boolean = true) => {
    const id = removeAccents(category);
    setSelectedCategory(id);
    scrollToView(id, autoScroll);
    manualCategorySelection = true;
  }, []);

  const scrollToView = (id: string, autoScroll: boolean) => {
    const titleElement = document.getElementById(`${id}_${id}`);
    const categoryElement = document.getElementById(id);
    const scroller = document.getElementById('categoryOptionsScroller');

    if (!titleElement || !categoryElement || !scroller) return;

    // Scroll horizontally to the Category element
    scroller.scrollTo({
      behavior: 'smooth',
      left: categoryElement.offsetLeft - THRESHOLD_SCROLL,
    });

    if (autoScroll) {
      // Scroll vertically to the Category title on the Catalog
      window.scroll({ top: titleElement.offsetTop, behavior: 'smooth' });
    }
  }

  useEffect(() => {
    if (!categories) return;

    const intersectionObserver = new IntersectionObserver((entries) => {
      const isIntersecting = entries.some((entry) => entry.isIntersecting);

      if (isIntersecting && entries.length === 1 && !manualCategorySelection) {
        const [target] = entries[0].target.id.split('_');
        onCategorySelected(target, false);
      }
    });

    categories.forEach(option => {
      const id = removeAccents(option.category);

      // FIXME: This try catch shouldn't exist. We need to find a better way to implement this feature.
      try {
        const element = document.querySelector(`#${id}_${id}`);
        if (element) {
          intersectionObserver.observe(element);
        }
      } catch (error) {
        Sentry.captureException(error);
        Sentry.captureMessage('Error on querySelector - MainContent');
      }
    });

    return () => intersectionObserver.disconnect();
  }, [categories, onCategorySelected]);

  useEffect(() => {
    window.addEventListener('scroll', _ => {
      clearTimeout(isScrolling);
      isScrolling = setTimeout(() => {
        manualCategorySelection = false;
      }, 150);
    },
      false
    );
  }, []);

  const renderCatalogType = (items: ProductMenu[]) => {
    return (
      <CatalogContainer>
        {items.map((item) => {
          return (
            <CatalogInfo
              key={item._id}
              data={item}
              hasSellViaWhatsappEnabled={configuration.sellViaWhatsappEnabled}
              configuration={configuration}
              selectedMenuOption={selectedMenuOption}
              handleAddClick={() => handleProductSelection(item)}
            />
          );
        })}
      </CatalogContainer>
    );
  };

  const renderMenuFoodType = (items: ProductMenu[]) => {
    return items.map((item) => {
      return (
        <MenuContainer key={`${item._id}`}>
          <CardInfo
            data={item}
            configuration={configuration}
            selectedMenuOption={selectedMenuOption}
            hasSellViaWhatsappEnabled={configuration.sellViaWhatsappEnabled}
            handleAddClick={() => handleProductSelection(item)}
          />
        </MenuContainer>
      );
    });
  };

  return (
    <>
      {(Boolean(categories.length) && categories.length > 1) && (
        <CategoryOptions
          tabsAvailable={tabsAvailable}
          categories={categories}
          onCategorySelected={(selected) => onCategorySelected(selected)}
        />
      )}

      {Boolean(data.length) &&
        data.map((productCatalog) => {
          return (
            <div key={productCatalog.category.id} id={productCatalog.category.id}>
              <MenuHeaderTitle
                productCatalog={productCatalog}
                titleColor={titleColor}
                subTitleColor={subTitleColor}
                shouldShowTitle={shouldShowTitle}
              />

              {layoutType === LayoutType.MENU
                ? renderMenuFoodType(productCatalog.products)
                : renderCatalogType(productCatalog.products)}
            </div>
          );
        })}
    </>
  );
};
