import React, { useEffect, useState } from 'react';
import parse from 'html-react-parser';
import LazyLoad from 'react-lazyload';
import { confirmAlert } from 'react-confirm-alert';
import { Popover, Switch, Table } from 'antd';
import { MenuOutlined } from '@ant-design/icons';

import {
    SortableContainer,
    SortableElement,
    SortableHandle,
} from 'react-sortable-hoc';
import { arrayMoveImmutable } from 'array-move';

import { ActionType } from '../../../../../../models/Enum';
import { Product } from '../../../../../../models/Product';
import { sanitizePrice } from '../../../../../../utils/priceUtil';
import formatCurrency from '../../../../../../utils/formatCurrency';
import { PREFIX_LOCALSTORAGE } from '../../../../../../utils/Constants';

import { ExpandedComplementItems } from '../ExpandedComplementItems';
import { RoundTag } from '../../../../../../common-styles';
import { TableContextMenu } from '@/components/TableContextMenu';

const SortableItem = SortableElement((props: any) => <tr {...props} />);
const SortableContainerWrapper = SortableContainer((props: any) => (
    <tbody {...props} />
));
const DragHandle = SortableHandle(() => (
    <MenuOutlined style={{ cursor: 'grab', color: 'rgb(153, 153, 153)' }} />
));

interface ProductTableProps {
    handleAction(item: Product, actionType: ActionType): void;
    isLoading: boolean;
    onTableChanged(items: Product[]): void;
    products: Product[];
};

export const ProductTable = ({
    isLoading,
    products,
    onTableChanged,
    handleAction,
}: ProductTableProps) => {
    const [dataSource, setDataSource] = useState<Product[]>([]);

    useEffect(() => {
        setDataSource(products);
    }, [products]);

    const currency = localStorage.getItem(`${PREFIX_LOCALSTORAGE}:currency`) || 'BRL'; // TODO: https://github.com/uiltonjose/qrcodepreferido-app/issues/52

    const handleRemoveCategory = (product: Product) => {
        confirmAlert({
            title: 'Atenção',
            message: `Tem certeza que deseja remover o produto ${product.name}? \n Uma vez removido, esta ação não poderá ser revertida.`,
            buttons: [
                {
                    label: 'Cancelar',
                    onClick: () => { },
                },
                {
                    label: 'Confirmar',
                    onClick: () => handleAction(product, ActionType.DELETE),
                },
            ],
        });
    };

    const renderContextMenu = (product: Product) => [
        {
            label: (
                <span onClick={() => handleAction(product, ActionType.CLONE)}>
                    Duplicar
                </span>
            ),
            key: 'duplicate',
        },
        {
            label: (
                <span onClick={() => handleRemoveCategory(product)}>
                    Remover
                </span>
            ),
            key: 'remove',
            danger: true,
        },
    ];

    const getFormattedPrice = (value?: string) => {
        if (!value ||
            value === undefined ||
            isNaN(Number(sanitizePrice(value, false)))) {
            return value;
        }

        const price = Number(sanitizePrice(value));
        return formatCurrency(price, currency);
    };

    const onSortEnd = ({
        oldIndex,
        newIndex,
    }: {
        oldIndex: number;
        newIndex: number;
    }) => {
        if (oldIndex !== newIndex) {
            const newData = arrayMoveImmutable(
                ([] as Product[]).concat(dataSource),
                oldIndex,
                newIndex
            ).filter((el) => !!el);

            onTableChanged(
                newData.map((item, index) => {
                    return {
                        ...item,
                        order: index,
                    };
                })
            );
        }
    };

    const DraggableContainer = (props: any) => (
        <SortableContainerWrapper
            useDragHandle
            disableAutoscroll
            helperClass="row-dragging"
            onSortEnd={onSortEnd}
            {...props}
        />
    );

    const DraggableBodyRow = ({ ...restProps }) => {
        const index = dataSource.findIndex(
            (x) => x.order === restProps['data-row-key']
        );
        return <SortableItem index={index} {...restProps} />;
    };

    const hasStockEnabled = () => products.some(product => product.stock.isEnabled)

    return (
        <>
            <Table
                bordered
                components={{
                    body: {
                        wrapper: DraggableContainer,
                        row: DraggableBodyRow,
                    },
                }}
                dataSource={dataSource}
                loading={isLoading}
                pagination={false}
                rowKey="order"
                scroll={{ x: 1300 }}
                style={{ marginTop: '16px' }}
            >
                <Table.Column
                    title=""
                    align={'center' as 'center'}
                    fixed="left"
                    width={50}
                    render={() => <DragHandle />}
                />
                <Table.Column
                    title="Status"
                    align={'center' as 'center'}
                    fixed="left"
                    width={100}
                    render={(product: Product) => (
                        <Switch
                            key={product._id}
                            defaultChecked={product.isEnabled}
                            checkedChildren="Ativo"
                            unCheckedChildren="Pausado"
                            onChange={(value) => handleAction({ ...product, isEnabled: value }, ActionType.UPDATE)}
                        />
                    )}
                />
                {hasStockEnabled() && (
                    <Table.ColumnGroup title="Estoque">
                        <Table.Column
                            align={'center' as 'center'}
                            dataIndex={['stock', 'quantity']}
                            title="Quantidade"
                            width={110}
                        />
                        <Table.Column
                            title="Status"
                            width={120}
                            render={(product: Product) => {
                                if (!product.stock.isEnabled) {
                                    return (
                                        <RoundTag color="green" key={product._id}>
                                            Disponível
                                        </RoundTag>
                                    );
                                }

                                const isEmptyStock = product.stock.quantity <= 0;
                                const isLowStock =
                                    isEmptyStock
                                        ? false
                                        : product.stock.quantity <= product.stock.minQuantityAlert

                                let status = {
                                    color: 'green',
                                    label: 'Disponível',
                                };

                                if (isEmptyStock) {
                                    status = {
                                        color: 'red',
                                        label: 'Sem estoque',
                                    };
                                } else if (isLowStock) {
                                    status = {
                                        color: 'orange',
                                        label: 'Baixo estoque',
                                    };
                                }

                                return (
                                    <RoundTag color={status.color} key={product._id}>
                                        {status.label}
                                    </RoundTag>
                                );
                            }}
                        />
                    </Table.ColumnGroup>
                )}
                <Table.Column
                    title="Imagem"
                    align={'center' as 'center'}
                    width={90}
                    render={(product: Product) => {
                        return (
                            product.imageUrl && (
                                <LazyLoad height={60} once={true} key={product._id}>
                                    <img
                                        className="image-thumbnail"
                                        src={product.imageUrl}
                                        alt={product.name}
                                    />
                                </LazyLoad>
                            )
                        );
                    }}
                />
                <Table.Column
                    dataIndex="name"
                    title="Título"
                    width={200}
                />
                <Table.Column
                    title="Descrição"
                    width={220}
                    render={(product: Product) => {
                        const text = product.description;
                        const description =
                            text !== undefined && text.length > 100
                                ? text.trim().substring(0, 100) + '...'
                                : text;
                        return description && parse(description);
                    }}
                />
                <Table.Column
                    title="N. Complementos"
                    width={150}
                    render={(product: Product) => {
                        return (
                            product.complements && product.complements.length ?
                                <Popover content={<ExpandedComplementItems complements={product.complements ?? []} />}>
                                    <span style={{ fontSize: '16px', paddingRight: '6px' }}>{product.complements?.length}</span>
                                    <i className="fa fa-info-circle" aria-hidden="true" />
                                </Popover>
                                : null
                        );
                    }}
                />
                <Table.Column
                    title="Preço"
                    width={100}
                    render={(product: Product) => getFormattedPrice(product.price)}
                />
                <Table.Column
                    fixed="right"
                    align='center'
                    width={90}
                    render={(product: Product) => (
                        <TableContextMenu
                            isButtonType
                            handlePrimaryClick={() => handleAction(product, ActionType.OPEN)}
                            primaryOption="Editar"
                            menuProps={{ items: renderContextMenu(product) }}
                        />
                    )}
                />
            </Table>
        </>
    );
};