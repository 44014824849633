import React, { useState, useCallback, useMemo } from 'react';
import { Button } from 'antd';

import { ContentHeader } from '../../../components/ContentHeader';
import { UserEditor } from './components/Editor';
import { UserFilters } from './components/Filters';
import { UserTable } from './components/Table';
import { AlertNotification } from '@/components/AlertNotification';
import { NotificationType } from '@/components/AlertNotification/NotificationType';

import { MenuOption } from '../../../models/MenuOption';
import { User } from '../../../models/User';
import { useUsers } from './hooks/useUsers';
import { useUser } from './hooks/useUser';

import { removeAccents } from '../../../utils/Util';
import { translateRole } from '../../../utils/RoleUtil';
import { ActionType, Role } from '../../../models/Enum';
import { DEFAULT_ALL_COMPANIES } from '../../../utils/Constants';

import { CounterLabelContent } from './styles';

export const UserPage = () => {
  const [isShowingEditorPage, setIsShowingEditorPage] = useState<boolean>(false);
  const [selectedUser, setSelectedUser] = useState<User>();
  const [searchInput, setSearchInput] = useState<string>('');
  const [selectedAction, setSelectedAction] = useState<ActionType>(ActionType.OPEN);
  const [selectedRoles, setSelectedRoles] = useState<Role[]>([]);
  const [selectedCompanyId, setSelectedCompanyId] = useState(DEFAULT_ALL_COMPANIES);

  const { data: users, isLoading, refetch } = useUsers();
  const { deleteUser } = useUser();

  const handleRowClick = (action: ActionType, selectedUser?: User) => {
    if (action === ActionType.ADD || action === ActionType.UPDATE || action === ActionType.OPEN) {
      setSelectedAction(action);
      setSelectedUser(selectedUser);
      setIsShowingEditorPage(true);
    } else {
      deleteUser(
        selectedUser?._id,
        {
          onSuccess: () => {
            AlertNotification({
              message: 'Operação realizada com sucesso',
            });
            refetch();
          },
          onError: () => {
            AlertNotification({
              message: 'Erro ao tentar remover usuário',
              type: NotificationType.ERROR,
            });
          }
        }
      );
    }
  };

  const filteredUsers = useMemo(() => {
    const input = removeAccents(searchInput.toLowerCase());

    if (!input && selectedRoles.length === 0 && selectedCompanyId === DEFAULT_ALL_COMPANIES) return null;

    let result = users?.filter((user) => {
      const { email, name, role, companyName } = user;

      return (
        email.toLowerCase().includes(searchInput.toLowerCase()) ||
        name.toLowerCase().includes(input) ||
        translateRole(role).label.toLowerCase().includes(input) ||
        companyName?.toLowerCase().includes(input)
      );
    });

    if (Boolean(selectedRoles.length)) {
      result = result?.filter((item => selectedRoles.some(role => role === item.role)))
    }

    if (selectedCompanyId && selectedCompanyId !== DEFAULT_ALL_COMPANIES) {
      result = result?.filter((item => item.companyAssociation.some(associated => associated.companyId === selectedCompanyId) || item.company === selectedCompanyId));
    }

    return result;
  }, [selectedCompanyId, searchInput, selectedRoles, users]);

  const listOfUsers = filteredUsers ?? users;

  const onSearchFiltersChange = useCallback((searchFilter: string) => {
    setSearchInput(searchFilter);
  }, []);

  const onRolesFiltersChanged = useCallback((options: Role[]) => setSelectedRoles(options), []);

  return (
    <>
      {isShowingEditorPage ? (
        <UserEditor
          action={selectedAction}
          onClosePage={() => setIsShowingEditorPage(false)}
          user={selectedUser} />
      ) : (
        <>
          <ContentHeader title={MenuOption.USER} />

          <UserFilters
            selectedRole={selectedRoles}
            companyId={selectedCompanyId}
            onCompanySelected={(companyId) => setSelectedCompanyId(companyId)}
            onRolesSelected={onRolesFiltersChanged}
            setSearchInputFilter={onSearchFiltersChange}
          />

          <Button type="primary" onClick={() => handleRowClick(ActionType.ADD, undefined)}>
            <i
              className="fa fa-plus-circle"
              aria-hidden="true"
              style={{ marginRight: '10px' }}
            />
            Adicionar
          </Button>

          <CounterLabelContent>
            <p>
              <span>Total:</span> {listOfUsers?.length}
            </p>
          </CounterLabelContent>

          <UserTable
            isLoading={isLoading}
            onRowSelected={handleRowClick}
            users={listOfUsers ?? []}
          />
        </>
      )}
    </>
  );
};
