import React, { useMemo } from 'react';
import { Popconfirm } from 'antd';

import { PREFIX_LOCALSTORAGE } from '../../../../../utils/Constants';

import linkImg from '../../../../../assets/images/link.svg';

import { AlertNotification } from '../../../../../components/AlertNotification';
import { ButtonContainer } from './style';
import { getClientMenuUrl } from '../../../../../utils/Util';

export const LinkButton = () => {
  const slug = useMemo(() => {
    return localStorage.getItem(`${PREFIX_LOCALSTORAGE}slug`) || '';
  }, []);

  const getLink = () => getClientMenuUrl(`qrlinks/${slug}`);

  const copyLinkToClipboard = () => {
    const url = getLink();
    navigator.clipboard.writeText(url);

    AlertNotification({
      message: 'Link copiado.',
    });
  };

  const openLink = () => {
    const a = document.createElement('a');
    a.target = '_blank';
    a.href = getLink();
    a.click();
  };

  return (
    <Popconfirm
      placement="bottomRight"
      title={<span>Deseja copiar ou abrir o link?</span>}
      onConfirm={copyLinkToClipboard}
      okText="Copiar"
      cancelText="Abrir"
      onCancel={openLink}
    >
      <ButtonContainer>
        <div>
          <img src={linkImg} alt="Link Button" />
        </div>
        <span>Link para página</span>
      </ButtonContainer>
    </Popconfirm>
  );
}
