import React, { useMemo } from 'react';
import { Popover, Space, Table } from 'antd';
import { EyeOutlined, PrinterFilled } from '@ant-design/icons';
import type { ColumnsType } from 'antd/lib/table';

import { WhatsappOrder } from '../../../../../models/DataResponse';
import { RoundTag } from '../../../../../common-styles';
import { useCompanyFeatures } from '../../../../../hooks';
import { formatDatetimeByTimezone } from '../../../../../utils/dateTime';
import { dispatchOrderPrintRequest, getTotalFromOrder } from '../../../../../utils/OrderUtil';
import { TableGenericAction } from '../../../../../components/TableGenericAction';
import { ProductOrderTable } from '../../../../../components/ProductOrderTable';
import { getOrderStatusData } from '../../../../../models/OrderStatus';

interface HistoryOrdersTableProps {
  companyId: string;
  currency: string;
  loading: boolean;
  onItemClick: (order: WhatsappOrder) => void;
  orders: WhatsappOrder[];
}

export const HistoryOrdersTable = ({ companyId, currency, loading, onItemClick, orders }: HistoryOrdersTableProps) => {
  const { companyFeatures } = useCompanyFeatures(companyId);

  const hasCouponAvailable = useMemo(() => orders.some((item) => item.coupon), [orders]);

  const hasAddressAvailable = useMemo(() => orders.some((item) => item.client.address), [orders]);

  const mainEntryColumns = [
    {
      title: 'Status',
      dataIndex: 'status',
      visible: true,
      render: (_: any, order: WhatsappOrder) => {
        const { label, color } = getOrderStatusData(order.status);

        return (
          <RoundTag color={color}>
            {label}
          </RoundTag>
        );
      },
    },
    {
      title: 'Código',
      dataIndex: 'orderId',
      visible: true,
    },
    {
      title: 'Data/Hora',
      visible: true,
      render: (_: any, order: WhatsappOrder) => formatDatetimeByTimezone(order.createdAt, companyFeatures?.timezone),
    },
    {
      title: 'Cliente',
      dataIndex: ['client', 'name'],
      visible: true,
    },
    {
      title: 'Fone',
      dataIndex: ['client', 'phone'],
      visible: true,
    },
    {
      title: 'Endereço',
      dataIndex: ['client', 'address'],
      visible: hasAddressAvailable,
    },
    {
      title: 'Pagamento',
      dataIndex: ['client', 'paymentType'],
      visible: true,
    },
    {
      title: 'Total',
      visible: true,
      render: (_: any, order: WhatsappOrder) => getTotalFromOrder(currency, order).totalFormatted,
    },
    {
      title: 'Cupom',
      visible: hasCouponAvailable,
      render: (_: any, order: WhatsappOrder) => order.coupon ? order.coupon.couponCode : '',
    },
    {
      title: 'N. Itens',
      visible: true,
      render: (_: any, order: WhatsappOrder) => {
        const amount = order.products.reduce((prevAmount, currentValue) => prevAmount + currentValue.counter, 0);

        return (
          <Popover
            content={
              <ProductOrderTable
                currency={currency}
                displayPrice
                products={order.products}
                showComplementTitle={companyFeatures?.sellsConfiguration?.orderComplementExhibition?.showTitle ?? false}
              />}
          >
            <span style={{ fontSize: '16px', paddingRight: '6px' }}>{amount}</span>
            <i className="fa fa-info-circle" aria-hidden="true" />
          </Popover>
        );
      },
    },
    {
      title: 'Ações',
      fixed: 'right',
      width: "15%",
      visible: true,
      render: (_: any, order: WhatsappOrder) => (
        <Space direction="vertical" key={order._id}>
          <TableGenericAction
            dashed
            icon={<PrinterFilled style={{ fontSize: '18px' }} />}
            label="Imprimir"
            onClick={() => dispatchOrderPrintRequest(
              order._id,
              companyId,
              currency
            )}
          />

          <TableGenericAction
            icon={<EyeOutlined style={{ fontSize: '18px' }} />}
            label="Detalhes"
            onClick={() => onItemClick(order)}
          />
        </Space>
      ),
    },
  ].filter(item => item.visible);


  return (
    <Table
      bordered
      columns={mainEntryColumns as ColumnsType<WhatsappOrder>}
      dataSource={orders}
      loading={loading}
      scroll={{ x: 1300 }}
      rowKey={(c: WhatsappOrder) => c._id}
    />
  );
}