import React from 'react';
import ReactDOM from 'react-dom';
import { Toaster } from 'react-hot-toast';
import { QueryClientProvider } from 'react-query';

import * as Sentry from '@sentry/react';

import { ConfigProvider } from 'antd';
import ptBR from 'antd/lib/locale/pt_BR';

import { queryClient } from './services/queryClient';

import { App } from './App';

import './i18n';

// Fonts
import 'fontsource-roboto';
import 'typeface-roboto';

// Styles
import 'antd/dist/antd.less';
import 'react-confirm-alert/src/react-confirm-alert.css';
import 'react-phone-number-input/style.css'
import 'react-loading-skeleton/dist/skeleton.css'

const baseURL = process.env.REACT_APP_SERVER_BASE_URL || 'http://localhost:5500'

Sentry.init({
  dsn: 'https://3049ca6fc1c64518a74331c293d97bdc@o863288.ingest.sentry.io/5822185',
  integrations: [
    Sentry.browserTracingIntegration(),
    Sentry.replayIntegration(),
  ],

  tracePropagationTargets: [baseURL],
  // Set tracesSampleRate to 1.0 to capture 100%
  // of transactions for performance monitoring.
  // We recommend adjusting this value in production
  tracesSampleRate: 1.0,
  enabled: process.env.NODE_ENV !== 'development',
});

ReactDOM.render(
  <React.StrictMode>
    <ConfigProvider locale={ptBR}>
      <Toaster />
      <QueryClientProvider client={queryClient}>
        <App />
      </QueryClientProvider>
    </ConfigProvider>
  </React.StrictMode>,
  document.getElementById('root')
);