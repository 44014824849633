import React from "react";
import { LdsGrid, LoadingContent, LoadingOverlay } from "./styles";

interface LoadingPageProps {
  message?: string;
}

export const LoadingPage = ({ message }: LoadingPageProps) => (
  <LoadingOverlay>
    <LoadingContent>
      <LdsGrid>
        <div></div>
        <div></div>
        <div></div>
        <div></div>
        <div></div>
        <div></div>
        <div></div>
        <div></div>
        <div></div>
      </LdsGrid>
      <p>{message || 'A processar o seu pedido, por favor aguarde...'}</p>
    </LoadingContent>
  </LoadingOverlay>
);
