import React, { ReactNode } from 'react';

import { Row, Col } from 'reactstrap';
import { Link } from 'react-router-dom';
import { Container, ContainerSubList, ItemContainer, LabelContainer, ValueContainer } from './styles';

interface SummaryListProps {
  children: object;
  subtitle?: string;
  title: string;
  to?: string;
  visible?: boolean;
}

interface SummarySubListProps {
  children: object;
  title: string;
  visible?: boolean;
}

interface ItemProps {
  children: ReactNode;
  visible?: boolean;
}

const Item = ({ children, visible = true }: ItemProps) => (
  visible ? <ItemContainer>{children}</ItemContainer> : null
);

const Label = ({ children }: { children: ReactNode }) => (
  <LabelContainer>
    {children}
  </LabelContainer>
);
const Value = ({ children }: { children: ReactNode }) => (
  <ValueContainer sm={9}>
    {children}
  </ValueContainer>
);

const SummarySubList = ({ children, title, visible = true }: SummarySubListProps) => {
  return (
    visible ? (
      <ContainerSubList>
        <Row className="header">
          <Col>
            <div className="subtitle">{title}</div>
          </Col>
        </Row>
        <div className="body">{children}</div>
      </ContainerSubList>
    ) : null
  );
}

export const SummaryList = ({ children, subtitle, title, to, visible = true }: SummaryListProps) => {
  return (
    visible ? (
      <Container>
        <Row className="header">
          <Col>
            <div className="title">{title}</div>
          </Col>
          {subtitle && (
            <Col>
              <div className="subtitle"><small>{subtitle}</small></div>
            </Col>
          )}
          {to && (
            <Col className="actions">
              <Link key="btn-edit" to={{ pathname: to }}>
                Alterar
              </Link>
            </Col>
          )}
        </Row>
        <div className="body">{children}</div>
      </Container>
    ) : null
  );
}

SummaryList.Item = Item;
SummaryList.Label = Label;
SummaryList.Value = Value;
SummaryList.SummarySubList = SummarySubList;
