import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { ModalPage } from '../../../../../components/Modal';

interface InformativeOfSellsPopUpProps {
  onClose(): void;
}

export const InformativeOfSellsPopUp = ({ onClose }: InformativeOfSellsPopUpProps) => {
  const { t } = useTranslation();
  const [open, setOpen] = useState(false);

  useEffect(() => {
    setOpen(true);
  }, []);

  return (
    <ModalPage
      isOpen={open}
      toggle={() => {
        setOpen(false);
        onClose();
      }}
      title={t('informative_sells_pop_up.title')}>
      <div>
        <p>
          <strong>{t('informative_sells_pop_up.description')}</strong>
        </p>
        <p>
          <strong>1</strong> - {t('informative_sells_pop_up.point_1')}
        </p>
        <p>
          <strong>2</strong> - {t('informative_sells_pop_up.point_2')}
        </p>
        <p>
          <strong>3</strong> - {t('informative_sells_pop_up.point_3')}
        </p>
        <p>
          <strong>4</strong> - {t('informative_sells_pop_up.point_4')}
        </p>
      </div>
    </ModalPage>
  );
};

