import { MenuConfiguration } from "@/models/DataResponse";
import { useQueryClient } from "react-query";

export const CLIENT_CONFIGURATION = 'clientConfiguration';

export const useMenuConfiguration = () => {
  const queryClient = useQueryClient();

  const menuConfiguration = queryClient.getQueryData<MenuConfiguration>(CLIENT_CONFIGURATION);

  return { menuConfiguration };
};
