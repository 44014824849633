import { LocalStorageService } from "@/services/LocalStorageService";

export const CLIENT_CURRENT_LANGUAGE = 'clientCurrentLanguage';

export const useGetClientCurrentLanguage = () => {

  const currentLanguage = LocalStorageService.get<string>(CLIENT_CURRENT_LANGUAGE);

  return { currentLanguage };
};
