import api from './api';
import { Category } from '@/models/Category';

export class CategoryService {
  public static async create(category: Category): Promise<Category> {
    const response = await api.post('api/category', category);

    return response.data.data;
  }
  
  public static async update(category: Category): Promise<Category> {
    const headers = {
      company: category.company,
    };
    
    const response = await api.put(`api/category/${category._id}`, category, {
      headers: headers,
    });
    
    return response.data.data;
  }
  
  public static async updateListPositions(categories: Category[]): Promise<Category[]> {
    const response = await api.put(`/api/category/changepositions/${categories[0].company}`, categories);

    return response.data.data;
  }
}
