import React from 'react';
import { useTranslation } from 'react-i18next';
import { RulesInfo } from './styles';

export const CouponRulesInfo = () => {
  const { t } = useTranslation('translation', { keyPrefix: 'coupon.rules' });

  return (
    <RulesInfo>
      <p>{t('title')}</p>
      <ul>
        <li>{t('rule_one')}</li>
        <li>{t('rule_two')}</li>
        <li>{t('rule_three')}</li>
      </ul>
    </RulesInfo>
  );
}
