import React from "react";

import { Separator } from "@/common-styles";
import { OrderCost } from "../OrderCost";
import { ProductMenu } from "@/models/ProductCatalog";
import { SellsConfiguration } from "@/models/SellsConfiguration";
import { CheckoutOrderButton } from "../CheckoutOrderButton";

import { ResumeOrderContainer, SummaryLabel } from "./styles";
import { useTranslation } from "react-i18next";

interface OrderSummaryProps {
  isCountByProduct: boolean;
  handleEditAction: (isUpdate: boolean, product: ProductMenu) => void;
  sellsConfiguration: SellsConfiguration;
  onCheckout: () => void;
}

export const OrderSummary = ({ isCountByProduct, handleEditAction, sellsConfiguration, onCheckout }: OrderSummaryProps) => {
  const { t } = useTranslation();

  return (
    <ResumeOrderContainer>
      <SummaryLabel>{t('my_order_summary')}</SummaryLabel>

      <Separator />

      <OrderCost
        hideTotal
        sellsConfiguration={sellsConfiguration}
        handleEditAction={handleEditAction}
      />

      <CheckoutOrderButton
        isCountByProduct={isCountByProduct}
        currency={sellsConfiguration.currency}
        onClick={onCheckout}
      />
    </ResumeOrderContainer>
  );
}
