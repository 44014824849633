import React from "react";
import isNil from "lodash/isNil";
import { useTranslation } from "react-i18next";
import { AddressInput } from "./components/AddressInput";
import { FormInputField } from "@/components/FormInputField";
import { OrderSection } from "../../../OrderSection";

interface ClientAddressProps {
  radius?: number | undefined;
  onAddressSelected: (address: string) => void;
}

export const ClientAddress = ({ radius, onAddressSelected }: ClientAddressProps) => {
  const { t } = useTranslation();
  return (
    <OrderSection title={t('order.address.title')}>

      <AddressInput
        isAutocomplete={!isNil(radius)}
        onAddressSelected={onAddressSelected}
      />

      <FormInputField
        label={t('order.address.zip_code')}
        name="postalCode"
        placeholder={t('order.address.zip_code')}
        maxLength={9}
      />

      <FormInputField
        label={t('order.address.complement')}
        name="complement"
        placeholder={t('order.address.complement')}
        maxLength={100}
      />

      <FormInputField
        label={t('order.address.reference')}
        name="referencePoint"
        placeholder={t('order.address.reference')}
        maxLength={100}
        help={t('order.address.reference_tip')}
      />
    </OrderSection>
  );
};