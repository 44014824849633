import React from 'react';

import { Space, Table } from 'antd';
import type { ColumnsType } from 'antd/lib/table';
import { MenuOutlined } from '@ant-design/icons';

import {
  SortableContainer,
  SortableElement,
  SortableHandle,
} from 'react-sortable-hoc';
import { arrayMoveImmutable } from 'array-move';

import { LinkPage } from '../../../../../../../models/QRLink';
import { TableDeleteAction } from '../../../../../../../components/TableDeleteAction';
import { TableEditAction } from '../../../../../../../components/TableEditAction';
import LazyLoad from 'react-lazyload';
import { RoundTag } from '../../../../../../../common-styles';

const SortableItem = SortableElement((props: any) => <tr {...props} />);
const SortableContainerWrapper = SortableContainer((props: any) => (
  <tbody {...props} />
));
const DragHandle = SortableHandle(() => (
  <MenuOutlined style={{ cursor: 'grab', color: 'rgb(153, 153, 153)' }} />
));

interface LinksTableProps {
  handleDeleteLinkPage(item: LinkPage): void;
  handleEditLink(item: LinkPage): void;
  links: LinkPage[];
  onTableChanged(items: LinkPage[]): void;
};

export const LinksTable = ({
  handleDeleteLinkPage,
  handleEditLink,
  links,
  onTableChanged,
}: LinksTableProps) => {

  const isThumbnailAvailable = links.some(link => link.imageUrl);

  const columns = [
    {
      title: '',
      className: 'drag-visible',
      align: 'center' as 'center',
      visible: true,
      render: () => <DragHandle />,
    },
    {
      title: 'Info',
      visible: true,
      render: (_: any, item: LinkPage) => {
        return (
          <>
            <p>{item.name}</p>
            <p><RoundTag color={item.isEnabled ? 'green' : 'red'}>
              {item.isEnabled ? 'Ativo' : 'Pausado'}
            </RoundTag></p>
            <small>{item.url}</small>
          </>
        );
      },
    },
    {
      title: 'Thumbnail',
      align: 'center' as 'center',
      visible: isThumbnailAvailable,
      render: (_: any, item: LinkPage) => (
        item.imageUrl && (
          <LazyLoad height={60} once={true} key={item._id}>
            <img
              className="image-thumbnail"
              src={item.imageUrl}
              alt={item.name}
            />
          </LazyLoad>
        )
      ),
    },
    {
      title: 'Ações',
      visible: true,
      render: (_: any, item: LinkPage) => (
        <Space direction="vertical" key={item._id}>
          <TableEditAction onClick={() => handleEditLink(item)} />

          <TableDeleteAction
            onClick={() => handleDeleteLinkPage(item)}
            title={
              <span>
                Tem certeza que deseja remover este item <strong>{item?.name}</strong>?
              </span>
            }
          />
        </Space>
      ),
    },
  ].filter(item => item.visible);

  const onSortEnd = ({
    oldIndex,
    newIndex,
  }: {
    oldIndex: number;
    newIndex: number;
  }) => {
    if (oldIndex !== newIndex) {
      const newData = arrayMoveImmutable(
        ([] as LinkPage[]).concat(links),
        oldIndex,
        newIndex
      ).filter((el) => !!el);

      onTableChanged(
        newData.map((item, index) => {
          return {
            ...item,
            order: index,
          };
        })
      );
    }
  };

  const DraggableContainer = (props: any) => (
    <SortableContainerWrapper
      useDragHandle
      disableAutoscroll
      helperClass="row-dragging"
      onSortEnd={onSortEnd}
      {...props}
    />
  );

  const DraggableBodyRow = ({ ...restProps }) => {
    const index = links.findIndex(
      (x) => x.order === restProps['data-row-key']
    );
    return <SortableItem index={index} {...restProps} />;
  };

  return (
    <Table
      style={{ paddingBottom: '40px' }}
      pagination={false}
      dataSource={[...links]}
      columns={columns as ColumnsType<LinkPage>}
      rowKey="order"
      scroll={{ x: 500 }}
      components={{
        body: {
          wrapper: DraggableContainer,
          row: DraggableBodyRow,
        },
      }}
    />
  );
};
