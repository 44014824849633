import React, { ReactNode } from "react";
import { FormInstance } from "antd/es/form";
import { createContext, useContext } from "react";

type FormContextType = FormInstance | null;

const FormContext = createContext<FormContextType>(null);

export const useFormOrderInstance = (): FormInstance => {
  const context = useContext(FormContext);
  if (!context) {
    throw new Error("useFormInstance must be used within a FormProvider");
  }
  return context;
};

interface FormProviderProps {
  form: FormInstance;
  children: ReactNode;
}

export const FormOrderProvider = ({ form, children }: FormProviderProps) => {
  return (
    <FormContext.Provider value={form}>{children}</FormContext.Provider>
  );
};
