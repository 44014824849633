import { useCallback } from 'react';
import { useQueryClient } from 'react-query';

import api from '../../../../../services/api';
import { CompanyActivityLogService } from '../../../../../services/CompanyActivityLogService';
import { Category } from '@/models/Category';
import { CategoryHasDependency } from '../../../../../models/responseData/CategoryHasDependency';
import { CATEGORIES } from '../../../../../hooks';
import { AlertNotification } from '@/components/AlertNotification';
import { NotificationType } from '@/components/AlertNotification/NotificationType';

export const useDeleteCategory = () => {
  const queryClient = useQueryClient();

  const checkCategoryCanBeDeleted = useCallback(async (category: Category) => {
    const headers = {
      company: category.company,
    };

    try {
      const response = await api.get(
        `/api/category/checkDependency/${category._id}`,
        {
          headers,
        }
      );

      return response.data.data;
    } catch (e) {
      return false;
    }
  }, []);

  const executeDeletion = useCallback(async (category: Category) => {
    await api
      .delete(`api/category/${category._id}`, {
        headers: {
          company: category.company,
        },
      })
      .then(() => {
        queryClient.invalidateQueries(CATEGORIES);

        CompanyActivityLogService.register({ action: `Categoria <strong>${category.category}</strong> removida`, actionArea: 'Categoria', extra: category._id });

        AlertNotification({
          message: 'Categoria removida com sucesso.',
        });
      })
      .catch(() => {
        AlertNotification({
          message: 'Não foi possível excluir a categoria.',
          type: NotificationType.ERROR,
        });
      });
  }, [queryClient]);

  const handleDeleteAction = useCallback(async (category: Category) => {
    const response: CategoryHasDependency = await checkCategoryCanBeDeleted(category);
    if (response.canDelete) {
      executeDeletion(category);
    } else {
      AlertNotification({
        message: 'Não foi possível excluir a categoria',
        description:
          'Não é permitido remover esta categoria, pois existem produtos associados a mesma.',
        type: NotificationType.ERROR,
      });
    }
  }, [checkCategoryCanBeDeleted, executeDeletion]);

  return { handleDeleteAction };
};
