import { useFetch } from "@/hooks/useFetch";
import { Language } from "@/models/Languages";

const LANGUAGES = 'languages';

export const useLanguages = () => {
  const { data, ...rest } = useFetch<Language[]>({
      queryKey: [LANGUAGES],
      url: 'api/languages',
    });
  
    return { data, ...rest };
};
