import React, { useState } from 'react';
import { Button, Drawer, Form, Space, Switch, Table } from 'antd';
import { FormInputField } from '../../../../../../../../components/FormInputField';
import { CompanyFeatures } from '../../../../../../../../models/CompanyFeatures';
import { BreakPoints, useBreakpoint, useModal, useUpdateCompanyFeatures } from '../../../../../../../../hooks';
import { CustomInformationOption } from '../../../../../../../../models/SellsConfiguration';
import { RoundTag } from '../../../../../../../../common-styles';
import { TableEditAction } from '../../../../../../../../components/TableEditAction';
import { EditorCustomInformationItem } from '../EditorCustomInformationItem';
import { TableDeleteAction } from '../../../../../../../../components/TableDeleteAction';
import { AlertNotification } from '@/components/AlertNotification';
import { NotificationType } from '@/components/AlertNotification/NotificationType';

interface EditorCustomInformationProps {
  close(): void;
  companyFeatures: CompanyFeatures;
  isOpen: boolean;
};

export const EditorCustomInformation = ({ close, companyFeatures, isOpen }: EditorCustomInformationProps) => {
  const [selectedInfo, setSelectedInfo] = useState<CustomInformationOption>();
  const { breakPnt } = useBreakpoint();
  const { isLoading, update } = useUpdateCompanyFeatures();
  const { close: closeItemEditor, isOpen: isOpenItemEditor, toggle } = useModal();
  const { sellsConfiguration: { customInformation } } = companyFeatures;
  const [options, setOptions] = useState<CustomInformationOption[]>(customInformation?.options ?? []);

  const onFinish = async (values: any) => {
    if (options.length < 1) {
      AlertNotification({
        message: 'É preciso adicionar pelo menos uma opção.',
        type: NotificationType.ERROR,
      });

      return;
    }

    const body = {
      ...values,
      options
    }

    companyFeatures.sellsConfiguration.customInformation = body;

    update(companyFeatures, {
      onSuccess: () => {
        AlertNotification({
          message: 'Operação realizada com sucesso.',
        });
        close();
      },
      onError: () => {
        AlertNotification({
          message: 'Erro ao tentar proceder com a operação.',
          type: NotificationType.ERROR,
        });
      },
    });
  };

  return (
    <>
      <EditorCustomInformationItem
        close={closeItemEditor}
        customInformationOption={selectedInfo}
        isOpen={isOpenItemEditor}
        onOptionCreated={(option) => setOptions((prev) => [...prev, option])}
        onOptionChanged={(oldOption, newOption) => {
          const index = options.indexOf(oldOption);
          options[index] = newOption;
          setOptions(options);
        }}
      />

      <Drawer
        destroyOnClose={true}
        maskClosable={false}
        onClose={close}
        placement="right"
        size="large"
        title={'Informações adicionais'}
        open={isOpen}
      >
        <Form
          layout="vertical"
          initialValues={customInformation}
          onFinish={onFinish}
        >
          <Form.Item
            label={<label className="">Status</label>}
            name="isEnabled"
            valuePropName="isEnabled"
          >
            <Switch
              id="isEnabled"
              key="isEnabled"
              defaultChecked={customInformation?.isEnabled}
              checkedChildren="Ativo"
              unCheckedChildren="Pausado"
            />
          </Form.Item>

          <Form.Item
            label={<label className="">Escolha obrigatória?</label>}
            name="isRequired"
            valuePropName="isRequired"
            help="Essa opção irá forçar o cliente a escolher uma das opções disponíveis, no resumo do pedido."
          >
            <Switch
              id="isRequired"
              key="isRequired"
              defaultChecked={customInformation?.isRequired}
              checkedChildren="Sim"
              unCheckedChildren="Não"
            />
          </Form.Item>

          <FormInputField
            label="Título"
            name="title"
            placeholder="Escreva um título..."
            rules={[
              {
                required: true,
                message: 'Campo obrigatório',
              },
            ]}
            style={{ marginTop: '16px' }}
          />

          <FormInputField
            label="Descrição"
            name="description"
            placeholder="Descrição..."
            isInputArea={true}
            inputTextAreaProps={{ rows: 5 }}
          />

          <Button
            type="primary"
            onClick={() => {
              setSelectedInfo(undefined);
              toggle();
            }}
          >
            <i
              className="fa fa-plus-circle"
              aria-hidden="true"
              style={{ marginRight: '10px' }}
            />
            Nova opção
          </Button>

          <Table
            bordered
            dataSource={[...options]}
            pagination={false}
            style={{ marginTop: '16px' }}
          >
            <Table.Column
              title="Status"
              align={'center' as 'center'}
              width={breakPnt === BreakPoints.LG ? 100 : 70}
              render={(option: CustomInformationOption) => (
                <RoundTag color={option.isEnabled ? 'green' : 'red'} key={option._id}>
                  {option.isEnabled ? 'Ativo' : 'Pausado'}
                </RoundTag>
              )}
            />
            <Table.Column
              dataIndex="title"
              title="Título"
            />
            <Table.Column
              dataIndex="description"
              title="Descrição"
            />

            <Table.Column
              title="Ações"
              align={'center' as 'center'}
              render={(option: CustomInformationOption) => (
                <Space direction="vertical" key={option._id}>
                  <TableEditAction onClick={() => {
                    setSelectedInfo(option);
                    toggle();
                  }} />
                  <TableDeleteAction
                    onClick={() => {
                      const index = options.indexOf(option);
                      const list = [...options];
                      list.splice(index, 1);
                      setOptions(list);
                    }}
                    title={
                      <span>
                        Tem certeza que deseja remover esta opção{' '}
                        <strong>{option.title}</strong>? <br />
                        Uma vez removido, esta ação não poderá ser revertida.
                      </span>
                    }
                  />
                </Space>
              )}
            />
          </Table>

          <Space style={{ marginTop: '16px' }}>
            <Button danger onClick={close}>
              <i
                className="fa fa-times"
                aria-hidden="true"
                style={{ marginRight: '10px' }}
              />
              Cancelar
            </Button>

            <Button type="primary" htmlType="submit" loading={isLoading}>
              <i
                className="fa fa-check-circle"
                aria-hidden="true"
                style={{ marginRight: '10px' }}
              />
              Salvar
            </Button>
          </Space>
        </Form>
      </Drawer>
    </>
  );
};
