import React from 'react';
import parse from 'html-react-parser';
import shortid from 'shortid';
import { useTranslation } from 'react-i18next';

import { OrderComplementExhibition } from '../../../../../../../../../../../models/DataResponse';
import { ProductMenu } from '../../../../../../../../../../../models/ProductCatalog';

import { 
  EditLabel, 
  LabelButtonContainer, 
  ProductComplementItemOption,
  ProductComplements,
  ProductComplementTitle,
  ProductDescription,
  ProductOrderItemContainer, 
  ProductPrice,
  ProductTitle,
  RemoveLabel,
 } from './styles';
import { calculateProductsTotalCost } from '@/hooks';
import formatCurrency from '@/utils/formatCurrency';

interface ProductOrderItemProps {
  product: ProductMenu;
  currency: string;
  orderComplementExhibition: OrderComplementExhibition;
  handleDeleteAction: any;
  handleEditAction: any;
}

export const ProductOrderItem = ({
  product,
  currency,
  orderComplementExhibition,
  handleDeleteAction,
  handleEditAction,
}: ProductOrderItemProps) => {
  const { t } = useTranslation();
  const { name, description, price, counter, extraNote, selectedComplements } = product;

  const totalPrice = calculateProductsTotalCost([product]);
  let priceStr = price;
  if (!isNaN(Number(totalPrice))) {
    priceStr = formatCurrency(totalPrice, currency);
  }

  return (
    <ProductOrderItemContainer>
      <div key={`${product._id}${shortid.generate()}`}>
        <ProductTitle>{name && `(${counter}x) ${parse(name)}`}</ProductTitle>
        <ProductDescription>
          {description && parse(description)}
        </ProductDescription>
        {selectedComplements && (
          <ProductComplements>
            <ul>
              {selectedComplements?.map((complements) => {
                return (
                  <>
                    {orderComplementExhibition.showTitle && (
                      <ProductComplementTitle>
                        {complements.title}
                      </ProductComplementTitle>
                    )}

                    {complements.complementItemOptions
                      .filter(
                        (item) =>
                          item.counter !== undefined && item.counter !== 0
                      )
                      .map((option) => {
                        return (
                          <ProductComplementItemOption
                            showTitle={orderComplementExhibition.showTitle}
                            key={option._id}
                          >
                            {`${option.counter}x ${option.title}`}
                          </ProductComplementItemOption>
                        );
                      })}
                  </>
                );
              })}
            </ul>
          </ProductComplements>
        )}
        {extraNote && (
          <>
            <br />
            <ProductDescription>Obs.: {extraNote}</ProductDescription>
          </>
        )}
        <LabelButtonContainer>
          <EditLabel onClick={handleEditAction}>{t('common.edit')}</EditLabel>
          <RemoveLabel onClick={handleDeleteAction}>{t('common.remove')}</RemoveLabel>
        </LabelButtonContainer>
      </div>

      <ProductPrice>{priceStr}</ProductPrice>
    </ProductOrderItemContainer>
  );
};
