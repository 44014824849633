import React, { useState } from "react";
import PhoneInput, { Country } from 'react-phone-number-input';
import './styles.css';

interface PhoneNumberMaskInputProps {
  autoComplete?: string;
  defaultCountry?: Country;
  defaultValue?: string;
  placeholder?: string;
  rules?: any;
  onValueChanged?: (value: string | undefined) => void;
}

export const PhoneNumberMaskInput = ({ autoComplete = "nope", defaultCountry, defaultValue, placeholder, rules, onValueChanged }: PhoneNumberMaskInputProps) => {
  const [phone, setPhone] = useState<string>();
  
  return (
    <PhoneInput
      className="phone-number-mask-input"
      defaultCountry={defaultCountry}
      placeholder={placeholder}
      rules={rules}
      value={defaultValue || phone }
      onChange={(value) => {
        setPhone(value?.toString());
        if (onValueChanged) {
          onValueChanged(value?.toString() || '');
        }
       }}
      autoComplete={autoComplete}
    />
  );
};
