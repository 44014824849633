import styled from 'styled-components';

interface LabelOptionsContainerProps {
  isSelected: boolean;
}

interface ContainerProps {
  hasTabOptions: boolean;
}

export const Container = styled.div.withConfig({
  shouldForwardProp: (prop) => prop !== 'hasTabOptions',
})<ContainerProps>`
  background-color: white;
  height: 60px;
  display: flex;
  overflow: auto;
  white-space: nowrap;
  margin-top: ${props => props.hasTabOptions ? '0' : '40px'};
  z-index: 0;

  padding-right: 20px;

  /* Scrollbar styles for Webkit browsers */
  &::-webkit-scrollbar {
    height: 10px; /* Height of the horizontal scrollbar */
  }

  &::-webkit-scrollbar-track {
    background: #f1f1f1; /* Background of the scrollbar track */
  }

  &::-webkit-scrollbar-thumb {
    background: #888; /* Color of the scrollbar thumb */
    border-radius: 10px; /* Rounded corners */
  }

  &::-webkit-scrollbar-thumb:hover {
    background: #555; /* Color of the scrollbar thumb when hovered */
  }

  /* Scrollbar styles for Firefox */
  scrollbar-width: thin; /* Set scrollbar width */
  scrollbar-color: #888 #f1f1f1; /* Thumb color and track color */

  @media (max-width: 500px) {
    width: 400px; 
  }

  @media (max-width: 400px) {
    width: 400px; 
  }

  @media (max-width: 320px) {
    width: 320px;
  }
`;

export const OptionsItemsContainer = styled.div`
  display: flex;
  text-align: center;
  justify-content: center;
  align-items: center;
  margin: 0 auto;
`;

export const LabelOptionsContainer = styled.div.withConfig({
  shouldForwardProp: (prop) => prop !== 'isSelected',
})<LabelOptionsContainerProps>`
  overflow: hidden;
  
  display: flex;
  flex-direction: column;

  margin-left: 16px;

  > small {
    background: ${props => props.isSelected ? '#1A1919' : 'white'};
    border: 1px solid #1A1919;
    border-radius: 16px;
    color: ${props => props.isSelected ? 'white' : '#1A1919'};
    cursor: pointer;
    font-family: 'Open Sans';
    font-size: 12px;
    font-style: normal;
    font-weight: 600;
    height: 30px;
    line-height: 14px;
    padding: 8px 12px;
  }
`;