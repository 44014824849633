import React, { useState } from "react";
import { Tabs, TabsProps } from "antd";
import { InputsLangs } from "../InputsLangs";

export interface TabLangItem {
  titleId: string;
  descriptionId: string;
  keyLang: string;
  lang: string;
  isTitleRequired: boolean;
  useRichText: boolean;
}

interface TabLangsProps {
  defaultTabKey: string;
  langs: TabLangItem[];
  titleMaxLength?: number;
  descriptionMaxLength?: number;
}

export const TabLangs = ({ defaultTabKey, langs, titleMaxLength, descriptionMaxLength }: TabLangsProps) => {
  const [currentTab, setCurrentTab] = useState<string>(defaultTabKey);

  const items: TabsProps['items'] = langs.map(({ titleId, descriptionId, isTitleRequired, keyLang, lang, useRichText }) => ({
    key: keyLang,
    label: lang,
    children: (
      <InputsLangs
        descriptionId={descriptionId}
        isTitleRequired={isTitleRequired}
        titleId={titleId}
        useRichText={useRichText}
        titleMaxLength={titleMaxLength}
        descriptionMaxLength={descriptionMaxLength}
      />
    ),
  }));

  return (
    <Tabs
      defaultValue={defaultTabKey}
      activeKey={currentTab.toString()}
      items={items}
      onChange={(key: string) => setCurrentTab(key)}
      style={{ marginTop: '16px' }}
      size='large'
    />
  );
};
