import styled from 'styled-components';

interface DetailsButtonProps {
  color: string;
  isDetail: boolean;
}

interface CommonProps {
  hasImage?: boolean;
}

const gridWithImage = `'title img'
'desc img'
'price img'
'add img'`;

export const Container = styled.div`
  height: fit-content;
  display: grid;
  grid-template-columns: 1fr;
  grid-template-rows: auto;
  grid-template-areas: ${gridWithImage};

  align-items: initial;
  margin-top: 12px;

  background-color: #f1f1f1;
  border-radius: 10px;
  padding: 14px;

  @media (max-width: 500px) {
    margin-top: 0;
  }
`;

export const ProductTitle = styled.div`
  grid-area: title;

  span {
    font-family: Ubuntu;
    font-weight: 600;
    font-size: 18px;
    color: #0c0901;
    text-align: start;
  }

  @media (max-width: 320px) {
    font-size: 15px;
  }
`;

export const ProductDescription = styled.div`
  grid-area: desc;
  margin-top: 10px;
  span {
    color: #6c6c80;
  }
`;

export const ProductPrice = styled.span`
  grid-area: price;
  text-align: left;
  margin-top: 10px;
  justify-self: start;

  > p {
    font-family: Ubuntu;
    font-weight: bold;
    font-size: 18px;
    color: #3f3e3e;
    text-align: start;
  }
`;

export const ProductImage = styled.div`
  grid-area: img;
  cursor: pointer;
`;

export const DetailsButton = styled.div.withConfig({
  shouldForwardProp: (prop) => prop !== 'isDetail',
})<DetailsButtonProps>`
  width: ${(props) => (props.isDetail ? '110px' : '115px')};
  display: flex;
  justify-content: center;

  grid-area: add;
  margin-top: 16px;
  padding: 8px 10px;

  text-align: center;

  background-color: #27AE60;
  color: white;
  font-size: 14px;
  font-weight: bold;
  border-radius: 10px;

  cursor: pointer;

  &:hover {
    opacity: 0.7;
  }
`;

/**
 * @deprecated TO BE UPDATED
 */
export const PriceDiscountContent = styled.div<CommonProps>`
  display: flex;
  flex-direction: ${(props) => (props.hasImage ? 'row' : 'column')};
  text-align: ${(props) => (props.hasImage ? 'left' : 'right')};
  justify-content: left;
  margin-top: 6px;

  > h4 {
    text-decoration: line-through;
    color: #b4b4b4;
    font-weight: normal;
    font-size: 15px;
    margin-top: 4px;
  }

  > h3 {
    font-weight: bold;
    font-size: 18px;
    margin-left: 8px;
    margin-top: 2px;
    color: #3f3e3e;
  }

  > small {
    margin-top: 4px;
    font-weight: bold;
  }
`;
