import React from "react";
import { DatePicker, Form, Space, TimePicker } from "antd";
import moment from 'moment';
import { useTranslation } from "react-i18next";

import { SellsConfiguration } from "@/models/SellsConfiguration";
import { FormHelperText } from "@/common-styles";
import { DATE_FORMAT, SERVER_DATE_FORMAT, TIME_FORMAT } from "@/utils/dateTime";
import { OrderSection } from "../../../OrderSection";

interface ScheduleOrderProps {
  sellsConfiguration: SellsConfiguration;
  hintMessagePreSchedule: string;
}

export const ScheduleOrder = ({ sellsConfiguration, hintMessagePreSchedule }: ScheduleOrderProps) => {
  const { t } = useTranslation();
  
  const {
    isDatetimeForDeliveryOrPickupVisible,
    isTimePickerForDeliveryOrPickupVisible,
    startDatePicker, isTimePickerRequired, isDatePickerRequired } = sellsConfiguration;

  const getLabelForDatetime = () => {
    let translationKey = 'time';
    if (isDatetimeForDeliveryOrPickupVisible && isTimePickerForDeliveryOrPickupVisible) {
      translationKey = 'dateTime';
    } else if (isDatetimeForDeliveryOrPickupVisible) {
      translationKey = 'date';
    }

    return t(`order.schedule.${translationKey}`);
  }

  if (!isDatetimeForDeliveryOrPickupVisible && !isTimePickerForDeliveryOrPickupVisible) return null;

  return (
    <OrderSection title={getLabelForDatetime()}>
      <Space>
        {isDatetimeForDeliveryOrPickupVisible && (
          <Form.Item
            name="scheduledDate"
            rules={[{
              required: isDatePickerRequired,
              message: t('common.required_field'),
            }]}
          >
            <DatePicker
              placeholder="Data"
              format={DATE_FORMAT}
              disabledDate={(current) => {
                let compareDateMoment = moment(moment().format(SERVER_DATE_FORMAT), SERVER_DATE_FORMAT)
                const startDateMoment = startDatePicker && moment(startDatePicker, SERVER_DATE_FORMAT);

                if (startDateMoment && startDateMoment >= compareDateMoment) {
                  compareDateMoment = startDateMoment;
                }

                return current && current < compareDateMoment;
              }}
              style={{ width: '100%' }}
            />
          </Form.Item>
        )}

        {isTimePickerForDeliveryOrPickupVisible && (
          <Form.Item
            name="scheduledTime"
            rules={[{
              required: isTimePickerRequired,
              message: t('common.required_field'),
            }]}>
            <TimePicker format={TIME_FORMAT} />
          </Form.Item>
        )}
      </Space>

      <FormHelperText
        style={{
          fontStyle: 'italic',
          overflowWrap: 'break-word',
          padding: '6px 12px',
          textAlign: 'center',
        }}>
        {hintMessagePreSchedule}
      </FormHelperText>
    </OrderSection>
  );
};
