import React, { useEffect, useState } from "react";
import { AutoComplete, Form, Input } from "antd";
import { SearchOutlined } from '@ant-design/icons';
import { useTranslation } from "react-i18next";

import { useAddressAutocomplete } from "@/hooks/useAddressAutocomplete";
import { useDebounceValue } from "@/hooks/useDebounceValue";
import { DEFAULT_RULE } from "@/components/FormInputField";
import { LoaderSpinner } from "@/components/LoaderSpinner";

interface AutocompleteFieldProps {
  onAddressSelected: (address: string) => void;
}

export const AutocompleteField = ({ onAddressSelected }: AutocompleteFieldProps) => {
  const [isFieldEmpty, setIsFieldEmpty] = useState(false);
  const [selectedAddress, setSelectedAddress] = useState<boolean>(false);
  const [searchText, setSearchText] = useState('');
  const debouncedSearch = useDebounceValue(searchText);
  const { data: addresses, isLoading, setInputAddressSearch } = useAddressAutocomplete();
  const { t } = useTranslation();

  const onSelect = (value: string) => {
    onAddressSelected(value);
    setSelectedAddress(true);
  };

  const handleBlur = () => setIsFieldEmpty(!selectedAddress);

  useEffect(() => {
    setInputAddressSearch(debouncedSearch);
  }, [debouncedSearch, setInputAddressSearch]);


  return (
    <Form.Item
      help={isFieldEmpty ? t('order.address.address_input_help') : ''}
      label={<label className="label-input-field">{t('order.address.address_input')}</label>}
      name="address"
      rules={DEFAULT_RULE}
      style={{ marginTop: '16px' }}
    >
      <AutoComplete
        options={addresses?.map((address) => ({ value: address }))}
        onSelect={onSelect}
        onSearch={setSearchText}
        onBlur={handleBlur}
        onClear={() => setSelectedAddress(false)}
      >
        <Input
          addonAfter={isLoading ? <LoaderSpinner isSmall /> : <SearchOutlined />}
          id="address"
          placeholder={t('order.address.address_input_hint')}
          style={{ height: '40px' }}
        />
      </AutoComplete>
    </Form.Item>
  );
};
