import { useCallback } from "react";
import { useUpdateOrder } from "../../../../hooks";
import { AlertNotification } from "../../../../components/AlertNotification";
import { NotificationType } from "../../../../components/AlertNotification/NotificationType";

export const useUpdateOrderStatus = () => {
  const { update } = useUpdateOrder();

  const updateOrderStatus = useCallback((order, newStatus) => {
    update(
      {
        ...order,
        status: newStatus,
      },
      {
        onSuccess: () => {
          AlertNotification({
            message: 'Operação realizada com sucesso',
          });
        },
        onError: () => {
          AlertNotification({
            message: 'Falha ao tentar atualizar o status do Pedido',
            type: NotificationType.ERROR,
          });
        }
      }
    );
  }, [update]);

  return { updateOrderStatus };
};