import React from 'react';
import { Space, Table } from 'antd';
import {
  LinkOutlined,
} from '@ant-design/icons';
import type { ColumnsType } from 'antd/lib/table';

import { TableGenericAction } from '../../../../../components/TableGenericAction';

import { Company } from '../../../../../models/Company';
import { getClientMenuUrl } from '../../../../../utils/Util';
import { useListCompaniesForCustomers } from '../../../../../hooks';

export const StoresTable = () => {
  const { data: companies, isLoading } = useListCompaniesForCustomers();

  const handleLinkClick = (menuPath: string) => {
    const a = document.createElement('a');
    a.target = '_blank';
    a.href = getClientMenuUrl(menuPath);
    a.click();
  };

  const columns: ColumnsType<Company> = [
    {
      title: 'Empresa',
      dataIndex: 'fantasyName',
    },
    {
      title: 'Código de acesso',
      dataIndex: 'accessCode',
    },
    {
      title: 'URL',
      render: (_: any, item: Company) => getClientMenuUrl(item.slug),
    },
    {
      title: 'Ações',
      width: 150,
      render: (_: any, item: Company) => (
        <Space direction="vertical" key={item._id}>
          <TableGenericAction
            icon={<LinkOutlined style={{ fontSize: '18px' }} />}
            label="Cardápio"
            onClick={() => handleLinkClick(item.slug)}
          />
        </Space>
      ),
    },
  ];

  return (
    <Table
      bordered
      columns={columns}
      dataSource={companies ? [...companies] : []}
      rowKey={(c: Company) => c._id}
      loading={isLoading}
      pagination={false}
      scroll={{ x: 1300 }}
    />
  );
};
