import styled from "styled-components";

export const Container = styled.div`
  display: grid;
  grid-template-columns: 1fr 1fr;
  gap: 10px;
  max-width: 400px;

  .checkbox-item {
    margin-left: 10px;
  }
`;

export const Label = styled.p`
  font-size: 16px;
  font-family: Ubuntu;
`;