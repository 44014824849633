import React from "react";
import { Button, Space } from "antd";

interface PageFooterButtonsProps {
  onClose: () => void;
}

export const PageFooterButtons = ({ onClose }: PageFooterButtonsProps) => {
  return (
    <Space style={{ width: '100%', justifyContent: 'end' }}>
      <Button
        danger
        style={{ marginRight: '10px' }}
        onClick={onClose}
      >
        <i
          className="fa fa-times"
          aria-hidden="true"
          style={{ marginRight: '10px' }}
        />
        Cancelar
      </Button>

      <Button htmlType="submit" type="primary">
        <i
          className="fa fa-check-circle"
          aria-hidden="true"
          style={{ marginRight: '10px' }}
        />{' '}
        Salvar
      </Button>
    </Space>
  );
}
