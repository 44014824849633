import React, { useState } from "react";
import { useParams } from "react-router-dom";
import { Button, Checkbox, Form, Space } from "antd";

import { useFeatures, useModal } from "@/hooks";
import { FormSwitchItem } from "@/components/FormSwitchItem";
import { MenuTabOptions } from "./components/MenuTabOptions";

export const MultiTabs = () => {
  const { id: companyId } = useParams();
  const { data: features } = useFeatures(companyId);

  const { close, isOpen, open: openMenuOption } = useModal();
  const [isEnabled, setIsEnabled] = useState<boolean>(features?.menuOptionsEnabled ?? false);

  return (
    <Space direction="vertical" style={{ width: '100%' }}>

      <FormSwitchItem
        label="Módulo multi-abas"
        propertyId="menuOptionsEnabled"
        defaultChecked={isEnabled}
        checkedValueText={'Ativo'}
        uncheckedValueText={'Pausado'}
        handleCheckboxChange={(value) => setIsEnabled(value)}
      />

      {isEnabled && (
        <>
          <Form.Item
            name="menuOptionsShouldBeHidden"
            valuePropName="checked"
            help="Ao selecionar a opção de Ocultar Aba, as Abas serão escondidas ou exibidas conforme a configuração escolhida de exibir por dia ou por hora. Por padrão, todas as abas serão exibidas."
          >
            <Checkbox>Ocultar aba</Checkbox>
          </Form.Item>

          <Button
            type='dashed'
            onClick={() => openMenuOption()}
            style={{ marginTop: '20px', background: '#31B86F', color: 'white' }}
          >
            <i
              className="fa fa-check-circle"
              aria-hidden="true"
              style={{ marginRight: '10px' }}
            />
            Adicionar Tab
          </Button>

          <MenuTabOptions
            isOpen={isOpen}
            featureId={features?._id}
            onClose={() => close()}
            onOpen={() => openMenuOption()}
            previousMenuOptions={features?.menuOptions || []} />
        </>
      )}
    </Space>
  );
};
