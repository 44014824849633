import { useCallback } from 'react';
import { useMutation, useQueryClient } from 'react-query';
import { Features } from '@/models/Features';
import { FeatureService } from '@/pages/backoffice/Companies/containers/Features/services/FeatureService';
import { FEATURES } from './useFeatures';

export const useUpdateFeatures = () => {
  const queryClient = useQueryClient();

  const { mutate, ...rest } = useMutation(
    (features: Features) => FeatureService.update(features),
    {
      onSettled: () => {
        queryClient.invalidateQueries(FEATURES);
      },
    },
  );

  const update = useCallback(
    (features, { onSuccess, onError }) => {
      mutate(features, {
        onSuccess,
        onError,
      });
    },
    [mutate]
  );

  return { update, ...rest };
};
