import React from "react";
import { Container } from "./styles";

interface OrderTitleSectionProps {
  title: string;
  subtitle?: string;
}

export const OrderTitleSection = ({ title, subtitle }: OrderTitleSectionProps) => (
  <Container>
    <h2>{title}</h2>
    {subtitle && <p>{subtitle}</p>}
  </Container>
);
