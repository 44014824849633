import React from 'react';
import { useTranslation } from 'react-i18next';
import { Coupon } from '../../../../../../../../../models/Coupon';
import formatCurrency from '../../../../../../../../../utils/formatCurrency';
import { Container, Label, Value } from './styles';

interface CouponCodeProps {
  currency: string;
  subtotal: number;
  coupon: Coupon;
};

export const CouponCode = ({ currency, subtotal, coupon }: CouponCodeProps) => {
  const { t } = useTranslation();
  const isPercentage = Number(coupon.discountType) === 0;

  let formattedValue = formatCurrency(coupon.discountValue, currency);

  if (isPercentage) {
    const discount = subtotal - (subtotal * coupon.discountValue) / 100;
    formattedValue = `${coupon.discountValue}% (${formatCurrency(
      subtotal - discount,
      currency
    )})`;
  }

  return (
    <Container>
      <Label>{t('order.cost.coupon')}</Label>
      <Value>-{formattedValue}</Value>
    </Container>
  );
};
