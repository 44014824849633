
import { Drawer, Layout } from "antd";
import React, { useMemo } from "react";
import { Navigate, Route, Routes, useNavigate } from "react-router-dom";
import { confirmAlert } from "react-confirm-alert";
import packageJson from '../../package.json';

import { PageNotFound } from "../components/PageNotFound";
import { SideBarMenu } from "../components/SideBarMenu";
import { SideBarMenuContent } from "../components/SideBarMenu/components/SideBarMenuContent";
import { TopBar } from "../components/TopBar";
import { BreakPoints, useBreakpoint, useListProductsOutOfStock, useLoggedUser, useModal, useUnreadContactsCounter } from "../hooks";
import { Role } from "../models/Enum";
import { ActivityLogsPage } from "../pages/backoffice/ActivityLogs";
import { AdminActivityLogPage } from "../pages/backoffice/AdminActivityLog";
import { CategoryPage } from "../pages/backoffice/Category/containers/List";
import { ListCompanyPage } from "../pages/backoffice/Companies/containers/List";
import { ContactPage } from "../pages/backoffice/Contacts";
import { CouponPage } from "../pages/backoffice/Coupon";
import { FeedbackPage } from "../pages/backoffice/Feedback";
import { HistoryOrders } from "../pages/backoffice/HistoryOrders";
import { OrdersPage } from "../pages/backoffice/Orders";
import { OrdersDriverPage } from "../pages/backoffice/OrdersDriver";
import { ProductsPage } from "../pages/backoffice/Products";
import PushInformationPage from "../pages/backoffice/PushInformation";
import { QRcodeGeneratorPage } from "../pages/backoffice/QRcode";
import { QRLinksPage } from "../pages/backoffice/QRLinks";
import { SettingsDefaultTextsPage } from "../pages/backoffice/Settings/SettingsDefaultTexts";
import { SettingsFeedbackPage } from "../pages/backoffice/Settings/SettingsFeedback";
import { SettingsManagerPage } from "../pages/backoffice/Settings/SettingsManager";
import { SettingsOrdersPage } from "../pages/backoffice/Settings/SettingsOrders";
import { SettingsProfilePage } from "../pages/backoffice/Settings/SettingsProfile";
import { SettingsStorePage } from "../pages/backoffice/Settings/SettingsStore";
import { StorekeeperPage } from "../pages/backoffice/Storekeepers";
import { UserPage } from "../pages/backoffice/Users";
import OrderDetailsStaff from "../pages/backoffice/OrderDetailsStaff";
import { PendingCompanyRegistrationPage } from "../pages/backoffice/Companies/containers/PendingCompanyRegistration";
import { ViewCompany } from "../pages/backoffice/Companies/containers/View";
import { CreateCompanyPage } from "../pages/backoffice/Companies/containers/Create";
import { EditCompany } from "../pages/backoffice/Companies/containers/Edit";
import { FeaturesPage } from "../pages/backoffice/Companies/containers/Features";
import { CreateCategoryPage } from "@/pages/backoffice/Category/containers/Create";
import { UpdateCategoryPage } from "@/pages/backoffice/Category/containers/Edit";

//TODO - review this style
import './styles.css';

const { Content, Footer } = Layout;

export const Backoffice = () => {
  const { data: unreadContactsReader } = useUnreadContactsCounter();
  const { data: loggedUser, isError } = useLoggedUser();
  const { data: stock } = useListProductsOutOfStock(loggedUser?.user?.company);
  const navigate = useNavigate();

  const { breakPnt } = useBreakpoint()
  const { close, isOpen, toggle: toggleSideMenu } = useModal();

  const routePath = (path: string = "") => `/sysadmin/${path}`;
  const showSideMenu = useMemo(() => breakPnt === BreakPoints.LG, [breakPnt]);

  const redirectTo = () => {
    const role = loggedUser?.user?.role;

    let redirectPath = 'categories';

    if (role === Role.ADMIN) {
      redirectPath = 'companies';
    } else if (role === Role.MANAGER || role === Role.KITCHEN || role === Role.DRIVER) {
      redirectPath = role === Role.DRIVER ? 'orders-driver' : 'orders';
    } 

    return routePath(redirectPath);
  }

  if (isError) {
    confirmAlert({
      title: 'Sessão inválida',
      message: 'Você será redirecionado para efetuar um novo login.',
      buttons: [
        {
          label: 'Ok',
          onClick: () => {
            localStorage.clear();
            navigate('/session');
          },
        },
      ],
      closeOnClickOutside: false,
    });
  }

  if (!loggedUser) {
    return null;
  }

  return (
    <Layout style={{ minHeight: '100vh', minWidth: '374px' }}>
      {showSideMenu && (
        <SideBarMenu
          contactsBadgeCounter={unreadContactsReader?.total}
          stockCounter={{
            outOfStock: stock?.listOutOfStock?.length,
            lowStock: stock?.listLowStock?.length,
          }}
        />
      )}

      <Drawer
        placement="left"
        closable={false}
        onClose={close}
        open={isOpen}
        width={220}
        bodyStyle={{ background: '#15395b', padding: '0', height: '100vh' }}
      >
        <SideBarMenuContent
          contactsBadgeCounter={unreadContactsReader?.total}
          stockCounter={{
            outOfStock: stock?.listOutOfStock?.length,
            lowStock: stock?.listLowStock?.length,
          }}
          onMenuSelected={() => toggleSideMenu()}
        />
      </Drawer>

      <Layout className="site-layout">
        <TopBar
          companyInfo={loggedUser.companyInfo}
          onSideMenuClick={toggleSideMenu}
          username={loggedUser.user.name ?? '--'}
        />

        <Content style={{ margin: '0 16px' }}>
          <div
            className="site-layout-background"
            style={{ padding: 24, minHeight: 360, marginTop: '16px' }}
          >
            <Routes>
              <Route element={<Navigate to={redirectTo()} />} path={routePath()} />
              <Route element={loggedUser.user.role === Role.ADMIN ? <AdminActivityLogPage /> : <ActivityLogsPage />} path={routePath('activity-log')} />
              <Route element={loggedUser.user.role === Role.ADMIN ? <ListCompanyPage /> : <StorekeeperPage />} path={routePath('companies')} />
              <Route element={loggedUser.user.role === Role.DRIVER ? <OrdersDriverPage /> : <OrdersPage />} path={routePath('orders')} />

              <Route element={<CreateCompanyPage />} path={routePath('companies/create')} />
              <Route element={<EditCompany />} path={routePath('companies/edit/:id')} />
              <Route element={<ViewCompany />} path={routePath('companies/view/:id')} />
              <Route element={<FeaturesPage />} path={routePath('companies/view/:id/features')} />
              <Route element={<PendingCompanyRegistrationPage />} path={routePath('companies/:uuid/pending')} />

              <Route element={<CategoryPage />} index path={routePath('categories')} />
              <Route element={<CreateCategoryPage />} index path={routePath('categories/:companyId')} />
              <Route element={<UpdateCategoryPage />} index path={routePath('categories/:companyId/:categoryId')} />

              <Route element={<ContactPage />} path={routePath('contacts')} />
              <Route element={<CouponPage />} path={routePath('coupons')} />
              <Route element={<FeedbackPage />} path={routePath('feedbacks')} />
              <Route element={<HistoryOrders />} path={routePath('history/orders')} />
              <Route element={<OrderDetailsStaff />} path={routePath('order-detail')} />
              <Route element={<ProductsPage />} path={routePath('products')} />
              <Route element={<PushInformationPage />} path={routePath('banner')} />
              <Route element={<QRcodeGeneratorPage />} path={routePath('qrcode/generator')} />
              <Route element={<QRLinksPage />} path={routePath('qrlinks')} />
              <Route element={<SettingsDefaultTextsPage />} path={routePath('settings/default-texts')} />
              <Route element={<SettingsFeedbackPage />} path={routePath('settings/feedback')} />
              <Route element={<SettingsManagerPage />} path={routePath('settings/general')} />
              <Route element={<SettingsOrdersPage />} path={routePath('settings/orders')} />
              <Route element={<SettingsProfilePage />} path={routePath('settings/profile')} />
              <Route element={<SettingsStorePage />} path={routePath('settings/store')} />
              <Route element={<UserPage />} path={routePath('users')} />
              <Route element={<PageNotFound />} path="*" />
            </Routes>
          </div>
        </Content>

        <Footer style={{ textAlign: 'center' }}>©{new Date().getFullYear()} QRcode Preferido. All Rights Reserved. (v{packageJson.version})</Footer>
      </Layout>
    </Layout>
  );
};
