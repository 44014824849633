import React, { useContext, useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import { Form, Input } from "antd";
import { FormInputField } from "../../../../../components/FormInputField";
import { FormSubtitle } from "../../../../../common-styles";
import { FormSwitchItem } from "../../../../../components/FormSwitchItem";
import { CompanySelection } from "../../../../../components/CompanySelection";
import { CentralizerContainer, RemoveLabel } from "./styles";
import { CompanyFormContext } from "../../containers/Create";
import { useGetCompany } from "../../hooks/useGetCompany";

interface LinkSetupProps {
  isEdit?: boolean;
}

const menuNameValidationRules = [
  { required: true, message: 'Campo obrigatório' },
  { pattern: /^([a-z0-9-]+)$/, message: 'Permitido apenas letras, números e hífen. Exemplo: lanchonete-manu.' },
];

export const LinkSetup = ({ isEdit }: LinkSetupProps) => {
  const [isCentralizer, setIsCentralizer] = useState<boolean>();
  const [isEnabled, setIsEnabled] = useState<boolean>();
  const [companyAssociatedToCentralizer, setCompanyAssociatedToCentralizer] = useState<string | null>();
  const formContext = useContext(CompanyFormContext);
  const { id } = useParams();
  const { data: company } = useGetCompany(id);

  useEffect(() => {
    if (formContext) {
      formContext.setFieldsValue({
        parentAssociation: companyAssociatedToCentralizer
      });
    }
  }, [companyAssociatedToCentralizer, formContext]);

  useEffect(() => {
    if (company) {
      setIsCentralizer(company.isCentralizer);
      setIsEnabled(company.isActive);
      setCompanyAssociatedToCentralizer(company.parentAssociation);
    }
  }, [company]);

  return (
    <>
      <FormSubtitle>Configuração do link</FormSubtitle>

      <FormInputField
        label="Slug (Nome do link)"
        name="menuUrl"
        placeholder="meu-menu"
        rules={menuNameValidationRules}
      />

      <FormSwitchItem
        label="Empresa ativa?"
        propertyId="isActive"
        defaultChecked={isEnabled}
        handleCheckboxChange={(checked) => setIsEnabled(checked)}
      />

      <FormSwitchItem
        label="É centralizador?"
        propertyId="isCentralizer"
        defaultChecked={isCentralizer}
        handleCheckboxChange={(checked) => setIsCentralizer(checked)}
      />

      {!isCentralizer && (
        <CentralizerContainer>
          <CompanySelection
            companyId={companyAssociatedToCentralizer}
            companyIdToBeIgnored={id}
            inputLabel='Associar centralizador'
            help='Ao selecionar uma empresa, a mesma estará associada a escolhida, sendo esta aqui considerada como filha da empresa escolhida.'
            onCompanySelect={(company) => setCompanyAssociatedToCentralizer(company)}
          />

          {companyAssociatedToCentralizer && (
            <RemoveLabel onClick={() => setCompanyAssociatedToCentralizer(null)}>Remover associação</RemoveLabel>
          )}
        </CentralizerContainer>
      )}

      {isEdit && (
        <FormInputField
          label="Observação"
          name="observation"
          isInputArea
        />
      )}

      <Form.Item name="parentAssociation" hidden>
        <Input />
      </Form.Item>
    </>
  );
}
