import React, { useEffect, useState } from 'react';
import { AxiosError } from 'axios';
import { Button, Input } from 'antd';
import { useTranslation } from 'react-i18next';

import { useCouponCode } from './hooks/useCouponCode';
import { useFormOrderInstance } from '../../../../hooks/useFormOrderContext';
import { Coupon } from '@/models/Coupon';
import { CouponRulesInfo } from '../../../../../../../../../components/CouponRulesInfo';
import { OrderSection } from '../../../OrderSection';

import { ErrorMessage, SuccessMessage } from './styles';

interface CouponInputProps {
  onCouponResult: (coupon: Coupon | undefined) => void;
  slug: string;
};

export const CouponInput = ({ onCouponResult, slug }: CouponInputProps) => {
  const [coupon, setCoupon] = useState<string>();
  const [errorMessage, setErrorMessage] = useState<string>();
  const [successMessage, setSuccessMessage] = useState<boolean>(false);
  const { data: couponResponse, error, isLoading, setCouponCode, setPhone } = useCouponCode(slug);
  const form = useFormOrderInstance();
  const { t } = useTranslation();

  useEffect(() => {
    if (error) {
      const errorResponse = error as AxiosError;
      let message = t('order.coupon.invalid');

      if (errorResponse.response) {
        switch (errorResponse.response.status) {
          case 403:
            message = t('order.coupon.used');
            break;
        }
      }
      setSuccessMessage(false);
      setErrorMessage(message);
      onCouponResult(undefined);
    }
  }, [error, onCouponResult, t]);

  useEffect(() => {
    if (couponResponse) {
      setSuccessMessage(true);
      setErrorMessage('');
      onCouponResult(couponResponse);
      form.setFieldsValue({ coupon: couponResponse });
    }
  }, [couponResponse, onCouponResult, form]);

  const handleCheckCouponCode = async () => {
    const phone = form.getFieldValue('phone');

    if (!phone) {
      setErrorMessage(t('order.coupon.phone_validation.phone_required'));
      return;
    }

    if (phone?.length < 9) {
      setErrorMessage(t('order.coupon.phone_validation.invalid_phone'));
      return;
    }

    setErrorMessage('');
    setPhone(phone);
    setCouponCode(coupon);
  };

  return (
    <OrderSection title={t('order.coupon.title')}>
      <div style={{ display: 'flex', alignItems: 'center' }}>
        <Input
          type="text"
          value={coupon}
          placeholder={t('order.coupon.coupon_code')}
          onChange={(e) => setCoupon(e.target.value.toUpperCase())}
          style={{ height: '40px', marginRight: '12px' }}
        />

        <Button
          loading={isLoading}
          type="primary"
          onClick={handleCheckCouponCode}
        >
          {t('order.coupon.apply')}
        </Button>
      </div>

      {errorMessage && (
        <ErrorMessage>
          {errorMessage}{' '}
          <i
            className="fa fa-times"
            aria-hidden="true"
            style={{ marginLeft: '2px' }}
          />
        </ErrorMessage>
      )}
      {successMessage && (
        <SuccessMessage>
          {t('order.coupon.coupon_applied')}{' '}
          <i
            className="fa fa-check"
            aria-hidden="true"
            style={{ marginLeft: '2px' }}
          />
        </SuccessMessage>
      )}

      <CouponRulesInfo />
    </OrderSection>
  );
};
