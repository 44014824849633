import React, { useState } from 'react';
import { IncrementalButtonsContent } from './style';

interface IncrementalButtonsProps {
  startCounter: number;
  maxAllowed?: number;
  disabledIncreaseButton?: boolean;
  withBorder?: boolean;
  onValueChanged(value: number): void;
}

export const IncrementalButtons = ({
  startCounter,
  maxAllowed,
  disabledIncreaseButton,
  withBorder,
  onValueChanged,
}: IncrementalButtonsProps) => {
  const [counter, setCounter] = useState(startCounter);

  let minAllowed = 1;

  // if maxAllowed is provided, we consider minAllowed as 0;
  if (maxAllowed) {
    minAllowed = 0;
  }

  if (!disabledIncreaseButton) {
    disabledIncreaseButton = false;
  }

  if (!withBorder) {
    withBorder = false;
  }

  function handleIncrementCounter() {
    if (maxAllowed && maxAllowed === counter) {
      return;
    }

    const newValue = counter + 1;
    setCounter(newValue);
    onValueChanged(newValue);
  }

  function handleDecrementCounter() {
    if (counter !== minAllowed) {
      const newValue = counter - 1;
      setCounter(newValue);
      onValueChanged(newValue);
    }
  }

  return (
    <IncrementalButtonsContent withBorder={withBorder} isSingleElement={true}>
      {counter !== 0 && (
        <button
          onClick={handleDecrementCounter}
          disabled={minAllowed === 1 && counter <= 1}
        >
          <span>-</span>
        </button>
      )}

      {counter !== 0 && <small>{counter}</small>}
      <button
        onClick={handleIncrementCounter}
        disabled={disabledIncreaseButton || maxAllowed === counter}
      >
        <span>+</span>
      </button>
    </IncrementalButtonsContent>
  );
};
