import React from 'react';
import { Button, Form } from 'antd';

import { CompanyFeatures } from '../../../../../models/CompanyFeatures';
import { FormLayout } from '../../../../../utils/Styles';
import { FormInputField } from '../../../../../components/FormInputField';
import { useUpdateCompanyFeatures } from '../../../../../hooks';
import { EasypayCompanyFeature } from '../../../../../models/Easypay';
import { AlertNotification } from '@/components/AlertNotification';
import { NotificationType } from '@/components/AlertNotification/NotificationType';

interface EasypaySetupProps {
  companyFeatures: CompanyFeatures;
}

export const EasypaySetup = ({ companyFeatures }: EasypaySetupProps) => {
  const { update } = useUpdateCompanyFeatures();

  const updateCompanyFeatures = (companyFeatures: CompanyFeatures) => {
    update(
      { ...companyFeatures },
      {
        onSuccess: () => AlertNotification({
          message: 'Atualizado com sucesso.',
        }),
        onError: () => AlertNotification({
          message: 'Erro ao tentar atualizar.',
          type: NotificationType.ERROR,
        })
      },
    );
  }

  const handleFormSubmit = async (formData: any) => {
    const easypay: EasypayCompanyFeature = {
      id: formData.id.toString(),
      marginValue: {
        delivery: Number(formData.marginValue.delivery),
        eatInLoco: Number(formData.marginValue.eatInLoco),
        takeAway: Number(formData.marginValue.takeAway),
      },
    };

    const featuresToUpdate = {
      ...companyFeatures,
      easypay,
    };

    updateCompanyFeatures(featuresToUpdate);
  }

  return (
    <Form
      layout="vertical"
      {...FormLayout}
      initialValues={companyFeatures.easypay}
      onFinish={handleFormSubmit}
    >
      <FormInputField
        label="Easypay ID"
        name="id"
        placeholder="Easypay ID"
        style={{ marginTop: '1rem' }}
        rules={[
          {
            required: true,
            message: 'Campo obrigatório',
          },
        ]}
      />

      <FormInputField
        label="Margem delivery"
        namePath={['marginValue', 'delivery']}
        placeholder="Margem para delivery"
        style={{ marginTop: '1rem' }}
        type='number'
        rules={[
          {
            required: true,
            message: 'Campo obrigatório',
          },
        ]}
      />

      <FormInputField
        label="Margem takeaway"
        namePath={['marginValue', 'takeAway']}
        placeholder="Margem para takeaway"
        style={{ marginTop: '1rem' }}
        type='number'
        rules={[
          {
            required: true,
            message: 'Campo obrigatório',
          },
        ]}
      />

      <FormInputField
        label="Margem comer no local"
        namePath={['marginValue', 'eatInLoco']}
        placeholder="Margem para comer no local"
        style={{ marginTop: '1rem' }}
        type='number'
        rules={[
          {
            required: true,
            message: 'Campo obrigatório',
          },
        ]}
      />

      <div style={{ marginTop: '16px', textAlign: 'start' }}>
        <Button type="primary" htmlType="submit">
          <i
            className="fa fa-check-circle"
            aria-hidden="true"
            style={{ marginRight: '10px' }}
          />
          Salvar
        </Button>
      </div>
    </Form>
  );
}
