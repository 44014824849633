import { useCallback } from "react";
import { MenuTabOption } from "@/models/DataResponse";
import { useMutation, useQueryClient } from "react-query";
import { FEATURES } from "@/hooks";
import { TabMenuOptionService } from "../services/TabMenuOptionService";

export const useSaveMenuOptions = () => {
  const queryClient = useQueryClient();

  const { mutate: mutateCreate } = useMutation(
    (option: MenuTabOption) => TabMenuOptionService.create(option)
  );

  const { mutate: mutateUpdate } = useMutation(
    (option: MenuTabOption) => TabMenuOptionService.update(option)
  );

  const create = useCallback((tabMenuOption, { onSuccess, onError }) => {
    mutateCreate(tabMenuOption, {
      onSuccess: () => {
        queryClient.invalidateQueries(FEATURES);
        onSuccess();
      },
      onError,
    });
  }, [mutateCreate, queryClient]);

  const update = useCallback((tabMenuOption, { onSuccess, onError }) => {
    mutateUpdate(tabMenuOption, {
      onSuccess: () => {
        queryClient.invalidateQueries(FEATURES);
        onSuccess();
      },
      onError,
    });
  }, [mutateUpdate, queryClient]);

  const createOrUpdate = useCallback((tabMenuOption, { onSuccess, onError }) => {
    if (tabMenuOption._id) {
      update(tabMenuOption, { onSuccess, onError });
    } else {
      create(tabMenuOption, { onSuccess, onError });
    }
  }, [create, update]);

  return { createOrUpdate };
};
