import styled from 'styled-components';

interface CategoryContainerProps {
  titleColor: string;
  subTitleColor: string;
}

export const CategoryInformative = styled.div.withConfig({
  shouldForwardProp: (prop) => prop !== 'titleColor' && prop !== 'subTitleColor',
}) <CategoryContainerProps>`
  background-color: #f1f1f1;
  border-radius: 10px;
  padding-top: 2px;
  padding-bottom: 2px;
  margin: 12px 14px;

  h1 {
    text-align: center;
    margin-top: 30px;
    font-size: 26px;
    color: ${(props) => props.titleColor};
  }

  .description-container {
    text-align: center;
    margin-left: 16px;
    margin-right: 16px;
    overflow-wrap: break-word;
    color: ${(props) => props.subTitleColor};
  }

  @media (max-width: 500px) {
    h1 {
      font-size: 20px;
    }

    .description-container {
      max-width: 380px;
      margin-left: 20px;
      margin-right: 20px;
    }
  }
  @media (max-width: 400px) {
    .description-container {
      max-width: 290px;
      margin-left: 50px;
      margin-right: 50px;
    }
  }
  @media (max-width: 280px) {
    h1 {
      font-size: 15px;
    }
  }
`;

export const CategoryForProducts = styled.div.withConfig({
  shouldForwardProp: (prop) => prop !== 'titleColor' && prop !== 'subTitleColor',
}) <CategoryContainerProps>`
  h3 {
    margin-top: 40px;
    padding-left: 12px;
    font-size: 28px;
    line-height: 22px;
    color: ${(props) => props.titleColor};
    text-align: start;
  }

  .description-container {
    text-align: start;
    margin-left: 16px;
    margin-right: 16px;
    overflow-wrap: break-word;
    color: ${(props) => props.subTitleColor};
  }

  @media (max-width: 500px) {
    h3 {
      margin-top: 30px;
      font-size: 25px;
    }
  
    .description-container {
      max-width: 380px;
    }
  }
  @media (max-width: 400px) {
    .description-container {
      max-width: 290px;
    }
  }
`;
