import React, { useState } from 'react';
import { Drawer } from 'antd';
import ReactStars from 'react-rating-stars-component';
import toast from 'react-hot-toast';
import { useTranslation } from 'react-i18next';

import api from '../../../../../services/api';
import { isMobile } from '../../../../../utils/Util';
import { FeedbackFooter } from './components/FeedbackFooter';
import {
  CommentLabel,
  Heading,
  HintCharCounter,
  InputComment,
  ItemsGrid,
  Main,
  ScoreContainer,
  Title,
} from './styles';

interface FeedbackDrawerProps {
  slug: string;
  items: string[];
  isOpen: boolean;
  onClose: () => void;
}

interface FormData {
  comment: string;
  likedItems: string[];
  slug: string;
  rating: number;
};

const DESCRIPTION_CHARACTER_LIMIT = 500;

export const FeedbackDrawer = ({ slug, items, isOpen, onClose }: FeedbackDrawerProps) => {
  const [selectedItems, setSelectedItems] = useState<string[]>([]);
  const [formData, setFormData] = useState<FormData>({} as FormData);
  const { t } = useTranslation();
  const isMobileDevice = isMobile.any();

  const ratingChanged = (newRating: number) => {
    setFormData({ ...formData, rating: newRating });
  };

  const handleSelectItem = (selectedItem: string) => {
    const alreadySelected = selectedItems.findIndex(
      (item) => item === selectedItem
    );
    if (alreadySelected >= 0) {
      const filtered = selectedItems.filter((item) => item !== selectedItem);
      setSelectedItems(filtered);
    } else {
      setSelectedItems([...selectedItems, selectedItem]);
    }
  }

  async function handleSubmit() {
    formData.slug = slug;
    formData.likedItems = selectedItems;

    try {
      const response = await api.post('api/feedback', formData);
      if (response.status) {
        toast.success(t('feedback.feedback_sent'));
        onClose();
      }
    } catch (error) {
      console.error(error);
      toast.error(t('feedback.stars_required'));
    }
  }

  return (
    <Drawer
      onClose={onClose}
      headerStyle={{ display: 'none' }}
      open={isOpen}
      destroyOnClose
      height={isMobileDevice ? '100%' : undefined}
      placement={isMobileDevice ? 'bottom' : 'right'}
      closeIcon={null}
      footer={<FeedbackFooter onClose={onClose} handleSubmit={handleSubmit} />}
    >
      <Main>
        <Title>{t('feedback.title')}</Title>
        <Heading>{t('feedback.subtitle')}</Heading>
        <ScoreContainer className="no_highlights">
          <ReactStars
            count={5}
            onChange={ratingChanged}
            size={40}
            activeColor="#ffd700"
          />
        </ScoreContainer>
        <Heading>{t('feedback.what_did_you_like')}</Heading>
        <ItemsGrid>
          {items.map((item) => (
            <li
              key={item}
              onClick={() => handleSelectItem(item)}
              className={selectedItems.includes(item) ? 'selected' : ''}
            >
              <span>{item}</span>
            </li>
          ))}
        </ItemsGrid>

        <CommentLabel>{t('feedback.leave_comment')}</CommentLabel>
        <InputComment
          placeholder={t('feedback.comment')}
          rows={5}
          maxLength={500}
          onChange={(e) =>
            setFormData({ ...formData, comment: e.target.value })
          }
        />
        <HintCharCounter>
          {`${formData.comment ? formData.comment.length : 0
            }/${DESCRIPTION_CHARACTER_LIMIT}`}
        </HintCharCounter>
      </Main>
    </Drawer>
  );
};
