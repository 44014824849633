import React, { useEffect, useState, useMemo } from 'react';
import { useParams, useLocation } from 'react-router-dom';
import { AxiosError } from 'axios';
import * as Sentry from '@sentry/browser';
import { confirmAlert } from 'react-confirm-alert';
import { useTranslation } from 'react-i18next';
import { observer } from 'mobx-react';
import { ProductStore } from '../../../hooks/ProductStore/productsStore';

import MenuController from './MenuController';
import { logEstablishmentEvent } from '../../../services/firebase';
import { ScheduleInformationDrawer } from './components/ScheduleInformationDrawer';
import { Footer } from '../../../components/Footer';
import { MainContent } from './components/MainContent';
import { NoConnection } from '../../../components/NoConnection';
import { PageNotFound } from '../../../components/PageNotFound';
import { TabOptions } from './components/TabOptions';
import { ModalImage } from '../../../components/ModalImage';
import { LoaderSpinner } from '../../../components/LoaderSpinner';
import { HeaderMetadata } from '../../../components/HeaderMetadata';
import { useCheckPendingPaymentProcess } from './hooks/useCheckPendingPaymentProcess';
import { PaymentLoadingModal } from '../../../components/PaymentLoadingModal';
import { useClientProducts } from '../../../hooks/useClientProducts';
import { FeedbackDrawer } from './components/FeedbackDrawer';
import { CheckoutOrderButton } from './components/ResumeOrderDrawer/components/CheckoutOrderButton';
import { OrderSummary } from './components/ResumeOrderDrawer/components/OrderSummary';
import { ClientOrder } from '@/models/ClientOrder';
import { CLIENT_INFO_DATA, LocalStorageService } from '@/services/LocalStorageService';
import { ResumeOrderDrawer } from './components/ResumeOrderDrawer';
import { MenuTabOption } from '../../../models/DataResponse';
import { ProductCatalog, ProductMenu } from '../../../models/ProductCatalog';
import { isMobile, removeAccents } from '../../../utils/Util';
import { OrderProductDetailDrawer } from './components/OrderProductDetailDrawer';
import { useModal } from '@/hooks';
import { useBootClientSetup } from './hooks/useBootClientSetup';
import { useClientLanguage } from './hooks/useClientLanguage';
import { InformativeOfSellsPopUp } from './components/InformativeOfSellsPopUp';
import { EstablishmentHeaderV2 } from './components/EstablishmentHeaderV2';
import { EstablishmentHeaderV1 } from './components/EstablishmentHeaderV1';
import { MainContainer } from './styles';
import './styles.css'; // FIXME: To be removed

const menuController = new MenuController();

export const DynamicMenu = observer(() => {
  const [menuNotFound, setMenuNotFound] = useState(false);
  const [alreadyShownSellsViaWhatsapp, setAlreadyShownSellsViaWhatsapp] = useState(false);
  const [data, setData] = useState<ProductCatalog[]>([]);
  const [dataFiltered, setDataFiltered] = useState<ProductCatalog[]>([]);
  const [menuOptions, setMenuOptions] = useState<MenuTabOption[]>([]);
  const [selectedMenuOption, setSelectedMenuOption] = useState<MenuTabOption | null>(null);
  const [selectedProduct, setSelectedProduct] = useState<ProductMenu>();
  const [prevClientOrderData, setPrevClientOrderData] = useState<ClientOrder>();
  const { isOpen: isOpenFeedbackDrawer, close: closeFeedbackDrawer, toggle: toggleFeedbackDrawer } = useModal();
  const { isOpen: isAboutDetailsOpen, toggle: toggleAboutUsDrawer } = useModal();
  const { close: closeOrderSummary, isOpen: isOpenOrderSummary, open: openOrderSummary } = useModal();

  const { t } = useTranslation();
  const companySlug = useParams().menu;
  const { hash: selectedTab } = useLocation();
  const { currentLanguage, changeLanguage } = useClientLanguage();
  const { data: bootData, error, isCountByProduct, isError, isFetching: isFetchingBoot, refetch } = useBootClientSetup({ slug: companySlug, currentLanguage });
  const { tabOptions, company, configuration } = bootData || {};
  const { products, isFetching: isFetchingProducts } = useClientProducts({ companyId: company?._id, currentLanguage });
  const isFetching = isFetchingBoot || isFetchingProducts;
  const { hasPaymentPending } = useCheckPendingPaymentProcess(companySlug, configuration);
  const isMobileDevice = isMobile.any();

  useEffect(() => {
    if (!products || !company) return;
    if (selectedMenuOption && removeAccents(selectedTab).toLocaleLowerCase() === selectedMenuOption?.name.toLocaleLowerCase()) return;

    if (
      configuration?.menuOptionsEnabled &&
      (tabOptions?.length && tabOptions?.length > 0)
    ) {
      let menuOptionFilter = tabOptions;

      menuOptionFilter = menuController.filterMenuOptionsByConfig(
        configuration.menuOptionsShouldBeHidden,
        menuOptionFilter
      );

      if (menuOptionFilter.length === 0) {
        // None Tab is available for the current day
        setMenuNotFound(true);
        return;
      }

      const menuOption = menuController.getSelectedMenuOption(
        menuOptionFilter,
        selectedTab
      );
      setMenuOptions(menuOptionFilter);
      setSelectedMenuOption(menuOption);

      const filteredData = menuController.doFilterMenu(
        menuOption,
        products
      );

      setDataFiltered(filteredData);
    } else {
      setDataFiltered(products);
    }
    setData(products);

    if (menuController.canLogInAnalyticsFirebase()) {
      logEstablishmentEvent(company.slug);
    }
  }, [tabOptions, company, configuration, products, selectedTab, selectedMenuOption]);

  const handleChangeMenuOption = (selectedOption: MenuTabOption) => {
    setSelectedMenuOption(selectedOption);

    const filteredData = menuController.doFilterMenu(selectedOption, data);
    setDataFiltered(filteredData);
  };

  const handleProductSelection = (isUpdate: boolean = false, product: ProductMenu) => {
    if (!isUpdate) {
      product.counter = 1;
    }
    product.isUpdate = isUpdate;
    setSelectedProduct(product);
  }

  const showNotificationPopUp = () => {
    return (
      configuration?.companyFeatures?.notificationPopUp?.isEnabled &&
      configuration?.companyFeatures.notificationPopUp.content
    );
  };

  const shouldShowTitle = useMemo(() => {
    if (!configuration?.menuOptionsEnabled) {
      return true;
    }

    return dataFiltered.length > 1;
  }, [configuration, dataFiltered]);

  const canShowPopupSellsViaWhatsApp = useMemo(() => {
    // There's no SellsVia Whatsapp Enabled or
    // It was disabled by the Company
    if (
      !configuration?.sellViaWhatsappEnabled ||
      !configuration?.companyFeatures?.showInformeSellsViaWhatsapp
    ) {
      return false;
    }

    // If the company has a menu option selected and it has the SellsViaWhatsapp enabled
    if (selectedMenuOption && configuration.menuOptionsEnabled) {
      return (
        selectedMenuOption.isSellViaWhatsappEnabled &&
        !alreadyShownSellsViaWhatsapp
      );
    }

    return !alreadyShownSellsViaWhatsapp;
  }, [configuration, selectedMenuOption, alreadyShownSellsViaWhatsapp]);

  const canRenderResumeOrder = () => {
    const hasSellViaWhatsappEnabled = configuration?.sellViaWhatsappEnabled;
    const hasProducts = ProductStore.products.length > 0;

    // For the case that we have Tabs available, we will check for both cases:
    // With or without Whatsapp.
    if (selectedMenuOption) {
      return hasSellViaWhatsappEnabled &&
        selectedMenuOption.isSellViaWhatsappEnabled
        ? hasProducts
        : false;
    }

    // If there's no TABs, we will check for the traditional menus, if it has WhatsApp enabled or not.
    return hasSellViaWhatsappEnabled && hasProducts;
  };

  const checkPreviousClientOrder = () => {
    if (prevClientOrderData) return;

    const previousOrderData = LocalStorageService.get<ClientOrder>(`${CLIENT_INFO_DATA}-${configuration?.companyName}`);

    if (previousOrderData) {
      confirmAlert({
        title: t('common.attention'),
        message: t('client.detected_previous_data', { name: previousOrderData.name }),
        buttons: [
          {
            label: t('common.yes'),
            onClick: () => {
              setPrevClientOrderData(previousOrderData);
              openOrderSummary();
            },
          },
          {
            label: t('common.no'),
            onClick: () => openOrderSummary(),
          },
        ],
      });
    } else {
      openOrderSummary();
    }
  };

  const handleCheckoutOrder = () => {
    if (!prevClientOrderData) {
      checkPreviousClientOrder();
      return;
    }
    openOrderSummary();
  };

  if (isError) {
    const axiosError = error as AxiosError;
    if (axiosError.message === 'Network Error') {
      confirmAlert({
        customUI: ({ onClose }) => {
          return <NoConnection onClose={onClose} onRetry={() => refetch()} />;
        }
      });
      return null;
    } else {
      Sentry.captureMessage('Not found menu for ' + companySlug);
      return <PageNotFound message={t('menu_not_found.not_available')} extra={<small><strong>slug: {companySlug}</strong></small>} />;
    }
  }

  if (menuNotFound) {
    return <PageNotFound message={t('menu_not_found.not_available')} extra={<small><strong>slug: {companySlug}</strong></small>} />;
  }

  if (isFetching || !companySlug || !configuration || !company) {
    return <LoaderSpinner />;
  }

  return (
    <>
      <HeaderMetadata title={company?.fantasyName} facebookPixelId={configuration.companyFeatures.facebookPixel} />

      <div id="page-menuonline">
        <div>
          {showNotificationPopUp() && (
            <ModalImage
              content={configuration.companyFeatures?.notificationPopUp?.content ?? ''}
            />
          )}

          {canShowPopupSellsViaWhatsApp && (
            <InformativeOfSellsPopUp onClose={() => setAlreadyShownSellsViaWhatsapp(true)} />
          )}

          <MainContainer>
            {configuration.modules.isNewHeaderUiEnabled ? (
              <EstablishmentHeaderV2
                company={company}
                configuration={configuration}
                onLanguageChanged={(lang) => changeLanguage(lang)}
                toggleFeedbackDrawer={toggleFeedbackDrawer}
                toggleAboutUsDrawer={toggleAboutUsDrawer}
              />
            ) : (
              <EstablishmentHeaderV1
                company={company}
                configuration={configuration}
                changeLanguage={changeLanguage}
                toggleAboutUsDrawer={toggleAboutUsDrawer}
                titleColor={menuController.getTitleColor(company)}
                toggleFeedbackDrawer={toggleFeedbackDrawer}
              />
            )}

            {(selectedMenuOption && menuOptions.length > 1) && (
              <TabOptions
                menuOptions={menuOptions}
                selectedMenuOption={selectedMenuOption}
                labelColor={menuController.getTitleColor(company)}
                handleChangeMenuOption={handleChangeMenuOption}
              />
            )}

            {isAboutDetailsOpen && (
              <ScheduleInformationDrawer
                openingHours={configuration.companyFeatures?.openingHours ?? []}
                dismissPage={toggleAboutUsDrawer}
              />
            )}

            <MainContent
              configuration={configuration}
              data={dataFiltered}
              handleProductSelection={(product) => handleProductSelection(false, product)}
              layoutType={company?.layout.type}
              selectedMenuOption={selectedMenuOption}
              shouldShowTitle={shouldShowTitle}
              subTitleColor={menuController.getSubTitleColor(company)}
              titleColor={menuController.getTitleColor(company)}
              tabsAvailable={selectedMenuOption != null && menuOptions.length > 1}
            />

            {canRenderResumeOrder() && !isMobileDevice && (
              <OrderSummary
                isCountByProduct={isCountByProduct}
                handleEditAction={handleProductSelection}
                sellsConfiguration={configuration.companyFeatures.sellsConfiguration}
                onCheckout={handleCheckoutOrder}
              />
            )}

            <PaymentLoadingModal isOpen={Boolean(hasPaymentPending)}>
              <h3>{t('payment.waiting_payment')}</h3>
            </PaymentLoadingModal>
          </MainContainer>

          <Footer
            instagram={company?.instagram}
            facebook={company?.facebook}
            whatsapp={company?.whatsapp}
            phone={company?.phoneFooter}
            website={company?.websiteFooter}
          />
        </div>
      </div>

      {canRenderResumeOrder() && (
        <>
          {isMobileDevice && !isOpenOrderSummary && (
            <CheckoutOrderButton
              isCountByProduct={isCountByProduct}
              currency={configuration.companyFeatures.sellsConfiguration.currency}
              onClick={handleCheckoutOrder}
            />
          )}

          <ResumeOrderDrawer
            companyName={configuration.companyName}
            companyFeatures={configuration.companyFeatures}
            slug={companySlug ?? ''}
            handleEditSelectedProduct={handleProductSelection}
            close={closeOrderSummary}
            isOpen={isOpenOrderSummary}
            previousOrderCustomerData={prevClientOrderData}
          />
        </>
      )}

      <FeedbackDrawer
        isOpen={isOpenFeedbackDrawer}
        items={configuration.companyFeatures.feedback.items}
        onClose={closeFeedbackDrawer}
        slug={companySlug}
      />

      {selectedProduct && (
        <OrderProductDetailDrawer
          configuration={configuration}
          product={selectedProduct}
          selectedMenuOption={selectedMenuOption}
          slug={companySlug}
          onClose={() => setSelectedProduct(undefined)}
        />
      )}
    </>
  );
});
