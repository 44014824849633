import React, { useCallback, useEffect, useState } from 'react';
import { Button, Drawer, Form } from 'antd';
import { useTranslation } from 'react-i18next';

import { ClientOrderForm } from './components/ClientOrderForm';
import { ClosedStore } from '../ClosedStore';
import { PaymentCardIframe } from './components/PaymentCardIframe';
import { PaymentLoadingModal } from '../../../../../components/PaymentLoadingModal';
import { ProductsNotAvailableModal } from './components/ProductsNotAvailable';
import { ClientOrder } from '@/models/ClientOrder';
import { CompanyFeatures } from '../../../../../models/CompanyFeatures';
import { ProductMenu } from '../../../../../models/ProductCatalog';
import { ProductOutOfStockResponse, ProductsCannotBeSold } from '../../../../../models/ProductStock';
import { MBWAY_PAYMENT_TYPE } from '../../../../../utils/Constants';
import { isMobile } from '../../../../../utils/Util';
import { isOpenNow } from '../../ScheduleUtils';
import { ProductStore } from '../../../../../hooks/ProductStore/productsStore';
import { calculateProductCost, useCheckStock, useModal } from '../../../../../hooks';
import { FormOrderProvider } from './hooks/useFormOrderContext';
import { FormHelperText } from '../../../../../common-styles';
import { DATE_FORMAT, TIME_FORMAT } from '@/utils/dateTime';
import { useFormSubmission } from './hooks/useFormSubmission';
import { showInfoModal } from '@/utils/DisplayInfoModal';
import { CloseButton, FooterContainer, FormContainer } from './styles';
import { AlertNotification } from '@/components/AlertNotification';
import { NotificationType } from '@/components/AlertNotification/NotificationType';
import { useOrderValidator } from './hooks/useOrderValidator';


interface ResumeOrderDrawerProps {
  close(): void;
  companyFeatures: CompanyFeatures;
  companyName: string;
  handleEditSelectedProduct: (isUpdate: boolean, product: ProductMenu) => void;
  isOpen: boolean;
  slug: string;
  previousOrderCustomerData?: ClientOrder;
};

export const ResumeOrderDrawer = ({
  close: closeDrawer,
  companyName,
  companyFeatures,
  handleEditSelectedProduct,
  isOpen,
  slug,
  previousOrderCustomerData,
}: ResumeOrderDrawerProps) => {
  const { sellsConfiguration } = companyFeatures;
  const isMobileDevice = isMobile.any();
  const [form] = Form.useForm();

  const [isClosedStore, setClosedStore] = useState(false);
  const [productsCannotBeSold, setProductsCannotBeSold] = useState<ProductsCannotBeSold>();

  const { toggle: toggleProductNotAvailable, isOpen: isOpenProductNotAvailable } = useModal();
  const { isOrderValid } = useOrderValidator({ companyFeatures });
  const { checkStock } = useCheckStock(slug);
  const { t } = useTranslation();

  const {
    handleClientOrderSubmit,
    isLoadingOrderRequest,
    isLoadingOrderSendMessage,
    isRequestCompleted,
    isWaitingPayment,
    paymentCardUrl,
    paymentMethod,
    setPaymentCardUrl,
    setIsWaitingPayment,
  } = useFormSubmission({ slug, companyFeatures });

  const isLoading = isLoadingOrderRequest || isLoadingOrderSendMessage;

  const onStockValidation = useCallback(() => {
    return new Promise((resolve) => {
      checkStock(
        ProductStore.products.map(product => {
          return {
            id: product._id,
            quantity: product.counter,
          }
        }), {
        onSuccess: (result: ProductOutOfStockResponse) => {
          if (!result.notAvailable || !result.withoutStock) {
            closeDrawer();
            setProductsCannotBeSold(result.data);
            toggleProductNotAvailable();
            resolve(false);
            return;
          }

          resolve(true);
        },
        onError: () => {
          resolve(false);
          AlertNotification({
            message: t('stock.check_error'),
            type: NotificationType.ERROR,
          });
        },
      });
    });
  }, [closeDrawer, checkStock, toggleProductNotAvailable, t]);

  useEffect(() => {
    if (!isOpen) return;

    const checkStoreOpened = () => {
      if (!companyFeatures.isEstablishmentOpen) {
        return false;
      }

      return isOpenNow(companyFeatures.openingHours);
    }

    if (!checkStoreOpened()) {
      setClosedStore(true);
      return;
    }

    onStockValidation();
  }, [companyFeatures.isEstablishmentOpen, companyFeatures.openingHours, companyName, isOpen, onStockValidation]);

  useEffect(() => {
    if (isRequestCompleted) {
      closeDrawer();
    }
  }, [isRequestCompleted, closeDrawer]);

  const createOrder = (values: any) => {
    const coupon = form.getFieldValue('coupon');
    const costResult = calculateProductCost({
      extraFees: sellsConfiguration.extraFees,
      coupon,
      deliveryFee: values.clientFare,
    });

    const order = { ...values } as ClientOrder;
    order.totalCost = costResult.total;
    order.subtotal = costResult.subtotal;
    order.coupon = coupon;
    order.customerAddressInfo = form.getFieldValue('customerAddressInfo');

    return order;
  };

  const handleFormSubmit = async (values: any) => {
    const isSuccess = await onStockValidation();
    if (!isSuccess) return;

    const order = createOrder(values);

    if (!isOrderValid(order)) return;

    handleClientOrderSubmit(
      order,
      values.scheduledDate && values.scheduledDate.format(DATE_FORMAT),
      values.scheduledTime && values.scheduledTime.format(TIME_FORMAT),
    );
  };

  const handleFormSubmitFailed = (values: any) => {
    console.error('handleFormSubmitFailed values:', values);
    showInfoModal({ message: t('form.required_fields') });
  };

  return (
    <>
      {paymentCardUrl && (
        <PaymentCardIframe
          url={paymentCardUrl}
          onClose={() => {
            setPaymentCardUrl(undefined);
            setIsWaitingPayment(true);
          }} />
      )}

      <PaymentLoadingModal isOpen={isWaitingPayment}>
        {
          paymentMethod?.toUpperCase() === MBWAY_PAYMENT_TYPE ? (
            <>
              <h3>{t('payment.mbway.processing')}</h3>
              <h4 style={{ textAlign: 'center' }}>{t('payment.mbway.instructions')}</h4>
            </>
          ) : (
            <h3>{t('payment.processing')}</h3>
          )
        }
      </PaymentLoadingModal>

      {productsCannotBeSold && (
        <ProductsNotAvailableModal
          isOpen={isOpenProductNotAvailable}
          close={toggleProductNotAvailable}
          productsCannotBeSold={productsCannotBeSold} />
      )}

      <Drawer
        closeIcon={null}
        destroyOnClose={true}
        height={isMobileDevice ? '100%' : undefined}
        maskClosable={false}
        onClose={closeDrawer}
        open={isOpen}
        placement={isMobileDevice ? 'bottom' : 'right'}
        title={<h2 style={{ marginBottom: 0, lineHeight: '1.1', color: '#1A1919' }}>{t('order_summary')}</h2>}
        width={isMobileDevice ? '100%' : '500px'}
        bodyStyle={{ padding: 0 }}
        extra={
          <CloseButton
            aria-label={t('common.close')}
            className="close"
            data-dismiss="modal"
            type="button"
            onClick={closeDrawer}
          >
            <span aria-hidden={true} style={{ color: '#1A1919' }}>
              ×
            </span>
          </CloseButton>
        }
      >
        <FormOrderProvider form={form}>
          <FormContainer
            initialValues={previousOrderCustomerData}
            form={form}
            layout="vertical"
            onFinish={handleFormSubmit}
            onFinishFailed={handleFormSubmitFailed}
          >
            <ClientOrderForm
              companyFeatures={companyFeatures}
              handleEditSelectedProduct={handleEditSelectedProduct}
              previousOrderCustomerData={previousOrderCustomerData}
              slug={slug}
            />

            <FooterContainer>
              <Button
                loading={isLoading || isWaitingPayment}
                style={{ marginTop: '16px', fontWeight: 'bold' }}
                type='primary'
                htmlType='submit'
              >
                {t('order.finish_order')}
              </Button>

              <FormHelperText style={{ textAlign: 'center', marginTop: '20px', fontSize: '12px' }}>
                {t('order.whatsapp_redirect')}
              </FormHelperText>
            </FooterContainer>

            {isClosedStore && (
              <ClosedStore
                companyFeatures={companyFeatures}
                dismissPage={() => setClosedStore(false)}
              />
            )}
          </FormContainer>
        </FormOrderProvider>
      </Drawer>
    </>
  );
};
