import React, { useState } from "react";
import { Form, Radio, RadioChangeEvent, Skeleton } from "antd";
import { useCompanyFeatures, useUpdateCompanyFeatures } from "../../../../../../../hooks";
import { Currency } from "../../../../../../../utils/Currency";
import { AlertNotification } from "../../../../../../../components/AlertNotification";
import { FormLabelField } from "../../../../../../../components/FormLabelField";

interface CustomerMenuCurrencyProps {
  companyId: string | undefined;
}

export const CustomerMenuCurrency = ({ companyId }: CustomerMenuCurrencyProps) => {
  const { companyFeatures, isLoading } = useCompanyFeatures(companyId);
  const { update: updateCompanyFeatures } = useUpdateCompanyFeatures();
  const [value, setValue] = useState<string>(companyFeatures?.sellsConfiguration.currency || Currency[0].value);

  const onChange = (e: RadioChangeEvent) => {
    if (!companyFeatures) return;

    const currency = e.target.value;

    updateCompanyFeatures({
      ...companyFeatures,
      sellsConfiguration: {
        ...companyFeatures.sellsConfiguration,
        currency,
      },
    }, {
      onSuccess: AlertNotification({
        message: 'Moeda alterada com sucesso',
      }),
    });

    setValue(currency);
  };

  return (
    <Form.Item label={<FormLabelField label="Moeda" />}>
      {isLoading ? <Skeleton /> : (
        <Radio.Group onChange={onChange} value={value}>
          {Currency.map((currency) => <Radio key={currency.value} value={currency.value}>{currency.label}</Radio>)}
        </Radio.Group>
      )}
    </Form.Item>
  );
}