import styled, { keyframes } from 'styled-components';

const ldsGridAnimation = keyframes`
  0%, 100% {
    opacity: 1;
  }
  50% {
    opacity: 0.5;
  }
`;

export const LoadingOverlay = styled.div`
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.5);
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 9999;
`;

export const LoadingContent = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  padding: 30px;
  background: #2a2a2a55;
  color: white;
  border-radius: 5px;
  box-shadow: 0 0 15px rgba(0, 0, 0, 0.2);

  p {
    margin-top: 20px;
    font-size: 1.2em;
  }
`;

export const LdsGrid = styled.div`
  display: inline-block;
  position: relative;
  width: 80px;
  height: 80px;

  div {
    position: absolute;
    width: 16px;
    height: 16px;
    border-radius: 50%;
    background: #fff;
    animation: ${ldsGridAnimation} 1.2s linear infinite;
  }

  div:nth-child(1) { top: 8px; left: 8px; animation-delay: 0s; }
  div:nth-child(2) { top: 8px; left: 32px; animation-delay: -0.4s; }
  div:nth-child(3) { top: 8px; left: 56px; animation-delay: -0.8s; }
  div:nth-child(4) { top: 32px; left: 8px; animation-delay: -0.4s; }
  div:nth-child(5) { top: 32px; left: 32px; animation-delay: -0.8s; }
  div:nth-child(6) { top: 32px; left: 56px; animation-delay: -1.2s; }
  div:nth-child(7) { top: 56px; left: 8px; animation-delay: -0.8s; }
  div:nth-child(8) { top: 56px; left: 32px; animation-delay: -1.2s; }
  div:nth-child(9) { top: 56px; left: 56px; animation-delay: -1.6s; }
`;