import { confirmAlert } from "react-confirm-alert";

interface ShowInfoModalParams {
  title?: string;
  message?: string;
  buttons?: ButtonModal[];
}

interface ButtonModal {
  label: string;
  onClick: () => void;
}

export const showInfoModal = ({ title = 'Atenção!', message, buttons }: ShowInfoModalParams) => {
  confirmAlert({
    title,
    message,
    buttons: buttons ?? [
      {
        label: 'Ok',
        onClick: () => { },
      },
    ],
  });
};
