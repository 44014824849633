import React, { useState } from 'react';
import { useQueryClient } from 'react-query';
import { Button, Drawer, Form, Tabs } from 'antd';

import { LinkItem } from '../LinkItem';

import { LinkPage, QRLink } from '../../../../../models/QRLink';
import { RestType, useMutations } from '../../../../../hooks/useMutations';
import { QR_LINKS, useLoggedUser } from '../../../../../hooks';

import { LinksTable } from './components/LinksTable';
import { FormInputField } from '../../../../../components/FormInputField';
import { FormRadioGroup } from '../../../../../components/FormRadioGroup';
import { registerAction } from '../../../../../services/internalAnalytics';
import { QRLinkEditorFooter } from './components/Footer';
import { AlertNotification } from '@/components/AlertNotification';
import { NotificationType } from '@/components/AlertNotification/NotificationType';

interface LinkTreeDetailsProps {
  isVisible: boolean;
  linkTree?: QRLink;
  onClose(shouldRefetch: boolean): void;
};

enum Tab {
  DETAILS = 'Detalhes',
  LINKS = 'Links',
}

const TITLE_CHARACTER_LIMIT = 80;

const getSanitizedLinks = (links: LinkPage[]) => {
  return links.map((link, index) => {
    return {
      ...link,
      order: index,
    };
  });
}

export const LinkTreeEditor = ({ isVisible, linkTree, onClose }: LinkTreeDetailsProps) => {
  const [linkPage, setLinkPage] = useState<LinkPage | undefined>();
  const [isLinkPageVisible, setLinkPageVisible] = useState(false);
  const [isEnabled, setEnabled] = useState(
    linkTree ? linkTree.isEnabled : true
  );
  const [isGridType, setIsGridType] = useState(
    linkTree ? linkTree.isGrid : false
  );
  const [links, setLinks] = useState<LinkPage[]>(linkTree ? getSanitizedLinks(linkTree.links) : []);
  const [currentTab, setCurrentTab] = useState(Tab.DETAILS);
  const [shouldRefetch, setShouldRefetch] = useState(false);

  const { userLogged } = useLoggedUser();

  const [form] = Form.useForm();

  const restType =
    linkTree && linkTree !== undefined ? RestType.PUT : RestType.POST;

  const url =
    linkTree && linkTree !== undefined
      ? `api/linktree/${linkTree._id}`
      : 'api/linktree/';

  const mutation = useMutations(restType, url);
  const queryClient = useQueryClient();

  const onTabChanged = (key: string) => setCurrentTab(key as Tab);

  const handleAddNewLink = () => {
    setLinkPage(undefined);
    setLinkPageVisible(true);
  };

  const handleEditLink = (selectedItem: LinkPage) => {
    setLinkPage(selectedItem);
    setLinkPageVisible(true);
  };

  const handleDeleteLinkPage = (selectedItem: LinkPage) => {
    const index = links.indexOf(selectedItem);

    if (index !== -1) {
      const list = [...links];
      list.splice(index, 1);
      setLinks(list);
      setShouldRefetch(true);
    }
  };

  const onFinish = (values: any) => {
    if (links.length === 0) {
      AlertNotification({
        message: 'É preciso adicionar pelo menos 1 Link.',
        type: NotificationType.ERROR,
      });
      return;
    }

    const body = {
      ...values,
      links,
      company: userLogged.user.company,
      isEnabled,
      isGrid: isGridType,
    };
    mutation.mutate(body, {
      onSuccess: (result) => {
        queryClient.setQueryData(QR_LINKS, result.data.data);

        mutation.reset();
        onClose(false);
        AlertNotification({
          message: 'Operação realizada com sucesso.',
        });

        registerAction('Linktree', userLogged);
      },
      onError: () => {
        AlertNotification({
          message: 'Erro ao tentar proceder com a operação.',
          type: NotificationType.ERROR,
        });
      },
    });
  };

  return (
    <>
      <LinkItem
        linkItem={linkPage}
        isVisible={isLinkPageVisible}
        handleItem={(isUpdate, item) => {
          if (isUpdate) {
            const index = links.findIndex((link) => link._id === item._id);
            if (index !== -1) {
              links[index] = item;
              setLinks(links);
            }
          } else {
            const link = { ...item, order: links.length - 1 }
            setLinks([...links, link]);
          }
        }}
        onClose={() => setLinkPageVisible(false)}
      />

      <Drawer
        destroyOnClose={true}
        footer={
          <QRLinkEditorFooter
            loading={mutation.isLoading}
            onClose={() => onClose(shouldRefetch)}
            onSubmit={() => form.submit()} />
        }
        maskClosable={false}
        onClose={() => onClose(shouldRefetch)}
        placement="right"
        size="large"
        title="QRLinks"
        open={isVisible}
      >
        <Form
          form={form}
          initialValues={linkTree}
          layout="vertical"
          onFinish={onFinish}
        >
          <Tabs
            size="large"
            activeKey={currentTab.toString()}
            onChange={onTabChanged}
          >
            <Tabs.TabPane tab={Tab.DETAILS} key={Tab.DETAILS}>
              <FormRadioGroup
                label="Disponibilidade"
                propertyId="isEnabled"
                defaultValue={isEnabled}
                handleOnChange={(checked) => setEnabled(checked)}
              />

              <FormRadioGroup
                label="Tipo de layout"
                propertyId="isGridType"
                defaultValue={isGridType}
                options={[
                  { label: 'Grid', value: true },
                  { label: 'Lista', value: false },
                ]}
                handleOnChange={(checked) => setIsGridType(checked)}
              />

              <FormInputField
                label="Título"
                name="title"
                rules={[
                  {
                    required: true,
                    message: 'Título obrigatório',
                  },
                ]}
                placeholder="Título"
                maxLength={TITLE_CHARACTER_LIMIT}
              />

              <FormInputField
                label="Descrição"
                name="subtitle"
                placeholder="Descrição"
              />
            </Tabs.TabPane>

            <Tabs.TabPane tab={Tab.LINKS} key={Tab.LINKS}>
              <Button
                type="primary"
                onClick={handleAddNewLink}
                style={{ marginBottom: '16px' }}
              >
                <i
                  className="fa fa-plus-circle"
                  aria-hidden="true"
                  style={{ marginRight: '10px' }}
                />
                Novo link
              </Button>

              <LinksTable
                handleDeleteLinkPage={handleDeleteLinkPage}
                handleEditLink={handleEditLink}
                links={links}
                onTableChanged={(links) => setLinks(links)}
              />
            </Tabs.TabPane>
          </Tabs>
        </Form>
      </Drawer>
    </>
  );
};
