import React, { useState } from 'react';
import { Form, Radio, Space } from 'antd';
import { useTranslation } from 'react-i18next';
import { SellsConfiguration } from '@/models/SellsConfiguration';
import { DeliveryType } from '@/utils/DeliveryUtil';
import { DEFAULT_RULE } from '@/components/FormInputField';
import { RadioItem } from '@/components/RadioItem';
import { OrderSection } from '../../../OrderSection';

interface DeliveryTypeProps {
  sellsConfiguration: SellsConfiguration;
  onDeliveryChange: (deliveryType: string) => void;
}

export const DeliveryTypeRadioGroup = ({ sellsConfiguration, onDeliveryChange }: DeliveryTypeProps) => {
  const [deliveryType, setDeliveryType] = useState<string>();
  const { hasDelivery, hasPickUp, hasEatInLoco } = sellsConfiguration;
  const { t } = useTranslation();

  const deliveryOptions = [
    { label: t('order.delivery.options.delivery'), value: DeliveryType.DELIVERY, isEnabled: hasDelivery },
    { label: t('order.delivery.options.take_away'), value: DeliveryType.TAKE_AWAY, isEnabled: hasPickUp },
    { label: t('order.delivery.options.eat_in_loco'), value: DeliveryType.EAT_IN_LOCO, isEnabled: hasEatInLoco },
  ].filter(({ isEnabled }) => isEnabled);

  if (deliveryOptions.length <= 1) return null;

  return (
    <OrderSection title={t('order.delivery.type')}>
      <Form.Item name="deliveryType" rules={DEFAULT_RULE}>
        <Radio.Group
          value={deliveryType}
          onChange={(e) => {
            const type = e.target.value;
            setDeliveryType(type);
            onDeliveryChange(type);
          }}
        >
          <Space direction="vertical">
            {deliveryOptions.map(({ label, value }) => <div key={value}><RadioItem value={value} label={label} /></div>)}
          </Space>
        </Radio.Group>
      </Form.Item>
    </OrderSection>
  );
};
