import * as Sentry from '@sentry/browser';
import api from '../../../services/api';

import { Company } from '../../../models/Company';
import {
  MenuTabOption,
  MenuConfiguration,
} from '../../../models/DataResponse';
import { ClientOrder } from '@/models/ClientOrder';
import { ProductCatalog } from '../../../models/ProductCatalog';

import {
  removeAccents,
} from '../../../utils/Util';
import { sanitizePrice } from '../../../utils/priceUtil';
import { OrderBody, prepareOrderToSend } from '../../../utils/PrepareOrderToSendUtil';
import { CLIENT_INFO_DATA, LocalStorageService } from '../../../services/LocalStorageService';
import { getComplementOrderMessage } from '../../../utils/OrderUtil';

export interface SendOrderData {
  companySlug: string;
  menuConfiguration: MenuConfiguration;
  order: ClientOrder;
  currentLanguage: string;
}

export const ALL_KEY = 'Todos';

/**
 * @deprecated TO BE DISCONTINUED
 */
class MenuController {

  private getSanitizedTabName(value: string): string {
    return removeAccents(value).toLowerCase();
  }

  getSelectedMenuOption = (
    options: MenuTabOption[],
    selectedTab?: string
  ) => {
    const today = new Date();

    let selected =
      selectedTab && selectedTab !== undefined
        ? options.find(
          (option) =>
            this.getSanitizedTabName(option.name) ===
            this.getSanitizedTabName(selectedTab)
        ) ?? options[0]
        : options[0];

    // If selectedTab is not undefined, that means we received an access from a shared link
    // We'll ignore others configurations.
    if (selectedTab) {
      return selected;
    }

    const weekdays = options.filter((item) => item.weekdays !== 'everyday');
    if (weekdays.length > 0) {
      weekdays.forEach((item) =>
        item.weekdays.split('|').forEach((day) => {
          if (Number(day) === today.getDay()) {
            selected = item;
          }
        })
      );
    } else {
      const customHours = options.filter((item) => item.allDay === false);
      if (customHours.length > 0) {
        let minutes = `${today.getMinutes()}`;
        if (today.getMinutes() < 10) {
          minutes = `0${minutes}`;
        }

        let hours = `${today.getHours()}`;
        if (today.getHours() < 10) {
          hours = `0${hours}`;
        }

        const todayHours = `${hours}:${minutes}`;
        const filteredByHours = customHours.filter((item) => {
          return (
            todayHours.localeCompare(item.startHour) >= 0 &&
            todayHours.localeCompare(item.endHour) <= 0
          );
        });

        if (filteredByHours.length > 0) {
          selected = filteredByHours[0];
        }
      }
    }

    return selected;
  };

  filterMenuOptionsByConfig = (
    menuOptionsShouldBeHidden: boolean,
    options: MenuTabOption[]
  ) => {
    if (menuOptionsShouldBeHidden) {
      const today = new Date();

      const weekDays = options.filter((item) => item.weekdays !== 'everyday');
      if (weekDays.length > 0) {
        options = options.filter((item) => {
          return (
            item.weekdays === 'everyday' ||
            item.weekdays
              .split('|')
              .some((day) => Number(day) === today.getDay())
          );
        });
      }

      let minutes = `${today.getMinutes()}`;
      if (today.getMinutes() < 10) {
        minutes = `0${minutes}`;
      }

      let hours = `${today.getHours()}`;
      if (today.getHours() < 10) {
        hours = `0${hours}`;
      }

      const todayHours = `${hours}:${minutes}`;
      const customHours = options.filter((item) => item.allDay === false);
      if (customHours.length > 0) {
        options = options.filter((item) => {
          return (
            item.allDay === true ||
            (todayHours.localeCompare(item.startHour) >= 0 &&
              todayHours.localeCompare(item.endHour) <= 0)
          );
        });
      }
    }

    return options.sort((a, b) => {
      if (a.order && b.order) {
        return a.order - b.order;
      }
      return -1;
    });
  };

  doFilterMenu = (
    selectedMenuOption: MenuTabOption,
    menuCatalog: ProductCatalog[]
  ) => {
    const result = menuCatalog.filter((menuItem) => {
      const availableTabsName = menuItem.tabsAssociated.map((tab) => tab.name);
      return (
        availableTabsName.includes(selectedMenuOption.name) ||
        availableTabsName.includes(ALL_KEY)
      );
    });

    return result;
  };

  sanitizeValue = (price: string): string => {
    return sanitizePrice(price);
  };

  getTitleColor = (company: Company | undefined) => {
    let titleColor = company?.styles?.titleColor;
    if (titleColor) {
      if (!titleColor.includes('#')) {
        titleColor = `#${titleColor}`;
      }
      return titleColor;
    }

    return '#000000';
  };

  getSubTitleColor = (company: Company | undefined) => {
    let subTitleColor = company?.styles?.subTitleColor;
    if (!subTitleColor) {
      return this.getTitleColor(company);
    }

    if (!subTitleColor.includes('#')) {
      subTitleColor = `#${subTitleColor}`;
    }
    return subTitleColor;
  };

  canLogInAnalyticsFirebase = (): boolean => {
    const accessUrl = window.location.href;
    return !(
      accessUrl.includes('localhost') ||
      accessUrl.includes('qrcode-preferido-site-dev.web.app')
    );
  };

  // TODO: move this to a dedicated hook
  sendOrder = async ({ companySlug, menuConfiguration, order, currentLanguage }: SendOrderData) => {
    const { sendLinkToFollowOrder } = menuConfiguration.companyFeatures.sellsConfiguration;

    const orderBody = prepareOrderToSend({
      menuConfiguration,
      order,
    });
    const response = await this.dispatchOrderToServer(orderBody, currentLanguage);

    this.saveClientIntoDataLocally(menuConfiguration.companyName, order);

    return getComplementOrderMessage(
      sendLinkToFollowOrder,
      response?.message,
      companySlug,
      response?.order?.orderId,
    );
  };

  // TODO: Move this to a dedicated hook
  private dispatchOrderToServer = async (body: OrderBody, currentLanguage: string) => {
    try {
      const response = await api.post(`api/orders?lang=${currentLanguage}`, body);
      const { order, message } = response.data.data;
      return { order, message };
    } catch (err) {
      Sentry.captureException(err);
      console.error('Error calling Order POST', err);
    }
  };

  private saveClientIntoDataLocally(companyName: string, order: ClientOrder) {
    const orderCloned = {
      ...order,
      coupon: undefined,
      observation: '',
      scheduledTime: '',
      scheduledDate: '',
    };

    LocalStorageService.save(`${CLIENT_INFO_DATA}-${companyName}`, orderCloned);
  }
}

export default MenuController;
