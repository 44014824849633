import React, { useMemo, useState } from "react";
import { Button, Form, Select } from "antd";
import { PageDetailsButtonsContainer } from "../../../../../../../common-styles";
import { FormInputField } from "../../../../../../../components/FormInputField";

import { FormLayout } from "../../../../../../../utils/Styles";
import { validateEmail } from "../../../../../../../utils/Util";
import { useFeatures, useListCompaniesForCustomers, useLoggedUser } from "../../../../../../../hooks";
import { Role } from "../../../../../../../models/Enum";
import { User } from "../../../../../../../models/User";
import { DEFAULT_SELECT_COMPANY } from "../../../../../../../utils/Constants";
import { FormRadioGroup } from "../../../../../../../components/FormRadioGroup";
import { useRolesDropdownItems } from "@/hooks/useRolesDropdownItems";
import { useUser } from "@/pages/backoffice/Users/hooks/useUser";
import { AlertNotification } from "@/components/AlertNotification";
import { NotificationType } from "@/components/AlertNotification/NotificationType";

interface CreateUserProps {
  onClosePage(): void;
  user?: User;
}

export const CreateOrUpdateUser = ({ onClosePage, user }: CreateUserProps) => {
  const [companyNames, setCompanyNames] = useState<string[]>(user ? user.companyAssociation.map(company => company.companyName) : []);
  const [companyFantasyName, setCompanyFantasyName] = useState(user?.companyName);
  const [selectedRole, setSelectedRole] = useState(user ? user.role : undefined);
  const [isActive, setIsActive] = useState(user?.isActive ?? true);

  const { create, isLoading, update } = useUser();
  const { data: companies } = useListCompaniesForCustomers();
  const { userLogged: { user: { company: companyId, companyName, role } } } = useLoggedUser();
  const { data: features } = useFeatures(companyId);
  const rolesItems = useRolesDropdownItems({ currentRole: role as Role, hasSellsModule: features?.sellViaWhatsapp ?? false });

  const isAdmin = useMemo(() => role === Role.ADMIN || false, [role]);

  const getCompanyByName = (name: string) => companies?.find(item => item.fantasyName === name);

  const getCompanyName = () => isAdmin ? companyFantasyName : companyName;

  const getCompanyAssociation = () => {
    const companyName = getCompanyName();
    return (selectedRole === Role.KITCHEN || selectedRole === Role.DRIVER) ? companyNames.filter(item => item !== companyName).map(name => {
      return {
        companyId: getCompanyByName(name)?._id ?? '',
        companyName: name ?? '',
      }
    }) : [];
  }

  const onFormSubmit = (values: any) => {
    const companyName = getCompanyName();
    const company = isAdmin ? companies?.find(item => item.fantasyName === companyFantasyName)?._id : companyId;
    const companyAssociation = getCompanyAssociation();

    if (user) {
      update(
        {
          ...values,
          _id: user._id,
          company,
          companyAssociation,
          companyName,
          role: selectedRole,
        },
        {
          onSuccess: () => {
            AlertNotification({
              message: 'Operação realizada com sucesso',
            });

            onClosePage();
          },
          onError: () => {
            AlertNotification({
              message: 'Atenção',
              description: 'Erro ao tentar atualizar usuário.',
              type: NotificationType.ERROR,
            });
          }
        }
      );
    } else {
      create(
        {
          ...values,
          company,
          companyAssociation,
          companyName,
          role: selectedRole,
        },
        {
          onSuccess: () => {
            AlertNotification({
              message: 'Operação realizada com sucesso',
            });

            onClosePage();
          },
          onError: () => {
            AlertNotification({
              message: 'Atenção',
              description: 'Email já cadastrado no sistema, escolha outro.',
              type: NotificationType.ERROR,
            });
          }
        }
      );
    }
  }

  return (
    <Form
      {...FormLayout}
      initialValues={user}
      layout="vertical"
      onFinish={onFormSubmit}
    >
      {user && (
        <FormRadioGroup
          label="Disponibilidade"
          propertyId="isActive"
          defaultValue={isActive}
          handleOnChange={(checked) => setIsActive(checked)}
        />
      )}

      <FormInputField
        label="Nome"
        name="name"
        rules={[
          {
            required: true,
            message: 'Campo obrigatório',
          },
        ]}
        maxLength={200}
      />

      <FormInputField
        disableInput={user !== undefined}
        label={<label className="ant-form-item-required label-input-field">Email</label>}
        name="email"
        onInput={e => e.currentTarget.value = e.currentTarget.value.trim().toLocaleLowerCase()}
        rules={[
          () => ({
            validator(_: any, value = '') {
              if (value.length === 0) {
                return Promise.reject('Campo email obrigatório.');
              } else if (!validateEmail(value)) {
                return Promise.reject('Insira um email válido.');
              } else {
                return Promise.resolve();
              }
            },
          }),
        ]}
        maxLength={200}
      />

      <Form.Item
        name="role"
        label={<label className="label-input-field">Perfíl</label>}
        rules={[{ required: true, message: 'Campo obrigatório.' }]}
      >
        <Select
          defaultValue={selectedRole}
          value={selectedRole}
          onChange={(value) => setSelectedRole(value)}
        >
          <Select.Option value="" disabled>
            Selecione um perfíl
          </Select.Option>

          {rolesItems.map((role) => (
            <Select.Option key={role.label} value={role.value}>
              {role.label}
            </Select.Option>
          ))}
        </Select>
      </Form.Item>

      {isAdmin && (
        <Form.Item
          label={<label className="ant-form-item-required label-input-field">Empresa</label>}
          rules={[{ required: isAdmin, message: 'Campo obrigatório.' }]}
        >
          <Select
            defaultValue={companyFantasyName}
            onChange={(company) => setCompanyFantasyName(company)}
            showSearch
            value={companyFantasyName}
          >
            <Select.Option value="" disabled>
              {DEFAULT_SELECT_COMPANY}
            </Select.Option>

            {companies?.map((company) => (
              <Select.Option key={company._id} value={company.fantasyName}>
                {company.fantasyName}
              </Select.Option>
            ))}
          </Select>
        </Form.Item>
      )}

      {(selectedRole === Role.KITCHEN || selectedRole === Role.DRIVER) && role !== Role.USER && (
        <Form.Item
          help="Campo opcional. Por padrão, está associado ao centralizador, ou seja, todos os pedidos de todos os estabelecimentos, irão aparecer para este perfíl."
          label={<label className="label-input-field">Estabelecimentos associados</label>}
        >
          <Select
            defaultValue={companyNames}
            mode="multiple"
            onChange={(options) => setCompanyNames(options)}
            value={companyNames}
            showSearch
          >
            {companies?.map((company) => (
              <Select.Option key={company._id} value={company.fantasyName}>
                {company.fantasyName}
              </Select.Option>
            ))}
          </Select>
        </Form.Item>
      )}

      <PageDetailsButtonsContainer>
        <Button
          danger
          style={{ marginRight: '10px' }}
          onClick={() => onClosePage()}
        >
          <i
            className="fa fa-times"
            aria-hidden="true"
            style={{ marginRight: '10px' }}
          />
          Cancelar
        </Button>

        <Button
          type="primary"
          htmlType="submit"
          loading={isLoading}
        >
          <i
            className="fa fa-check-circle"
            aria-hidden="true"
            style={{ marginRight: '10px' }}
          />
          Salvar
        </Button>
      </PageDetailsButtonsContainer>
    </Form>
  );
}