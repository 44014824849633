import { useCallback, useState } from "react";
import * as Sentry from '@sentry/browser';
import { confirmAlert } from "react-confirm-alert";
import { useTranslation } from "react-i18next";
import MenuController from "../MenuController";
import { ProductStore } from '../../../../hooks/ProductStore/productsStore';
import { sendWhatsAppMessage } from "../../../../utils/SendWhatsappMessageUtil";
import { ClientOrder } from "@/models/ClientOrder";
import { useMenuConfiguration } from "./useMenuConfiguration";
import { useGetClientCurrentLanguage } from "./useGetClientCurrentLanguage";

export const useOrderRequest = (slug: string) => {
  const [isLoading, setIsLoading] = useState<boolean>();
  const [isRequestCompleted, setIsRequestCompleted] = useState<boolean>(false);
  const { menuConfiguration } = useMenuConfiguration();
  const { currentLanguage } = useGetClientCurrentLanguage();
  const { t } = useTranslation();

  const showDialogInformingOrderWasSent = useCallback((callback: () => any) => {
    confirmAlert({
      title: t('order.ready'),
      message: menuConfiguration?.companyFeatures?.informingOrderWasSentToWhatsappMessage,
      buttons: [
        {
          label: t('common.ok'),
          onClick: () => callback(),
        },
      ],
      closeOnClickOutside: false,
      onKeypressEscape: () => callback(),
    });
  }, [menuConfiguration, t]);

  const executeOrderRequest = useCallback(async (order: ClientOrder) => {
    if (!menuConfiguration) return;

    setIsLoading(true);

    const message = await new MenuController().sendOrder({
      companySlug: slug,
      menuConfiguration,
      order,
      currentLanguage: currentLanguage ?? 'pt',
    });

    if (!message) {
      Sentry.captureMessage('[Non-Fatal] Order not sent to the Server');
      setIsLoading(false);
      confirmAlert({
        title: t('common.attention'),
        message: t('order.errors.order_not_sent'),
        buttons: [
          {
            label: t('common.ok'),
            onClick: () => { },
          },
        ],
      });
      return;
    }

    setIsLoading(false);
    setIsRequestCompleted(true);
    ProductStore.clearProducts();

    showDialogInformingOrderWasSent(() => {
      sendWhatsAppMessage(message, menuConfiguration.whatsappNumber);
    });
  }, [currentLanguage, menuConfiguration, showDialogInformingOrderWasSent, slug, t]);

  return {
    executeOrderRequest,
    isLoading,
    isRequestCompleted,
    setIsRequestCompleted,
  };
}
