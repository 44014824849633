import React from "react";
import { useParams } from "react-router-dom";
import { CustomerMenuCurrency } from "../../CustomerMenuCurrency";
import { Timezone } from "../../Timezone";
import { MapQuestApiKey } from "../../MapQuestApiKey";
import { FeatureFlags } from "../../FeatureFlags";

export const General = () => {
  const { id: companyId } = useParams();

  return (
    <>
      <CustomerMenuCurrency companyId={companyId} />

      <Timezone companyId={companyId} />

      <MapQuestApiKey companyId={companyId} />

      <FeatureFlags />
    </>
  );
};
