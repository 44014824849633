import i18n from 'i18next';
import { initReactI18next } from 'react-i18next';
import LanguageDetector from 'i18next-browser-languagedetector';

import deTranslation from './locales/de/translation.json';
import enTranslation from './locales/en/translation.json';
import esTranslation from './locales/es/translation.json';
import frTranslation from './locales/fr/translation.json';
import itTranslation from './locales/it/translation.json';
import ptBrTranslation from './locales/pt-BR/translation.json';
import ptPtTranslation from './locales/pt-PT/translation.json';

i18n
  .use(LanguageDetector)
  .use(initReactI18next)
  .init({
    lng: 'pt-BR',
    resources: {
      de: {
        translation: deTranslation
      },
      en: {
        translation: enTranslation
      },
      es: {
        translation: esTranslation
      },
      fr: {
        translation: frTranslation
      },
      it: {
        translation: itTranslation
      },
      'pt-BR': {
        translation: ptBrTranslation
      },
      'pt-PT': {
        translation: ptPtTranslation
      }
    },
    fallbackLng: 'pt-BR',
    debug: true, // set to true to see logs in console
    interpolation: {
      escapeValue: false, // react already safes from xss
    }
  });

export default i18n;
