import React, { useEffect } from 'react';
import { Drawer, Form, Switch } from 'antd';
import { FormInputField } from '../../../../../../../../components/FormInputField';
import { FeeButtons } from './components/FeeButtons';
import { ExtraFee } from '../../../../../../../../models/SellsConfiguration';
import { CompanyFeatures } from '../../../../../../../../models/CompanyFeatures';
import { useFee } from '../../hooks/useFee';
import { AlertNotification } from '@/components/AlertNotification';
import { NotificationType } from '@/components/AlertNotification/NotificationType';

interface FeeEditorProps {
  companyFeatures: CompanyFeatures;
  extraFee?: ExtraFee;
  isOpen: boolean;
  onClose(): void;
}

export const FeeEditor = ({ companyFeatures, extraFee, isOpen, onClose }: FeeEditorProps) => {
  const { isLoading, processFee } = useFee({ companyFeatures, extraFee });
  const [form] = Form.useForm();

  useEffect(() => {
    form.resetFields();

    if (!extraFee) return;

    form.setFieldsValue(extraFee);
  }, [extraFee, form]);

  const onFormSubmit = (values: any) => {
    processFee(values, (isSuccess: boolean) => {
      if (isSuccess) {
        const message = extraFee ? 'Atualizado com sucesso.' : 'Criado com sucesso.';
        AlertNotification({ message });
        form.resetFields();
        onClose();
      } else {
        AlertNotification({
          message: 'Erro ao tentar efetuar a operação.',
          type: NotificationType.ERROR,
        });
      }
    });
  };

  return (
    <Drawer
      title={extraFee ? 'Atualizar taxa' : 'Nova taxa'}
      onClose={onClose}
      destroyOnClose={true}
      maskClosable={false}
      placement="right"
      open={isOpen}
      footer={<FeeButtons formRef={form} isLoading={isLoading} onClose={onClose} />}
    >
      <Form
        layout="vertical"
        initialValues={extraFee}
        onFinish={onFormSubmit}
        form={form}
      >
        {extraFee && (
          <Form.Item
            label={<label className="label-input-field">Disponibilidade</label>}
            name="isEnabled"
          >
            <Switch
              id="isEnabled"
              key="isEnabled"
              defaultChecked={extraFee.isEnabled}
              checkedChildren="Ativo"
              unCheckedChildren="Inativo"
            />
          </Form.Item>
        )}

        <FormInputField
          help='Use este campo para descrever a taxa que será cobrada. Ex.: Taxa de serviço, Taxa de entrega, etc.'
          label="Nome da taxa"
          isInputArea
          maxLength={35}
          name="label"
          placeholder="Taxa de serviço"
          useDefaultRules
        />

        <FormInputField
          label="Valor"
          name="value"
          placeholder="Valor da taxa"
          prefix={companyFeatures.sellsConfiguration.currency}
          type="number"
          useDefaultRules
        />

        <FormInputField
          isInputArea
          inputTextAreaProps={{ rows: 3, showCount: true }}
          label="Dica (Opcional)"
          maxLength={50}
          name="tooltip"
          placeholder="Digite a descrição ou definição da taxa em questão aqui."
        />
      </Form>
    </Drawer>
  )
}