import React from 'react'
import { Divider } from 'antd';
import { CompanyFeatures } from '../../../../../../models/CompanyFeatures';
import { FormSwitchItem } from '../../../../../../components/FormSwitchItem';
import { FormRadioGroup } from '../../../../../../components/FormRadioGroup';
import { NamePath } from '@/components/FormInputField';

interface ControlVisibilityFieldsProps {
  companyFeatures: CompanyFeatures;
  handleCheckboxChange(checkedValue: boolean, switchId: string | NamePath): void;
}

const requiredOrOptionalOptions = [
  { label: 'Obrigatório', value: true },
  { label: 'Opcional', value: false },
];

export const ControlVisibilityFields = ({ companyFeatures, handleCheckboxChange }: ControlVisibilityFieldsProps) => {
  return (
    <>
      <FormSwitchItem
        label="Exibir o valor zero no resumo do Whatsapp?"
        propertyId="showZeroValue"
        defaultChecked={companyFeatures.sellsConfiguration?.showZeroValue ?? true}
        handleCheckboxChange={handleCheckboxChange}
        checkedValueText="Exibir"
        uncheckedValueText="Não exibir"
        helper={'Essa opção permite exibir/esconder o valor de um produto/complemento quando é zero. Ao escolher não exibir, o valor zero não irá ser exibido no resumo do WhatsApp e também na impressão do pedido.'}
      />

      <Divider />
      <FormSwitchItem
        label="Exibir o título do complemento?"
        propertyId="orderComplementExibitionTitle"
        defaultChecked={
          companyFeatures.sellsConfiguration?.orderComplementExhibition
            .showTitle ?? false
        }
        handleCheckboxChange={handleCheckboxChange}
      />

      <Divider />
      <FormSwitchItem
        label="Número de telefone obrigatório?"
        propertyId="isTelephoneRequired"
        defaultChecked={companyFeatures.sellsConfiguration?.isTelephoneRequired ?? true}
        handleCheckboxChange={handleCheckboxChange}
        helper={
          'Essa opção é obrigatória para o uso da funcionalidade dos cupons.'
        }
      />

      <Divider />
      <FormSwitchItem
        label="Exibir os dados fiscais?"
        propertyId="isTaxDataVisible"
        defaultChecked={companyFeatures.sellsConfiguration?.isTaxDataVisible ?? false}
        handleCheckboxChange={handleCheckboxChange}
        helper={'Dados para o seu cliente solicitar fatura/nota fiscal.'}
      />

      {companyFeatures.sellsConfiguration?.isTaxDataVisible && (
        <FormRadioGroup
          propertyId="isTaxDataRequired"
          options={requiredOrOptionalOptions}
          defaultValue={companyFeatures.sellsConfiguration?.isTaxDataRequired ?? false}
          handleOnChange={(value) => handleCheckboxChange(value, 'isTaxDataRequired')}
        />
      )}

      <Divider />
      <FormSwitchItem
        label="Exibir o campo email?"
        propertyId="isEmailVisible"
        helper={'Campo de email que será exibido no resumo do pedido, para o cliente informar mais dados, caso o mesmo não possua WhatsApp.'}
        defaultChecked={companyFeatures.sellsConfiguration?.isEmailVisible ?? false}
        handleCheckboxChange={handleCheckboxChange}
      />

      {companyFeatures.sellsConfiguration?.isEmailVisible && (
        <FormRadioGroup
          propertyId="isEmailRequired"
          options={requiredOrOptionalOptions}
          defaultValue={companyFeatures.sellsConfiguration?.isEmailRequired ?? false}
          handleOnChange={(value) => handleCheckboxChange(value, 'isEmailRequired')}
        />
      )}

      <Divider />
      <FormSwitchItem
        label="Exibir o campo Instagram?"
        propertyId="isInstagramVisible"
        helper={
          'Campo de instagram que será exibido no resumo do pedido, para o cliente informar mais dados, caso o mesmo não possua WhatsApp.'
        }
        defaultChecked={companyFeatures.sellsConfiguration?.isInstagramVisible ?? false}
        handleCheckboxChange={handleCheckboxChange}
      />

      {companyFeatures.sellsConfiguration?.isInstagramVisible && (
        <FormRadioGroup
          propertyId="isInstagramRequired"
          options={requiredOrOptionalOptions}
          defaultValue={companyFeatures.sellsConfiguration?.isInstagramRequired ?? false}
          handleOnChange={(value) => handleCheckboxChange(value, 'isInstagramRequired')}
        />
      )}
    </>
  );
}