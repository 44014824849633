import React from "react";

interface FlagProps {
  lang: string;
}

export const Flag = ({ lang }: FlagProps) => (
  <div style={{ width: 16, borderRadius: '50%' }}>
    <img
      alt={lang}
      key={lang}
      src={require(`../../icons/${lang}.png`)}
      style={{ width: 16, objectFit: 'fill', borderRadius: '50%' }} />
  </div>
);
