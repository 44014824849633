import React, { useState } from "react";
import { Form, Radio, Space } from "antd";
import { useTranslation } from "react-i18next";
import { CustomInformation, CustomInformationOption } from "@/models/SellsConfiguration";
import { useFormOrderInstance } from "../../../../hooks/useFormOrderContext";
import { OrderSection } from "../../../OrderSection";
import { RadioItem } from "@/components/RadioItem";

interface CustomInformationProps {
  customInformation: CustomInformation;
}

export const CustomInformationWrapper = ({ customInformation }: CustomInformationProps) => {
  const [selectedInformation, setSelectedInformation] = useState<CustomInformation>();
  const form = useFormOrderInstance();
  const { t } = useTranslation();

  const getOptionInfoLabel = (option: CustomInformationOption) => {
    let label = option.title;
    if (option.description) {
      label = `${label} - ${option.description}`;
    }
    return label;
  };

  if (!customInformation.isEnabled) return null;

  return (
    <OrderSection title={customInformation.title}>
      {customInformation.description && <span>{customInformation.description}</span>}

      <Form.Item
        name="additionalInformationFormItem"
        rules={[{
          required: customInformation.isRequired,
          message: t('common.required_field'),
        }]}>

        <Radio.Group
          value={selectedInformation}
          onChange={(e) => {
            const selectedOptionInfo = e.target.value;

            const toSave = {
              title: selectedOptionInfo.title,
              option: {
                title: selectedOptionInfo.title,
              }
            }

            form.setFieldValue('additionalInformation', toSave);
            setSelectedInformation(selectedOptionInfo);
          }}
        >
          <Space direction="vertical">
            {customInformation.options
              .filter(option => option.isEnabled)
              .map((option) => <div key={option._id}><RadioItem value={option} label={getOptionInfoLabel(option)} /></div>)}
          </Space>
        </Radio.Group>
      </Form.Item>
    </OrderSection>
  );
};
