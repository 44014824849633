import React, { useEffect, useMemo, useState } from 'react';
import { Form, Input, Select } from 'antd';
import { CaretDownOutlined } from '@ant-design/icons';

import { useFeatures, useListCompaniesForCustomers, useLoggedUser } from '../../../../../hooks';
import { useRolesDropdownItems } from '@/hooks/useRolesDropdownItems';
import { useDebounceValue } from '@/hooks/useDebounceValue';
import { DEFAULT_ALL_COMPANIES } from '../../../../../utils/Constants';
import { Role } from '../../../../../models/Enum';
import { ContainerFilters, ContentFilters } from '../../../../../common-styles';
import { FormLabelField } from '@/components/FormLabelField';

interface UserFiltersProps {
  companyId: string;
  selectedRole: Role[];
  onCompanySelected: (companyId: string) => void;
  onRolesSelected(options: Role[]): void;
  setSearchInputFilter: (searchInput: string) => void;
}

export const UserFilters = ({ companyId, selectedRole: givenRole, onCompanySelected, onRolesSelected, setSearchInputFilter }: UserFiltersProps) => {
  const [selectedProfile, setSelectedProfile] = useState<Role[]>(givenRole);
  const [search, setSearch] = useState('');
  const debouncedSearch = useDebounceValue(search);
  const { data: companies } = useListCompaniesForCustomers();
  const { userLogged: { user: { role, company } } } = useLoggedUser();
  const { data: features } = useFeatures(company);
  const rolesItems = useRolesDropdownItems({ currentRole: role as Role, hasSellsModule: features?.sellViaWhatsapp ?? false });

  useEffect(() => {
    setSearchInputFilter(debouncedSearch);
  }, [debouncedSearch, setSearchInputFilter]);

  const companiesName = useMemo(() => {
    const names = companies?.map((company) => {
      return {
        value: company._id,
        label: company.fantasyName,
      };
    });
    names?.sort((a, b) => a.label.localeCompare(b.label)).unshift({
      value: '',
      label: DEFAULT_ALL_COMPANIES,
    });
    return names;
  }, [companies]);

  return (
    <ContainerFilters>
      <Form
        layout="vertical"
        style={{ marginTop: '16px' }}
      >
        <ContentFilters>
          <Form.Item label={<FormLabelField label="Pesquisar" />}>
            <Input.Search
              allowClear
              defaultValue={search}
              placeholder="Pesquise por qualquer entrada"
              onChange={(event) => setSearch(event.target.value)}
              onSearch={setSearch}
              style={{ height: '40px' }}
            />
          </Form.Item>

          {rolesItems.length > 1 && (
            <Form.Item label={<FormLabelField label="Perfil" />}>
              <Select
                defaultValue={selectedProfile}
                onChange={(options: Role[]) => {
                  onRolesSelected(options);
                  setSelectedProfile(options);
                }}
                placeholder="Nenhum perfíl selecionado"
                value={selectedProfile}
                mode="multiple"
              >
                {rolesItems.map((role) => (
                  <Select.Option key={role.label} value={role.value}>
                    {role.label}
                  </Select.Option>
                ))}
              </Select>
            </Form.Item>
          )}

          {role !== Role.USER && (
            <Form.Item label={<FormLabelField label="Empresas" />}>
              <Select
                defaultValue={companyId}
                onChange={onCompanySelected}
                suffixIcon={<CaretDownOutlined />}
                value={companyId}
              >
                {companiesName?.map((company) => (
                  <Select.Option
                    key={company.value}
                    value={company.value}
                  >
                    {company.label}
                  </Select.Option>
                ))}
              </Select>
            </Form.Item>
          )}
        </ContentFilters>
      </Form>
    </ContainerFilters>
  );
}
