import { useEffect, useState } from 'react';

export const DEBOUNCE_TIME = 500;

export const useDebounceValue = <T>(value: T, dependencies: any[] = [], delay = DEBOUNCE_TIME): T => {
  const [debouncedValue, setDebouncedValue] = useState(value);

  useEffect(() => {
    const handler = setTimeout(() => {
      setDebouncedValue(value);
    }, delay);

    return () => {
      clearTimeout(handler);
    };
  }, [dependencies, delay, value]);

  return debouncedValue;
};
