import React, { useState } from "react";
import { Form, Select } from "antd";
import { useCompanyFeatures, useTimezones, useUpdateCompanyFeatures } from "../../../../../../../hooks";
import { AlertNotification } from "../../../../../../../components/AlertNotification";
import { FormLabelField } from "../../../../../../../components/FormLabelField";

interface TimezoneProps {
  companyId: string | undefined;
}

export const Timezone = ({ companyId }: TimezoneProps) => {
  const [selectedTimezone, setSelectedTimezone] = useState<string>();

  const { companyFeatures } = useCompanyFeatures(companyId);
  const { update: updateCompanyFeatures } = useUpdateCompanyFeatures();
  const { timezones } = useTimezones();

  const handleChange = (value: string) => {
    setSelectedTimezone(value);

    if (companyFeatures) {
      companyFeatures.timezone = value;

      updateCompanyFeatures({ ...companyFeatures }, {
        onSuccess: AlertNotification({
          message: 'Timezone atualizado',
        }),
      });
    }
  };

  return (
    <Form.Item label={<FormLabelField label="Timezone" />}>
      <Select
        defaultValue={companyFeatures?.timezone}
        value={selectedTimezone}
        onChange={handleChange}
      >
        {timezones?.map((tz) => (
          <Select.Option key={tz._id} value={tz.timezone}>
            {tz.timezone}
          </Select.Option>
        ))}
      </Select>
    </Form.Item>
  );
}