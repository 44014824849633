import i18n from "@/i18n";
import { useCallback, useEffect, useState } from "react";
import { CLIENT_CURRENT_LANGUAGE } from "./useGetClientCurrentLanguage";
import { LocalStorageService } from "@/services/LocalStorageService";

export const useClientLanguage = () => {
  const [currentLanguage, setCurrentLanguage] = useState<string>('pt');

  const changeLanguage = useCallback((language: string) => {
    i18n.changeLanguage(language);
    setCurrentLanguage(language);

    LocalStorageService.save(CLIENT_CURRENT_LANGUAGE, language);
  }, []);

  useEffect(() => {
    const { language } = navigator;
    const [lang] = language.split('-');
    
    changeLanguage(lang);
  }, [changeLanguage]);

  return { currentLanguage, changeLanguage };
};
