import React from 'react';
import { Form, Radio } from 'antd';
import { RadioGroupOptionType } from 'antd/lib/radio/interface';
import { FormLabelField } from '../FormLabelField';

export declare type InternalNamePath = (string | number)[];
export declare type NamePath = string | number | InternalNamePath;

const defaultOptions: Option[] = [
  { label: 'Ativo', value: true },
  { label: 'Pausado', value: false },
];

export interface Option {
  label: string;
  value: boolean;
}

interface FormRadioGroupProps {
  defaultValue?: boolean;
  disabled?: boolean;
  handleOnChange(checked: boolean): void;
  helper?: string;
  isRequired?: boolean;
  label?: string;
  namePath?: NamePath;
  options?: Option[];
  optionType?: RadioGroupOptionType;
  propertyId?: string;
  requiredMessage?: string;
};

export const FormRadioGroup = ({
  defaultValue,
  disabled,
  handleOnChange,
  helper,
  isRequired,
  label,
  namePath,
  options,
  optionType,
  propertyId,
  requiredMessage,
}: FormRadioGroupProps) => {
  const groupOptions = options || defaultOptions;

  return (
    <Form.Item
      label={label && <FormLabelField label={label} />}
      name={propertyId ?? namePath}
      rules={[
        {
          required: isRequired,
          message: requiredMessage,
        },
      ]}
      help={helper}
    >
      <Radio.Group
        options={groupOptions}
        value={defaultValue}
        defaultValue={defaultValue}
        disabled={disabled ?? false}
        onChange={(e) => handleOnChange(e.target.value)}
        optionType={optionType ?? 'default'}
        buttonStyle="solid"
      />
    </Form.Item>
  );
};