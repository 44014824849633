import React from 'react';
import { List, Tooltip } from 'antd';
import { DeleteFilled as DeleteIcon, EditFilled as EditIcon } from '@ant-design/icons';
import { showInfoModal } from '@/utils/DisplayInfoModal';

interface FeedbackItemListProps {
  feedbackItems: string[];
  deleteAction(item: string): void;
  editAction(item: string): void;
};

export const FeedbackItemList = ({ feedbackItems, deleteAction, editAction }: FeedbackItemListProps) => {
  const handleDeleteAction = (item: string) => {
    showInfoModal({
      message: `Tem certeza que deseja excluir o item ${item}?`,
      buttons: [
        {
          label: 'Sim',
          onClick: () => deleteAction(item),
        },
        {
          label: 'Não',
          onClick: () => { },
        },
      ],
    });
  }

  const handleEditAction = (item: string) => editAction(item);

  return (
    <List
      size="small"
      dataSource={feedbackItems}
      footer={feedbackItems.length === 0 && <small>Nenhum item cadastrado, irá ser exibido os itens padrões na página de Feedback.</small>}
      style={{ width: '30%', marginTop: '20px' }}
      renderItem={(item, index) => (
        <List.Item
          style={{ background: index % 2 === 0 ? '#f2f2f2' : '' }}
          actions={[
            <Tooltip
              title="Editar"
              style={{ cursor: 'pointer' }}
            >
              <EditIcon onClick={() => handleEditAction(item)} />
            </Tooltip>,
            <Tooltip
              title="Editar"
              style={{ cursor: 'pointer', marginLeft: '10px' }}
            >
              <DeleteIcon onClick={() => handleDeleteAction(item)} />
            </Tooltip>
          ]}
        >
          {item}
        </List.Item>
      )}
    />
  );
};
