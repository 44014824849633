import { useState } from "react";
import { useFetch } from "@/hooks/useFetch";
import { useGetClientCurrentLanguage } from "./useGetClientCurrentLanguage";

const ORDER_MESSAGE = 'orderMessage';

interface OrderMessage {
  message: string;
}

export const useGetOrderMessage = () => {
  const [orderId, setOrderId] = useState<string>();
  const { currentLanguage } = useGetClientCurrentLanguage();
  
  const { data, isLoading } = useFetch<OrderMessage>({
    enabled: orderId !== undefined,
    queryKey: [ORDER_MESSAGE],
    url: `api/orders/${orderId}/message?lang=${currentLanguage}`,
  });

  return { data, isLoading, setOrderId };
};