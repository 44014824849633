
import styled from 'styled-components';

export const LanguageSwitcher = styled.div`
  position: absolute;
  top: 20px;
  right: 20px; 
  cursor: pointer;
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 50%; 
  width: 32px;
  height: 32px;
  color: white;
`;

export const Label = styled.span`
  padding: 5px;
  border: 2px solid #FFF;
  background-color: #FFF;
  color: #333;
  font-size: 12px;
  font-weight: bold;
  display: inline-block;
  border-radius: 4px;

  &:hover {
    background-color: #333;
    color: #FFF;
  }
`;

export const LanguageRow = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  gap: 8px;
`;
