import React, { createContext } from "react";
import { useNavigate } from "react-router-dom";
import { Button, Form, FormInstance, Space } from "antd";
import { useCompany } from "../../../../../hooks/useCompany";
import { ContentHeader } from "../../../../../components/ContentHeader";
import { CustomerInformation } from "../../components/CustomerInformation";
import { LinkSetup } from "../../components/LinkSetup";
import { LogoAndCover } from "./components/LogoAndCover";
import { FormLayout } from "../../../../../utils/Styles";
import { SocialMedia } from "./components/SocialMedia";
import { StylesSetup } from "../../components/StylesSetup";
import { LoadingPage } from "../../../../../components/LoadingPage";
import { AlertNotification } from "@/components/AlertNotification";
import { NotificationType } from "@/components/AlertNotification/NotificationType";

export const CompanyFormContext = createContext<FormInstance<any> | null>(null);

export const CreateCompanyPage = () => {
  const navigate = useNavigate();
  const [form] = Form.useForm();
  const { create, isLoadingCreate } = useCompany();

  const handleSubmit = (values: any) => {
    create({
      company: values,
      onSuccess: () => {
        // TODO: Dispatch the CompanyFeatures view in a modal
        AlertNotification({
          message: 'Empresa criada com sucesso',
        });
        navigate(-1);
      },
      onError: () => {
        AlertNotification({
          message: 'Erro ao tentar cadastrar empresa',
          type: NotificationType.ERROR,
        });
      }
    });
  };

  return (
    <>
      <ContentHeader title="Nova Empresa" />
      <CompanyFormContext.Provider value={form}>
        <Form {...FormLayout} layout="vertical" form={form} onFinish={handleSubmit}>

          <CustomerInformation />

          <LinkSetup />

          <LogoAndCover />

          <StylesSetup />

          <SocialMedia />

          <Space direction="horizontal" style={{ marginTop: 20 }}>
            <Button
              danger
              onClick={() => navigate(-1)}
            >
              Cancelar
            </Button>

            <Button
              type="primary"
              htmlType="submit"
            >
              <i
                className="fa fa-check-circle"
                aria-hidden="true"
                style={{ marginRight: '10px' }}
              />
              Salvar
            </Button>
          </Space>
        </Form>
      </CompanyFormContext.Provider>

      {isLoadingCreate && <LoadingPage />}
    </>
  );
};