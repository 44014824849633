import { Company } from '../models/Company';
import { useFetch } from './useFetch';

const COMPANY = 'company';

export const useGetCompany = (companyId: string | undefined) => {
  const { data, ...rest } = useFetch<Company>({
    enabled: !!companyId,
    queryKey: COMPANY,
    url: `/api/company/${companyId}`,
  });

  return {
    data,
    ...rest,
  };
};
