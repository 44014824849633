import { Tag } from 'antd';
import styled from 'styled-components';

// TODO: remove me
export const ContentPageDetail = styled.div`
  max-width: 900px;
  overflow-y: auto;

  background-color: #fff;
  /* just in case there no content*/
  padding: 20px 25px 30px;
  margin: 0 auto 25px;
  margin-top: 50px;
  /* shadows and rounded borders */
  -moz-border-radius: 2px;
  -webkit-border-radius: 2px;
  border-radius: 2px;
  -moz-box-shadow: 0px 2px 2px rgba(0, 0, 0, 0.3);
  -webkit-box-shadow: 0px 2px 2px rgba(0, 0, 0, 0.3);
  box-shadow: 0px 2px 2px rgba(0, 0, 0, 0.3);
`;

// TODO: REMOVE ME
export const ContentMainPageDetail = styled(ContentPageDetail)`
  margin-top: 150px;
  margin-left: 200px;
`;

// TODO: REMOVE ME
export const ContainerPageDetail = styled.div`
  width: 90%;
  position: absolute;
  top: 0;
  left: 0;
  -webkit-background-size: cover;
  -moz-background-size: cover;
  -o-background-size: cover;
  background-size: cover;
  margin-top: 50px;
`;

export const PageTitle = styled.p`
  color: #3f3e3e;
  font-size: 22px;
  font-weight: bold;
  padding-bottom: 16px;
`;

export const Filters = styled.div`
  width: 100%;
  display: flex;
  justify-content: center;
  margin-bottom: 30px;

  .tag-filter {
    font-size: 18px;
    font-weight: 500;
    background: none;
    color: #31b86f;

    margin: 0 10px;

    transition: opacity 0.3s;
    opacity: 0.4;

    &:hover {
      opacity: 0.7;
    }
  }

  .tag-filter-recurrent::after {
    content: '';
    display: block;
    width: 55px;
    margin: 0 auto;
    border-bottom: 10px solid #03bb85;
  }

  .tag-filter-eventual::after {
    content: '';
    display: block;
    width: 55px;
    margin: 0 auto;
    border-bottom: 10px solid #ff6961;
  }

  .tag-actived {
    opacity: 1;
  }
`;

export const CounterLabelContainer = styled.div`
  text-align: left;
  display: flex;
  flex-direction: column;
  line-height: 10px;
`;

export const PageDetailsButtonsContainer = styled.div`
  margin-top: 16px;
  text-align: end;
`;

export const Separator = styled.hr`

  border-top: 1px solid rgba(0, 0, 0, 0.1);
  margin-top: 20px;
  padding-bottom: 10px;
`;

export const ActionListColumn = styled.div`
  display: flex;
  flex-direction: column;
`;

export const TopLabel = styled.p`
  color: #3f3e3e;
  font-size: 18px;
  font-weight: bold;

  line-height: 0px;

  .input-required-label {
    color: gray;
    font-style: italic;
  }
`;

export const InputLabel = styled.span`
  color: #3f3e3e;
  font-size: 18px;
  font-weight: bold;

  padding-bottom: 6px;

  .input-required-label {
    color: gray;
    font-style: italic;
  }
`;

export const CustomTable = styled.table`
  border-collapse: collapse;
  border: 1px solid #ddd;
  border-left: 0;
  border-radius: 10px;
  border-spacing: 0px;
  -moz-border-radius: 10px;
  width: 100%;

  tr {
    border: 1px solid #ddd;
  }
  tr,
  td,
  th {
    border: none;
    padding: 8px;
    word-wrap: break-word;
  }
  .secondary-td {
    width: 120px;
  }
  tr:nth-child(even) {
    background-color: #f2f2f2;
  }
  tr:nth-child(odd) {
    background-color: #fff;
  }
  tr:hover {
    background-color: #ddd;
  }
  th {
    padding-top: 12px;
    padding-bottom: 12px;
    background-color: #678f8e;
    color: white;
    font-size: 16px;
    text-align: start;
  }
  td {
    color: rgba(0, 0, 0, 0.87);
  }
`;

export const GeneralForm = styled.div`
  display: flex;
  flex-direction: column;
`;

export const EmptyList = styled.p`
  font-size: 28px;
  text-align: center;
  margin-top: 20px;
`;

export const HourErrorMessage = styled.div`
  color: red;
  font-size: 12px;
  margin-top: -15px;
`;

export const FormHelperText = styled.p`
  margin-bottom: 6px;
  font-style: italic;
  font-weight: 500;
  i {
    margin-right: 10px;
  }
`;

export const LoadingContainer = styled.div`
  width: 100%;
  display: flex;
  justify-content: center;
`;

export const TableActionLabel = styled.small`
  padding: 4px;
  font-size: 15px;
  cursor: pointer;
`;

export const TableWrapper = styled.div`
  overflow-x: scroll;
  overflow-y: hidden;
  white-space: nowrap;
  -webkit-overflow-scrolling: touch;

  #table-child {
    display: inline-block;
  }
`;


export const RoundTag = styled(Tag)`
  border-radius: 10px;
`;

export const ContainerFilters = styled.div`
  display: flex;
  flex-direction: column;
  padding-bottom: 10px;
`;

export const ContentFilters = styled.div`
  display: grid; 
  grid-template-columns: 1fr 1fr 1fr;
  justify-content: center;
  gap: 20px;
`;

export const FormItemDatePicker = styled.div`
  display: flex;
  flex-direction: column;
  margin-top: 16px;
`;

export const FormSubtitle = styled.p`
  font-size: 22px;
  font-weight: bold;

  margin-top: 12px;
  margin-bottom: 20px;
`;