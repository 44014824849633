import React, { useEffect, useMemo, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { io } from 'socket.io-client';
import moment from 'moment';

import { ContentHeader } from '../../../components/ContentHeader';

import { OrderCard } from './components/OrderCard';
import { TableOrders } from './components/Table';

import { MenuOption } from '../../../models/MenuOption';
import { WhatsappOrder } from '../../../models/DataResponse';
import { SERVER_DATE_FORMAT } from '../../../utils/dateTime';
import { PREFIX_LOCALSTORAGE } from '../../../utils/Constants';

import {
  AlertNotification,
} from '../../../components/AlertNotification';
import { BreakPoints, useBreakpoint, useDriver, useListOrdersToDriver, useLoggedUser } from '../../../hooks';
import { baseURL } from '../../../services/endpoints';

const serviceBell = require('../../../assets/sounds/driver_notification.mp3');

export const OrdersDriverPage = () => {
  const today = moment(new Date()).format(SERVER_DATE_FORMAT);
  const { data: orders = [], refetch } = useListOrdersToDriver(today);
  const { data: driver } = useDriver();

  const { breakPnt } = useBreakpoint()
  const mobileDevice = useMemo(() => breakPnt !== BreakPoints.LG, [breakPnt]);

  const [audio] = useState(new Audio(serviceBell));
  const navigate = useNavigate();
  const { userLogged } = useLoggedUser();

  const companyId = userLogged.user.company;

  const selectedCurrency = useMemo(() => {
    // TODO: https://github.com/uiltonjose/qrcodepreferido-app/issues/52
    return localStorage.getItem(`${PREFIX_LOCALSTORAGE}:currency`)!!;
  }, []);

  useEffect(() => {
    const socket = io(baseURL);
    
    socket.on('orderUpdated', (data) => {
      const receivedDriverId = data.driver?.id;
      const loggedDriverId = driver?._id;

      if (receivedDriverId && loggedDriverId && receivedDriverId === loggedDriverId) {
        AlertNotification({
          message: 'Novo pedido recebido.',
        });

        audio.play();
      }

      refetch();
    });
  }, [audio, driver, refetch]);

  const handleItemClick = (order: WhatsappOrder) => {
    navigate('/sysadmin/order-detail', {
      state: {
        order,
        companyId,
      },
    });
  };

  return (
    <>
      <ContentHeader title={MenuOption.WHATSAPP_ORDERS} />

      {mobileDevice ? (
        <div>
          {orders.map((order) => {
            return (
              <OrderCard
                key={order._id}
                order={order}
                currency={selectedCurrency}
                handleItemClick={handleItemClick}
              />
            );
          })}
        </div>) : (
        <TableOrders
          companyId={companyId}
          currency={selectedCurrency}
          handleItemClick={handleItemClick}
          orders={orders}
        />
      )}
    </>
  );
};
