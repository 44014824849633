import React, { useState } from "react";
import { useParams } from "react-router-dom";
import { Space } from "antd";
import { useFeatures } from "@/hooks";
import { FormSwitchItem } from "@/components/FormSwitchItem";
import { FormInputField } from "@/components/FormInputField";

export const Payments = () => {
  const { id: companyId } = useParams();
  const { data: features } = useFeatures(companyId);
  const [isEnabled, setIsEnabled] = useState<boolean>(features?.easypay.isEnabled ?? false);

  return (
    <Space direction="vertical" style={{ width: '100%' }}>
      <FormSwitchItem
        label="Módulo Easypay"
        propertyId={["easypay", "isEnabled"]}
        defaultChecked={isEnabled}
        checkedValueText={'Ativo'}
        uncheckedValueText={'Pausado'}
        handleCheckboxChange={setIsEnabled}
      />

      {isEnabled && (
        <>
          <FormInputField
            label="Account Id"
            namePath={['easypay', 'accountId']}
            placeholder="Account Id"
            useDefaultRules
            style={{ width: '20%' }}
          />

          <FormInputField
            label="Api-Key"
            namePath={['easypay', 'apiKey']}
            placeholder="Api-Key"
            useDefaultRules
            style={{ width: '20%' }}
          />

          <FormInputField
            label="Host URL"
            namePath={['easypay', 'host']}
            placeholder="Host URL"
            useDefaultRules
            style={{ width: '50%' }}
          />

          <FormInputField
            label="Main Payment Account Id"
            namePath={['easypay', 'mainPaymentAccountId']}
            placeholder="Main Payment Account Id"
            useDefaultRules
            style={{ width: '20%' }}
          />

          <FormInputField
            label="Margin Account Id"
            namePath={['easypay', 'marginAccountId']}
            placeholder="Margin Account Id"
            style={{ width: '20%' }}
          />
        </>
      )}
    </Space>
  );
};
