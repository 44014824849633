import React from "react";
import { FormSubtitle } from "../../../../../../../common-styles";
import { FormInputField } from "../../../../../../../components/FormInputField";

export const LogoDimens = () => (
  <>
    <FormSubtitle style={{ marginTop: 50, marginBottom: "unset" }}>Logo (Dimensões)</FormSubtitle>
    <small>Ao deixar tudo vazio, o sistema irá assumir os valores padrões</small>

    <FormInputField
      label="Altura (Desktop)"
      namePath={['styles', 'logoDimens', 'height']}
      style={{ marginTop: 30 }}
    />

    <FormInputField
      label="Largura (Desktop)"
      namePath={['styles', 'logoDimens', 'width']}
      style={{ marginTop: 30 }}
    />

    <FormInputField
      label="Fit mode (Desktop)"
      namePath={['styles', 'logoDimens', 'objectFit']}
      help="Valores: cover, scale-down, fill, none"
      style={{ marginTop: 30 }}
    />

    <FormInputField
      label="Altura (Mobile)"
      namePath={['styles', 'logoDimens', 'mobileHeight']}
      style={{ marginTop: 30 }}
    />

    <FormInputField
      label="Largura (Mobile)"
      namePath={['styles', 'logoDimens', 'mobileWidth']}
      style={{ marginTop: 30 }}
    />

    <FormInputField
      label="Fit mode (Mobile)"
      namePath={['styles', 'logoDimens', 'mobileObjectFit']}
      help="Valores: cover, scale-down, fill, none"
      style={{ marginTop: 30 }}
    />
  </>
);