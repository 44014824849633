import React from "react";
import { Company } from "../../../../../../../models/Company";
import { SummaryList } from "../../../../../../../components/SummaryList";

interface SocialMidiasProps {
  company: Company | undefined;
}

const NONE = 'N/A';

export const SocialMidias = ({ company }: SocialMidiasProps) => (
  <SummaryList title="Mídias Sociais" subtitle="Rodapé do cardápio/catálogo">
    <SummaryList.Item>
      <SummaryList.Label>WhatsApp</SummaryList.Label>
      <SummaryList.Value>{company?.whatsapp ?? NONE}</SummaryList.Value>
    </SummaryList.Item>

    <SummaryList.Item>
      <SummaryList.Label>Instagram</SummaryList.Label>
      <SummaryList.Value>{company?.instagram ?? NONE}</SummaryList.Value>
    </SummaryList.Item>
    
    <SummaryList.Item>
      <SummaryList.Label>Facebook</SummaryList.Label>
      <SummaryList.Value>{company?.facebook ?? NONE}</SummaryList.Value>
    </SummaryList.Item>
    
    <SummaryList.Item>
      <SummaryList.Label>Telefone</SummaryList.Label>
      <SummaryList.Value>{company?.phoneFooter ?? NONE}</SummaryList.Value>
    </SummaryList.Item>
    
    <SummaryList.Item>
      <SummaryList.Label>Site</SummaryList.Label>
      <SummaryList.Value>{company?.websiteFooter ?? NONE}</SummaryList.Value>
    </SummaryList.Item>
  </SummaryList>
);
