import { useCallback } from "react";
import { useTranslation } from "react-i18next";
import { ClientOrder } from "@/models/ClientOrder";
import { CompanyFeatures } from "@/models/CompanyFeatures";
import { CASH_MONEY } from "@/utils/Constants";
import { DeliveryType } from "@/utils/DeliveryUtil";
import { showInfoModal } from "@/utils/DisplayInfoModal";
import formatCurrency from "@/utils/formatCurrency";

interface UseOrderValidatorProps {
  companyFeatures: CompanyFeatures;
}

export const useOrderValidator = ({ companyFeatures }: UseOrderValidatorProps) => {
  const { t } = useTranslation();

  const isOrderValid = useCallback((order: ClientOrder) => {
    const { sellsConfiguration } = companyFeatures;
    const isDelivery = order.deliveryType === DeliveryType.DELIVERY;

    if (isDelivery && (order.subtotal ?? 0) < sellsConfiguration.minOrderPrice) {
      showInfoModal({
        message: t('order.validations.min_order_value', { value: formatCurrency(sellsConfiguration?.minOrderPrice ?? 0, sellsConfiguration.currency) }),
      });
      return false;
    }

    if (isDelivery && companyFeatures.location?.radius && !order.customerAddressInfo?.isAddressAvailable) {
      showInfoModal({
        message: t('order.validations.address_out_of_range'),
      });
      return false;
    }

    if (CASH_MONEY.includes(order.paymentType) && order.moneyChange && Number(order.moneyChange) <= (order.totalCost ?? 0)) {
      showInfoModal({
        message: t('order.validations.change_not_enough'),
      });
      return false;
    }

    return true;
  }, [companyFeatures, t]);

  return { isOrderValid };
};