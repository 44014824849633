import { Category } from '@/models/Category';
import { DEFAULT_SELECT_COMPANY } from '../utils/Constants';
import { useFetch } from './useFetch';

export const CATEGORIES = 'categories';

export const useCategories = (companyId: string) => {
  const { data, ...rest } = useFetch<Category[]>({
    enabled: companyId !== DEFAULT_SELECT_COMPANY,
    queryKey: [CATEGORIES, companyId],
    url: `api/category?companyId=${companyId}`,
  });

  return { data, ...rest };
};
