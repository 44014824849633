import React, { useState } from 'react';
import { Form, Radio, Select, Tabs } from 'antd';
import { useNavigate, useParams } from 'react-router-dom';

import { Category } from '@/models/Category';
import { useSaveCategory } from '../../hooks/useSaveCategory';
import { useFeatures } from '@/hooks/useFeatures';
import { CompanyActivityLogService } from '../../../../../services/CompanyActivityLogService';
import { FormRadioGroup } from '../../../../../components/FormRadioGroup';
import { FormLabelField } from '../../../../../components/FormLabelField';
import { AlertNotification } from '@/components/AlertNotification';
import { NotificationType } from '@/components/AlertNotification/NotificationType';
import { PageFooterButtons } from '../../../../../components/PageFooterButtons';
import { InputTitleAndDescription } from '../../../../../components/InputTitleAndDescription';
import { ContentHeader } from '@/components/ContentHeader';
import { LoadingPage } from '@/components/LoadingPage';
import { FormHelperText } from '../../../../../common-styles';
import { FormLayout } from '@/utils/Styles';

enum Tab {
  DETAILS = 'Detalhes',
  TABS = 'Opção de Abas',
}

const OPTION_ALL = {
  key: 'all',
  value: 'Todos',
};

const categoryTypes = [
  { label: 'Categoria', value: true },
  { label: 'Informativo', value: false },
];

const { TabPane } = Tabs;

export const CreateCategoryPage = () => {
  const navigate = useNavigate();
  const { companyId } = useParams();
  const { data: features } = useFeatures(companyId);

  const [isEnabled, setEnabled] = useState<boolean>(true);
  const [isSectionType, setSectionType] = useState<boolean>(true);
  const [currentTab, setCurrentTab] = useState(Tab.DETAILS);
  const [selectedMenuOptions, setSelectedMenuOptions] = useState<string[]>([OPTION_ALL.value]);

  const [form] = Form.useForm();
  const { isLoadingCreate, create } = useSaveCategory();

  const successMessage = () => {
    AlertNotification({
      message: 'Operação realizada com sucesso',
    });
  };

  const addOrUpdateCategory = async (values: any) => {
    const formData = { ...values } as Category;

    if (
      features?.menuOptions &&
      features?.menuOptions.length > 0 &&
      selectedMenuOptions &&
      selectedMenuOptions.length > 0
    ) {
      formData.tabsAssociated = selectedMenuOptions.map((tabName) => {
        const foundOption = features?.menuOptions.find(
          (option) => option.name === tabName
        );

        if (tabName === 'Todos') {
          tabName = 'all';
        }
        return foundOption ? `${foundOption._id}` : `${tabName}`;
      });
    } else {
      formData.tabsAssociated = [];
    }

    create(
      { ...formData, company: companyId },
      {
        onSuccess: (id: string) => {
          CompanyActivityLogService.register({ action: `Nova Categoria: <strong>${formData.category}</strong>`, actionArea: 'Categoria', extra: id });
          successMessage();
          navigate(-1);
        },
        onError: () =>
          AlertNotification({
            message: 'Erro ao tentar criar categoria',
            type: NotificationType.ERROR,
          }),
      }
    );
  };

  const dropdownListHandler = (options: string[]) => {
    options = options
      .filter((val) => !val || val !== OPTION_ALL.key)
      .filter((option) => {
        if (
          (options.includes(OPTION_ALL.value) &&
            options[0] !== OPTION_ALL.value) ||
          options[options.length - 1] === OPTION_ALL.value
        ) {
          return option === OPTION_ALL.value;
        }
        return option !== OPTION_ALL.value;
      });

    setSelectedMenuOptions(options);
  };

  const onFinishFailed = () => {
    setCurrentTab(Tab.DETAILS);
  };

  const onTabChanged = (key: string) => setCurrentTab(key as Tab);

  return (
    <>
      <ContentHeader title="Nova Categoria" />

      <Form
        {...FormLayout}
        form={form}
        layout="vertical"
        onFinish={addOrUpdateCategory}
        onFinishFailed={onFinishFailed}
      >
        <Tabs
          size="large"
          activeKey={currentTab.toString()}
          onChange={onTabChanged}
        >
          <TabPane tab={Tab.DETAILS} key={Tab.DETAILS}>
            <FormRadioGroup
              label="Disponibilidade"
              propertyId="isEnabled"
              defaultValue={isEnabled}
              handleOnChange={(checked) => setEnabled(checked)}
            />

            <Form.Item
              label={<FormLabelField label="Tipo" />}
              name="isSectionType"
            >
              <Radio.Group
                options={categoryTypes}
                value={isSectionType}
                defaultValue={isSectionType}
                onChange={(e) => setSectionType(e.target.value)}
                optionType="button"
                buttonStyle="solid"
              />
            </Form.Item>

            <InputTitleAndDescription
              defaultTitleId='category'
              features={features}
              useRichText
            />
          </TabPane>

          {features?.menuOptions && features?.menuOptions.length > 0 && (
            <TabPane tab={Tab.TABS} key={Tab.TABS}>
              <Form.Item
                label={<FormLabelField label="Abas" />}
                rules={[
                  {
                    required: true,
                    message: 'Campo obrigatório',
                  },
                ]}
              >
                <Select
                  id="tabsAssociated"
                  mode="multiple"
                  style={{ width: '50%' }}
                  placeholder="Selecione a opção de aba"
                  value={selectedMenuOptions}
                  onChange={dropdownListHandler}
                >
                  <Select.Option
                    key={OPTION_ALL.value}
                    value={OPTION_ALL.value}
                  >
                    {OPTION_ALL.value}
                  </Select.Option>

                  {features?.menuOptions?.map((option) => (
                    <Select.Option key={option.name} value={option.name}>
                      {option.name}
                    </Select.Option>
                  ))}
                </Select>
              </Form.Item>

              <FormHelperText style={{ marginTop: '10px' }}>
                A opção Todos, significa que a categoria será exibida em todas
                as abas.
              </FormHelperText>
            </TabPane>
          )}
        </Tabs>

        <PageFooterButtons onClose={() => navigate(-1)} />
      </Form>

      {isLoadingCreate && <LoadingPage />}
    </>
  );
};
