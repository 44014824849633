import { Company } from '../../../../models/Company';
import { useFetch } from '../../../../hooks/useFetch';

const GET_COMPANY = 'getCompany';

export const useGetCompany = (companyId: string | undefined) => {
  const { data, ...rest } = useFetch<Company>({
    queryKey: [GET_COMPANY, companyId],
    url: `api/company/${companyId}`,
  });

  return {
    data,
    ...rest,
  };
};
