import React, { useState, useEffect } from 'react';
import { Table } from 'antd';

import { useCompanyFeatures } from '../../../../../hooks';
import api from '../../../../../services/api';
import { formatDatetimeByTimezone } from '../../../../../utils/dateTime';
import {
  Feedback,
  FeedbackResponse,
} from '../../../../../models/responseData/Feedback';

import { FormHelperText, RoundTag } from '../../../../../common-styles';
import { DEFAULT_SELECT_COMPANY } from '../../../../../utils/Constants';

interface FeedbackTableProps {
  companyId: string;
};

// let reachedLimit = false;

export const FeedbackTable = ({ companyId }: FeedbackTableProps) => {
  const [feedbacks, setFeebacks] = useState<Feedback[]>([]);
  const [isLoading, setLoading] = useState<boolean>(true);
  // const [currentPage, setCurrentPage] = useState(1);

  const { companyFeatures } = useCompanyFeatures(companyId);

  useEffect(() => {
    if (!companyId || companyId === DEFAULT_SELECT_COMPANY) return;

    api
      .get(`api/feedback?page=1&limit=10&companyId=${companyId}`)
      .then((response) => {
        setLoading(false);

        const responseData: FeedbackResponse = response.data;
        setFeebacks(responseData.data);
        // const pagination = responseData.pagination;
        // reachedLimit = responseData.data.length === pagination.next.limit;
        // const feedbackList = responseData.data;
        // if (feedbackList.some((item) => item.companyId === companyId)) {
        //   setFeebacks((prevFeedbacks) => [...prevFeedbacks, ...feedbackList]);
        // } else {
        //   setFeebacks(feedbackList);
        // }
      })
      .catch((error) => {
        console.log(error);
        setLoading(false);
      });
  }, [companyId]);

  // FIXME: to be reviewed
  // useEffect(() => {
  //   const intersectionObserver = new IntersectionObserver((entries) => {
  //     if (reachedLimit && entries.some((entry) => entry.isIntersecting)) {
  //       setCurrentPage((prevPage) => prevPage + 1);
  //     }
  //   });

  //   const element = document.querySelector('#lastInvisibleRow');
  //   if (element) {
  //     intersectionObserver.observe(element);
  //   }

  //   return () => intersectionObserver.disconnect();
  // }, [isLoading]);

  const columns = [
    {
      title: 'Recebido em',
      dataIndex: 'isEnabled',
      render: (_: any, feedback: Feedback) =>
        formatDatetimeByTimezone(
          feedback.createdAt,
          companyFeatures?.timezone ?? 'UTC'
        ),
    },
    {
      title: 'Nota',
      dataIndex: 'rating',
      width: 80,
    },
    {
      title: 'Gostos',
      render: (_: any, feedback: Feedback) => {
        return feedback.likedItems.map((item, index) => <RoundTag color={index % 2 ? 'green' : 'orange'} key={item}>{item}</RoundTag>);
      },
    },
    {
      title: 'Comentários',
      dataIndex: 'comment',
    },
  ];

  return (
    <>
      <FormHelperText>
        <i className="fa fa-info-circle" aria-hidden="true" />
        Nota: onde 1 é a mais baixa e 5 a mais alta.
      </FormHelperText>

      <Table
        bordered
        columns={columns}
        dataSource={[...feedbacks]}
        loading={isLoading}
        pagination={false}
        rowKey={(f: Feedback) => f._id}
      />

      <div id="lastInvisibleRow" />
    </>
  );
}
