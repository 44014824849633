import React from "react";
import { FormInputField } from "../../../../../../../components/FormInputField";
import { FormSubtitle } from "../../../../../../../common-styles";

export const SocialMedia = () => {
  return (
    <>
      <FormSubtitle>Mídias Sociais</FormSubtitle>
      <FormInputField
        label="Instagram"
        namePath={['socialMedia', 'instagram']}
        placeholder="@instagram"
      />

      <FormInputField
        label="Facebook"
        namePath={['socialMedia', 'facebook']}
        placeholder="@facebook"
      />

      <FormInputField
        label="WhatsApp"
        namePath={['socialMedia', 'whatsapp']}
        placeholder="número whatsapp"
      />
    </>
  );
}