import React, { useMemo, useState } from 'react';
import parse from 'html-react-parser';
import LazyLoad from 'react-lazyload';
import { useTranslation } from 'react-i18next';

import { ModalImage } from '../../../../../components/ModalImage';
import {
  MenuTabOption,
  MenuConfiguration,
} from '../../../../../models/DataResponse';
import { ProductMenu } from '../../../../../models/ProductCatalog';
import { isEmptyObject } from '../../../../../utils/Util';
import { sanitizePrice } from '../../../../../utils/priceUtil';
import formatCurrency from '../../../../../utils/formatCurrency';
import {
  DetailsButton,
  Container,
  Content,
  OffLabel,
  PriceLabel,
} from './styles';

enum Buttons {
  ADD = 'card_info.add',
  DETAILS = 'card_info.details',
}

interface CatalogInfoProps {
  data: ProductMenu;
  hasSellViaWhatsappEnabled: boolean;
  selectedMenuOption: MenuTabOption | null;
  configuration: MenuConfiguration;
  handleAddClick?(): void;
};

export const CatalogInfo = ({
  data,
  handleAddClick,
  selectedMenuOption,
  configuration,
  hasSellViaWhatsappEnabled,
}: CatalogInfoProps) => {
  const [isImageOpen, setImageOpen] = useState(false);
  const [buttonLabel, setButtonLabel] = useState(Buttons.DETAILS);
  const { t } = useTranslation();

  const { name, price, description, imageUrl, promotion, complements } = data;
  const hasComplements = complements && complements.length > 0;

  const canShowButton = useMemo(() => {
    // For the case that we have Tabs available, we will check for both cases:
    // With or without Whatsapp.
    if (selectedMenuOption) {
      if (
        hasSellViaWhatsappEnabled &&
        selectedMenuOption.isSellViaWhatsappEnabled
      ) {
        setButtonLabel(Buttons.ADD);
        return selectedMenuOption.isSellViaWhatsappEnabled;
      } else {
        setButtonLabel(Buttons.DETAILS);
        return hasComplements;
      }
    }

    // If there's no TABs, we will check for the tradionals menus, if it has WhatsApp enabled or not.
    if (hasSellViaWhatsappEnabled) {
      setButtonLabel(Buttons.ADD);
      return (
        !selectedMenuOption ||
        (selectedMenuOption as MenuTabOption).isSellViaWhatsappEnabled
      );
    }

    return hasComplements;
  }, [hasSellViaWhatsappEnabled, hasComplements, selectedMenuOption]);

  const getPromotionWithDiffValues = () => {
    return (
      <div>
        <h4>{getFormattedPrice(promotion?.oldPrice)}</h4>
        <h3>{getFormattedPrice(promotion?.newPrice)}</h3>
      </div>
    );
  };

  const getPromotionWithDiscount = () => {
    return (
      <>
        <div>
          <h4>{getFormattedPrice(promotion?.oldPrice)}</h4>
          <h3>{`${promotion?.discountPercent}%`}</h3>
          <OffLabel>OFF</OffLabel>
        </div>
        {getNormalPrice(promotion?.newPrice, true)}
      </>
    );
  };

  const getFormattedPrice = (value?: string) => {
    if (
      !value ||
      value === undefined ||
      isNaN(Number(sanitizePrice(value, false)))
    ) {
      return value;
    }

    const price = Number(sanitizePrice(value));
    return formatCurrency(
      price,
      configuration.companyFeatures?.sellsConfiguration?.currency ?? 'BRL' // TODO: https://github.com/uiltonjose/qrcodepreferido-app/issues/52
    );
  }

  const getNormalPrice = (value?: string, hasPromotion: boolean = false) => {
    return (
      <PriceLabel hasPromotion={hasPromotion}>
        {getFormattedPrice(value)}
      </PriceLabel>
    );
  };

  const hasPromotion = () => {
    return (
      promotion &&
      !isEmptyObject(promotion) &&
      promotion.oldPrice &&
      promotion.newPrice
    );
  };

  const renderPrice = () => {
    return hasPromotion()
      ? promotion?.discountPercent
        ? getPromotionWithDiscount()
        : getPromotionWithDiffValues()
      : getNormalPrice(price);
  };

  const expandImage = () => setImageOpen(!isImageOpen);

  return (
    <Container>
      {imageUrl && (
        <div onClick={expandImage}>
          <LazyLoad height={120} once={true}>
            <img
              className="grid-item-picture-specs"
              src={imageUrl}
              alt={name}
            />
          </LazyLoad>
        </div>
      )}
      <Content>
        <h3>{name && parse(name)}</h3>
        <small>{description && parse(description)}</small>
        {renderPrice()}

        {canShowButton && (
          <DetailsButton
            isDetail={buttonLabel === Buttons.DETAILS}
            onClick={handleAddClick}
          >
            {t(buttonLabel)}
          </DetailsButton>
        )}
      </Content>

      {isImageOpen && <ModalImage content={imageUrl ?? ''} />}
    </Container>
  );
};
