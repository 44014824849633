import React from "react";
import { Company } from "../../../../../../../models/Company";
import { SummaryList } from "../../../../../../../components/SummaryList";

interface CompanyInfoProps {
  company: Company | undefined;
}

export const CompanyInfo = ({ company }: CompanyInfoProps) => (
  <SummaryList title="Dados da Empresa">
    <SummaryList.Item>
      <SummaryList.Label>Nome fantasia</SummaryList.Label>
      <SummaryList.Value>{company?.fantasyName}</SummaryList.Value>
    </SummaryList.Item>

    <SummaryList.Item>
      <SummaryList.Label>Razão social</SummaryList.Label>
      <SummaryList.Value>{company?.companyName}</SummaryList.Value>
    </SummaryList.Item>

    <SummaryList.Item>
      <SummaryList.Label>Responsável</SummaryList.Label>
      <SummaryList.Value>{company?.responsible}</SummaryList.Value>
    </SummaryList.Item>

    <SummaryList.Item>
      <SummaryList.Label>Email</SummaryList.Label>
      <SummaryList.Value>{company?.email}</SummaryList.Value>
    </SummaryList.Item>

    <SummaryList.Item>
      <SummaryList.Label>Endereço</SummaryList.Label>
      <SummaryList.Value>{company?.address}</SummaryList.Value>
    </SummaryList.Item>

    <SummaryList.Item>
      <SummaryList.Label>Identificação (CPF/CNPJ/NIF)</SummaryList.Label>
      <SummaryList.Value>{company?.cpfOrCnpj}</SummaryList.Value>
    </SummaryList.Item>

    <SummaryList.Item>
      <SummaryList.Label>Telefone</SummaryList.Label>
      <SummaryList.Value>{company?.phoneNumber}</SummaryList.Value>
    </SummaryList.Item>
  </SummaryList>
);
