import React from "react";
import { Form } from "antd";

import { FormInputField, NamePath } from "@/components/FormInputField";
import { FormLabelField } from "@/components/FormLabelField";
import { RichText } from "@/components/RichText";

interface InputsLangsProps {
  descriptionId: string | NamePath;
  isTitleRequired?: boolean;
  titleId: string | NamePath;
  useRichText?: boolean;
  titleMaxLength?: number;
  descriptionMaxLength?: number;
}

export const InputsLangs = ({
  descriptionId,
  isTitleRequired,
  titleId,
  useRichText,
  titleMaxLength,
  descriptionMaxLength,
}: InputsLangsProps) => {
  return (
    <>
      <FormInputField
        label="Título"
        name={titleId}
        placeholder="Título"
        maxLength={titleMaxLength}
        useDefaultRules={isTitleRequired}
      />

      {useRichText ? (
        <Form.Item label={<FormLabelField label="Descrição" />} name={descriptionId}>
          <RichText />
        </Form.Item>
      ) : (
        <FormInputField
          label="Descrição"
          name={descriptionId}
          placeholder="Descrição"
          isInputArea
          maxLength={descriptionMaxLength}
        />
      )}
    </>
  );
};
