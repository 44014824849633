import React from "react";
import { Form } from "antd";
import { useTranslation } from "react-i18next";

import { FormInputField } from "@/components/FormInputField";
import { SellsConfiguration } from "@/models/SellsConfiguration";
import { PhoneNumberMaskInput } from "@/components/PhoneNumberMaskInput";
import { useFormOrderInstance } from "../../../../hooks/useFormOrderContext";
import { OrderSection } from "../../../OrderSection";

interface IdentificationProps {
  sellsConfiguration: SellsConfiguration;
  onPhoneChanged: (phone: string | undefined) => void;
}

export const Identification = ({ sellsConfiguration, onPhoneChanged }: IdentificationProps) => {
  const { isTelephoneRequired, isEmailRequired, isEmailVisible, isInstagramVisible, isInstagramRequired } = sellsConfiguration;
  const form = useFormOrderInstance();
  const { t } = useTranslation();

  const getDefaultCountry = () => {
    if (navigator.language === 'pt-PT') {
      return 'PT';
    }
    return 'BR';
  };

  return (
    <OrderSection title={t('order.identification.title')} >
      <FormInputField
        name="name"
        label={t('order.identification.name')}
        placeholder={t('order.identification.name')}
        maxLength={50}
        useDefaultRules
      />

      <Form.Item
        name="phone"
        rules={[
          {
            required: isTelephoneRequired,
            message: 'Campo obrigatório',
          },
        ]}
        label={<label className="label-input-field">{t('order.identification.phone')}</label>}
      >
        <PhoneNumberMaskInput
          defaultCountry={getDefaultCountry()}
          placeholder={t('order.identification.phone')}
          rules={{ required: isTelephoneRequired }}
          onValueChanged={(value) => {
            form.setFieldValue("phone", value);
            onPhoneChanged(value);
          }}
        />
      </Form.Item >

      {isEmailVisible && (
        <FormInputField
          name="email"
          label={t('order.identification.email')}
          placeholder={t('order.identification.email')}
          rules={[
            {
              required: isEmailRequired,
              message: t('common.required_field'),
            },
          ]}
        />
      )}

      {isInstagramVisible && (
        <FormInputField
          name="instagram"
          label="Instagram"
          placeholder="Instagram"
          rules={[
            {
              required: isInstagramRequired,
              message: t('common.required_field'),
            },
          ]}
        />
      )}
    </OrderSection>
  );
};
