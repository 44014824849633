import React, { useState } from 'react';
import { Button } from 'antd';

import { ActionType } from '../../../../../../models/Enum';
import { DeliveryFee } from '../../../../../../models/DeliveryFee';
import { CompanyFeatures } from '../../../../../../models/CompanyFeatures';

import { DeliveryFeeDetails } from './DeliveryFeeDetails';
import { DeliveryFeeList } from './DeliveryFeeList';
import { UpdateCompanyFeaturesService } from '../../../../../../services/UpdateCompanyFeaturesService';
import { AlertNotification } from '@/components/AlertNotification';
import { NotificationType } from '@/components/AlertNotification/NotificationType';

interface DeliveryFeePageProps {
  companyId: string;
  currency: string;
  companyFeatures: CompanyFeatures;
}

export const DeliveryFeePage = ({
  companyId,
  currency,
  companyFeatures,
}: DeliveryFeePageProps) => {
  const [isPageOpen, setPageOpen] = useState(false);
  const [selectedClientFare, setSelectedClientFare] = useState<DeliveryFee>();
  const [clientFareList, setClientFareList] = useState<DeliveryFee[]>(
    companyFeatures.sellsConfiguration!!.clientFare || []
  );

  function handleClientFareEditAction(clientFare: DeliveryFee) {
    setSelectedClientFare(clientFare);
    setPageOpen(true);
  }

  const handleClientFareDeletion = async (clientFare: DeliveryFee) => {
    await UpdateCompanyFeaturesService.deleteDeliveryFee(clientFare._id, companyId);

    const filtered = clientFareList.filter((item) => item._id !== clientFare._id);
    setClientFareList(filtered);
  }

  const onClosePage = (actiontype: ActionType, clientFare?: DeliveryFee) => {
    switch (actiontype) {
      case ActionType.ADD:
        setClientFareList([...clientFareList, clientFare!]);
        break;
      case ActionType.UPDATE:
        const index = clientFareList.findIndex(
          (item) => item._id === clientFare?._id
        );
        if (index !== -1) {
          clientFareList[index] = clientFare!;
        } else {
          AlertNotification({
            message: 'Erro ao tentar atualizar Frete/Zona.',
            type: NotificationType.ERROR,
          });
        }
        break;
    }
    setPageOpen(false);
  };

  return (
    <>
      <Button
        type="primary"
        onClick={() => {
          setSelectedClientFare(undefined);
          setPageOpen(true);
        }}
      >
        <i
          className="fa fa-plus-circle"
          aria-hidden="true"
          style={{ marginRight: '10px' }}
        />
        Adicionar Frete
      </Button>

      <DeliveryFeeDetails
        isVisible={isPageOpen}
        companyFeatureId={companyFeatures._id}
        companyId={companyId}
        selectedDeliveryFee={selectedClientFare}
        currency={currency}
        onClose={onClosePage}
      />

      <DeliveryFeeList
        clientFareList={clientFareList}
        deleteAction={handleClientFareDeletion}
        editAction={handleClientFareEditAction}
        currency={currency}
      />
    </>
  );
};
