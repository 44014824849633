import React, { useCallback, useState } from "react";
import { FileRejection, useDropzone } from "react-dropzone";
import { FiUpload } from "react-icons/fi";
import { AlertNotification } from "../AlertNotification";
import { NotificationType } from "../AlertNotification/NotificationType";

import { Container, RemoveButton } from "./styles";

interface DropzoneProps {
  imageUrl?: string;
  onFileUploaded: (file: File | null) => void;
  recommendedDimensions?: string;
  shortImage?: boolean;
}

const showErrorMessage = (message: string) => {
  AlertNotification({
    message,
    type: NotificationType.ERROR,
  })
}

export const Dropzone = ({ imageUrl, onFileUploaded, shortImage, recommendedDimensions = '700x700' }: DropzoneProps) => {
  const [selectedFileUrl, setSelectedFileUrl] = useState(imageUrl);

  const imageSize = shortImage ? 1 : 2;
  const maxSize = imageSize * 1024 * 1024;

  const onDrop = useCallback((acceptedFiles) => {
    if (Boolean(acceptedFiles.length)) {
      const file = acceptedFiles[0];

      const fileUrl = URL.createObjectURL(file);
      setSelectedFileUrl(fileUrl);
      onFileUploaded(file);
    }
  }, [onFileUploaded]);

  const onDropRejected = useCallback((fileRejections: FileRejection[]) => {
    const fileTooLarge = fileRejections.find(
      ({ errors }) => errors[0].code === "file-too-large"
    );
    if (fileTooLarge) {
      showErrorMessage(`Imagem ${fileTooLarge.file.name} muito grande.`);
    }
  }, []);

  const removeImage = () => {
    onFileUploaded(null);
    setSelectedFileUrl(undefined);
  }

  const { getRootProps, getInputProps } = useDropzone({
    onDrop,
    onDropRejected,
    accept: {
      'image/png': ['.png'],
      'image/jpeg': ['.jpeg'],
      'image/jpg': ['.jpg'],
    },
    maxSize,
  });

  return (
    <div style={{ display: 'flex' }}>
      <Container {...getRootProps()}>
        <input {...getInputProps()} accept="image/*" />

        {selectedFileUrl ? (
          <img src={selectedFileUrl} alt="Imagem escolhida" />
        ) : (
          <p>
            <FiUpload />
            Clique ou arraste a imagem.
            <span>
              Tipos suportados: <strong>png, jpg, jpeg</strong>. Tamanho suportado: <strong>{imageSize}M</strong>.
            </span>
            <span>
              Dimensões recomendadas: <strong>{recommendedDimensions}</strong>
            </span>
          </p>
        )
        }
      </Container >

      {selectedFileUrl && (
        <RemoveButton
          className="fa fa-fw fa-trash"
          onClick={removeImage}
        />
      )}
    </div>
  );
};