import React from "react";
import { Badge, Button } from "antd";
import { ShoppingOutlined } from '@ant-design/icons';
import { useTranslation } from "react-i18next";
import { ProductStore } from "@/hooks/ProductStore/productsStore";
import { ProductMenu } from "@/models/ProductCatalog";
import formatCurrency from "@/utils/formatCurrency";
import { isMobile } from "@/utils/Util";
import { calculateProductsTotalCost } from "@/hooks";
import { Container } from "./styles";

interface CheckoutOrderButtonProps {
  currency: string;
  isCountByProduct: boolean;
  onClick(): void;
}

export const CheckoutOrderButton = ({ currency, isCountByProduct, onClick }: CheckoutOrderButtonProps) => {
  const { t } = useTranslation();
  const isMobileDevice = isMobile.any();
  const products: ProductMenu[] = ProductStore.products;
  const subtotal = calculateProductsTotalCost(products);
  const counter = products.reduce((previousValue, currentValue) => previousValue + currentValue.counter, 0);
  const productsComplementsCounter = () => ProductStore.getComplementsCounter();

  return (
    <Container isMobile={Boolean(isMobileDevice)}>
      <Button type="primary" style={{ width: '100%' }} onClick={onClick}>
        <div className="button-container">
          <Badge
            size="small"
            count={isCountByProduct ? counter : productsComplementsCounter}
            style={{ backgroundColor: '#FFF', color: 'red' }}
          >
            <ShoppingOutlined style={{ fontSize: '22px', color: '#FFF' }} />
          </Badge>
          <span className="label">{t('order.cost.checkout')}</span>
          <span className="label">({formatCurrency(subtotal, currency)})</span>
        </div>
      </Button>
    </Container>
  );
};
