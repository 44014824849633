import { useCallback } from 'react';
import { useMutation, useQueryClient } from 'react-query';
import { Product } from '../models/Product';
import { ProductService } from '../services/ProductService';
import { PRODUCTS } from './useProducts';

export const useProduct = () => {
  const queryClient = useQueryClient();

  const { mutate: mutateCreate, isLoading: isLoadingCreate } = useMutation(
    (product: Product) => ProductService.create(product)
  );

  const { mutate: mutateUpdate, isLoading: isLoadingUpdate } = useMutation(
    (product: Product) => ProductService.update(product)
  );

  const { mutate: mutateDelete, isLoading: isLoadingDelete } = useMutation(
    (product: Product) => ProductService.delete(product)
  );

  const create = useCallback((product, { onSuccess, onError }) => {
    mutateCreate(product, {
      onSuccess: (newProduct) => {
        queryClient.invalidateQueries(PRODUCTS);
        onSuccess(newProduct._id);
      },
      onError,
    });
  }, [mutateCreate, queryClient]
  );

  const update = useCallback((product, { onSuccess, onError }) => {
    mutateUpdate(product, {
      onSuccess: () => {
        queryClient.invalidateQueries(PRODUCTS);
        onSuccess();
      },
      onError,
    });
  }, [mutateUpdate, queryClient]);

  const deleteProduct = useCallback((product, { onSuccess, onError }) => {
    mutateDelete(product, {
      onSuccess: () => {
        queryClient.invalidateQueries(PRODUCTS);
        onSuccess();
      },
      onError,
    });
  }, [mutateDelete, queryClient]);

  return {
    create,
    deleteProduct,
    isLoadingCreate,
    isLoadingDelete,
    isLoadingUpdate,
    update,
  };
};
