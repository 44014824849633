import styled from 'styled-components';
import { Form } from 'antd';

export const FormContainer = styled(Form)`
  margin: unset;
  padding: unset;
  justify-content: start;
`;

export const CloseButton = styled.button`
  .close > span:not(.sr-only) {
  width: 1.25rem;
  font-size: 35px;
  display: block;
  transition: all 0.15s ease;
  }
`;

export const FooterContainer = styled.div`
  display: flex;
  flex-direction: column;
  
  text-align: center;
  margin: 20px 16px 20px;
`;