import React from 'react';
import { Button, Space } from 'antd';
import { useNavigate, useParams } from 'react-router-dom';
import { ContentHeader } from '../../../../../components/ContentHeader';
import { useGetCompany } from '../../hooks/useGetCompany';
import { LoadingPage } from '../../../../../components/LoadingPage';
import { CompanyInfo } from './components/CompanyInfo';
import { General } from './components/General';
import { LogoLinks } from './components/LogoLinks';
import { SocialMidias } from './components/SocialMidias';

export const ViewCompany = () => {
  const { id } = useParams();
  const { data: company, isLoading } = useGetCompany(id);

  const navigate = useNavigate();

  return (
    <>
      <ContentHeader title={company?.fantasyName ?? ''} />

      <General company={company} />

      <CompanyInfo company={company} />

      <SocialMidias company={company} />

      <LogoLinks company={company} />

      <Space direction='horizontal' style={{ marginTop: 20 }}>
        <Button
          danger
          onClick={() => navigate(-1)}
        >
          Cancelar
        </Button>
        <Button
          type="primary"
          onClick={() => navigate(`/sysadmin/companies/edit/${company?._id}`)}
          loading={isLoading}
        >
          <i
            className="fa fa-check-circle"
            aria-hidden="true"
            style={{ marginRight: '10px' }}
          />
          Editar
        </Button>
      </Space>

      {isLoading && <LoadingPage />}
    </>
  );
};
