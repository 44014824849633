import React, { useState } from "react";
import { Form, Radio, Space } from "antd";
import { useTranslation } from "react-i18next";

import { DeliveryFee } from "@/models/DeliveryFee";
import formatCurrency from "@/utils/formatCurrency";
import { SellsConfiguration } from "@/models/SellsConfiguration";
import { InformativeOfDeliveryFee } from "./components/InformativeOfDeliveryFee";
import { RadioItem } from "@/components/RadioItem";
import { OrderSection } from "../../../OrderSection";

import { ClientFareFooterContent } from "./styles";

interface DeliveryFeeFieldsProps {
  currency: string;
  sellsConfiguration: SellsConfiguration;
  onDeliveryFeeChange: (deliveryFee: DeliveryFee) => void;
}

export const DeliveryFeeFields = ({ currency, sellsConfiguration, onDeliveryFeeChange }: DeliveryFeeFieldsProps) => {
  const { t } = useTranslation();
  const [showFareInformative, setShowFareInformative] = useState<boolean>(false);
  const [deliveryFee, setDeliveryFee] = useState<DeliveryFee>();
  const { clientFare, isFareRequired } = sellsConfiguration;
  const deliveryFeeList = clientFare?.filter((fare) => fare.isEnabled) || [];
  const isDeliveryFeeOrZone = () => deliveryFeeList.filter((item) => item.price !== 0).length > 0;
  const deliveryFeeOptions = deliveryFeeList.map((fee) => ({ label: fee, value: fee, key: fee._id }));

  const getFeeLabel = (fee: DeliveryFee) => {
    let fareLabel = fee.destiny;
    if (fee.price && fee.price !== 0) {
      fareLabel = `${fee.destiny} - ${formatCurrency(
        fee.price,
        currency
      )}`;
    }
    return fareLabel;
  }

  if (deliveryFeeList.length === 0) return null;

  return (
    <OrderSection title={t(`order.delivery.fee_type.${isDeliveryFeeOrZone() ? 'fare' : 'zone'}`)}>
      <Form.Item
        name="clientFare"
        rules={[{
          required: isFareRequired,
          message: t('common.required_field'),
        }]}>

        <Radio.Group
          onChange={(e) => {
            const fee = e.target.value;
            setDeliveryFee(fee);
            onDeliveryFeeChange(fee);
          }}
          value={deliveryFee}
        >
          <Space direction="vertical">
            {deliveryFeeOptions.map(({ label, value, key }) => <div key={key}><RadioItem value={value} label={getFeeLabel(label)} /></div>)}
          </Space>
        </Radio.Group>
      </Form.Item>

      <ClientFareFooterContent>
        <span
          id="moreDetails"
          onClick={() => setShowFareInformative(true)}
        >
          {t('order.delivery.more_details')}
        </span>
      </ClientFareFooterContent>

      {showFareInformative && (
        <InformativeOfDeliveryFee
          deliveryFeeList={deliveryFeeList}
          currency={currency}
          closePopUp={() => setShowFareInformative(false)}
        />
      )}
    </OrderSection>
  );
};
