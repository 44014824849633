import React, { useCallback, useMemo, useState } from 'react';
import { useNavigate, useSearchParams } from 'react-router-dom';
import { Button } from 'antd';

import { Role } from '../../../../../models/Enum';
import { useCategories, useFeatures, useLoggedUser } from '../../../../../hooks';
import { useSaveCategory } from '../../hooks/useSaveCategory';
import { MenuOption } from '../../../../../models/MenuOption';
import { Category } from '@/models/Category';
import { removeAccents } from '../../../../../utils/Util';
import { DEFAULT_SELECT_COMPANY } from '../../../../../utils/Constants';
import { CategoryFilters } from '../../components/Filters';
import { AlertNotification } from '@/components/AlertNotification';
import { NotificationType } from '@/components/AlertNotification/NotificationType';
import { CategoryTable } from '../../components/Table';
import { ContentHeader } from '../../../../../components/ContentHeader';

export const CategoryPage = () => {
  const [searchInput, setSearchInput] = useState<string>('');
  const [filteredCategories, setFilteredCategories] = useState<Category[] | null>(null);
  const navigate = useNavigate();
  const [searchParams] = useSearchParams();
  const companyIdParam = searchParams.get('companyId');
  const { userLogged: { user: { company, companyAssociation, role } } } = useLoggedUser();
  const [selectedCompanyId, setSelectedCompanyId] = useState(companyIdParam ?? DEFAULT_SELECT_COMPANY);

  const updateQueryParams = useCallback((companyId: string) => {
    navigate(`?companyId=${companyId}`, { replace: true });
  }, [navigate]);

  const currentCompanyId = useMemo(() => {
    let companyId = selectedCompanyId;

    if (role === Role.USER && company) {
      companyId = company;
    } else if (role === Role.KITCHEN && companyAssociation.length === 1) {
      companyId = companyAssociation[0].companyId;
    }

    updateQueryParams(companyId);

    return companyId;
  }, [company, companyAssociation, role, selectedCompanyId, updateQueryParams]);

  const { data: categories, isFetching } = useCategories(currentCompanyId);
  const { updateListPosition } = useSaveCategory();
  const { data: features, isFetching: isFetchingFeatures } = useFeatures(currentCompanyId);
  const isLoading = isFetching || isFetchingFeatures;

  async function updateListPositions(list: Category[]) {
    updateListPosition([...list], {
      onSuccess: () => {
        AlertNotification({
          message: 'Posição atualizada',
        });
      },
      onError: () => {
        AlertNotification({
          message: 'Erro ao tentar atualizar lista',
          type: NotificationType.ERROR,
        });
      },
    });
  }

  const onSearchFiltersChanged = useCallback((searchFilter: string) => {
    setSearchInput(searchFilter);
  }, []);

  const onFiltersCategoryChanged = useCallback((categories: Category[] | null) => {
    setFilteredCategories(categories);
  }, []);

  const filteredCategoriesByInputSearch = useMemo(() => {
    const input = removeAccents(searchInput.toLowerCase());
    const list = filteredCategories ?? categories;

    return list?.filter((category) => {
      return (
        category?.category?.toLowerCase().includes(input) ||
        category?.description?.toLowerCase().includes(input)
      );
    });
  }, [categories, filteredCategories, searchInput]);

  const listOfCategories = filteredCategoriesByInputSearch ?? categories ?? [];

  return (
    <>
      <ContentHeader title={MenuOption.CATEGORIES} />
        
      <CategoryFilters
        categories={categories}
        companyId={currentCompanyId}
        features={features}
        onCompanySelect={(companyId) => setSelectedCompanyId(companyId)}
        setFilteredCategories={onFiltersCategoryChanged}
        setSearchInputFilter={onSearchFiltersChanged}
      />

      <Button type="primary" onClick={() => navigate(`${currentCompanyId}/`)} style={{ marginBottom: '10px' }}>
        <i
          className="fa fa-plus-circle"
          aria-hidden="true"
          style={{ marginRight: '10px' }}
        />
        Adicionar
      </Button>

      <CategoryTable
        categories={filteredCategories ?? listOfCategories}
        onTableChanged={updateListPositions}
        features={features}
        isLoading={isLoading}
      />
    </>
  );
};
