import React from 'react';
import CKEditor from '@ckeditor/ckeditor5-react';
import ClassicEditor from '@ckeditor/ckeditor5-build-classic';

// This file is still in JS due to the fact that the CKEditor5 library is not yet available in TS.
export const RichText = (props) => {
  const { value, onChange } = props;

  const editorConfig = {
    plugins: [
      'Essentials',
      'Heading',
      'Paragraph',
      'Bold',
      'Italic',
      'BlockQuote',
      'Link',
    ],
    toolbar: [
      'Heading',
      '|',
      'Bold',
      'Italic',
      'Link',
      'BlockQuote',
      'Undo',
      'Redo',
    ],
  };

  return (
    <CKEditor
      config={editorConfig}
      editor={ClassicEditor}
      data={value ?? ''}
      onInit={(editor) => editor.setData(value ?? '')}
      onChange={(_, editor) => {
        const data = editor.getData();
        if (onChange) {
          onChange(data);
        }
      }}
    />
  );
};
