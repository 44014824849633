import React, { useMemo, useState } from 'react';
import { Form, Radio, Space } from 'antd';
import { useTranslation } from 'react-i18next';

import { CASH_MONEY } from '../../../../../../../../../utils/Constants';
import { DEFAULT_RULE, FormInputField } from '@/components/FormInputField';
import { RadioItem } from '@/components/RadioItem';
import { OrderSection } from '../../../OrderSection';

interface PaymentMethodsProps {
  currency: string;
  paymentMethods: string[];
}

export const PaymentMethods = ({ currency, paymentMethods }: PaymentMethodsProps) => {
  const [paymentType, setPaymentType] = useState<string>();
  const { t } = useTranslation();

  const paymentTypeOptions = useMemo(() => paymentMethods.map((paymentMethod) => ({ label: paymentMethod, value: paymentMethod })), [paymentMethods]);

  if (paymentMethods.length <= 0) {
    return null;
  }

  return (
    <OrderSection title={t('order.cost.payment_methods')}>

      <Form.Item name="paymentType" rules={DEFAULT_RULE}>
        <Radio.Group
          onChange={(e) => setPaymentType(e.target.value)}
          value={paymentType}
        >
          <Space direction="vertical">
            {paymentTypeOptions.map(({ label, value }) => <div key={value}><RadioItem value={value} label={label.charAt(0).toUpperCase() + label.slice(1)} /></div>)}
          </Space>
        </Radio.Group>
      </Form.Item>

      {CASH_MONEY.includes(paymentType ?? '') && (
        <FormInputField
          label={t('order.cost.change_how_much')}
          maxLength={10}
          name="moneyChange"
          placeholder={t('order.cost.change')}
          type={'number'}
          style={{ marginTop: 'unset' }}
          prefix={currency}
        />
      )}
    </OrderSection>
  );
};
