import React, { useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import { Button, Form, Space, Tabs, TabsProps } from 'antd';
import { FormLayout } from "../../../../../utils/Styles";
import { useGetCompany } from "../../hooks/useGetCompany";
import { LoadingPage } from "../../../../../components/LoadingPage";
import { ContentHeader } from "../../../../../components/ContentHeader";
import { CustomerInformation } from "../../components/CustomerInformation";
import { LinkSetup } from "../../components/LinkSetup";
import { LogoAndCover } from "../Create/components/LogoAndCover";
import { StylesSetup } from "../../components/StylesSetup";
import { SocialMedia } from "../PendingCompanyRegistration/components/SocialMedia";
import { CompanyFormContext } from "../Create";
import { useCompany } from "../../../../../hooks/useCompany";
import { AlertNotification } from "../../../../../components/AlertNotification";
import { NotificationType } from "../../../../../components/AlertNotification/NotificationType";

enum Tab {
  GENERAL = 'Geral',
  LINK = 'Link',
  LOGO = 'Logos',
  LAYOUT = 'Layout',
  SOCIAL_MIDIA = 'Mídias sociais',
}

const items: TabsProps['items'] = [
  {
    key: Tab.LINK,
    label: Tab.LINK,
    children: <LinkSetup isEdit />,
  },
  {
    key: Tab.GENERAL,
    label: Tab.GENERAL,
    children: <CustomerInformation />,
  },
  {
    key: Tab.LOGO,
    label: Tab.LOGO,
    children: <LogoAndCover />,
  },
  {
    key: Tab.LAYOUT,
    label: Tab.LAYOUT,
    children: <StylesSetup />,
  },
  {
    key: Tab.SOCIAL_MIDIA,
    label: Tab.SOCIAL_MIDIA,
    children: <SocialMedia />,
  },
];

export const EditCompany = () => {
  const [currentTab, setCurrentTab] = useState(Tab.LINK);
  const { id } = useParams();
  const { data: company, isLoading: isLoadingGetCompany } = useGetCompany(id);
  const { update, isLoadingUpdate } = useCompany();
  const [form] = Form.useForm();
  const navigate = useNavigate();
  const isLoading = isLoadingGetCompany || isLoadingUpdate;

  const onTabChanged = (key: string) => setCurrentTab(key as Tab);

  const handleSubmit = (values: any) => {
    const toUpdate = {
      ...company,
      ...values,
      id,
    };

    if (values.menuUrl !== company?.slug) {
      toUpdate.slug = values.menuUrl
    }

    update({
      company: toUpdate,
      onSuccess: () => {
        AlertNotification({
          message: 'Empresa atualizada com sucesso',
        });
        navigate(-1);
      },
      onError: () => {
        AlertNotification({
          message: 'Erro ao tentar atualizar empresa',
          type: NotificationType.ERROR,
        });
      }
    });
  };

  const handleSubmitFailed = () => {
    setCurrentTab(Tab.GENERAL);
    AlertNotification({
      message: 'Erro ao tentar atualizar empresa',
      type: NotificationType.ERROR,
    });
  };

  return (
    <>
      <ContentHeader title={`Atualizar Empresa - ${company?.fantasyName}`} />

      <CompanyFormContext.Provider value={form}>
        <Form
          {...FormLayout}
          layout="vertical"
          key={company?._id}
          form={form}
          initialValues={company}
          onFinish={handleSubmit}
          onFinishFailed={handleSubmitFailed}
        >
          <Tabs
            activeKey={currentTab.toString()}
            items={items}
            onChange={onTabChanged}
            size='large'
          />

          <Space direction="horizontal" style={{ marginTop: 20 }}>
            <Button
              danger
              onClick={() => navigate(-1)}
            >
              Cancelar
            </Button>

            <Button
              type="primary"
              htmlType="submit"
            >
              <i
                className="fa fa-check-circle"
                aria-hidden="true"
                style={{ marginRight: '10px' }}
              />
              Salvar
            </Button>
          </Space>
        </Form>
      </CompanyFormContext.Provider>

      {isLoading && <LoadingPage />}
    </>
  );
};
