import React, { useState } from "react";
import { Button } from "antd";
import { PaymentList } from "../../../../../../components/PaymentList";
import { useUpdateCompanyFeatures } from "../../../../../../hooks";
import { CompanyFeatures } from "../../../../../../models/CompanyFeatures";
import { AlertNotification } from "@/components/AlertNotification";
import { NotificationType } from "@/components/AlertNotification/NotificationType";


interface ClientPaymentListProps {
  companyFeatures: CompanyFeatures;
}

export const ClientPaymentList = ({ companyFeatures }: ClientPaymentListProps) => {
  const [paymentOptions, setPaymentOptions] = useState<string[]>(companyFeatures.sellsConfiguration.paymentMethods);
  const { isLoading, update } = useUpdateCompanyFeatures();

  const handleSave = () => {
    companyFeatures.sellsConfiguration.paymentMethods = paymentOptions;

    update(companyFeatures, {
      onSuccess: () => {
        AlertNotification({
          message: 'Operação realizada com sucesso.',
        });
      },
      onError: () => {
        AlertNotification({
          message: 'Erro ao tentar proceder com a operação.',
          type: NotificationType.ERROR,
        });
      },
    });
  };

  return (
    <>
      <PaymentList
        paymentsMethods={paymentOptions}
        onChange={(payments: string[]) => setPaymentOptions(payments)}
      />

      <Button
        onClick={handleSave}
        loading={isLoading}
        style={{ marginTop: '20px' }}
        type="primary"
      >
        <i
          className="fa fa-check-circle"
          aria-hidden="true"
          style={{ marginRight: '10px' }}
        />

        Salvar
      </Button>
    </>
  );
}