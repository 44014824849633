import { AxiosError } from 'axios';
import { confirmAlert } from 'react-confirm-alert';
import { useQuery } from 'react-query';
import { useNavigate } from 'react-router-dom';
import api from '../services/api';

const DEFAULT_STALE_TIME = 1000;

export interface UseFetchProps {
  cacheTime?: number;
  enabled?: boolean;
  headers?: any;
  isPublicAPI?: boolean;
  onError?: ((err: unknown) => void) | undefined;
  onSettled?: ((data: any | undefined, error: unknown) => void) | undefined;
  onSuccess?: ((data: any) => void) | undefined;
  queryKey: string | any[];
  refetchInterval?: number | any;
  refetchOnWindowFocus?: boolean;
  retry?: boolean | number;
  staleTime?: number;
  url: string;
}

export function useFetch<T = unknown>({
  cacheTime,
  enabled = true,
  headers,
  isPublicAPI = false,
  onError,
  onSettled,
  onSuccess,
  queryKey,
  refetchInterval,
  refetchOnWindowFocus = false,
  retry = false,
  staleTime = DEFAULT_STALE_TIME,
  url,
}: UseFetchProps) {
  const navigate = useNavigate();

  const config = { headers };

  const { data, ...rest } = useQuery<T>(
    queryKey,
    async () => {
      const response = await api.get(url, config).catch(ex => {
        const error = ex as AxiosError;

        if (!isPublicAPI && error.response) {
          switch (error.response.status) {
            case 401:
              confirmAlert({
                title: 'Sessão inválida',
                message: 'Você será redirecionado para efetuar um novo login.',
                buttons: [
                  {
                    label: 'Ok',
                    onClick: () => {
                      localStorage.clear();
                      navigate('/session');
                    },
                  },
                ],
                closeOnClickOutside: false,
              });
              break;
          }
        }

        throw error;
      });

      return response.data.data;
    },
    {
      cacheTime,
      enabled,
      onError,
      onSettled,
      onSuccess,
      refetchInterval,
      refetchOnWindowFocus,
      retry,
      staleTime,
    }
  );

  return { data, ...rest };
}
