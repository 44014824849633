import React from 'react';
import { useTranslation } from 'react-i18next';
import { MenuConfiguration } from '@/models/DataResponse';
import { Company } from '@/models/Company';
import { LanguagesFlags } from './components/LanguagesFlags';
import { FeedbackRateButton } from '@/components/FeedbackRateButton';
import { EstablishmentName, HeaderContainer, Logo, Overlay } from './styles';
import { SeeMoreButton } from '../../styles';

interface EstablishmentHeaderProps {
  company: Company;
  configuration: MenuConfiguration;
  onLanguageChanged: (language: string) => void;
  toggleFeedbackDrawer: () => void;
  toggleAboutUsDrawer: () => void;
}

export const EstablishmentHeaderV2 = ({
  company,
  configuration,
  onLanguageChanged,
  toggleFeedbackDrawer,
  toggleAboutUsDrawer,
}: EstablishmentHeaderProps) => {
  const { t } = useTranslation();
  const { coverUrl, logoUrl, fantasyName } = company;

  return (
    <HeaderContainer backgroundImage={coverUrl}>
      <Overlay>
        <div className='logo-content'>
          <Logo src={logoUrl} alt="Logo" />

          <div className='establishment-info'>
            <EstablishmentName>{fantasyName}</EstablishmentName>
            <FeedbackRateButton
              customColor="white"
              feedbackConfig={configuration.companyFeatures.feedback}
              onClick={toggleFeedbackDrawer} />
          </div>
        </div>

        {configuration.modules.isMultilanguageEnabled && (
          <LanguagesFlags
            languages={configuration.languages.map(lang => lang.key)}
            onClick={onLanguageChanged} />
        )}

        {configuration.companyFeatures?.openingHours?.length > 0 && (
          <div className='schedule-container'>
            <SeeMoreButton
              color="white"
              onClick={toggleAboutUsDrawer}
            >
              <i
                className="fa fa-info-circle"
                aria-hidden="true"
                style={{ marginRight: '2px' }}
              />{' '}
              {t('schedules')}
            </SeeMoreButton>
          </div>
        )}
      </Overlay>
    </HeaderContainer>
  );
};
