import React from "react";
import { FormInputField } from "../../../../../../../components/FormInputField";
import { FormSubtitle } from "../../../../../../../common-styles";

export const SocialMedia = () => {
  return (
    <>
      <FormSubtitle style={{ marginTop: 50 }}>Mídias Sociais</FormSubtitle>
      <FormInputField
        label="Instagram"
        name="instagram"
        placeholder="@instagram"
      />

      <FormInputField
        label="Facebook"
        name="facebook"
        placeholder="@facebook"
      />

      <FormInputField
        label="WhatsApp"
        name="whatsapp"
        placeholder="número whatsapp"
      />

      <FormInputField
        label="Telefone"
        name="phoneFooter"
        placeholder="número do telefone"
      />

      <FormInputField
        label="Website"
        name="websiteFooter"
        placeholder="Website"
      />
    </>
  );
}