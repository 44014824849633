import i18n from '@/i18n';
import { OpeningHours } from '../../../models/DataResponse';
import { ScheduleByDayWeek, ScheduleItem } from '../../../models/Schedule';
import { MIDNIGHT } from '../../../utils/Constants';

const WEEKDAYS = [1, 2, 3, 4, 5, 6, 0];

type HourData = {
  startHour: string;
  endHour: string;
};

export const getScheduleItems = (openingHours: OpeningHours[], weekdays: string[]) => {
  const aggregatedDays: Map<Number, HourData[]> = getAggregatedDays(openingHours);

  const scheduleFinal: ScheduleItem[] = [];
  WEEKDAYS.forEach((day) => {
    const schedule = {
      day,
      weekdayText: weekdays[day],
      hoursText: aggregatedDays.has(day)
        ? aggregatedDays
          .get(day)!!
          .sort((a, b) => a.startHour.localeCompare(b.startHour))
          .map((item) => `${item.startHour} às ${item.endHour}`)
          .join(' - ')
        : i18n.t('common.closed'),
    };
    scheduleFinal.push(schedule);
  });

  return scheduleFinal;
}

export const getHoursByDayOfWeek = (openingHours: OpeningHours[]) => {
  const aggregatedDays = getAggregatedDays(openingHours);

  const scheduleFinal: ScheduleByDayWeek[] = [];
  WEEKDAYS.forEach((day) => {
    const schedule = {
      weekday: day,
      hours: aggregatedDays.has(day) ? aggregatedDays.get(day) : null,
    };
    scheduleFinal.push(schedule);
  });

  return scheduleFinal;
}

export const getAggregatedDays = (openingHours: OpeningHours[]) => {
  let aggregatedDays = new Map();
  openingHours.forEach((timer) => {
    const hours: HourData = {
      startHour: timer.startHour,
      endHour: timer.endHour,
    };

    timer.weekdays.forEach((day) => {
      if (aggregatedDays.has(day)) {
        aggregatedDays.get(day).push(hours);
      } else {
        aggregatedDays.set(day, [hours]);
      }
    });
  });

  return aggregatedDays;
}

export const getCurrentDay = (openingHours: OpeningHours[]) => {
  const now = new Date();
  const dayOfWeek = now.getDay();

  return getHoursByDayOfWeek(openingHours).filter(
    (item) => item.weekday === dayOfWeek
  )[0];
}

export const isOpenNow = (openingHours: OpeningHours[]) => {
  if (openingHours.length === 0) return true;

  const currentDay = getCurrentDay(openingHours);

  if (currentDay.hours === null) return false;

  const now = new Date();

  let minutes = `${now.getMinutes()}`;
  if (now.getMinutes() < 10) {
    minutes = `0${minutes}`;
  }

  let hours = `${now.getHours()}`;
  if (now.getHours() < 10) {
    hours = `0${hours}`;
  }

  let isOnRange = false;
  const nowHours = `${hours}:${minutes}`;
  currentDay.hours.forEach((current) => {
    let endHour = current.endHour;
    if (endHour === MIDNIGHT) {
      endHour = '24:00';
    }
    if (
      nowHours.localeCompare(current.startHour) >= 0 &&
      nowHours.localeCompare(endHour) <= 0
    ) {
      isOnRange = true;
      return;
    }
  });

  return isOnRange;
}

export const getClosedReason = (openingHours: OpeningHours[]) => {
  const currentDay = getCurrentDay(openingHours);

  // There's no hour, that means it's a closed day
  if (currentDay.hours === null) {
    return null;
  }

  // There's a single pair of hour, it's a simple start/end hour
  if (currentDay.hours.length === 1) {
    return currentDay.hours[0].startHour.toString();
  }

  const now = new Date();
  let minutes = `${now.getMinutes()}`;
  if (now.getMinutes() < 10) {
    minutes = `0${minutes}`;
  }
  const nowHours = `${now.getHours()}:${minutes}`;

  let result = null;
  currentDay.hours
    .sort((a, b) => a.startHour.localeCompare(b.startHour))
    .forEach((hour) => {
      if (nowHours.localeCompare(hour.startHour) <= -1) {
        result = hour.startHour;
      } else if (nowHours.localeCompare(hour.endHour) > 0) {
        result = null;
      }
    });

  return result;
}
