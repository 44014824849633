import React, { useState } from "react";
import { useParams } from "react-router-dom";
import { Divider, Form } from "antd";

import { useCompanyFeatures, useFeatures } from "@/hooks";
import { FormSwitchItem } from "@/components/FormSwitchItem";
import { FormInputField } from "@/components/FormInputField";
import { PaymentList } from "@/components/PaymentList";

export const Seals = () => {
  const { id: companyId } = useParams();
  const { data: features } = useFeatures(companyId);
  const { companyFeatures } = useCompanyFeatures(companyId);
  const [paymentMethods, setPaymentMethods] = useState<string[]>(companyFeatures?.sellsConfiguration?.paymentMethods ?? []);
  const [isEnabled, setIsEnabled] = useState<boolean>(features?.sellViaWhatsapp ?? false);

  return (
    <>
      <FormSwitchItem
        label="Módulo vendas"
        propertyId="sellViaWhatsapp"
        defaultChecked={isEnabled}
        checkedValueText={'Ativo'}
        uncheckedValueText={'Pausado'}
        handleCheckboxChange={(value) => setIsEnabled(value)}
      />

      {isEnabled && (
        <>
          <FormInputField
            label="Número do WhatsApp"
            name="whatsappNumber"
            placeholder="País + número"
            useDefaultRules={isEnabled}
            type="number"
            style={{ width: '20%' }}
          />

          <Divider orientation="left"><strong>Forma de pagamento</strong></Divider>
          <Form.Item name={'paymentMethods'}>
            <PaymentList
              paymentsMethods={paymentMethods}
              onChange={(options) => setPaymentMethods(options)}
            />
          </Form.Item>
        </>
      )}
    </>
  );
};
