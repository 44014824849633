import React, { useState } from "react";
import { useParams } from "react-router-dom";
import { Divider } from "antd";
import { AlertNotification } from "@/components/AlertNotification";
import { FormSwitchItem } from "@/components/FormSwitchItem";
import { useFeatures } from "@/hooks";
import { useUpdateFeatures } from "@/hooks/useUpdateFeatures";

export const FeatureFlags = () => {
  const { id: companyId } = useParams();
  const { data: features } = useFeatures(companyId);
  const { update } = useUpdateFeatures();
  const [isNewHeaderUiEnabled, setIsNewHeaderUiEnabled] = useState<boolean>(features?.newHeaderUiEnabled ?? false);

  const handleFeatureUpdate = (value: boolean) => {
    update({
      ...features,
      newHeaderUiEnabled: value,
    }, {
      onSuccess: AlertNotification({
        message: value ? 'Nova UI do header ativada' : 'Nova UI do header pausada',
      }),
    });

    setIsNewHeaderUiEnabled(value);
  };

  return (
    <>
      <Divider orientation="left"><strong>Feature flags</strong></Divider>
      <FormSwitchItem
        label="Nova UI do header"
        propertyId="newHeaderUiEnabled"
        defaultChecked={isNewHeaderUiEnabled}
        checkedValueText={'Ativo'}
        uncheckedValueText={'Pausado'}
        handleCheckboxChange={handleFeatureUpdate}
      />
    </>
  );
};
