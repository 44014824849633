import React from 'react';
import { Form, Switch } from 'antd';
import { FormHelper } from '../FormHelper';
import { FormLabelField } from '../FormLabelField';
import { NamePath } from '../FormInputField';

type FormSwitchItemProps = {
  label: string;
  propertyId: string | NamePath;
  helper?: string;
  defaultChecked?: boolean;
  disabled?: boolean;
  checkedValueText?: string;
  uncheckedValueText?: string;
  handleCheckboxChange?: (checked: boolean, propertyId: string | NamePath) => void;
};

export const FormSwitchItem = ({
  label,
  propertyId,
  helper,
  defaultChecked,
  disabled,
  checkedValueText,
  uncheckedValueText,
  handleCheckboxChange,
}: FormSwitchItemProps) => {
  const isDisabled = disabled || false;
  return (
    <Form.Item
      label={<FormLabelField label={label} />}
      name={propertyId}
      help={helper && <FormHelper label={helper} />}
    >
      <Switch
        checked={defaultChecked}
        checkedChildren={checkedValueText ?? 'Sim'}
        unCheckedChildren={uncheckedValueText ?? 'Não'}
        disabled={isDisabled}
        onChange={(checked) => {
          if (handleCheckboxChange) handleCheckboxChange(checked, propertyId);
        }}
      />
    </Form.Item>
  );
};
