import { useCallback } from "react";
import { useMutation, useQueryClient } from "react-query";
import { FEATURES } from "@/hooks";
import { TabMenuOptionService } from "../services/TabMenuOptionService";

export const useDeleteMenuOption = () => {
  const queryClient = useQueryClient();

  const { mutate } = useMutation(
    (id: string) => TabMenuOptionService.delete(id)
  );

  const deleteOption = useCallback((tabMenuOption, { onSuccess, onError }) => {
    mutate(tabMenuOption, {
      onSuccess: () => {
        queryClient.invalidateQueries(FEATURES);
        onSuccess();
      },
      onError,
    });
  }, [mutate, queryClient]);

  return { deleteOption };
};
