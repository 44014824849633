
import styled from 'styled-components';

interface BackgroundImageProps {
  backgroundImage?: string;
}

export const HeaderContainer = styled.div.withConfig({
  shouldForwardProp: (prop) => prop !== 'backgroundImage',
})<BackgroundImageProps>`
  position: relative;
  color: white;
  background-image: ${props => props.backgroundImage ? `url(${props.backgroundImage})` : 'none'};
  background-size: cover;
  display: flex;
  align-items: center;
  height: ${props => props.backgroundImage ? '235px' : '150px'};

  .schedule-container {
    height: 40px;
    margin-top: 16px ;
  }
`;

export const Overlay = styled.div`
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background: linear-gradient(to bottom, rgba(0,0,0,0.3) 0%, rgba(0,0,0,0.7) 50%, rgba(0,0,0,0.99) 100%);
  display: flex;
  flex-direction: column;
  justify-content: flex-end;
  padding-left: 20px; 

  .logo-content {
    display: flex;
    align-items: center;
    justify-content: flex-start;
  }

  .establishment-info {
    display: flex;
    flex-direction: column;
    margin-top: 14px;
  }
`;

export const Logo = styled.img`
  height: 60px;
  width: auto;
  margin-right: 10px;
  background-color: white;
  border-radius: 8px;
  padding: 4px;
`;

export const EstablishmentName = styled.h1`
  font-size: 24px; 
  color: white;
  line-height: 0.5;
  margin-top: 8px;
`;