import React, { useState } from 'react';
import { Drawer } from 'antd';
import { CloseOutlined } from '@ant-design/icons';
import { useTranslation } from 'react-i18next';

import { isMobile } from '../../../../../utils/Util';
import { getScheduleItems } from '../../ScheduleUtils';
import { OpeningHours } from '../../../../../models/DataResponse';
import { PageTitle } from '../../../../../common-styles';
import { ScheduleItem } from './styles';
import { useWeekdays } from '../../hooks/useWeekdays';

interface ScheduleInformationDrawerProps {
  openingHours: OpeningHours[];
  dismissPage(): void;
};

export const ScheduleInformationDrawer = ({ openingHours, dismissPage }: ScheduleInformationDrawerProps) => {
  const [isOpen, setOpen] = useState(true);
  const weekdays = useWeekdays();
  const { t } = useTranslation();

  const scheduleGroup = getScheduleItems(openingHours, weekdays);
  const isMobileDevice = isMobile.any();

  const handleClosePage = () => {
    setOpen(false);
    dismissPage();
  };

  const isToday = (day: number) => {
    const today = new Date().getDay();
    return today === day;
  };

  return (
    <Drawer
      closable={false}
      destroyOnClose={true}
      height={isMobileDevice ? '100%' : undefined}
      open={isOpen}
      placement={isMobileDevice ? 'bottom' : 'right'}
    >
      <CloseOutlined style={{ fontSize: '18px', fontWeight: 'bolder' }} onClick={handleClosePage} />
      <PageTitle style={{ marginTop: '16px' }}>{t('schedules')}</PageTitle>
      {scheduleGroup.map((schedule) => {
        return (
          <ScheduleItem>
            <div className="details-schedule__day-title">
              <span className={`details-schedule__day-title-text ${isToday(schedule.day) && 'schedule-today'}`}>
                {schedule.weekdayText}
              </span>
            </div>
            <span className={`details-schedule__day-title-text ${isToday(schedule.day) && 'schedule-today'}`}>
              {schedule.hoursText}
            </span>
          </ScheduleItem>
        );
      })}
    </Drawer>
  );
};
