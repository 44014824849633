import React from "react";
import { Button, Drawer, Form, Space } from "antd";
import { FormLayout } from "../../../../../utils/Styles";
import { Contact } from "../../../../../models/DataResponse";
import { FormInputField } from "../../../../../components/FormInputField";
import { useContactUpdate } from "../../../../../hooks";
import { AlertNotification } from "../../../../../components/AlertNotification";
import { NotificationType } from "../../../../../components/AlertNotification/NotificationType";

interface ProcessEditorProps {
  contact: Contact;
  onClose(): void;
}

export const ProcessEditor = ({ contact, onClose }: ProcessEditorProps) => {
  const { update } = useContactUpdate();

  const onSubmit = (values: any) => {
    const data = {
      ...contact,
      processDescription: values.processDescription,
    }

    update(
      data,
      {
        onSuccess: () => {
          AlertNotification({
            message: 'Operação realizada com sucesso',
          });
          onClose();
        },
        onError: () => {
          AlertNotification({
            message: 'Erro ao tentar atualizar processo',
            type: NotificationType.ERROR,
          });
        }
      }
    );
  }

  return (
    <Drawer
      title="Processo do contato"
      onClose={onClose}
      destroyOnClose={true}
      maskClosable={false}
      placement="right"
      open={contact != null}
      size="default"
    >

      <Form
        {...FormLayout}
        layout="vertical"
        initialValues={contact}
        onFinish={onSubmit}
      >
        <FormInputField
          isInputArea
          inputTextAreaProps={{ rows: 5 }}
          label="Descrição"
          name="processDescription"
          placeholder="Descrição do processo"
          rules={[
            {
              required: true,
              message: 'Campo obrigatório',
            },
          ]}
        />

        <Space>
          <Button
            danger
            style={{ marginRight: '10px' }}
            onClick={onClose}
          >
            <i
              className="fa fa-times"
              aria-hidden="true"
              style={{ marginRight: '10px' }}
            />
            Cancelar
          </Button>

          <Button
            type="primary"
            htmlType="submit"
            loading={false}
          >
            <i
              className="fa fa-check-circle"
              aria-hidden="true"
              style={{ marginRight: '10px' }}
            />{' '}
            Salvar
          </Button>
        </Space>
      </Form>

    </Drawer>
  );
}