import React from "react";
import { Form } from "antd";
import { Features } from "@/models/Features";
import { FormInputField } from "@/components/FormInputField";
import { FormLabelField } from "@/components/FormLabelField";
import { RichText } from "@/components/RichText";
import { TabLangItem, TabLangs } from "./components/TabLangs";

interface InputTitleAndDescriptionProps {
  features: Features | undefined;
  defaultTitleId?: string;
  defaultDescriptionId?: string;
  useRichText?: boolean;
  titleMaxLength?: number;
  descriptionMaxLength?: number;
}

const TITLE_CHARACTER_LIMIT = 100;

export const InputTitleAndDescription = ({
  features,
  defaultTitleId = 'title',
  defaultDescriptionId = 'description',
  useRichText,
  titleMaxLength,
  descriptionMaxLength,
}: InputTitleAndDescriptionProps) => {
  const buildLangsOptions = () => features?.multiLanguage.languages
    .sort((a, b) => b.default.toString().localeCompare(a.default.toString()))
    .map(l => ({
      titleId: l.default ? defaultTitleId : ['translations', defaultTitleId, l.key],
      descriptionId: l.default ? defaultDescriptionId : ['translations', defaultDescriptionId, l.key],
      keyLang: l.key,
      lang: l.label,
      isTitleRequired: l.default,
      useRichText,
    } as TabLangItem)) || [];

  return (
    <>
      {features?.multiLanguage.isEnabled ? (
        <TabLangs
          defaultTabKey={features?.multiLanguage.languages.find(l => l.default)?.key || 'pt'}
          langs={buildLangsOptions()}
          titleMaxLength={titleMaxLength ?? TITLE_CHARACTER_LIMIT}
          descriptionMaxLength={descriptionMaxLength}
        />
      ) : (
        <>
          <FormInputField
            label="Título"
            name={defaultTitleId}
            placeholder="Título"
            maxLength={titleMaxLength || TITLE_CHARACTER_LIMIT}
            useDefaultRules
          />

          {useRichText ? (
            <Form.Item label={<FormLabelField label="Descrição" />} name={defaultDescriptionId}>
              <RichText />
            </Form.Item>
          ) : (
            <FormInputField
              label="Descrição"
              name={defaultDescriptionId}
              placeholder="Descrição"
              isInputArea
              maxLength={descriptionMaxLength}
            />
          )}
        </>
      )}
    </>
  );
};
