import React from "react";
import { useTranslation } from "react-i18next";
import { FormInputField } from "@/components/FormInputField";

interface TaxDataAddressProps {
  isTaxDataRequired: boolean;
}

export const TaxDataAddress = ({ isTaxDataRequired }: TaxDataAddressProps) => {
  const { t } = useTranslation();

  return (
    <>
      <FormInputField
        namePath={['taxData', 'fiscalAddress', 'address']}
        label={t('order.identification.tax_data.completed_address')}
        placeholder={t('order.identification.tax_data.completed_address')}
        maxLength={100}
        useDefaultRules={isTaxDataRequired}
      />

      <FormInputField
        namePath={['taxData', 'fiscalAddress', 'number']}
        label={t('order.identification.tax_data.address_complement')}
        placeholder={t('order.identification.tax_data.address_complement')}
        maxLength={30}
        useDefaultRules={isTaxDataRequired}
      />

      <FormInputField
        namePath={['taxData', 'fiscalAddress', 'postalCode']}
        label={t('order.address.zip_code')}
        placeholder={t('order.address.zip_code')}
        maxLength={15}
        useDefaultRules={isTaxDataRequired}
      />
    </>
  );
};
