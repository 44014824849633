import React from 'react';
import { Form, Input } from 'antd';
import { FormLabelField } from '../FormLabelField';

export declare type InternalNamePath = (string | number)[];
export declare type NamePath = string | number | InternalNamePath;
type OnEvent = React.FormEventHandler<HTMLInputElement> | undefined;

type InputTextAreaProps = {
  showCount?: boolean;
  rows?: number;
};

export const DEFAULT_RULE = [
  {
    required: true,
    message: 'Campo obrigatório',
  },
];

interface FormInputFieldProps {
  autoComplete?: string;
  dependencies?: NamePath[];
  disableInput?: boolean;
  help?: string;
  inputPrefix?: string | React.ReactNode;
  inputTextAreaProps?: InputTextAreaProps;
  isInputArea?: boolean;
  label?: string | React.ReactNode;
  maxLength?: number;
  name?: string | NamePath;
  namePath?: NamePath; // TODO: deprecate this prop
  normalize?: any;
  onBlur?: OnEvent;
  onFocus?: OnEvent;
  onInput?: OnEvent;
  onChange?: OnEvent;
  placeholder?: string;
  prefix?: string | React.ReactNode;
  rules?: any;
  style?: React.CSSProperties;
  suffix?: string | React.ReactNode;
  type?: any;
  useDefaultRules?: boolean;
};

export const FormInputField = ({
  autoComplete = "nope",
  dependencies,
  disableInput,
  help,
  inputPrefix,
  inputTextAreaProps,
  isInputArea,
  label,
  maxLength,
  name,
  namePath,
  normalize,
  onBlur,
  onFocus,
  onInput,
  onChange,
  placeholder,
  prefix,
  rules,
  style,
  suffix,
  type,
  useDefaultRules,
}: FormInputFieldProps) => {
  const renderInput = () => {
    return (
      <Input
        addonBefore={prefix}
        addonAfter={suffix}
        disabled={disableInput}
        maxLength={maxLength}
        onInput={onInput}
        onBlur={onBlur}
        onFocus={onFocus}
        onChange={onChange}
        placeholder={placeholder}
        style={{ height: '40px' }}
        type={type ?? 'text'}
        prefix={inputPrefix}
        autoComplete={autoComplete}
      />
    );
  }

  return (
    <Form.Item
      dependencies={dependencies}
      help={help}
      label={<FormLabelField label={label} />}
      name={name ?? namePath}
      normalize={normalize}
      rules={useDefaultRules ? DEFAULT_RULE : rules}
      style={style ? style : { margin: '14px 0' }}
    >
      {isInputArea ? (
        <Input.TextArea
          disabled={disableInput}
          maxLength={maxLength}
          placeholder={placeholder}
          rows={inputTextAreaProps?.rows}
          showCount={inputTextAreaProps?.showCount}
        />
      ) : (renderInput())}
    </Form.Item>
  );
};
