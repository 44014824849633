import React from 'react';
import parse from 'html-react-parser';

import { Button, Skeleton, Switch, Table } from 'antd';
import { MenuOutlined } from '@ant-design/icons';

import {
  SortableContainer,
  SortableElement,
  SortableHandle,
} from 'react-sortable-hoc';
import { arrayMoveImmutable } from 'array-move';

import { CompanyFeatures } from '../../../../../../models/CompanyFeatures';
import { SummaryOrderItem } from '../../../../../../models/DataResponse';
import { defaultSummaryMessageOrder } from '../../../../../../utils/OrderUtil';
import { FormHelperText } from '../../../../../../common-styles';
import { Container, PreviewContainer, WhatsAppPreview } from './styles';
import {
  useOrderSummaryPreview,
  useUpdateCompanyFeatures,
} from '../../../../../../hooks';
import { AlertNotification } from '../../../../../../components/AlertNotification';

const SortableItem = SortableElement((props: any) => <tr {...props} />);
const SortableContainerWrapper = SortableContainer((props: any) => (
  <tbody {...props} />
));
const DragHandle = SortableHandle(() => (
  <MenuOutlined style={{ cursor: 'grab', color: 'rgb(153, 153, 153)' }} />
));

type OrderSummaryWhatsappTableProps = {
  companyId: string;
  companyFeatures: CompanyFeatures;
};

export const OrderSummaryWhatsappTable = ({
  companyId, companyFeatures
}: OrderSummaryWhatsappTableProps) => {
  const { preview, isFetchingPreview, refetch } = useOrderSummaryPreview(companyId);
  const { update } = useUpdateCompanyFeatures();

  const items = companyFeatures?.summaryMessageOrder || [];

  const columns = [
    {
      title: '',
      dataIndex: 'sort',
      className: 'drag-visible',
      width: 30,
      render: () => <DragHandle />,
    },
    {
      title: 'Ordem',
      dataIndex: 'order',
      align: 'center' as 'center',
      render: (text: string) => Number(text),
    },
    {
      title: 'Seção',
      dataIndex: 'value',
      align: 'center' as 'center',
    },
    {
      title: 'Status',
      width: 60,
      align: 'center' as 'center',
      render: (_: any, item: SummaryOrderItem) => (
        <Switch
          key={item._id}
          defaultChecked={item.status}
          checkedChildren="Ativo"
          unCheckedChildren="Pausado"
          onChange={(isChecked) => {
            const index = items.findIndex((seek) => seek._id === item._id);
            items[index].status = isChecked;
            tableInfoChanged(items);
          }}
        />
      ),
    },
  ];

  const onSortEnd = ({
    oldIndex,
    newIndex,
  }: {
    oldIndex: number;
    newIndex: number;
  }) => {
    if (items && oldIndex !== newIndex) {
      const newData = arrayMoveImmutable(
        ([] as SummaryOrderItem[]).concat(items),
        oldIndex,
        newIndex
      ).filter((el) => !!el);

      onTableChanged(
        newData.map((item, index) => {
          return {
            ...item,
            order: index,
          };
        })
      );
    }
  };

  const DraggableContainer = (props: any) => (
    <SortableContainerWrapper
      useDragHandle
      disableAutoscroll
      helperClass="row-dragging"
      onSortEnd={onSortEnd}
      {...props}
    />
  );

  const DraggableBodyRow = ({ ...restProps }) => {
    const index = items?.findIndex(
      (x) => x.order === restProps['data-row-key']
    );
    return <SortableItem index={index} {...restProps} />;
  };

  const tableInfoChanged = (items: SummaryOrderItem[]) => {
    onTableChanged(items);
  };

  const onTableChanged = (items: SummaryOrderItem[]): void => {
    update(
      { ...companyFeatures, summaryMessageOrder: items },
      {
        onSuccess: onTableUpdatedSuccessfully,
      }
    );
  };

  const onTableUpdatedSuccessfully = () => {
    refetch();
    AlertNotification({
      message: 'Atualizado com sucesso.',
    });
  };

  return (
    <>
      <Button
        type="primary"
        style={{ marginTop: '10px' }}
        onClick={() => {
          tableInfoChanged(defaultSummaryMessageOrder as SummaryOrderItem[]);
        }}
      >
        Voltar para o padrão
      </Button>

      <FormHelperText style={{ marginTop: '10px', marginLeft: '12px' }}>
        <ul>
          <li>
            Ao pausar uma seção, a mesma não irá ser exibida no resumo do
            pedido no WhatsApp.
          </li>
          <li>
            A alteração dessas ordens, resultará na apresentação das
            informações no pedido pelo WhatsApp.
          </li>
        </ul>
      </FormHelperText>

      <Container>
        <Table
          pagination={false}
          dataSource={items ? [...items] : []}
          columns={columns}
          rowKey="order"
          bordered
          components={{
            body: {
              wrapper: DraggableContainer,
              row: DraggableBodyRow,
            },
          }}
        />

        <PreviewContainer>
          <h2>Preview</h2>

          {isFetchingPreview ? (
            <Skeleton />
          ) : (
            <WhatsAppPreview>
              <p>{preview && parse(preview)}</p>
            </WhatsAppPreview>
          )}
        </PreviewContainer>
      </Container>
    </>
  );
};
