import { DEFAULT_TIMEZONE } from "./Constants";

export const DATE_FORMAT = 'DD/MM/YYYY';
export const SERVER_DATE_FORMAT = 'yyyy-MM-DD';
export const TIME_FORMAT = 'HH:mm';

export const formatTime = (dateStr: string) => {
  const date = new Date(dateStr);

  let minutes = `${date.getMinutes()}`;
  if (date.getMinutes() < 10) {
    minutes = `0${date.getMinutes()}`;
  }
  return `${date.getHours()}:${minutes}`;
};

export const getDayFromDate = (date: Date) => {
  const day = date.getDate();
  return day < 10 ? `0${day}` : `${day}`;
};

export const formatDateToString = (date: Date) => {
  const day = getDayFromDate(date);

  const mothRaw = date.getMonth() + 1;
  const month = mothRaw < 10 ? `0${mothRaw}` : mothRaw;
  return day + '/' + month + '/' + date.getFullYear();
};

export const formatDate = (dateStr: string) => {
  const date = new Date(dateStr);
  const day = getDayFromDate(date);

  const mothRaw = date.getMonth() + 1;
  const month = mothRaw < 10 ? `0${mothRaw}` : mothRaw;
  return day + '/' + month + '/' + date.getFullYear();
};

export const formatDatetime = (dateStr: string | undefined) => {
  if (!dateStr) return;
  
  const dateFormatted = formatDate(dateStr);
  const date = new Date(dateStr);

  let minutes = `${date.getMinutes()}`;
  if (date.getMinutes() < 10) {
    minutes = `0${date.getMinutes()}`;
  }

  return `${dateFormatted} ${date.getHours()}:${minutes}`;
};

export const formatDatetimeByTimezone = (dateStr: string, timezone?: string) => {
  const dateFormatted = formatDate(dateStr);
  const hours = getHoursByTimezone(dateStr, timezone ?? DEFAULT_TIMEZONE);

  return `${dateFormatted} ${hours}`;
};

export const formatTimeByTimezone = (dateStr: string, timezone?: string) => {
  return getHoursByTimezone(dateStr, timezone ?? DEFAULT_TIMEZONE);
};

export const isToday = (someDate: Date): boolean => {
  const today = new Date();
  return (
    someDate.getDate() === today.getDate() &&
    someDate.getMonth() === today.getMonth() &&
    someDate.getFullYear() === today.getFullYear()
  );
};

export const getDaysInMonth = (month: any, year: any): number => {
  return new Date(year, month, 0).getDate();
};

export const convertTimeInMinutes = (date: Date): number => {
  const minutes = date.getHours() * 60;
  return date.getMinutes() + minutes;
};

export const convertServerDateToLocalDate = (dateString: string) => {
  const split = dateString.split('-');
  const year = split[0];
  const month = split[1];
  const day = split[2];
  return `${day}/${month}/${year}`;
};

export const getHoursByTimezone = (dateStr: string, timezone?: string) => {
  const date = new Date(dateStr);

  return date.toLocaleTimeString('pt-br', {
    hour: '2-digit',
    minute: '2-digit',
    timeZone: timezone ?? 'Europe/Lisbon',
  });
};
