import React from "react";
import { FormInputField } from "../../../../../../../components/FormInputField";
import { FormSubtitle } from "../../../../../../../common-styles";

export const LogoAndCover = () => {
  return (
    <>
      <FormSubtitle>Logo e Cover</FormSubtitle>
      <FormInputField
        label="Logo para a nossa landing page"
        name="logoSiteUrl"
        help="Esta logo será usada em nosso site. Ao deixar vazio, esta loja não irá aparecer na lista de cliente em nosso site."
        style={{ marginTop: 20 }}
      />

      <FormInputField
        label="Logo"
        name="logoUrl"
        useDefaultRules
        style={{ marginTop: 40 }}
      />

      <FormInputField
        label="Cover"
        name="coverUrl"
        style={{ marginTop: 20 }}
      />
    </>
  );
}