import React, { useEffect, useMemo, useState } from 'react';
import parse from 'html-react-parser';
import { Drawer, Input } from 'antd';
import { useTranslation } from 'react-i18next';

import { ClosedStore } from '../ClosedStore';
import { ProductStore } from '../../../../../hooks/ProductStore/productsStore';
import {
  MenuTabOption,
  MenuConfiguration
} from '../../../../../models/DataResponse';
import { ProductMenu, ComplementItemOption, ComplementOfProduct } from '../../../../../models/ProductCatalog';
import { ComplementAction } from '../../../../../models/Enum';
import { ProductOutOfStockResponse } from '../../../../../models/ProductStock';
import formatCurrency from '../../../../../utils/formatCurrency';
import { sanitizePrice } from '../../../../../utils/priceUtil';
import { isMobile } from '../../../../../utils/Util';
import { isOpenNow } from '../../ScheduleUtils';
import { useCheckStock } from '../../../../../hooks';
import { AlertNotification } from '@/components/AlertNotification';
import { NotificationType } from '@/components/AlertNotification/NotificationType';
import { ProductImage } from './ProductImage';
import { ProductComplements, ComplementWrapper } from './Complements';
import { IncrementalButtons } from './IncrementalButtons';

import {
  AddButtonHolder,
  CloseButton,
  Container,
  ErrorMessage,
  FooterContainer,
  OrderCommentLabel,
  ProductDescription,
  Title,
} from './style';

interface OrderProductDetailDrawerProps {
  configuration: MenuConfiguration;
  product: ProductMenu;
  selectedMenuOption: MenuTabOption | null;
  slug: string;
  onClose: () => void;
}

let selectedComplements = [] as ComplementOfProduct[];

export const OrderProductDetailDrawer = ({
  configuration,
  product,
  selectedMenuOption,
  slug,
  onClose,
}: OrderProductDetailDrawerProps) => {
  const [open, setOpen] = useState(false);
  const [isButtonDisabled, setButtonDisabled] = useState(false);
  const [isClosedStore, setClosedStore] = useState(false);
  const [counter, setCounter] = useState(1);
  const [requiredFieldsCount, setRequiredFieldsCount] = useState(0);
  const [complementAmount, setComplementAmount] = useState(0);
  const [extraNote, setExtraNote] = useState('');
  const [errorMessage, setErrorMessage] = useState('');
  const { t } = useTranslation();

  const { currency, orderCountType } = configuration.companyFeatures.sellsConfiguration;
  const isCountByProduct = orderCountType === 'BY_PRODUCT';
  const companyFeatures = configuration.companyFeatures!!;
  const hasSellViaWhatsappEnabled = useMemo(() => {
    const whatsappFeatureEnabled = configuration.sellViaWhatsappEnabled;
    if (selectedMenuOption) {
      return (
        whatsappFeatureEnabled && selectedMenuOption.isSellViaWhatsappEnabled
      );
    }
    return whatsappFeatureEnabled;
  }, [selectedMenuOption, configuration.sellViaWhatsappEnabled]);

  const { checkStock, isLoading: isLoadingCheckStock } = useCheckStock(slug);

  const isMobileDevice = useMemo(() => {
    return isMobile.any();
  }, []);

  useEffect(() => {
    selectedComplements = [];

    if (product.isUpdate) {
      setCounter(product.counter);
      setExtraNote(product.extraNote || '');

      if (product.selectedComplements) {
        selectedComplements = product.selectedComplements;
        calculateComplementAmount(selectedComplements);
      }
    } else {
      product.selectedComplements = [];
      product.complements?.forEach((complement) => {
        complement.isFulfilledRequirements = undefined;
        complement.complementItemOptions.forEach((option) => {
          option.counter = 0;
        });
      });
    }

    const requiredFieldsCount = product.complements?.filter(
      (item) => item.isEnabled && item.isRequired
    ).length || 0;

    if (
      !product.isUpdate &&
      ((requiredFieldsCount > 0) ||
        (!isCountByProduct && product.complements?.length))
    ) {
      setButtonDisabled(true);
    }

    setRequiredFieldsCount(requiredFieldsCount);

    setOpen(true);
  }, [product, isCountByProduct]);

  function handleClose() {
    selectedComplements = [];
    setOpen(false);
    setCounter(1);
    onClose();
  }

  function calculatePrice() {
    const totalValue = Number(sanitizePrice(product.price)) + complementAmount;
    return formatCurrency(totalValue * counter, currency);
  }

  function calculateComplementAmount(complements: ComplementOfProduct[]) {
    let computedOptions = [] as ComplementItemOption[];
    complements.forEach((complements) => {
      computedOptions = computedOptions.concat(
        complements.complementItemOptions
      );
    });

    let amount = 0;
    computedOptions
      .filter((item) => item.counter !== undefined && item.counter !== 0)
      .forEach((option) => (amount += option.price * option.counter));

    setComplementAmount(amount);
  }

  function handleComplementValueChanged(wrapper: ComplementWrapper) {
    const { complement, action } = wrapper;

    switch (action) {
      case ComplementAction.ADD:
      case ComplementAction.UPDATE:
        // Updating list of complements
        const index = selectedComplements.findIndex(
          (item) => item._id === complement._id
        );
        if (index !== -1) {
          selectedComplements[index] = complement;
        } else {
          selectedComplements.push(complement);
        }
        break;
      case ComplementAction.DELETE:
        const options = complement.complementItemOptions.filter(
          (option) => option.counter > 0
        );

        if (options.length === 0) {
          selectedComplements = selectedComplements.filter(
            (item) => item._id !== complement._id
          );
        }
        break;
    }

    // Check button availability
    if (requiredFieldsCount > 0) {
      const requiredComplementsFilled = selectedComplements.filter(
        (item) => item.isRequired && item.isFulfilledRequirements
      );
      setButtonDisabled(
        !(requiredComplementsFilled.length === requiredFieldsCount)
      );
    } else if (!isCountByProduct) {
      setButtonDisabled(selectedComplements.length === 0);
    }

    calculateComplementAmount(selectedComplements);
  }

  function checkStoreOpened() {
    if (!companyFeatures.isEstablishmentOpen) {
      return false;
    }

    return isOpenNow(companyFeatures.openingHours);
  }

  function handleProductSubmit() {
    if (!checkStoreOpened()) {
      setClosedStore(true);
      return;
    }

    product.counter = counter;
    product.extraNote = extraNote;
    product.selectedComplements = selectedComplements;

    let quantity = counter;
    const productsOnStore = ProductStore.findProducts(product._id);
    if (Boolean(productsOnStore.length)) {
      if (product.isUpdate) {
        quantity = productsOnStore.reduce((prevAmount, currentValue) => {
          if (currentValue.transientId === product.transientId) {
            return prevAmount + product.counter
          }
          return prevAmount + currentValue.counter
        }, 0);
      } else {
        quantity = productsOnStore.reduce((prevAmount, currentValue) => prevAmount + currentValue.counter, quantity);
      }
    }

    checkStock(
      [{
        id: product._id,
        quantity
      }], {
      onSuccess: (result: ProductOutOfStockResponse) => {
        if (!result.notAvailable) {
          setErrorMessage(t('stock.product_unavailable'));
          return;
        }

        if (!result.withoutStock) {
          let message = t('stock.product_unavailable_now')
          const { quantityAvailableToBuy } = result.data.productWithoutStock[0];
          if (quantityAvailableToBuy > 0) {
            message = t('stock.insufficient_quantity', { quantityAvailableToBuy });
          }

          setErrorMessage(message);
          return;
        }

        if (product.isUpdate) {
          ProductStore.updateProduct(product);
        } else {
          ProductStore.addProduct(product);
        }
        setOpen(false);
        onClose();
      },
      onError: () => {
        AlertNotification({
          message: t('stock.check_error'),
          type: NotificationType.ERROR,
        });
      },
    });
  }

  const canShowIncrementButton = () => {
    return (
      isCountByProduct ||
      (!isCountByProduct && product.complements?.length === 0)
    );
  };

  const body = (
    <Container>
      <ProductImage url={product.imageUrl} />

      <ProductDescription>
        <span>{product.description && parse(product.description)}</span>
      </ProductDescription>

      <ProductComplements
        hasSellViaWhatsappEnabled={hasSellViaWhatsappEnabled}
        product={product}
        currency={currency}
        onValueChanged={(complement) =>
          handleComplementValueChanged(complement)
        }
      />

      {hasSellViaWhatsappEnabled && (
        <>
          <OrderCommentLabel>{t('product_details.any_comment')}</OrderCommentLabel>
          <Input.TextArea
            placeholder={t('product_details.any_comment')}
            showCount
            rows={5}
            maxLength={140}
            value={extraNote}
            onChange={(e) => setExtraNote(e.target.value)}
          />
        </>
      )}

      {isClosedStore && (
        <ClosedStore
          companyFeatures={companyFeatures}
          dismissPage={() => setClosedStore(false)}
        />
      )}
    </Container>
  );

  const renderFooter = () => {
    if (!hasSellViaWhatsappEnabled) return null;

    return (
      <>
        {errorMessage && (
          <ErrorMessage>
            <p>{errorMessage}</p>
          </ErrorMessage>
        )}

        <FooterContainer>
          {canShowIncrementButton() && (
            <IncrementalButtons
              startCounter={counter}
              withBorder={true}
              onValueChanged={(value: number) => setCounter(value)}
            />
          )}

          <AddButtonHolder
            withIncrementButton={canShowIncrementButton()}
            onClick={handleProductSubmit}
            disabled={isButtonDisabled || isLoadingCheckStock}
          >
            <div>
              <span>{product.isUpdate ? t('common.update') : t('common.add')}</span>
              <span>{calculatePrice()}</span>
            </div>
          </AddButtonHolder>
        </FooterContainer>
      </>
    );
  }

  const renderTitle = () => {
    return (
      <Title>
        {product.name}
      </Title>
    );
  }

  const renderCloseButton = () => {
    return (
      <CloseButton
        aria-label="Close"
        className="close"
        data-dismiss="modal"
        type="button"
        onClick={handleClose}
      >
        <span aria-hidden={true} style={{ color: '#1A1919' }}>
          ×
        </span>
      </CloseButton>
    );
  }

  return (
    <Drawer
      destroyOnClose={true}
      footer={renderFooter()}
      height={isMobileDevice ? '100%' : undefined}
      maskClosable={false}
      onClose={handleClose}
      placement={isMobileDevice ? 'bottom' : 'right'}
      size="large"
      title={renderTitle()}
      open={open}
      closeIcon={null}
      extra={renderCloseButton()}
    >
      {body}
    </Drawer>
  );
};
