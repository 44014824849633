import React, { useMemo, useState } from "react";
import { Checkbox, Form } from "antd";
import { useTranslation } from "react-i18next";

import { FormInputField } from "@/components/FormInputField";
import { SellsConfiguration } from "@/models/SellsConfiguration";
import { useFormOrderInstance } from "../../../../hooks/useFormOrderContext";
import { validateCPF, validateNIF } from "@/utils/Util";
import { TaxDataAddress } from "./components/TaxDataAddress";
import { OrderSection } from "../../../OrderSection";
import { IdentificationErrorMessage } from "./styles";

interface TaxDataProps {
  canShowAddressFields: boolean;
  sellsConfiguration: SellsConfiguration;
  useSameAddress: boolean;
  phone: string | undefined;
  setUseSameAddress: (value: boolean) => void;
}

export const TaxData = ({
  canShowAddressFields,
  phone,
  sellsConfiguration,
  setUseSameAddress,
  useSameAddress,
}: TaxDataProps) => {

  const [isValidIdentification, setValidIdentification] = useState(true);
  const { isTaxDataRequired } = sellsConfiguration;
  const form = useFormOrderInstance();
  const { t } = useTranslation();

  const identificationMask = useMemo(() => {
    let idMask = '';
    let idLabel = 'ID';
    if (phone?.includes('+351')) {
      idMask = '999 999 999';
      idLabel = 'NIF';
    } else if (phone?.includes('+55')) {
      idMask = '999.999.999-99';
      idLabel = 'CPF';
    }

    return {
      idLabel,
      idMask,
    };
  }, [phone]);

  const validateIdentification = () => {
    if (!isTaxDataRequired) {
      setValidIdentification(true);
      return;
    }

    const identification = form.getFieldValue(['taxData', 'identification']);
    if (identification) {
      setValidIdentification(
        sellsConfiguration.currency === '€' // // TODO: https://github.com/uiltonjose/qrcodepreferido-app/issues/52
          ? validateNIF(identification)
          : validateCPF(identification)
      );
    }
  }
  return (
    <OrderSection title={t('order.identification.tax_data.title')} >
      <FormInputField
        label={identificationMask.idLabel}
        placeholder={identificationMask.idLabel}
        // mask={identificationMask.idMask} // TODO: add mask implementation
        namePath={['taxData', 'identification']}
        onBlur={validateIdentification}
        useDefaultRules={isTaxDataRequired}
      />
      {!isValidIdentification && (
        <IdentificationErrorMessage>
          {t('order.identification.tax_data.invalid_id_validation')}{' '}
          <i
            className="fa fa-times"
            aria-hidden="true"
            style={{ marginLeft: '2px' }}
          />
        </IdentificationErrorMessage>
      )}

      <FormInputField
        label={t('common.name')}
        placeholder={t('common.name')}
        maxLength={100}
        namePath={['taxData', 'name']}
        useDefaultRules={isTaxDataRequired}
      />

      {canShowAddressFields && (
        <Form.Item name="canUseSameAddress">
          <Checkbox
            id="canUseSameAddress"
            checked={useSameAddress}
            onChange={(e) => {
              const isChecked = e.target.checked;
              setUseSameAddress(isChecked);
              form.setFieldValue("canUseSameAddress", isChecked);
            }}
          >
            {t('order.identification.tax_data.use_same_address')}
          </Checkbox>
        </Form.Item>
      )}

      {!useSameAddress && <TaxDataAddress isTaxDataRequired={isTaxDataRequired} />}
    </OrderSection>
  );
};
