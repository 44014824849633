import React from 'react';
import { Table } from 'antd';
import {
  EyeOutlined,
  LinkOutlined,
  SlidersOutlined,
} from '@ant-design/icons';
import type { ColumnsType } from 'antd/lib/table';
import { Link } from 'react-router-dom';

import { Company } from '../../../../../models/Company';
import { formatDate } from '../../../../../utils/dateTime';
import { getClientMenuUrl } from '../../../../../utils/Util';
import { RoundTag } from '../../../../../common-styles';
import { TableContextMenu } from '../../../../../components/TableContextMenu';

interface CompanyTableProps {
  companies: Company[];
  loading: boolean;
};

const renderContextMenu = (company: Company) => [
  {
    label: (
      <Link to={`/sysadmin/companies/view/${company._id}`}>
        View
      </Link>
    ),
    key: 'view',
    icon: <EyeOutlined style={{ fontSize: '18px' }} />,
  },
  {
    label: (
      <div onClick={() => {
        const a = document.createElement('a');
        a.target = '_blank';
        a.href = getClientMenuUrl(company.menuUrl);
        a.click();
      }}>
        Link
      </div>
    ),
    key: 'link',
    icon: <LinkOutlined style={{ fontSize: '18px' }} />,
  },
  {
    label: (
      <Link to={`/sysadmin/companies/view/${company._id}/features`}>
        Features
      </Link>
    ),
    key: 'features',
    icon: <SlidersOutlined style={{ fontSize: '18px' }} />,
  },
];

export const CompanyTable = ({
  companies,
  loading,
}: CompanyTableProps) => {
  const columns: ColumnsType<Company> = [
    {
      title: 'Empresa',
      dataIndex: 'fantasyName',
      key: 'fantasyName',
      sorter: (a, b) => a.fantasyName.localeCompare(b.fantasyName),
      sortDirections: ["descend", "ascend"],
    },
    {
      title: 'Módulos',
      key: 'modules',
      render: (_: any, company: Company) => {
        const features = company.features[0];
        if (!features) return null;

        return <ul style={{ listStyleType: 'none' }} key={company._id}>
          {features.sellViaWhatsapp && <li><i className="fa fa-whatsapp" aria-hidden="true" /> - WhatsApp</li>}
          {features.menuOptionsEnabled && <li><i className="fa fa-window-restore" aria-hidden="true" /> - Multiplas abas</li>}
          {features.multiLanguage?.isEnabled && <li><i className="fa fa-language" aria-hidden="true" /> - Multilinguas</li>}
          {features.easypay?.isEnabled && <li><i className="fa fa-credit-card" aria-hidden="true" /> - Easypay</li>}
        </ul>
      },
    },
    {
      title: 'Centralizador?',
      sorter: (a, b) => a.isCentralizer.toString().localeCompare(b.isCentralizer.toString()),
      sortDirections: ["descend", "ascend"],
      render: (_: any, company: Company) => {
        const color = company.isCentralizer ? 'blue' : 'orange';
        return (
          <RoundTag color={color} key={company._id}>
            {company.isCentralizer ? 'Sim' : 'Não'}
          </RoundTag>
        );
      },
    },
    {
      title: 'Empresa associada',
      render: (_: any, company: Company) => company.companyParentAssociatedInfo[0]?.fantasyName
    },
    {
      title: 'Código de acesso',
      dataIndex: 'accessCode',
    },
    {
      title: 'Data de adesão',
      sorter: (a, b) => a.createdAt.localeCompare(b.createdAt),
      sortDirections: ["descend", "ascend"],
      render: (_: any, company: Company) => formatDate(company.createdAt)
    },
    {
      title: 'Responsável',
      dataIndex: 'responsible',
      key: 'responsible',
    },
    {
      align: 'center',
      fixed: 'right',
      width: 150,
      render: (_: any, company: Company) => (
        <TableContextMenu
          isButtonType
          primaryOption={<Link to={`/sysadmin/companies/edit/${company._id}`}>Editar</Link>}
          menuProps={{ items: renderContextMenu(company) }}
        />
      ),
    },
  ];

  return (
    <Table
      bordered
      columns={columns}
      dataSource={[...companies]}
      loading={loading}
      rowKey={(c: Company) => c._id}
      scroll={{ x: 1300 }}
      pagination={false}
    />
  );
};
