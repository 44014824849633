import React, { useEffect, useMemo, useState } from 'react';
import { Form, Input, Select } from 'antd';
import { CaretDownOutlined } from '@ant-design/icons';
import { isEmpty } from 'lodash';
import { useDebounceValue } from '@/hooks/useDebounceValue';
import { ContainerFilters, ContentFilters } from '../../../../../common-styles';
import { Category } from '@/models/Category';
import { Product } from '../../../../../models/Product';
import { Features } from '../../../../../models/Features';
import { useListCompaniesForCustomers, useLoggedUser } from '../../../../../hooks';
import { Role } from '../../../../../models/Enum';
import { ALL_TABS, DEFAULT_SELECT_COMPANY } from '../../../../../utils/Constants';

interface ProductFiltersProps {
  categories: Category[];
  companyId: string;
  features?: Features;
  onCategoryListChanged: (categories: Category[]) => void;
  onCompanySelect: (companyId: string) => void;
  products: Product[];
  setSearchInputFilter: (searchInput: string) => void;
}

const ALL_CATEGORY = 'Todas as categorias';

export const ProductFilters = ({
  categories,
  companyId,
  features,
  onCategoryListChanged,
  onCompanySelect,
  products,
  setSearchInputFilter,
}: ProductFiltersProps) => {
  const [selectedTabName, setSelectedTabName] = useState(ALL_TABS);
  const [selectedCategoryId, setSelectedCategoryId] = useState(ALL_CATEGORY);

  const [search, setSearch] = useState('');
  const debouncedSearch = useDebounceValue(search);

  const { data: companies } = useListCompaniesForCustomers();
  const { userLogged: { user: { role, companyAssociation } } } = useLoggedUser();

  useEffect(() => {
    setSearchInputFilter(debouncedSearch);
  }, [debouncedSearch, setSearchInputFilter]);

  const companiesName = useMemo(() => {
    let names;

    if (role === Role.KITCHEN && !isEmpty(companyAssociation)) {
      names = companyAssociation.map((company) => {
        return {
          value: company.companyId,
          label: company.companyName,
        };
      });
    } else {
      names = companies?.map((company) => {
        return {
          value: company._id,
          label: company.fantasyName,
        };
      }) ?? [];
    }

    names.sort((a, b) => a.label.localeCompare(b.label)).unshift({
      value: '',
      label: DEFAULT_SELECT_COMPANY,
    });
    return names;
  }, [companies, companyAssociation, role]);

  const categoriesName = useMemo(() => {
    const names = categories?.filter((item) => item.isEnabled && item.isSectionType)
      .map((category) => {
        return {
          value: category._id,
          label: category.category,
        };
      });
    names?.unshift({
      value: ALL_CATEGORY,
      label: ALL_CATEGORY,
    });
    return names;
  }, [categories]);

  const tabsName = useMemo(() => {
    if (features?.menuOptions) {
      const names =
        features?.menuOptions.map((menuOption) => {
          return {
            value: menuOption._id,
            label: menuOption.name,
          };
        }) || [];
      names.unshift({
        value: ALL_TABS,
        label: ALL_TABS,
      });
      return names;
    }
    return [];
  }, [features]);

  const handleSelectedCategoryName = (categoryId: string) => {
    setSelectedCategoryId(categoryId);

    if (categoryId === ALL_CATEGORY) {
      onCategoryListChanged(categories);
      return;
    }

    const filtered = categories.filter(
      (category) => category._id === categoryId
    );

    onCategoryListChanged(filtered);
  }

  const handleTabSelection = (tab: string) => {
    setSelectedTabName(tab);

    if (tab === ALL_TABS) {
      onCategoryListChanged(categories);
      return;
    }

    const filteredCategories = categories.filter((item) => {
      return item.tabsAssociated.includes(tab);
    });

    onCategoryListChanged(filteredCategories);
  };

  return (
    <ContainerFilters>
      <Form
        layout="vertical"
        style={{ marginTop: '16px' }}
      >
        <ContentFilters>
          {role !== Role.USER && (
            <Form.Item label={<label className="label-input-field">Empresas</label>}>
              <Select
                defaultValue={companyId}
                onChange={onCompanySelect}
                suffixIcon={<CaretDownOutlined />}
              >
                {companiesName?.map((company) => (
                  <Select.Option
                    key={company.value}
                    value={company.value}
                  >
                    {company.label}
                  </Select.Option>
                ))}
              </Select>
            </Form.Item>
          )}

          <Form.Item label={<label className="label-input-field">Pesquisar</label>}>
            <Input.Search
              allowClear
              defaultValue={search}
              onChange={(event) => setSearch(event.target.value)}
              onSearch={setSearch}
              placeholder="Pesquise por qualquer entrada"
              style={{ height: '40px' }}
            />
          </Form.Item>

          <Form.Item label={<label className="label-input-field">Filtrar por categoria</label>}>
            <Select
              defaultValue={selectedCategoryId}
              onChange={handleSelectedCategoryName}
              suffixIcon={<CaretDownOutlined />}
            >
              {categoriesName.map((category) => (
                <Select.Option
                  key={category.value}
                  value={category.value}
                >
                  {category.label}
                </Select.Option>
              ))}
            </Select>
          </Form.Item>

          {features?.menuOptions !== undefined &&
            features?.menuOptions.length > 0 && (
              <Form.Item label={<label className="label-input-field">Filtrar por Aba</label>}>
                <Select
                  defaultValue={selectedTabName}
                  onChange={handleTabSelection}
                  suffixIcon={<CaretDownOutlined />}
                >
                  {tabsName.map((tab) => (
                    <Select.Option key={tab.value} value={tab.value}>
                      {tab.label}
                    </Select.Option>
                  ))}
                </Select>
              </Form.Item>
            )}

        </ContentFilters>
      </Form>
    </ContainerFilters>
  );
}