import { useCallback } from "react";
import { useMutation } from "react-query";
import { PaymentService } from "../components/ResumeOrderDrawer/services/EasypayService";
import { EasypayPayment } from "../components/ResumeOrderDrawer/models/EasypayPayment";
import { LocalStorageService, PAYMENT_EASYPAY } from "../../../../services/LocalStorageService";

export enum EasyPayPaymentType {
  MBWAY = 'mbw',
  CREDIT_CARD = 'cc',
}

export const useEasypayPayment = () => {
  const { mutate, isLoading } = useMutation(
    (request: EasypayPayment) => PaymentService.payment(request)
  );

  const submitPayment = useCallback((paymentRequest, { onSuccess, onError }) => {
    mutate(paymentRequest, {
      onSuccess: (response) => {
        if (response.status === 'ok') {
          LocalStorageService.save(PAYMENT_EASYPAY, response);
          onSuccess(response);
        } else {
          onError();
        }
      },
      onError,
    });
  }, [mutate]
  );

  return {
    isLoading,
    submitPayment,
  };
}