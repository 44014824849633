import { useCallback } from 'react';
import { useMutation, useQueryClient } from 'react-query';
import { CategoryService } from '../../../../services/CategoryService';
import { CATEGORIES } from '../../../../hooks/useCategories';
import { Category } from '@/models/Category';

export const useSaveCategory = () => {
  const queryClient = useQueryClient();

  const { mutate: mutateCreate, isLoading: isLoadingCreate } = useMutation(
    (category: Category) => CategoryService.create(category)
  );

  const { mutate: mutateUpdate, isLoading: isLoadingUpdate } = useMutation(
    (category: Category) => CategoryService.update(category)
  );

  const { mutate: mutateListPositionUpdate, isLoading: isLoadingListPositionUpdate } = useMutation(
    (categories: Category[]) => CategoryService.updateListPositions(categories)
  );

  const create = useCallback((category, { onSuccess, onError }) => {
    mutateCreate(category, {
      onSuccess: (newCategory) => {
        queryClient.invalidateQueries(CATEGORIES);
        onSuccess(newCategory._id);
      },
      onError,
    });
  }, [mutateCreate, queryClient]);

  const update = useCallback((category, { onSuccess, onError }) => {
    mutateUpdate(category, {
      onSuccess: () => {
        queryClient.invalidateQueries(CATEGORIES);
        onSuccess();
      },
      onError,
    });
  }, [mutateUpdate, queryClient]);

  const updateListPosition = useCallback((categories, { onSuccess, onError }) => {
    mutateListPositionUpdate(categories, {
      onSuccess: () => {
        queryClient.invalidateQueries(CATEGORIES);
        onSuccess();
      },
      onError,
    });
  }, [mutateListPositionUpdate, queryClient]
  );

  return {
    isLoadingCreate,
    create,
    isLoadingUpdate,
    update,
    isLoadingListPositionUpdate,
    updateListPosition,
  };
};
