import React, { useState } from 'react';

import { ComplementItemOption } from '../../../../../../models/ProductCatalog';
import { ComplementAction } from '../../../../../../models/Enum';
import formatCurrency from '../../../../../../utils/formatCurrency';
import { IncrementalButtons } from '../IncrementalButtons';

import {
  ColorItem,
  ComplementContentRow,
  ItemContainer,
  Separator,
  TitleHolder,
} from './style';

interface ComplementItemOptionsMultipleChoicesProps {
  currency: string;
  maxAllowed: number;
  hasSellViaWhatsappEnabled: boolean;
  options: ComplementItemOption[];
  onSelectedOptions(action: ComplementAction): void;
};

export const ComplementItemOptionsMultipleChoices =
  ({
    options,
    currency,
    maxAllowed,
    hasSellViaWhatsappEnabled,
    onSelectedOptions,
  }: ComplementItemOptionsMultipleChoicesProps) => {
    const [itemsCounter, setItemsCounter] = useState(getItemsCounter());

    function onValueChanged(value: number, item: ComplementItemOption) {
      let action = ComplementAction.DELETE;
      if (value !== 0) {
        action = ComplementAction.ADD;
      }
      item.counter = value;

      setItemsCounter(getItemsCounter());
      onSelectedOptions(action);
    }

    function getItemsCounter(): number {
      let counter = 0;
      options.forEach((option) => (counter += option.counter));
      return counter;
    }

    return (
      <ul>
        {options.map((item) => {
          const counter = item.counter !== undefined ? item.counter : 0;

          return (
            <li key={item._id} style={{ listStyle: 'none' }}>
              <ComplementContentRow
                hasExtraProps={
                  (item.description !== undefined && item.description !== '') ||
                  item.price > 0
                }
              >
                <ItemContainer>
                  <TitleHolder>
                    {item.color !== undefined && item.color !== '' && (
                      <ColorItem color={item.color} />
                    )}
                    <p className="item-title-container">{item.title}</p>
                  </TitleHolder>

                  {item.description !== undefined &&
                    item.description !== '' && (
                      <p className="item-description-container">
                        {item.description}
                      </p>
                    )}
                  {item.price > 0 && (
                    <span>{formatCurrency(item.price, currency)}</span>
                  )}
                </ItemContainer>

                {hasSellViaWhatsappEnabled && (
                  <IncrementalButtons
                    startCounter={counter}
                    maxAllowed={maxAllowed}
                    disabledIncreaseButton={maxAllowed === itemsCounter}
                    onValueChanged={(value) => onValueChanged(value, item)}
                  />
                )}
              </ComplementContentRow>

              <Separator />
            </li>
          );
        })}
      </ul>
    );
  };
