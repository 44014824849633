import { useCallback } from 'react';
import { useMutation, useQueryClient } from 'react-query';
import { CompanyService } from '../pages/backoffice/Companies/services/CompanyService';
import { Company } from '../models/Company';
import { ACTIVE_COMPANIES } from '../pages/backoffice/Companies/hooks/useCompanies';

export const useCompany = () => {
  const queryClient = useQueryClient();

  const { mutate: mutateCreate, isLoading: isLoadingCreate } = useMutation(
    (company: Company | any) => CompanyService.create(company)
  );

  const { mutate: mutateUpdate, isLoading: isLoadingUpdate } = useMutation(
    (company: Company) => CompanyService.update(company)
  );

  const create = useCallback(({ company, onSuccess, onError }) => {
    mutateCreate(company, {
      onSuccess: (newCompany) => {
        queryClient.invalidateQueries(ACTIVE_COMPANIES);
        onSuccess(newCompany);
      },
      onError,
    });
  }, [mutateCreate, queryClient]
  );

  const update = useCallback(
    ({ company, onSuccess, onError }) => {
      mutateUpdate(company, {
        onSuccess: () => {
          queryClient.invalidateQueries(ACTIVE_COMPANIES);
          onSuccess();
        },
        onError,
      });
    },
    [mutateUpdate, queryClient]
  );

  return {
    create,
    isLoadingCreate,
    isLoadingUpdate,
    update,
  };
};
