import React from 'react';
import { DeleteFilled, EditFilled } from '@ant-design/icons';
import { List, Space } from 'antd';
import { confirmAlert } from 'react-confirm-alert';

import { OpeningHours } from '../../../../../../../../models/DataResponse';
import { WeekdaysList } from '../../../../../../../../utils/Constants';
import { FormLabelField } from '@/components/FormLabelField';

interface WorkScheduleListProps {
  isSellsEnabled: boolean;
  items: OpeningHours[];
  deleteAction(id?: string): void;
  editAction(openingHour: OpeningHours): void;
};

export const WorkScheduleList = ({
  isSellsEnabled,
  items,
  deleteAction,
  editAction,
}: WorkScheduleListProps) => {
  function handleDeleteAction(openHour: OpeningHours) {
    confirmAlert({
      title: 'Atenção',
      message: `Tem certeza que deseja excluir o horário ${getWeekdays(openHour.weekdays)}?`,
      buttons: [
        {
          label: 'Sim',
          onClick: () => deleteAction(openHour?._id),
        },
        {
          label: 'Não',
          onClick: () => { },
        },
      ],
    });
  }

  function handleEditAction(openHour: OpeningHours) {
    editAction(openHour);
  }

  function getWeekdays(weekdays: number[]) {
    return weekdays.map((day) => WeekdaysList[day].substring(0, 3)).join(', ');
  }

  const getHoursFormatted = (start: string, end: string) => `${start} - ${end}`;

  return (
    <Space direction="vertical" style={{ marginTop: '20px' }}>
      {isSellsEnabled && <FormLabelField label="Horários" />}
      <List
        size="small"
        dataSource={items}
        footer={<small>Os dias que não possuirem horário, irão ser automaticamente considerados como dias fechados.</small>}
        style={{ marginTop: '20px' }}
        locale={{ emptyText: 'Nenhum horário cadastrado' }}
        renderItem={(item, index) => (
          <List.Item
            actions={[
              <EditFilled
                onClick={() => handleEditAction(item)}
                style={{ cursor: 'pointer' }}
              />,
              <DeleteFilled
                onClick={() => handleDeleteAction(item)}
                style={{ cursor: 'pointer', marginLeft: '10px' }}
              />
            ]}
          >
            <List.Item.Meta
              style={{ padding: '10px' }}
              title={getWeekdays(item.weekdays)}
              description={
                getHoursFormatted(
                  item.startHour,
                  item.endHour
                )
              }
            >
              {item}
            </List.Item.Meta>
          </List.Item>
        )}
      />
    </Space>
  );
};
