import React from 'react';
import { BoldLabel, BoldValue, Container, LabelContainer, NormalLabel, NormalValue } from './styles';
import { InfoTooltip } from '../../../../../../../../../components/InfoTooltip';

interface LabelAndPriceProps {
  isBold?: boolean;
  label: string;
  price: string;
  tooltip?: string;
}

export const LabelAndPrice = ({ isBold, label, price, tooltip }: LabelAndPriceProps) => (
  <Container>
    <LabelContainer>
      {isBold ? <BoldLabel>{label}</BoldLabel> : <NormalLabel>{label}</NormalLabel>}
      {tooltip && <InfoTooltip message={tooltip} />}
    </LabelContainer>

    {isBold ? <BoldValue>{price}</BoldValue> : <NormalValue>{price}</NormalValue>}
  </Container>
);