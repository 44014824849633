import { useGetClientCurrentLanguage } from '../../../../hooks/useGetClientCurrentLanguage';

export const useLanguageLabels = (availableLangsKeys: string[]) => {
  const { currentLanguage } = useGetClientCurrentLanguage();

  const translations = {
    en: [{ label: 'Portuguese', key: 'pt' }, { label: 'Spanish', key: 'es' }, { label: 'German', key: 'de' }, { label: 'French', key: 'fr' }, { label: 'Italian', key: 'it' }],
    pt: [{ label: 'Inglês', key: 'en' }, { label: 'Espanhol', key: 'es' }, { label: 'Alemão', key: 'de' }, { label: 'Francês', key: 'fr' }, { label: 'Italiano', key: 'it' }],
    es: [{ label: 'Portugués', key: 'pt' }, { label: 'Inglés', key: 'en' }, { label: 'Alemán', key: 'de' }, { label: 'Francés', key: 'fr' }, { label: 'Italiano', key: 'it' }],
    de: [{ label: 'Portugiesisch', key: 'pt' }, { label: 'Englisch', key: 'en' }, { label: 'Spanisch', key: 'es' }, { label: 'Französisch', key: 'fr' }, { label: 'Italienisch', key: 'it' }],
    fr: [{ label: 'Portugais', key: 'pt' }, { label: 'Anglais', key: 'en' }, { label: 'Espagnol', key: 'es' }, { label: 'Allemand', key: 'de' }, { label: 'Italien', key: 'it' }],
    it: [{ label: 'Portoghese', key: 'pt' }, { label: 'Inglese', key: 'en' }, { label: 'Spagnolo', key: 'es' }, { label: 'Tedesco', key: 'de' }, { label: 'Francese', key: 'fr' }],
  };

  return translations[currentLanguage as keyof typeof translations]?.filter((lang) => availableLangsKeys.includes(lang.key));
};
