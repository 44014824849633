import React from "react";
import { Checkbox } from "antd";
import { Language } from "@/models/Languages";
import { Container, Label } from "../LanguageList/styles";

interface ChooseDefaultLanguageProps {
  languages: Language[];
  onChange: (langs: Language[]) => void;
}

export const ChooseDefaultLanguage = ({ languages, onChange }: ChooseDefaultLanguageProps) => {
  const onDefaultChange = (lang: Language) => {
    const updatedLanguages = languages.map((l) => ({
      ...l,
      default: l.key === lang.key,
    }));

    onChange(updatedLanguages);
  };

  return (
    <Container>
      {languages?.map((lang) => (
        <div key={lang.key}>
          <Checkbox
            checked={languages.some(selected => selected.key === lang.key && selected.default)}
            onChange={() => onDefaultChange(lang)}
            value={lang}
          >
            <Label>{lang.label}</Label>
          </Checkbox>
        </div>
      ))}
    </Container>
  );
};
