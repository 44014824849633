import React, { useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { ModalHeadLess } from '../../../../../components/ModalHeadless';
import { CompanyFeatures } from '../../../../../models/CompanyFeatures';
import { getClosedReason } from '../../ScheduleUtils';
import { Container, Title, Subtitle } from './styles';
import { Button } from 'antd';

interface ClosedStoreProps {
  companyFeatures: CompanyFeatures;
  dismissPage: () => void;
};

export const ClosedStore = ({ companyFeatures, dismissPage }: ClosedStoreProps) => {
  const [isOpen, setOpen] = useState(true);
  const { t } = useTranslation();
  const { openingHours, isEstablishmentOpen } = companyFeatures

  const handleClosePage = () => {
    setOpen(false);
    dismissPage();
  };

  const titlePage = useMemo(() => {
    const closedReason = getClosedReason(openingHours);

    if (!isEstablishmentOpen || closedReason === null) {
      return t('store.closed');
    } else {
      return t('store.take_order_from', { closedReason });
    }
  }, [openingHours, isEstablishmentOpen, t]);

  return (
    <ModalHeadLess isOpen={isOpen} toggle={handleClosePage} bgModal={'white'}>
      <Container>
        <Title>{titlePage}</Title>
        <Subtitle>
          {t('store.continue_checking_products')}
        </Subtitle>

        <Button type="primary" onClick={handleClosePage} style={{ width: '100%' }}>
          {t('common.ok')}
        </Button>
      </Container>
    </ModalHeadLess>
  );
};

