import React, { useState } from 'react';

import { Button, Divider, Form, Input, Space } from 'antd';

import { DisabledFormInput } from '../../../../../../components/DisabledFormInput';

import api from '../../../../../../services/api';
import { FormLayout } from '../../../../../../utils/Styles';
import { useLoggedUser } from '../../../../../../hooks';
import { translateRole } from '../../../../../../utils/RoleUtil';
import { AlertNotification } from '@/components/AlertNotification';
import { NotificationType } from '@/components/AlertNotification/NotificationType';

const MIN_PASSWORD_LENGTH = 6;

export const Profile = () => {
  const [isLoading, setLoading] = useState<boolean>(false);
  const [showChangePassword, setShowChangePassword] = useState<boolean>(false);

  const { userLogged: { user: { name, email, role } } } = useLoggedUser();

  const failedOnValidation = (message: string) => {
    AlertNotification({
      message,
      type: NotificationType.ERROR,
    });
    setLoading(false);
  };

  const updatePassword = async (formData: any) => {
    setLoading(true);

    if (formData.password.length < MIN_PASSWORD_LENGTH) {
      failedOnValidation('A nova senha deve ter no mínimo 6 caracteres');
      return;
    }

    if (formData.password !== formData.confirmPassword) {
      failedOnValidation(
        'A nova senha e a confirmação de senha, não coincidem'
      );
      return;
    }

    try {
      const body = {
        currentPassword: formData.oldPassword,
        newPassword: formData.password,
      };

      await api.put('api/auth/updatepassword', body);

      AlertNotification({
        message: 'Senha atualizada com sucesso.',
      });

      setShowChangePassword(false);
    } catch (e) {
      AlertNotification({
        message:
          'Erro ao tentar atualizar a senha. Certifique-se que a sua senha atual está correta.',
        type: NotificationType.ERROR,
      });
    } finally {
      setLoading(false);
    }
  };

  return (
    <Form
      layout="vertical"
      {...FormLayout}
      onFinish={(values) => updatePassword(values)}
    >
      <DisabledFormInput label="Email" defaultValue={email} />

      <DisabledFormInput label="Nome" defaultValue={name} />

      <DisabledFormInput label="Perfíl" defaultValue={translateRole(role).label} />

      {!showChangePassword && (
        <Button type="primary" onClick={() => setShowChangePassword(true)}>
          Alterar Senha
        </Button>
      )}

      {showChangePassword && (
        <>
          <Divider orientation="left">Alterar Senha</Divider>

          <Form.Item
            label={<label className="label-input-field">Senha anterior</label>}
            name="oldPassword"
            rules={[
              {
                required: true,
                message: 'Campo obrigatório',
              },
            ]}
          >
            <Input.Password placeholder="Senha anterior" style={{ height: '40px' }} />
          </Form.Item>

          <Form.Item
            label={<label className="label-input-field">Nova senha</label>}
            name="password"
            help="No mínimo 6 digitos"
            rules={[
              {
                required: true,
                message: 'Campo obrigatório',
              },
            ]}
          >
            <Input.Password placeholder="Nova senha" style={{ height: '40px' }} />
          </Form.Item>

          <Form.Item
            label={<label className="label-input-field">Confirmar senha</label>}
            name="confirmPassword"
            rules={[
              {
                required: true,
                message: 'Campo obrigatório',
              },
            ]}
            style={{ marginTop: '16px' }}
          >
            <Input.Password placeholder="Confirmar senha" style={{ height: '40px' }} />
          </Form.Item>

          <Space>
            <Button danger onClick={() => setShowChangePassword(false)}>
              <i
                className="fa fa-times"
                aria-hidden="true"
                style={{ marginRight: '10px' }}
              />
              Cancelar
            </Button>

            <Button type="primary" htmlType="submit" loading={isLoading}>
              <i
                className="fa fa-check-circle"
                aria-hidden="true"
                style={{ marginRight: '10px' }}
              />
              Salvar
            </Button>
          </Space>
        </>
      )}
    </Form>
  );
};
