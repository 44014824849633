import React from 'react';
import { useTranslation } from 'react-i18next';
import { ProductMenu } from '../../../../../../../models/ProductCatalog';
import { SellsConfiguration } from '../../../../../../../models/SellsConfiguration';
import { DeliveryFee } from '../../../../../../../models/DeliveryFee';

import formatCurrency from '../../../../../../../utils/formatCurrency';

import { CouponCode } from './components/CouponCode';
import { useProductCost } from '../../../../../../../hooks';
import { LabelAndPrice } from './components/LabelAndPrice';
import { Coupon } from '@/models/Coupon';
import { ProductsChosen } from './components/ProductsChosen';

interface OrderCostProps {
  coupon?: Coupon;
  deliveryFee?: DeliveryFee;
  handleEditAction: (isUpdate: boolean, product: ProductMenu) => void;
  hideTotal?: boolean;
  sellsConfiguration: SellsConfiguration;
};

export const OrderCost = ({
  coupon,
  deliveryFee,
  handleEditAction,
  hideTotal,
  sellsConfiguration,
}: OrderCostProps) => {
  const { t } = useTranslation();
  const { currency, orderComplementExhibition, extraFees } = sellsConfiguration;
  const { subtotal, subtotalFormatted, totalCostFormatted } = useProductCost({
    currency,
    coupon,
    deliveryFee,
    extraFees,
  });

  return (
    <>
      <ProductsChosen
        currency={currency}
        orderComplementExhibition={orderComplementExhibition}
        handleEditAction={handleEditAction}
      />

      {deliveryFee ? (
        <>
          <LabelAndPrice
            label={t('order.cost.subtotal')}
            price={subtotalFormatted}
          />

          {coupon && (
            <CouponCode
              coupon={coupon}
              subtotal={subtotal}
              currency={currency}
            />
          )}

          <LabelAndPrice
            label={t('order.cost.delivery_fee')}
            price={formatCurrency(deliveryFee?.price ?? 0, currency)}
          />
        </>
      ) : (
        <>
          {coupon && (
            <>
              <LabelAndPrice
                label={t('order.cost.subtotal')}
                price={subtotalFormatted}
              />

              <CouponCode
                coupon={coupon}
                subtotal={subtotal}
                currency={currency}
              />
            </>
          )}
        </>
      )}

      {!hideTotal && extraFees.filter(item => item.isEnabled).map((extraFee) => (
        <LabelAndPrice
          key={extraFee._id}
          label={extraFee.label}
          price={formatCurrency(extraFee.value, currency)}
          tooltip={extraFee.tooltip}
        />
      ))}

      <LabelAndPrice
        isBold
        label={hideTotal ? t('order.cost.subtotal') : t('order.cost.total')}
        price={hideTotal ? subtotalFormatted : totalCostFormatted}
      />
    </>
  );
};
