import { AlertNotification } from "@/components/AlertNotification";
import { NotificationType } from "@/components/AlertNotification/NotificationType";
import { useCompanyFeatures, useLoggedUser, useUpdateCompanyFeatures } from "../../../../../../hooks";
import { SellsConfiguration } from "../../../../../../models/SellsConfiguration";

export const useUpdateSettingsCentralizer = () => {
  const { userLogged } = useLoggedUser();
  const { user: { company } } = userLogged;

  const { companyFeatures } = useCompanyFeatures(company);
  const { update } = useUpdateCompanyFeatures();

  const updateCompanySettingsCentralizer = (sellsConfiguration: SellsConfiguration) => {
    update(
      { ...companyFeatures, sellsConfiguration },
      {
        onSuccess: () => AlertNotification({
          message: 'Atualizado com sucesso.',
        }),
        onError: () => AlertNotification({
          message: 'Erro ao tentar atualizar.',
          type: NotificationType.ERROR,
        })
      },
    );
  }

  return { updateCompanySettingsCentralizer };
}