import styled from 'styled-components';

export const Container = styled.div`
  background-color: #ebebed;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: start;
  width: 100%;
  padding: 14px 14px 0;
  margin: 16px 0;

  > h2 {
    font-size: 1.25rem;      
    font-weight: 600;        
    color: #1f2937;        
  }

  > p {
    font-size: 0.875rem; 
    color: #4b5563;     
  }
`;
