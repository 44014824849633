import React, { useEffect, useMemo, useState } from 'react';
import { Button, Drawer, Form, Input } from 'antd';
import { ComplementItemOption } from '../../../../../../../../../models/ProductCatalog';
import { PREFIX_LOCALSTORAGE } from '../../../../../../../../../utils/Constants';
import { FormInputField } from '../../../../../../../../../components/FormInputField';
import { InputTitleAndDescription } from '@/components/InputTitleAndDescription';
import { Features } from '@/models/Features';
import { FormSwitchItem } from '@/components/FormSwitchItem';

interface ComplementItemProps {
  features: Features | undefined;
  complementItemOption?: ComplementItemOption;
  isVisible: boolean;
  handleItem(values: any): void;
  onClose(): void;
};

const DESCRIPTION_CHARACTER_LIMIT = 100;
const TITLE_CHARACTER_LIMIT = 45;

export const ComplementItem = ({
  features,
  complementItemOption,
  isVisible,
  handleItem,
  onClose,
}: ComplementItemProps) => {
  const [isEnabled, setIsEnabled] = useState<boolean>(complementItemOption?.isEnabled ?? true);
  const [color, setColor] = useState('');

  const currency = useMemo(() => {
    // TODO: https://github.com/uiltonjose/qrcodepreferido-app/issues/52
    return localStorage.getItem(`${PREFIX_LOCALSTORAGE}:currency`)!!;
  }, []);

  useEffect(() => {
    if (
      complementItemOption?.color !== undefined &&
      complementItemOption?.color !== ''
    ) {
      setColor(complementItemOption!!.color);
    }
  }, [complementItemOption]);

  const onFinish = (values: any) => {
    setColor('');
    handleItem(values);
  };

  return (
    <Drawer
      title={complementItemOption ? 'Atualizar item' : 'Novo item'}
      onClose={onClose}
      destroyOnClose={true}
      maskClosable={false}
      placement="right"
      open={isVisible}
    >
      <Form
        layout="vertical"
        initialValues={complementItemOption}
        onFinish={onFinish}
      >
        <FormSwitchItem
          label="Status"
          propertyId={["isEnabled"]}
          defaultChecked={isEnabled}
          checkedValueText={'Ativo'}
          uncheckedValueText={'Pausado'}
          handleCheckboxChange={setIsEnabled}
        />

        <InputTitleAndDescription
          features={features}
          titleMaxLength={TITLE_CHARACTER_LIMIT}
          descriptionMaxLength={DESCRIPTION_CHARACTER_LIMIT}
        />

        <FormInputField
          label="Preço"
          name="price"
          prefix={currency}
          placeholder="Preço"
          type="number"
        />

        <Form.Item
          label={
            <label className="label-input-field">Cor (em Hexadecimal)</label>
          }
          name="color"
        >
          <div style={{ display: 'flex' }}>
            <Input
              id="color"
              name="color"
              addonBefore="#"
              placeholder="Ex: #ee0000"
              value={color}
              onChange={(e) => setColor(e.target.value)}
              style={{ width: '150px', height: '40px' }}
            />
            <div
              style={{
                width: '30px',
                height: '32px',
                marginLeft: '4px',
                backgroundColor: `#${color}`,
              }}
            />
          </div>
        </Form.Item>

        <Button type="primary" htmlType="submit">
          <i
            className="fa fa-check-circle"
            aria-hidden="true"
            style={{ marginRight: '10px' }}
          />{' '}
          Salvar
        </Button>
      </Form>
    </Drawer>
  );
};
