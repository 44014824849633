import React from "react";
import moment from "moment";
import { Form, TimePicker } from "antd";
import { FormLabelField } from "../FormLabelField";
import { TIME_FORMAT } from "@/utils/dateTime";

interface TimePickersProps {
  id: string;
  defaultValue: string;
  label: string;
  isRequired?: boolean;
  handleTimeChange: (value: moment.Moment | null, dateString: string) => void;
}

export const TimePickers = ({
  id,
  label,
  defaultValue,
  isRequired,
  handleTimeChange,
}: TimePickersProps) => (
  <Form.Item
    name={id}
    label={<FormLabelField label={label} />}
    rules={[
      {
        required: isRequired,
        message: 'Campo obrigatório',
      },
    ]}>
    <TimePicker
      format={TIME_FORMAT}
      defaultValue={moment(defaultValue, TIME_FORMAT)}
      onChange={(value, dateString) => handleTimeChange(value, dateString)}
    />
  </Form.Item>
);
