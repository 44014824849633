import React from "react";
import { Form, Radio } from "antd";
import { LayoutType } from "../../../../../models/Enum";
import { FormSubtitle } from "../../../../../common-styles";
import { FormInputField } from "../../../../../components/FormInputField";
import { CoverDimens } from "./components/CoverDimens";
import { LogoDimens } from "./components/LogoDimens";
import { FormLabelField } from "../../../../../components/FormLabelField";

const LAYOUT_TYPES = [
  { label: 'Cardápio', value: LayoutType.MENU },
  { label: 'Catálogo', value: LayoutType.CATALOG },
];

export const StylesSetup = () => {
  return (
    <>
      <FormSubtitle>Layout</FormSubtitle>
      <Form.Item
        label={
          <FormLabelField label="Tipos" />
        }
        name={['layout', 'type']}
        rules={[
          {
            required: true,
            message: 'Escolha o tipo de layout',
          },
        ]}
      >
        <Radio.Group
          options={LAYOUT_TYPES}
          optionType="button"
          buttonStyle="solid"
          size="large"
        />
      </Form.Item>

      <FormInputField
        label="Cor do título"
        namePath={['styles', 'titleColor']}
        help="Ao deixar o campo vazio, será utilizado a cor padrão do menu. Para uma cor customizada, utilizar a cor em formato hexadecimal, ex: #DF0101"
      />

      <FormInputField
        label="Cor do subtítulo"
        namePath={['styles', 'subTitleColor']}
        help="Ao deixar o campo vazio, será utilizado a cor do título."
        style={{ marginTop: 30 }}
      />

      <CoverDimens />

      <LogoDimens />
    </>
  );
}
