import styled from 'styled-components';

export const ResumeOrderContainer = styled.div`
  padding: 10px;
  justify-content: start;
  margin-left: 20px;
  margin-right: 20px;
  margin-top: 40px;

  background-color: white;

  border: 1px solid #eeee;
  border-radius: 20px;
`;

export const SummaryLabel = styled.p`
  font-size: 22px;
  font-weight: bold;
  padding-top: 10px;
  padding-bottom: 15px;
  color: #3f3e3e;
  text-align: center;
`;