import styled from 'styled-components';
import { ImageDimension } from '../../../models/DataResponse';

interface DimensProps {
  dimens?: ImageDimension;
}

interface SelectedItemSeparatorProps {
  color: string;
}

interface SeeMoreButtonProps {
  color: string;
}

export const MainContainer = styled.main`
  box-shadow: 0 16px 16px -8px rgba(0, 0, 0, 0.2);
  text-align: left;
  max-width: 550px;
  padding-bottom: 40px;
  background-color: #fff;

  .header {
    position: relative;
  }
`;

export const LogoContent = styled.img<DimensProps>`
  height: ${(props) => (props.dimens?.height ? props.dimens?.height : '180px')};
  width: ${(props) => (props.dimens?.width ? props.dimens?.width : '80%')};
  object-fit: ${(props) =>
    props.dimens?.objectFit ? props.dimens?.objectFit : 'scale-down'};

  @media (max-width: 500px) {
    height: ${(props) =>
    props.dimens?.mobileHeight ? props.dimens?.mobileHeight : '20vh'};
    width: ${(props) => props.dimens?.mobileWidth};
    object-fit: ${(props) => props.dimens?.mobileObjectFit};
  }
`;

export const CoverContent = styled.img<DimensProps>`
  height: ${(props) => (props.dimens?.height ? props.dimens?.height : '20vh')};
  width: ${(props) => (props.dimens?.width ? props.dimens?.width : '100%')};
  object-fit: ${(props) =>
    props.dimens?.objectFit ? props.dimens?.objectFit : 'cover'};

  @media (max-width: 500px) {
    height: ${(props) =>
    props.dimens?.mobileHeight ? props.dimens?.mobileHeight : '30vh'};
    width: ${(props) => props.dimens?.mobileWidth};
    object-fit: ${(props) => props.dimens?.mobileObjectFit};
  }
`;

export const CatalogContainer = styled.div`
  justify-content: center;
  padding-left: 12px;
  padding-right: 12px;

  display: grid;
  grid-template-columns: 1fr 1fr;
  grid-auto-rows: minmax(250px, auto);
  grid-gap: 10px;

  @media (max-width: 500px) {
    margin-top: 10px;
    margin-left: 4px;
    margin-right: 4px;
    grid-template-columns: 50% 50%;
    grid-gap: 6px;
  }
`;

export const MenuContainer = styled.div`
  padding-left: 12px;
  padding-right: 12px;

  @media (max-width: 500px) {
    margin-top: 10px;
  }
`;

// Menu Options (For Tabs)
export const MenuOptionsContainer = styled.div`
  background-color: white;
  width: 100%;
  height: 60px;
  display: flex;
  overflow: auto;
  white-space: nowrap;
  margin-top: 25px;
  margin-bottom: 25px;
  z-index: 0;

  > div span {
    display: inline-block;
    color: white;
    text-align: center;
    padding: 14px;
    text-decoration: none;
  }

  @media (max-width: 500px) {
    width: 400px;
  }

  @media (max-width: 400px) {
    width: 400px;
  }

  @media (max-width: 320px) {
    width: 320px;
  }
`;

export const MenuOptionsItemsContainer = styled.div`
  display: flex;
  text-align: center;
  justify-content: center;
  align-items: center;
  margin: 0 auto;
  gap: 12px;
`;

export const MenuLabelOptionsContainer = styled.div`
  overflow: hidden;
  
  display: flex;
  flex-direction: column;

  > a {
    padding: 14px;
  }
`;

export const SelectedItemSeparator = styled.hr<SelectedItemSeparatorProps>`
  color: ${(props) => props.color};
  border-top: 4px solid;
  border-radius: 5px;
`;

export const SeeMoreButton = styled.div<SeeMoreButtonProps>`
  margin-left: auto;

  cursor: pointer;
  color: ${(props) => props.color};
  font-size: 14px;
`;

export const ScheduleAndFeedbackContainer = styled.div`
  display: flex;
  padding: 0 20px;
  margin-top: 20px;
`;
