import React from 'react';
import { Popconfirm, Table } from 'antd';
import { DeleteFilled as DeleteIcon, EditFilled as EditIcon } from '@ant-design/icons';
import { MenuTabOption } from '../../../../../../../../../../models/DataResponse';
import { WeekdaysList } from '../../../../../../../../../../utils/Constants';
import { useDeleteMenuOption } from '../../../../../hooks/useDeleteMenuOption';
import { RoundTag } from '@/common-styles';
import { AlertNotification } from '@/components/AlertNotification';
import { NotificationType } from '@/components/AlertNotification/NotificationType';

interface MenuOptionsTableProps {
  handleEditMenuOption: (option: MenuTabOption) => void;
  menuOptions: MenuTabOption[];
}

export const MenuOptionsTable = ({ menuOptions, handleEditMenuOption }: MenuOptionsTableProps) => {
  const { deleteOption } = useDeleteMenuOption();

  const handleDeleteOption = (id: string) => {
    deleteOption(id, {
      onSuccess: () => AlertNotification({
        message: 'Operação realizada com sucesso',
      }),
      onError: () => AlertNotification({
        message: 'Erro ao tentar excluir opção.',
        type: NotificationType.ERROR,
      }),
    });
  };

  const getWeekdays = (weekDays: string) => {
    if (!weekDays || weekDays === 'everyday') {
      return 'Todos os dias';
    }

    return weekDays
      .split('|')
      .filter((day) => !isNaN(Number(day)))
      .map((day) => WeekdaysList[Number(day)])
      .join(', ');
  };

  const getHours = (option: MenuTabOption) => `${option.startHour} às ${option.endHour}`;

  return (
    <Table
      bordered
      dataSource={menuOptions}
      pagination={false}
      rowKey={(option: MenuTabOption) => option._id}
    >
      <Table.Column
        title="Status"
        dataIndex="isEnabled"
        width={80}
        render={(isEnabled: boolean) => {
          const color = isEnabled ? 'green' : 'orange';
          const label = isEnabled ? 'Ativo' : 'Pausado';

          return (
            <RoundTag color={color}>
              {label}
            </RoundTag>
          );
        }}
      />
      <Table.Column title="Nome" dataIndex="name" />
      <Table.Column
        title="Horários"
        render={(option: MenuTabOption) => {
          return option.allDay ? <RoundTag>Dia inteiro</RoundTag> : getHours(option);
        }}
      />
      <Table.Column
        title="Dias"
        dataIndex="weekdays"
        render={(weekdays: string) => getWeekdays(weekdays)}
      />
      <Table.Column
        title="Vendas"
        dataIndex="isSellViaWhatsappEnabled"
        render={(isSellViaWhatsappEnabled: boolean) => {
          const color = isSellViaWhatsappEnabled ? 'green' : 'orange';
          const label = isSellViaWhatsappEnabled ? 'Ativo' : 'Pausado';

          return (
            <RoundTag color={color}>
              {label}
            </RoundTag>
          );
        }}
      />
      <Table.Column
        title="Ações"
        render={(_: any, option: MenuTabOption) => {
          return (
            <>
              <EditIcon
                onClick={() => handleEditMenuOption(option)}
                style={{ cursor: 'pointer' }}
              />
              <Popconfirm
                placement="bottomRight"
                title={`Tem certeza que deseja excluir o menu ${option.name}?`}
                onConfirm={() => handleDeleteOption(option._id)}
                okText="Sim"
                cancelText="Não"
              >
                <DeleteIcon
                  onClick={() => { }}
                  style={{ cursor: 'pointer', marginLeft: '10px' }}
                />
              </Popconfirm>
            </>
          );
        }}
      />
    </Table>
  );
};
