import React from "react";
import { LabelText } from "./styles";

interface FormLabelFieldProps {
  label?: string | React.ReactNode;
  style?: React.CSSProperties;
}

export const FormLabelField = ({ label, style }: FormLabelFieldProps) => (
  <LabelText style={style}>{label}</LabelText>
);