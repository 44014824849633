import { Space } from 'antd';
import styled from 'styled-components';

export const Content = styled.div``;

export const FooterActionButtonsContainer = styled(Space)`
  margin-top: 50px;
  width: 100%;
  justify-content: end;
`;
