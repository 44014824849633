import Big from 'big.js';

import { Coupon } from "../models/Coupon";
import { ComplementItemOption, ProductMenu } from "../models/ProductCatalog";
import { DeliveryFee } from "../models/DeliveryFee";
import { ExtraFee } from "../models/SellsConfiguration";
import formatCurrency from "../utils/formatCurrency";
import { sanitizePrice } from "../utils/priceUtil";
import { ProductStore } from "./ProductStore/productsStore";

export interface UseProductCostProps {
  coupon?: Coupon;
  currency?: string;
  deliveryFee?: DeliveryFee;
  extraFees?: ExtraFee[];
}

export const calculateProductsTotalCost = (products: ProductMenu[]): number => {
  let amount = 0;

  products.forEach((product) => {
    let computedOptions = [] as ComplementItemOption[];
    let complementAmount = 0;

    product.complements?.forEach((complement) =>
      complement.complementItemOptions.forEach((option) =>
        computedOptions.push(option)
      )
    );

    computedOptions
      .filter(
        (product) => product.counter !== undefined && product.counter !== 0
      )
      .forEach(
        (option) => (complementAmount += option.price * option.counter)
      );

    let productPrice = product.price;

    if (
      !productPrice ||
      productPrice === undefined ||
      isNaN(Number(sanitizePrice(productPrice)))
    ) {
      productPrice = '0';
    }

    const unitPrice = Number(sanitizePrice(productPrice)) + complementAmount;
    amount += unitPrice * product.counter;
  });

  return amount;
}

export const calculateProductCost = ({ coupon, currency, deliveryFee, extraFees }: UseProductCostProps) => {
  const products = ProductStore.products;
  const subtotalBig = new Big(calculateProductsTotalCost(products));
  let totalValueBig = subtotalBig;

  if (coupon) {
    const isPercentage = Number(coupon.discountType) === 0;
    if (isPercentage) {
      const discount = totalValueBig.times(coupon.discountValue).div(100);
      totalValueBig = totalValueBig.minus(discount);
    } else {
      totalValueBig = totalValueBig.minus(coupon.discountValue);
    }
  }

  if (deliveryFee && deliveryFee.price) {
    totalValueBig = totalValueBig.plus(deliveryFee.price);
  }

  if (extraFees) {
    const fees = extraFees.filter(item => item.isEnabled)
      .reduce((prevAmount, currentValue) =>
        new Big(prevAmount).plus(currentValue.value),
        new Big(0));
    totalValueBig = totalValueBig.plus(fees);
  }

  const subtotal = parseFloat(subtotalBig.toString());
  const total = parseFloat(totalValueBig.toString());
  return {
    subtotal,
    subtotalFormatted: currency ? formatCurrency(subtotal, currency) : '',
    total,
    totalCostFormatted: currency ? formatCurrency(total, currency) : '',
  };
};

export const useProductCost = ({ coupon, currency, deliveryFee, extraFees }: UseProductCostProps) => calculateProductCost({ coupon, currency, deliveryFee, extraFees });
