import React from "react";
import { Form, Select } from "antd";
import { CaretDownOutlined } from '@ant-design/icons';
import { useListCompaniesForCustomers } from "../../hooks";
import { DEFAULT_SELECT_COMPANY } from "../../utils/Constants";
import { FormLabelField } from "../FormLabelField";

interface CompanySelectionProps {
  centered?: boolean;
  companyId?: string | null;
  companyIdToBeIgnored?: string;
  help?: string;
  inputLabel?: string;
  onCompanySelect: (companyId: string) => void;
}

export const CompanySelection = ({
  centered,
  companyId,
  companyIdToBeIgnored,
  help,
  inputLabel,
  onCompanySelect
}: CompanySelectionProps) => {
  const { data: companies } = useListCompaniesForCustomers();

  return (
    <Form
      layout="vertical"
      style={{ margin: centered ? 'auto' : '0', paddingTop: '16px', width: '40%' }}
    >
      <Form.Item
        label={<FormLabelField label={inputLabel ?? 'Empresas'} />}
        help={help}
      >
        <Select
          defaultValue={companyId ?? ''}
          value={companyId ?? ''}
          onChange={onCompanySelect}
          showSearch
          suffixIcon={<CaretDownOutlined />}
        >
          <Select.Option value="" disabled>
            {DEFAULT_SELECT_COMPANY}
          </Select.Option>

          {companies?.filter(company => company._id !== companyIdToBeIgnored).map((company) => (
            <Select.Option
              key={company._id}
              value={company._id}
            >
              {company.fantasyName}
            </Select.Option>
          ))}
        </Select>
      </Form.Item>
    </Form>
  );
}