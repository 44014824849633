
import styled from 'styled-components';

export const ClientFareFooterContent = styled.div`
  display: flex;
  justify-content: space-between;

  #moreDetails {
    cursor: pointer;
    text-decoration: underline;
    color: #6c6c80;
    font-weight: 500;
    font-size: 14px;
  }
`;
