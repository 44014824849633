import styled from 'styled-components';

export const RulesInfo = styled.div`
  display: flex;
  flex-direction: column;
  align-items: start;

  background-color: #f5f5f5;
  border-radius: 10px;

  padding: 14px 20px;
  margin: 30px 0;

  p {
    color: black;
    font-weight: bold;
    font-size: 16px;
  }

  li {
    margin-left: 20px;
    counter-increment: li;
    text-align: start;
  }
`;
