import React from 'react';
import { Table } from 'antd';
import parse from 'html-react-parser';
import { confirmAlert } from 'react-confirm-alert';
import { RoundTag } from '../../../../../common-styles';
import { User } from '../../../../../models/User';
import { formatDatetime } from '../../../../../utils/dateTime';
import { translateRole } from '../../../../../utils/RoleUtil';
import { ActionType, Role } from '../../../../../models/Enum';
import { useLoggedUser } from '../../../../../hooks';
import { useResendInvitation } from '../../hooks/useResendInvitation';
import { AlertNotification } from '@/components/AlertNotification';
import { TableContextMenu } from '@/components/TableContextMenu';
import { NotificationType } from '@/components/AlertNotification/NotificationType';

interface UserTableProps {
  isLoading: boolean;
  onRowSelected(action: ActionType, user: User): void;
  users: User[];
};

export const UserTable = ({ isLoading, users, onRowSelected }: UserTableProps) => {
  const { userLogged: { user: { _id: userId, role } } } = useLoggedUser();
  const { sendInvite } = useResendInvitation();

  const handleEditUser = (user: User) => {
    if (user._id === userId) {
      AlertNotification({
        message: 'Você não pode editar seu próprio perfil.',
        type: NotificationType.WARNING,
      });
      return;
    }

    onRowSelected(ActionType.UPDATE, user);
  }

  const handleRemoveUser = (user: User) => {
    confirmAlert({
      title: 'Atenção',
      message: `Tem certeza que deseja remover o usuário ${user.name}? \n Uma vez removido, esta ação não poderá ser revertida.`,
      buttons: [
        {
          label: 'Cancelar',
          onClick: () => { },
        },
        {
          label: 'Confirmar',
          onClick: () => onRowSelected(ActionType.DELETE, user),
        },
      ],
    });
  };

  const renderContextMenu = (user: User) => [
    {
      label: (
        <span onClick={() => onRowSelected(ActionType.OPEN, user)}>
          Visualizar
        </span>
      ),
      key: 'view',
      isEnabled: true,
    },
    {
      label: <span onClick={() => handleRemoveUser(user)}>Remover</span>,
      key: 'remove',
      danger: true,
      isEnabled: user._id !== userId,
    },
    {
      label: (
        <span onClick={() => sendInvite(user._id, {
          onSuccess: () => {
            AlertNotification({
              message: 'Convite reenviado com sucesso',
            });
          },
          onError: () => {
            AlertNotification({
              message: 'Atenção',
              description: 'Erro ao tentar reenviar convite.',
              type: NotificationType.ERROR,
            });
          }
        })}>
          Reenviar convite
        </span>
      ),
      key: 'resend',
      isEnabled: user.isPendingConfirmation,
    },
  ].filter(item => item.isEnabled);

  return (
    <Table
      bordered
      dataSource={[...users]}
      loading={isLoading}
      scroll={{ x: 1300 }}
      rowKey={(user: User) => user._id}
      style={{ paddingBottom: '40px' }}
    >
      <Table.Column
        title="Status"
        dataIndex="isActive"
        sortDirections={["descend", "ascend"]}
        sorter={(a: User, b: User) => b.isActive.toString().localeCompare(a.isActive.toString())}
        render={(isActive) => {
          const color = isActive ? 'green' : 'red';
          return (
            <RoundTag color={color}>
              {isActive ? 'Ativo' : 'Inativo'}
            </RoundTag>
          );
        }}
      />
      <Table.Column
        title="Perfíl"
        dataIndex="role"
        sortDirections={["descend", "ascend"]}
        sorter={(a: User, b: User) => translateRole(a.role).label.localeCompare(translateRole(b.role).label)}
        render={(role) => {
          const { color, label } = translateRole(role);
          return (
            <RoundTag color={color}>
              {label}
            </RoundTag>
          );
        }}
      />
      <Table.Column
        title="Nome"
        dataIndex="name"
        sortDirections={["descend", "ascend"]}
        sorter={(a: User, b: User) => a.name.localeCompare(b.name)}
      />
      <Table.Column
        title="Email"
        dataIndex="email"
        sortDirections={["descend", "ascend"]}
        sorter={(a: User, b: User) => a.email.localeCompare(b.email)}
      />
      {role !== Role.USER && (
        <Table.Column
          title="Empresa"
          dataIndex="companyName"
          sortDirections={["descend", "ascend"]}
          sorter={(a: User, b: User) => a.companyName.localeCompare(b.email)}
        />
      )}
      {role !== Role.USER && (
        <Table.Column
          title="Estabelecimentos associados"
          render={(user: User) => user.companyAssociation.map(company => {
            let { companyName } = company;
            if (companyName.includes(' - ')) {
              const [first, second] = companyName.split('-');
              companyName = `${first} <br/> ${second}`
            }
            return (
              <RoundTag color={'gray'} key={companyName}>
                {parse(companyName)}
              </RoundTag>
            );
          })}
        />
      )}
      <Table.Column
        title="Data de criação"
        dataIndex="createdAt"
        sortDirections={["descend", "ascend"]}
        sorter={(a: User, b: User) => a.createdAt.localeCompare(b.createdAt)}
        render={(createdAt: string) => formatDatetime(createdAt)}
      />
      <Table.Column
        title="Últ. acesso"
        dataIndex="lastAccess"
        sortDirections={["descend", "ascend"]}
        sorter={(a: User, b: User) => {
          if (!a.lastAccess && !b.lastAccess) return 0;
          if (!a.lastAccess) return -1;
          if (!b.lastAccess) return 1;
          return a.lastAccess.localeCompare(b.lastAccess);
        }}
        render={(lastAccess: string) => lastAccess && formatDatetime(lastAccess)}
      />
      <Table.Column
        title="Email confirmado"
        dataIndex="isPendingConfirmation"
        sortDirections={["descend", "ascend"]}
        sorter={(a: User, b: User) => b.isPendingConfirmation.toString().localeCompare(a.isPendingConfirmation.toString())}
        render={(isPendingConfirmation) => {
          const color = isPendingConfirmation ? 'orange' : 'green';
          return (
            <RoundTag color={color}>
              {isPendingConfirmation ? 'Pendente' : 'Confirmado'}
            </RoundTag>
          );
        }}
      />
      <Table.Column
        width={150}
        align="center"
        render={(user: User) => (
          <TableContextMenu
            isButtonType
            handlePrimaryClick={() => handleEditUser(user)}
            primaryOption="Editar"
            menuProps={{ items: renderContextMenu(user) }}
          />
        )}
      />
    </Table>
  );
};
